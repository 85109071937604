import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup, Table, DropdownButton, Dropdown} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
//const jsPDF = require('jspdf');
import jsPDF from 'jspdf';
import {Link, Route} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
const currentDate = new Date();







class Send_Email_Request extends PureComponent {
    
  constructor() {
    super();
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      scores_id: [], //this displays ID number for the scores saved for each clinician        
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual
        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to
      patient_phone_number_to_send_score: '', //this is the variable where clinicians enter the phone number of a patient they want to send a score to
      patient_name_to_send_score: '', //this is the variable where clinicians enter the given name of a patient they want to send a score to, which makes the email more personable
      clinician_name_to_send_score: '', //this is the variable where clinicians enter their own given name when they send  score to a patient, which makes the email more personable 
        
      chosen_score: 'aclrsi', //this is the variable for the drop down menu which allows clinicians to choose which score link they would like to send
      chosen_score_translation: 'ACL Return to Sport after Injury (ACL-RSI)', //this is the variable that takes the value from the drop down menu which allows clinicians to choose which score link they would like to send, and makes it something more appropriate for the example email        
        
    //Calculator variables    
        selectedValueS1: 0,
        selectedValueS1_text: 'How much does illness affect your life: ',

        selectedValueS2: 0,
        selectedValueS2_text: 'How long do you think your illness will continue: ',

        selectedValueS3: 10,
        selectedValueS3_text: 'How much control do you feel you have over your illness: ',

        selectedValueS4: 10,
        selectedValueS4_text: 'How much do you think your treatment can help: ',

        selectedValueS5: 0,
        selectedValueS5_text: 'How much do you experience symptoms from your illness: ',

        selectedValueS6: 0,
        selectedValueS6_text: 'How concerned are you about your illness: ',

        selectedValueS7: 10,
        selectedValueS7_text: 'How well do you feel you understand your illness: ',

        selectedValueS8: 0,
        selectedValueS8_text: 'How much does your illness affect you emotionally: ',
      
        first_cause: '', //free text responses for this score
        second_cause: '', //free text responses for this score
        third_cause: '', //free text responses for this score  
                   
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.modifyData = this.modifyData.bind(this);
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
      
  this.handleSelect = this.handleSelect.bind(this);
      
     
      
  }
    
componentDidMount() {
    
    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  BIPQScoresRef.collection("bipq_scores").get().then(querySnapshot =>{
      
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id //this part is a weird flaw in the system where querySnapshot doesn't actually pull the doc ID, so you have to do it seperately and then combined the document data with each doc ID
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  })
              
        
}  else {
    
  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  BIPQScoresRef.collection("bipq_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  BIPQScoresRef.collection("bipq_scores").get().then(querySnapshot =>{
      

    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
                       
        
} else {
    

    
  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  BIPQScoresRef.collection("bipq_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value.replace('"','')
  });
} 
    
handleSelect(e) {
    this.setState({ chosen_score: e.target.value });
    if (e.target.value == "aclrsi"){
        this.setState({chosen_score_translation: "ACL Return to Sport after Injury (ACL-RSI)"})
    }
    if (e.target.value == "aofash"){
        this.setState({chosen_score_translation: "AOFAS Hallux MTP IP (AOFASH)"})
    }
    if (e.target.value == "bipq"){
        this.setState({chosen_score_translation: "Brief Illness Perception Questionnaire (BIPQ)"})
    }
    if (e.target.value == "dhi"){
        this.setState({chosen_score_translation: "Dizziness Handicap Inventory (DHI)"})
    }
    if (e.target.value == "faam"){
        this.setState({chosen_score_translation: "Foot and Ankle Ability Measure (FAAM)"})
    }
    if (e.target.value == "hoos12"){
        this.setState({chosen_score_translation: "Hip disability and Osteoarthritis Outcome Score 12 (HOOS-12)"})
    }
    if (e.target.value == "koos12"){
        this.setState({chosen_score_translation: "Knee injury and Osteoarthritis Outcome Score 12 (KOOS-12)"})
    }
    if (e.target.value == "lefs"){
        this.setState({chosen_score_translation: "Lower Extremity Functional Scale (LEFS)"})
    }
    if (e.target.value == "lefs_spanish"){
        this.setState({chosen_score_translation: "Escala Funcional De Extremidades Inferiores (LEFS Spanish)"})
    }
    if (e.target.value == "ndi"){
        this.setState({chosen_score_translation: "Neck Disability Index"})
    }
    if (e.target.value == "ndi_spanish"){
        this.setState({chosen_score_translation: "El Índice de Discapacidad Cervical (NDI Spanish)"})
    }
    if (e.target.value == "ndi_italian"){
        this.setState({chosen_score_translation: "Indice Di Disabilità Del Collo (NDI Italian)"})
    }    
    if (e.target.value == "odi"){
        this.setState({chosen_score_translation: "Oswestry Disability Index (ODI)"})
    }
    if (e.target.value == "quickdash"){
        this.setState({chosen_score_translation: "QuickDASH"})
    }
    if (e.target.value == "quickdash_spanish"){
        this.setState({chosen_score_translation: "QuickDASH Espanol"})
    }
    if (e.target.value == "sf12"){
        this.setState({chosen_score_translation: "12 Item Short Form Survey (SF-12)"})
    }
    if (e.target.value == "sf36"){
        this.setState({chosen_score_translation: "36 Item Short Form Survey (SF-36)"})
    }
    if (e.target.value == "spadi"){
        this.setState({chosen_score_translation: "Shouler Pain and Disability Index (SPADI)"})
    }
    if (e.target.value == "spadi_spanish"){
        this.setState({chosen_score_translation: "Indice de Dolor y Discapacidad del Hombro (SPADI Spanish)"})
    }
    if (e.target.value == "tsk"){
        this.setState({chosen_score_translation: "Tampa Scale for Kinesiophobia (TSK)"})
    }    
    if (e.target.value == "visaa"){
        this.setState({chosen_score_translation: "Victorian Institute of Sports Assessment - Achilles (VISA-A)"})
    }
    if (e.target.value == "visag"){
        this.setState({chosen_score_translation: "Victorian Institute of Sports Assessment - Greater Trochanter (VISA-G)"})
    }
    if (e.target.value == "visah"){
        this.setState({chosen_score_translation: "Victorian Institute of Sports Assessment - Proximal Hamstring Tendon (VISA-H)"})
    }
    if (e.target.value == "visap"){
        this.setState({chosen_score_translation: "Victorian Institute of Sports Assessment - Patellar Tendon (VISA-P)"})
    }
    if (e.target.value == "vas"){
        this.setState({chosen_score_translation: "Visual Analogue Scale (VAS)"})
    }    
    if (e.target.value == "whoqolbref"){
        this.setState({chosen_score_translation: "World Health Organization Quality of Life Abbreviated (WHOQOL-BREF)"})
    }        
  }   

    
 
handleSubmit(e) {
  e.preventDefault();  
  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });  
  const bipq_score = BIPQScoresRef.collection("bipq_scores").add({
    shared_with: this.state.clinician_to_share_with,
    user: this.state.user_id,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Brief Illness Perception Questionnaire',
    Question_01_BIPQ: this.state.selectedValueS1,
    Question_02_BIPQ: this.state.selectedValueS2,
    Question_03_BIPQ: this.state.selectedValueS3,
    Question_04_BIPQ: this.state.selectedValueS4,
    Question_05_BIPQ: this.state.selectedValueS5,
    Question_06_BIPQ: this.state.selectedValueS6,
    Question_07_BIPQ: this.state.selectedValueS7,            
    Question_08_BIPQ: this.state.selectedValueS8,
    First_Cause_BIPQ: this.state.first_cause,
    Second_Cause_BIPQ: this.state.second_cause,
    Third_Cause_BIPQ: this.state.third_cause,      
    Total_Score_BIPQ:((this.state.selectedValueS1+this.state.selectedValueS2+(10-this.state.selectedValueS3)+(10-this.state.selectedValueS4)+this.state.selectedValueS5+this.state.selectedValueS6+(10-this.state.selectedValueS7)+this.state.selectedValueS8)),         
  });
  this.setState({
    clinician_to_share_with: '',
    user_id: '',      
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.state.clinician_to_share_with + '.');   
    
} 
    
modifyData(score_id) {  
  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.collection("bipq_scores").doc(String(score_id)).update({
    shared_with: "None"    
  });  
    alert('Score deleted.');     
    
} 
    
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection(this.state.chosen_score+"_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent_email: this.state.user.email,       
    patient_name_to_send_score: this.state.patient_name_to_send_score,
    clinician_who_sent_name: this.state.clinician_name_to_send_score,        
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',
    clinician_who_sent: '',
    patient_name_to_send_score: '',
    clinician_name_to_send_score: '',  
  });
    if (this.state.patient_email_to_send_score !== ''){
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');
    }
    else {
    alert('Thank you for using OrthoPowerTools! Your invitation was not sent because you did not enter the patients email address. To send the invitation, please enter the patients email address at the top of the page in the section that states "Enter the patients email address here"');        
    }       
    
}     
    
 
    
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallbackS1  = (dataFromChild) => {
    this.setState({selectedValueS1: dataFromChild});
    }
    myCallbackS2  = (dataFromChild) => {
    this.setState({selectedValueS2: dataFromChild});
    }
    myCallbackS3  = (dataFromChild) => {
    this.setState({selectedValueS3: dataFromChild});
    }
    myCallbackS4  = (dataFromChild) => {
    this.setState({selectedValueS4: dataFromChild});
    }
    myCallbackS5  = (dataFromChild) => {
    this.setState({selectedValueS5: dataFromChild});
    }
    myCallbackS6  = (dataFromChild) => {
    this.setState({selectedValueS6: dataFromChild});
    }
    myCallbackS7  = (dataFromChild) => {
    this.setState({selectedValueS7: dataFromChild});
    }
    myCallbackS8  = (dataFromChild) => {
    this.setState({selectedValueS8: dataFromChild});
    }  
    myCallbackDropdown = (dataFromChild) => {
    this.setState({chosen_score: dataFromChild});
    }
  
    
    

    
render() {
    
    let {chosen_score_translation} = this.state;
        
    const spanish_score = () => {
         if (chosen_score_translation == "Escala Funcional De Extremidades Inferiores (LEFS Spanish)" | chosen_score_translation =="El Índice de Discapacidad Cervical (NDI Spanish)" | chosen_score_translation == "QuickDASH Espanol" | chosen_score_translation == "Indice de Dolor y Discapacidad del Hombro (SPADI Spanish)"){
            return <span>
                      
            <p><h4>Email text:</h4></p>
            <p><h5>To: {this.state.patient_email_to_send_score}</h5></p>
            <p><h5>From: orthopowertools@gmail.com</h5></p>
            <p><h5>Subject: {this.state.chosen_score_translation} Solicitada Por El Proveedor</h5></p>
      
      
            <p><h5>Hola {this.state.patient_name_to_send_score},<br/><br/>Uno de sus proveedores de atención médica, {this.state.clinician_name_to_send_score}, le ha pedido que complete una encuesta de {this.state.chosen_score_translation}. La información de esta encuesta es confidencial y solo se compartirá con su proveedor. <br/><br/>Haga clic <u> aquí </u> para completar la encuesta.<br/><br/>Atentamente,<br/>OrthoPowerTools </h5></p>        
      
      
            <p><h4>English Translation:</h4></p></span>                
                
                
                
            }
        }
    
    const italian_score = () => {
         if (chosen_score_translation =="Indice Di Disabilità Del Collo (NDI Italian)"){
            return <span>
                      
            <p><h4>Email text:</h4></p>
            <p><h5>To: {this.state.patient_email_to_send_score}</h5></p>
            <p><h5>From: orthopowertools@gmail.com</h5></p>
            <p><h5>Subject: {this.state.chosen_score_translation} Solicitada Por El Proveedor</h5></p>
      
      
            <p><h5>Ciao {this.state.patient_name_to_send_score},<br/><br/>Uno dei tuoi fornitori di assistenza sanitaria, {this.state.clinician_name_to_send_score}, ti ha chiesto di completare un sondaggio {this.state.chosen_score_translation}. Le informazioni in questo sondaggio sono riservate e verranno condivise solo con il tuo provider. <br/><br/>Fai clic <u>qui</u> per completare il sondaggio.<br/><br/>Cordiali saluti,<br/>OrthoPowerTools </h5></p>        
      
      
            <p><h4>English Translation:</h4></p></span>                
                
                
                
            }
        }    
        
        
    
    if (this.state.user === null){
        return(
            
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>                 
        <Link to="/" style={{padding:15}}>Home</Link>            
        </div>
      </header>     

      <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
        <h1>Welcome to OrthoPowerTools, a project of <a href="https://orthotoolkit.com/">OrthoToolKit!</a></h1>

        <p>This initiative strives to improve the way in which people understand and communicate their orthopaedic health by providing all the features of OrthoToolKit as well as an <b>easy-to-use system</b> for clinicians to collect, store, and analyze common outcome measures from their patients.</p>
      
      <br/>
      
        
      <p>To learn more, please visit our <Link to="/">home page</Link></p>
      

 
      

      </div>
    </div>  
            
            
            
        );
    }
    
  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>                 
        <Link to="/" style={{padding:15}}>Home</Link>            
        </div>
      </header>  
      

            <div style={{marginLeft:50, marginRight:50}}>
            <br/>
            <h2>Send an Email with Unique Score Link To Your Patients</h2>
            <br/>
            <form onSubmit={this.handleSubmitSendInvite}>
      
      
            <h4>Please select the score you would like to send to your patient and then fill in the below three text inputs. When you click the 'Send Score Invite' button, an email will be sent to your patient that contains a unique link to the chosen score. Remind them to check their spam folder if they do not receive it shortly in their primary inbox (email will originate from orthopowertools@gmail.com). When the patient finishes the survey and clicks submit, it will automatically be shared with you. An example email is listed below.</h4>
      
      
              <h4>Choose a score:</h4>                    

                <select name="scores" id="scores" value={this.state.chosen_score} onChange={this.handleSelect}>
                  <option value="aclrsi">ACL Return to Sport after Injury (ACL-RSI)</option>                       
                  <option value="aofash">AOFAS Hallux MTP IP (AOFASH)</option>        
                  <option value="bipq">Brief Illness Perception Questionnaire (BIPQ)</option> 
                  <option value="dhi">Dizziness Handicap Inventory (DHI)</option> 
                  <option value="faam">Foot and Ankle Ability Measure (FAAM)</option>        
                  <option value="hoos12">Hip disability and Osteoarthritis Outcome Score 12 (HOOS-12)</option>
                  <option value="koos12">Knee injury and Osteoarthritis Outcome Score 12 (KOOS-12)</option>
                  <option value="lefs">Lower Extremity Functional Scale (LEFS)</option>
                  <option value="lefs_spanish">Escala Funcional De Extremidades Inferiores (LEFS Spanish)</option>    
                  <option value="ndi">Neck Disability Index</option>    
                  <option value="ndi_spanish">El Índice de Discapacidad Cervical (NDI Spanish)</option>
                  <option value="ndi_italian">Indice Di Disabilità Del Collo (NDI Italian)</option>
                  <option value="odi">Oswestry Disability Index (ODI)</option>    
                  <option value="quickdash">QuickDASH</option>
                  <option value="quickdash_spanish">QuickDASH Espanol</option>    
                  <option value="sf12">12 Item Short Form Survey (SF-12)</option>    
                  <option value="sf36">36 Item Short Form Survey (SF-36)</option> 
                  <option value="spadi">Shouler Pain and Disability Index (SPADI)</option>
                  <option value="spadi_spanish">Indice de Dolor y Discapacidad del Hombro (SPADI Spanish)</option>    
                  <option value="tsk">Tampa Scale for Kinesiophobia (TSK)</option> 
                  <option value="vas">Visual Analogue Scale (VAS)</option>    
                  <option value="visaa">Victorian Institute of Sports Assessment - Achilles (VISA-A)</option>    
                  <option value="visag">Victorian Institute of Sports Assessment - Greater Trochanter (VISA-G)</option>  
                  <option value="visah">Victorian Institute of Sports Assessment - Proximal Hamstring Tendon (VISA-H)</option>
                  <option value="visap">Victorian Institute of Sports Assessment - Patellar Tendon (VISA-P)</option>
                  <option value="whoqolbref">World Health Organization Quality of Life Abbreviated (WHOQOL-BREF)</option>   
                </select>
      
      
              <h4>Patient email address:</h4>                    
              <input type="text" name="patient_email_to_send_score" placeholder="Enter patient's email address here" onChange={this.handleChange} value={this.state.patient_email_to_send_score} /> 
      
              <h4>Patient given name:</h4>                          
              <input type="text" name="patient_name_to_send_score" placeholder="Enter patient's given name here (how name will be displayed on saved score)" onChange={this.handleChange} value={this.state.patient_name_to_send_score} /> 
      
              <h4>Your given name:</h4>                                
              <input type="text" name="clinician_name_to_send_score" placeholder="Enter the your name here (as you would like it to appear in the score invitation)" onChange={this.handleChange} value={this.state.clinician_name_to_send_score} />       
              <br />
      
          <button style = {{width: '100%'}}>Send Score Invite</button>       
      

            {spanish_score()}
            {italian_score()}

            <p><h5>To: {this.state.patient_email_to_send_score}</h5></p>
            <p><h5>From: orthopowertools@gmail.com</h5></p>
            <p><h5>Subject: Provider Requested {this.state.chosen_score_translation} Completion</h5></p>
      
      
            <p><h5>Hello {this.state.patient_name_to_send_score},<br/><br/>One of your health care providers, {this.state.clinician_name_to_send_score}, has asked that you complete a {this.state.chosen_score_translation} Survey. The information in this survey is confidential and will only be shared with your provider. <br/><br/>Please click <u>here</u> to complete the survey.<br/><br/>Best regards,<br/>OrthoPowerTools </h5></p>                
                             

     
                    
            </form>      


   
      
      
              <div className='user-profile'>
                 <img src={this.state.user.photoURL} />
              </div>
      
      
            <br/>
      
            <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
            {this.state.user ? (
                <span>
                <button onClick={this.logout}>Logout</button>
                </span>
            ) : (
                <StyledFirebaseAuth
                    uiConfig={this.uiConfig}
                    firebaseAuth={firebase.auth()}
                />
  
            )}  
            </div>

            </div>      
      

      
    </div>
  );
}
}
    
export default Send_Email_Request;


