import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Slider, {createSliderWithTooltip} from 'rc-slider';

///////////////////////////////////////////Calculator Methods
class Buttons1 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent1(value);
if (value === 4)
this.props.callbackFromParent1_text("Always");
if (value === 2)
this.props.callbackFromParent1_text("Sometimes");
if (value === 0)
this.props.callbackFromParent1_text("Does not");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>P1. Does looking up increase your problem?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>P1. Does looking up increase your problem?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons2 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent2(value);
if (value === 4)
this.props.callbackFromParent2_text("Always");
if (value === 2)
this.props.callbackFromParent2_text("Sometimes");
if (value === 0)
this.props.callbackFromParent2_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>E2. Because of your problem, do you feel frustrated?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>E2. Because of your problem, do you feel frustrated?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons3 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent3(value);
if (value === 4)
this.props.callbackFromParent3_text("Always");
if (value === 2)
this.props.callbackFromParent3_text("Sometimes");
if (value === 0)
this.props.callbackFromParent3_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>F3. Because of your problem, do you restrict your travel for business or pleasure?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>F3. Because of your problem, do you restrict your travel for business or pleasure?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons4 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent4(value);
if (value === 4)
this.props.callbackFromParent4_text("Always");
if (value === 2)
this.props.callbackFromParent4_text("Sometimes");
if (value === 0)
this.props.callbackFromParent4_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>P4. Does walking down the aisle of a supermarket increase your problem?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>P4. Does walking down the aisle of a supermarket increase your problem?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons5 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent5(value);
if (value === 4)
this.props.callbackFromParent5_text("Always");
if (value === 2)
this.props.callbackFromParent5_text("Sometimes");
if (value === 0)
this.props.callbackFromParent5_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>F5. Because of your problem, do you have difficulty getting into or out of bed?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>F5. Because of your problem, do you have difficulty getting into or out of bed?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons6 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent6(value);
if (value === 4)
this.props.callbackFromParent6_text("Always");
if (value === 2)
this.props.callbackFromParent6_text("Sometimes");
if (value === 0)
this.props.callbackFromParent6_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>F6. Does your problem significantly restrict your participation in social activities, such as going out to dinner, going to movies, dancing or to parties?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>F6. Does your problem significantly restrict your participation in social activities, such as going out to dinner, going to movies, dancing or to parties?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons7 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent7(value);
if (value === 4)
this.props.callbackFromParent7_text("Always");
if (value === 2)
this.props.callbackFromParent7_text("Sometimes");
if (value === 0)
this.props.callbackFromParent7_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>F7. Because of your problem, do you have difficulty reading?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>F7. Because of your problem, do you have difficulty reading?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons8 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent8(value);
if (value === 4)
this.props.callbackFromParent8_text("Always");
if (value === 2)
this.props.callbackFromParent8_text("Sometimes");
if (value === 0)
this.props.callbackFromParent8_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>F8. Does performing more ambitious activities like sports, dancing, and household chores, such as sweeping or putting dishes away increase your problem?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>F8. Does performing more ambitious activities like sports, dancing, and household chores, such as sweeping or putting dishes away increase your problem?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons9 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent9(value);
if (value === 4)
this.props.callbackFromParent9_text("Always");
if (value === 2)
this.props.callbackFromParent9_text("Sometimes");
if (value === 0)
this.props.callbackFromParent9_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>E9. Because of your problem, are you afraid to leave your home without having someone accompany you?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>E9. Because of your problem, are you afraid to leave your home without having someone accompany you?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons10 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent10(value);
if (value === 4)
this.props.callbackFromParent10_text("Always");
if (value === 2)
this.props.callbackFromParent10_text("Sometimes");
if (value === 0)
this.props.callbackFromParent10_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>E10. Because of your problem, have you been embarrassed in front of others?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>E10. Because of your problem, have you been embarrassed in front of others?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons11 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent11(value);
if (value === 4)
this.props.callbackFromParent11_text("Always");
if (value === 2)
this.props.callbackFromParent11_text("Sometimes");
if (value === 0)
this.props.callbackFromParent11_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>P11. Do quick movements of your head increase your problem?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>P11. Do quick movements of your head increase your problem?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons12 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent12(value);
if (value === 4)
this.props.callbackFromParent12_text("Always");
if (value === 2)
this.props.callbackFromParent12_text("Sometimes");
if (value === 0)
this.props.callbackFromParent12_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>F12. Because of your problem, do you avoid heights?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>F12. Because of your problem, do you avoid heights?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons13 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent13(value);
if (value === 4)
this.props.callbackFromParent13_text("Always");
if (value === 2)
this.props.callbackFromParent13_text("Sometimes");
if (value === 0)
this.props.callbackFromParent13_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>P13. Does turning over in bed increase your problem?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>P13. Does turning over in bed increase your problem?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons14 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent14(value);
if (value === 4)
this.props.callbackFromParent14_text("Always");
if (value === 2)
this.props.callbackFromParent14_text("Sometimes");
if (value === 0)
this.props.callbackFromParent14_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>F14. Because of your problem, is it difficult for you to do strenuous housework or yard work?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>F14. Because of your problem, is it difficult for you to do strenuous housework or yard work?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons15 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent15(value);
if (value === 4)
this.props.callbackFromParent15_text("Always");
if (value === 2)
this.props.callbackFromParent15_text("Sometimes");
if (value === 0)
this.props.callbackFromParent15_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>E15. Because of your problem, are you afraid people may think that you are intoxicated?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>E15. Because of your problem, are you afraid people may think that you are intoxicated?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons16 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent16(value);
if (value === 4)
this.props.callbackFromParent16_text("Always");
if (value === 2)
this.props.callbackFromParent16_text("Sometimes");
if (value === 0)
this.props.callbackFromParent16_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>F16. Because of your problem, is it difficult for you to go for a walk by yourself?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>F16. Because of your problem, is it difficult for you to go for a walk by yourself?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons17 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent17(value);
if (value === 4)
this.props.callbackFromParent17_text("Always");
if (value === 2)
this.props.callbackFromParent17_text("Sometimes");
if (value === 0)
this.props.callbackFromParent17_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>P17. Does walking down a sidewalk increase your problem?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>P17. Does walking down a sidewalk increase your problem?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons18 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent18(value);
if (value === 4)
this.props.callbackFromParent18_text("Always");
if (value === 2)
this.props.callbackFromParent18_text("Sometimes");
if (value === 0)
this.props.callbackFromParent18_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>E18. Because of your problem, is it difficult for you to concentrate?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>E18. Because of your problem, is it difficult for you to concentrate?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons19 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent19(value);
if (value === 4)
this.props.callbackFromParent19_text("Always");
if (value === 2)
this.props.callbackFromParent19_text("Sometimes");
if (value === 0)
this.props.callbackFromParent19_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>F19. Because of your problem, is it difficult for you to walk around your house in the dark?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>F19. Because of your problem, is it difficult for you to walk around your house in the dark?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons20 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent20(value);
if (value === 4)
this.props.callbackFromParent20_text("Always");
if (value === 2)
this.props.callbackFromParent20_text("Sometimes");
if (value === 0)
this.props.callbackFromParent20_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>E20. Because of your problem, are you afraid to stay home alone?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>E20. Because of your problem, are you afraid to stay home alone?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons21 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent21(value);
if (value === 4)
this.props.callbackFromParent21_text("Always");
if (value === 2)
this.props.callbackFromParent21_text("Sometimes");
if (value === 0)
this.props.callbackFromParent21_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>E21. Because of your problem, do you feel handicapped?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>E21. Because of your problem, do you feel handicapped?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons22 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent22(value);
if (value === 4)
this.props.callbackFromParent22_text("Always");
if (value === 2)
this.props.callbackFromParent22_text("Sometimes");
if (value === 0)
this.props.callbackFromParent22_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>E22. Has your problem placed stress on your relationship with members of your family or friends?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>E22. Has your problem placed stress on your relationship with members of your family or friends?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons23 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent23(value);
if (value === 4)
this.props.callbackFromParent23_text("Always");
if (value === 2)
this.props.callbackFromParent23_text("Sometimes");
if (value === 0)
this.props.callbackFromParent23_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>E23. Because of your problem, are you depressed?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>E23. Because of your problem, are you depressed?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons24 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent24(value);
if (value === 4)
this.props.callbackFromParent24_text("Always");
if (value === 2)
this.props.callbackFromParent24_text("Sometimes");
if (value === 0)
this.props.callbackFromParent24_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>F24. Does your problem interfere with your job or household responsibilities?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>F24. Does your problem interfere with your job or household responsibilities?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons25 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent25(value);
if (value === 4)
this.props.callbackFromParent25_text("Always");
if (value === 2)
this.props.callbackFromParent25_text("Sometimes");
if (value === 0)
this.props.callbackFromParent25_text("No");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>P25. Does bending over increase your problem?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>P25. Does bending over increase your problem?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (+4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sometimes (+2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No (+0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}


   
 


  


class DHI_Link extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

        
        
        
        
    //Calculator variables    
        selectedValue1: 0,
        selectedValue1_text: 'No',

        selectedValue2: 0,
        selectedValue2_text: 'No',

        selectedValue3: 0,
        selectedValue3_text: 'No',

        selectedValue4: 0,
        selectedValue4_text: 'No',

        selectedValue5: 0,
        selectedValue5_text: 'No',

        selectedValue6: 0,
        selectedValue6_text: 'No',

        selectedValue7: 0,
        selectedValue7_text: 'No',

        selectedValue8: 0,
        selectedValue8_text: 'No',

        selectedValue9: 0,
        selectedValue9_text: 'No',

        selectedValue10: 0,
        selectedValue10_text: 'No',

        selectedValue11: 0,
        selectedValue11_text: 'No',

        selectedValue12: 0,
        selectedValue12_text: 'No',

        selectedValue13: 0,
        selectedValue13_text: 'No',

        selectedValue14: 0,
        selectedValue14_text: 'No',

        selectedValue15: 0,
        selectedValue15_text: 'No',

        selectedValue16: 0,
        selectedValue16_text: 'No',

        selectedValue17: 0,
        selectedValue17_text: 'No',

        selectedValue18: 0,
        selectedValue18_text: 'No',

        selectedValue19: 0,
        selectedValue19_text: 'No',

        selectedValue20: 0,
        selectedValue20_text: 'No',

        selectedValue21: 0,
        selectedValue21_text: 'No',

        selectedValue22: 0,
        selectedValue22_text: 'No',

        selectedValue23: 0,
        selectedValue23_text: 'No',

        selectedValue24: 0,
        selectedValue24_text: 'No',

        selectedValue25: 0,
        selectedValue25_text: 'No',
           
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const DHIScoresRef = firebase.firestore();
  DHIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  DHIScoresRef.collection("dhi_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const DHIScoresRef = firebase.firestore();
  DHIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  DHIScoresRef.collection("dhi_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const DHIScoresRef = firebase.firestore();
  DHIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  DHIScoresRef.collection("dhi_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const DHIScoresRef = firebase.firestore();
  DHIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  DHIScoresRef.collection("dhi_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const DHIScoresRef = firebase.firestore();
  DHIScoresRef.settings({
      timestampsInSnapshots: true
  });  
    
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){
  const dhi_score = DHIScoresRef.collection("dhi_scores").doc(this.props.requestSurveyID).set({            
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Dizziness Handicap Inventory',
    Question_01_DHI: this.state.selectedValue1,
    Question_02_DHI: this.state.selectedValue2,
    Question_03_DHI: this.state.selectedValue3,
    Question_04_DHI: this.state.selectedValue4,
    Question_05_DHI: this.state.selectedValue5,
    Question_06_DHI: this.state.selectedValue6,
    Question_07_DHI: this.state.selectedValue7,            
    Question_08_DHI: this.state.selectedValue8,            
    Question_09_DHI: this.state.selectedValue9,            
    Question_10_DHI: this.state.selectedValue10,
    Question_11_DHI: this.state.selectedValue11,
    Question_12_DHI: this.state.selectedValue12,
    Question_13_DHI: this.state.selectedValue13,
    Question_14_DHI: this.state.selectedValue14,
    Question_15_DHI: this.state.selectedValue15,
    Question_16_DHI: this.state.selectedValue16,
    Question_17_DHI: this.state.selectedValue17,
    Question_18_DHI: this.state.selectedValue18,
    Question_19_DHI: this.state.selectedValue19,
    Question_20_DHI: this.state.selectedValue20,      
    Question_21_DHI: this.state.selectedValue21,      
    Question_22_DHI: this.state.selectedValue22,      
    Question_23_DHI: this.state.selectedValue23,      
    Question_24_DHI: this.state.selectedValue24,      
    Question_25_DHI: this.state.selectedValue25,      
    Total_Score_DHI: this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20+this.state.selectedValue21+this.state.selectedValue22+this.state.selectedValue23+this.state.selectedValue24+this.state.selectedValue25,   
  });
}
else {
  const dhi_score = DHIScoresRef.collection("dhi_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'Dizziness Handicap Inventory',
    Question_01_DHI: this.state.selectedValue1,
    Question_02_DHI: this.state.selectedValue2,
    Question_03_DHI: this.state.selectedValue3,
    Question_04_DHI: this.state.selectedValue4,
    Question_05_DHI: this.state.selectedValue5,
    Question_06_DHI: this.state.selectedValue6,
    Question_07_DHI: this.state.selectedValue7,            
    Question_08_DHI: this.state.selectedValue8,            
    Question_09_DHI: this.state.selectedValue9,            
    Question_10_DHI: this.state.selectedValue10,
    Question_11_DHI: this.state.selectedValue11,
    Question_12_DHI: this.state.selectedValue12,
    Question_13_DHI: this.state.selectedValue13,
    Question_14_DHI: this.state.selectedValue14,
    Question_15_DHI: this.state.selectedValue15,
    Question_16_DHI: this.state.selectedValue16,
    Question_17_DHI: this.state.selectedValue17,
    Question_18_DHI: this.state.selectedValue18,
    Question_19_DHI: this.state.selectedValue19,
    Question_20_DHI: this.state.selectedValue20,      
    Question_21_DHI: this.state.selectedValue21,      
    Question_22_DHI: this.state.selectedValue22,      
    Question_23_DHI: this.state.selectedValue23,      
    Question_24_DHI: this.state.selectedValue24,      
    Question_25_DHI: this.state.selectedValue25,      
    Total_Score_DHI: this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20+this.state.selectedValue21+this.state.selectedValue22+this.state.selectedValue23+this.state.selectedValue24+this.state.selectedValue25,   
  });      
}    
    
    

  this.setState({
    clinician_to_share_with: '',
    user_id: '',
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.props.clinicianSendingEmail + '. You may now close this alert and the entire window.');   
    
}  
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("dhi_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallback1  = (dataFromChild) => {
    this.setState({selectedValue1: dataFromChild});
    }
    myCallback1_text  = (dataFromChild) => {
    this.setState({selectedValue1_text: dataFromChild});
    }
    myCallback2  = (dataFromChild) => {
    this.setState({selectedValue2: dataFromChild});
    }
    myCallback2_text  = (dataFromChild) => {
    this.setState({selectedValue2_text: dataFromChild});
    }
    myCallback3  = (dataFromChild) => {
    this.setState({selectedValue3: dataFromChild});
    }
    myCallback3_text  = (dataFromChild) => {
    this.setState({selectedValue3_text: dataFromChild});
    }
    myCallback4  = (dataFromChild) => {
    this.setState({selectedValue4: dataFromChild});
    }
    myCallback4_text  = (dataFromChild) => {
    this.setState({selectedValue4_text: dataFromChild});
    }
    myCallback5  = (dataFromChild) => {
    this.setState({selectedValue5: dataFromChild});
    }
    myCallback5_text  = (dataFromChild) => {
    this.setState({selectedValue5_text: dataFromChild});
    }
    myCallback6  = (dataFromChild) => {
    this.setState({selectedValue6: dataFromChild});
    }
    myCallback6_text  = (dataFromChild) => {
    this.setState({selectedValue6_text: dataFromChild});
    }
    myCallback7  = (dataFromChild) => {
    this.setState({selectedValue7: dataFromChild});
    }
    myCallback7_text  = (dataFromChild) => {
    this.setState({selectedValue7_text: dataFromChild});
    }
    myCallback8  = (dataFromChild) => {
    this.setState({selectedValue8: dataFromChild});
    }
    myCallback8_text  = (dataFromChild) => {
    this.setState({selectedValue8_text: dataFromChild});
    }
    myCallback9  = (dataFromChild) => {
    this.setState({selectedValue9: dataFromChild});
    }
    myCallback9_text  = (dataFromChild) => {
    this.setState({selectedValue9_text: dataFromChild});
    }
    myCallback10  = (dataFromChild) => {
    this.setState({selectedValue10: dataFromChild});
    }
    myCallback10_text  = (dataFromChild) => {
    this.setState({selectedValue10_text: dataFromChild});
    }
    myCallback11  = (dataFromChild) => {
    this.setState({selectedValue11: dataFromChild});
    }
    myCallback11_text  = (dataFromChild) => {
    this.setState({selectedValue11_text: dataFromChild});
    }
    myCallback12  = (dataFromChild) => {
    this.setState({selectedValue12: dataFromChild});
    }
    myCallback12_text  = (dataFromChild) => {
    this.setState({selectedValue12_text: dataFromChild});
    }
    myCallback13  = (dataFromChild) => {
    this.setState({selectedValue13: dataFromChild});
    }
    myCallback13_text  = (dataFromChild) => {
    this.setState({selectedValue13_text: dataFromChild});
    }
    myCallback14  = (dataFromChild) => {
    this.setState({selectedValue14: dataFromChild});
    }
    myCallback14_text  = (dataFromChild) => {
    this.setState({selectedValue14_text: dataFromChild});
    }
    myCallback15  = (dataFromChild) => {
    this.setState({selectedValue15: dataFromChild});
    }
    myCallback15_text  = (dataFromChild) => {
    this.setState({selectedValue15_text: dataFromChild});
    }
    myCallback16  = (dataFromChild) => {
    this.setState({selectedValue16: dataFromChild});
    }
    myCallback16_text  = (dataFromChild) => {
    this.setState({selectedValue16_text: dataFromChild});
    }
    myCallback17  = (dataFromChild) => {
    this.setState({selectedValue17: dataFromChild});
    }
    myCallback17_text  = (dataFromChild) => {
    this.setState({selectedValue17_text: dataFromChild});
    }
    myCallback18  = (dataFromChild) => {
    this.setState({selectedValue18: dataFromChild});
    }
    myCallback18_text  = (dataFromChild) => {
    this.setState({selectedValue18_text: dataFromChild});
    }
    myCallback19  = (dataFromChild) => {
    this.setState({selectedValue19: dataFromChild});
    }
    myCallback19_text  = (dataFromChild) => {
    this.setState({selectedValue19_text: dataFromChild});
    }
    myCallback20  = (dataFromChild) => {
    this.setState({selectedValue20: dataFromChild});
    }
    myCallback20_text  = (dataFromChild) => {
    this.setState({selectedValue20_text: dataFromChild});
    }
    myCallback21  = (dataFromChild) => {
    this.setState({selectedValue21: dataFromChild});
    }
    myCallback21_text  = (dataFromChild) => {
    this.setState({selectedValue21_text: dataFromChild});
    }
    myCallback22  = (dataFromChild) => {
    this.setState({selectedValue22: dataFromChild});
    }
    myCallback22_text  = (dataFromChild) => {
    this.setState({selectedValue22_text: dataFromChild});
    }
    myCallback23  = (dataFromChild) => {
    this.setState({selectedValue23: dataFromChild});
    }
    myCallback23_text  = (dataFromChild) => {
    this.setState({selectedValue23_text: dataFromChild});
    }
    myCallback24  = (dataFromChild) => {
    this.setState({selectedValue24: dataFromChild});
    }
    myCallback24_text  = (dataFromChild) => {
    this.setState({selectedValue24_text: dataFromChild});
    }
    myCallback25  = (dataFromChild) => {
    this.setState({selectedValue25: dataFromChild});
    }
    myCallback25_text  = (dataFromChild) => {
    this.setState({selectedValue25_text: dataFromChild});
    }
       
  
    
    

    
render() { 
    
   

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>Dizziness Handicap Inventory (DHI) Calculator</h1>
                <h4>Hello, {this.props.submitterName}, your clinician, {this.props.clinicianSendingEmail}, has sent you this survey to help further understand your health. Please click the appropriate buttons below and then click "Submit Score". Please note that your <u>digital privacy is taken very seriously</u> and a number of technologies have been implemented to protect the privacy and integrity of your data, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Your data <u>will never be shared with anyone other than the assigned clinician</u>.</h4>

            <form onSubmit={this.handleSubmit}>



                <StickyContainer>
                  <Sticky>
                    {({ style }) => <div className="sticky_style" style={style}><Well bsSize="small">Total DHI Score: {((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20+this.state.selectedValue21+this.state.selectedValue22+this.state.selectedValue23+this.state.selectedValue24+this.state.selectedValue25).toFixed(0))} / {100
                    } = {((((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20+this.state.selectedValue21+this.state.selectedValue22+this.state.selectedValue23+this.state.selectedValue24+this.state.selectedValue25))/(100
                    )*100).toFixed(0))} % (P: {this.state.selectedValue1+this.state.selectedValue4+this.state.selectedValue11+this.state.selectedValue13+this.state.selectedValue17+this.state.selectedValue25}/{24} E: {this.state.selectedValue2+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue15+this.state.selectedValue18+this.state.selectedValue20+this.state.selectedValue21+this.state.selectedValue22+this.state.selectedValue23}/{36} F: {this.state.selectedValue3+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue12+this.state.selectedValue14+this.state.selectedValue16+this.state.selectedValue19+this.state.selectedValue24}/{40})
                    <p/>
                    Graphical Total DHI Score: (%)
                    <ProgressBar>
                    <ProgressBar active now={100-(100*((((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20+this.state.selectedValue21+this.state.selectedValue22+this.state.selectedValue23+this.state.selectedValue24+this.state.selectedValue25).toFixed(0))/(100
                    ))))}/>
                    <ProgressBar active bsStyle="danger" now={(100*((((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20+this.state.selectedValue21+this.state.selectedValue22+this.state.selectedValue23+this.state.selectedValue24+this.state.selectedValue25).toFixed(0)))/(100
                    )))}/>
                    </ProgressBar>    
                    </Well>
                    </div>}

                  </Sticky> 
                <p />
                    
                <br/>
                <br/>                      

                <h4>Instructions: The purpose of this scale is to identify difficulties that you may be experiencing because of your dizziness. Please check “Always (4)”, or “No (0)” or “Sometimes (2)” to each question. Answer each question only as it pertains to your dizziness problem.
                </h4>
                <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
                <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
                <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
                <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
                <Buttons5 callbackFromParent5={this.myCallback5} callbackFromParent5_text={this.myCallback5_text}/>
                <Buttons6 callbackFromParent6={this.myCallback6} callbackFromParent6_text={this.myCallback6_text}/>
                <Buttons7 callbackFromParent7={this.myCallback7} callbackFromParent7_text={this.myCallback7_text}/>
                <Buttons8 callbackFromParent8={this.myCallback8} callbackFromParent8_text={this.myCallback8_text}/>
                <Buttons9 callbackFromParent9={this.myCallback9} callbackFromParent9_text={this.myCallback9_text}/>
                <Buttons10 callbackFromParent10={this.myCallback10} callbackFromParent10_text={this.myCallback10_text}/>
                <Buttons11 callbackFromParent11={this.myCallback11} callbackFromParent11_text={this.myCallback11_text}/>
                <Buttons12 callbackFromParent12={this.myCallback12} callbackFromParent12_text={this.myCallback12_text}/>
                <Buttons13 callbackFromParent13={this.myCallback13} callbackFromParent13_text={this.myCallback13_text}/>
                <Buttons14 callbackFromParent14={this.myCallback14} callbackFromParent14_text={this.myCallback14_text}/>
                <Buttons15 callbackFromParent15={this.myCallback15} callbackFromParent15_text={this.myCallback15_text}/>
                <Buttons16 callbackFromParent16={this.myCallback16} callbackFromParent16_text={this.myCallback16_text}/>
                <Buttons17 callbackFromParent17={this.myCallback17} callbackFromParent17_text={this.myCallback17_text}/>
                <Buttons18 callbackFromParent18={this.myCallback18} callbackFromParent18_text={this.myCallback18_text}/>
                <Buttons19 callbackFromParent19={this.myCallback19} callbackFromParent19_text={this.myCallback19_text}/>
                <Buttons20 callbackFromParent20={this.myCallback20} callbackFromParent20_text={this.myCallback20_text}/>
                <Buttons21 callbackFromParent21={this.myCallback21} callbackFromParent21_text={this.myCallback21_text}/>
                <Buttons22 callbackFromParent22={this.myCallback22} callbackFromParent22_text={this.myCallback22_text}/>
                <Buttons23 callbackFromParent23={this.myCallback23} callbackFromParent23_text={this.myCallback23_text}/>
                <Buttons24 callbackFromParent24={this.myCallback24} callbackFromParent24_text={this.myCallback24_text}/>
                <Buttons25 callbackFromParent25={this.myCallback25} callbackFromParent25_text={this.myCallback25_text}/>                     
                        
                </StickyContainer> 

                <br />
                    
              <button style = {{width: '100%'}}>Submit Score</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(DHI_Link);
