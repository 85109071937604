import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup, Table} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
//const jsPDF = require('jspdf');
import jsPDF from 'jspdf';
import {Link, Route} from "react-router-dom";
import mainLogo from'../orthopowertools.png';

 


class SF36_Send_Link extends PureComponent {
    
  constructor() {
    super();
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      scores_id: [], //this displays ID number for the scores saved for each clinician        
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual
        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to
      patient_name_to_send_score: '', //this is the variable where clinicians enter the given name of a patient they want to send a score to, which makes the email more personable
      clinician_name_to_send_score: '', //this is the variable where clinicians enter their own given name when they send  score to a patient, which makes the email more personable         
        
    //Calculator variables    

    selectedValue1: 100,
    selectedValue1_text: 'Excellent general health',
    selectedValue2: 100,
    selectedValue2_text: 'Much better health than one year ago',
    selectedValue3: 100,
    selectedValue3_text: 'Vigorous activites not limited',
    selectedValue4: 100,
    selectedValue4_text: 'Moderate activities not limited',
    selectedValue5: 100,
    selectedValue5_text: 'Lifting or carrying groceries not limited',
    selectedValue6: 100,
    selectedValue6_text: 'Climbing several flights of stairs not limited',
    selectedValue7: 100,
    selectedValue7_text: 'Climbing one flight of stairs not limited',
    selectedValue8: 100,
    selectedValue8_text: 'Bending/kneeling/stooping not limited',
    selectedValue9: 100,
    selectedValue9_text: 'Walking more than one mile not limited',
    selectedValue10: 100,
    selectedValue10_text: 'Walking several blocks not limited',
    selectedValue11: 100,
    selectedValue11_text: 'Walking one block not limited',
    selectedValue12: 100,
    selectedValue12_text: 'Bathing or dressing self not limited',
    selectedValue13: 100,
    selectedValue13_text: 'No decrease in time spent on work due to physical health',
    selectedValue14: 100,
    selectedValue14_text: 'Has not accomplished less than would like due to physical health',
    selectedValue15: 100,
    selectedValue15_text: 'Was not limited in work due to physical health',
    selectedValue16: 100,
    selectedValue16_text: 'No difficulty performing work due to physical health',
    selectedValue17: 100,
    selectedValue17_text: 'No decrease in time spent on work due to emotional problems',
    selectedValue18: 100,
    selectedValue18_text: 'Has not accomplished less than would like due to emotional problems',
    selectedValue19: 100,
    selectedValue19_text: 'No difference in carefulness of work due to emotional problems',
    selectedValue20: 100,
    selectedValue20_text: 'No interference of health or emotional problems with social activities',
    selectedValue21: 100,
    selectedValue21_text: 'No bodily pain',
    selectedValue22: 100,
    selectedValue22_text: 'No interference of pain with work',
    selectedValue23: 100,
    selectedValue23_text: 'Always feels full of pep',
    selectedValue24: 100,
    selectedValue24_text: 'Never a nervous person',
    selectedValue25: 100,
    selectedValue25_text: 'Never felt so down in the dumps that nothing could cheer up',
    selectedValue26: 100,
    selectedValue26_text: 'Always felt calm and peaceful',
    selectedValue27: 100,
    selectedValue27_text: 'Always had a lot of energy',
    selectedValue28: 100,
    selectedValue28_text: 'Never felt downhearted and blue',
    selectedValue29: 100,
    selectedValue29_text: 'Never felt worn out',
    selectedValue30: 100,
    selectedValue30_text: 'Always was a happy person',
    selectedValue31: 100,
    selectedValue31_text: 'Never felt tired',
    selectedValue32: 100,
    selectedValue32_text: 'Physical health or emotional problems did not interfere with social activities',
    selectedValue33: 100,
    selectedValue33_text: '"I seem to get sick a little easier than other people" -- definitely false',
    selectedValue34: 100,
    selectedValue34_text: '"I am as healthy as anybody I know" -- definitely true',
    selectedValue35: 100,
    selectedValue35_text: '"I expect my health to get worse" -- definitely false',
    selectedValue36: 100,
    selectedValue36_text: '"My health is excellent" -- definitely true',                
               
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.modifyData = this.modifyData.bind(this);
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
      
     
      
  }
    
componentDidMount() {
    
    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const SF36ScoresRef = firebase.firestore();
  SF36ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF36ScoresRef.collection("sf36_scores").get().then(querySnapshot =>{
      
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id //this part is a weird flaw in the system where querySnapshot doesn't actually pull the doc ID, so you have to do it seperately and then combined the document data with each doc ID
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  })
              
        
}  else {
    
  const SF36ScoresRef = firebase.firestore();
  SF36ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF36ScoresRef.collection("sf36_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const SF36ScoresRef = firebase.firestore();
  SF36ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF36ScoresRef.collection("sf36_scores").get().then(querySnapshot =>{
      

    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
                       
        
} else {
    

    
  const SF36ScoresRef = firebase.firestore();
  SF36ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF36ScoresRef.collection("sf36_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value.replace('"','')
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const SF36ScoresRef = firebase.firestore();
  SF36ScoresRef.settings({
      timestampsInSnapshots: true
  });  
  const sf36_score = SF36ScoresRef.collection("sf36_scores").add({
    shared_with: this.state.clinician_to_share_with,
    user: this.state.user_id,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'SF36',
    Question_01_SF36: this.state.selectedValue1,
    Question_02_SF36: this.state.selectedValue2,
    Question_03_SF36: this.state.selectedValue3,
    Question_04_SF36: this.state.selectedValue4,
    Question_05_SF36: this.state.selectedValue5,
    Question_06_SF36: this.state.selectedValue6,
    Question_07_SF36: this.state.selectedValue7,            
    Question_08_SF36: this.state.selectedValue8,
    Question_09_SF36: this.state.selectedValue9,
    Question_10_SF36: this.state.selectedValue10,
    Question_11_SF36: this.state.selectedValue11,
    Question_12_SF36: this.state.selectedValue12,
    Question_13_SF36: this.state.selectedValue13,
    Question_14_SF36: this.state.selectedValue14,
    Question_15_SF36: this.state.selectedValue15,
    Question_16_SF36: this.state.selectedValue16,
    Question_17_SF36: this.state.selectedValue17,
    Question_18_SF36: this.state.selectedValue18,
    Question_19_SF36: this.state.selectedValue19,
    Question_20_SF36: this.state.selectedValue20,
    Question_21_SF36: this.state.selectedValue21,
    Question_22_SF36: this.state.selectedValue22,
    Question_23_SF36: this.state.selectedValue23,
    Question_24_SF36: this.state.selectedValue24,
    Question_25_SF36: this.state.selectedValue25,
    Question_26_SF36: this.state.selectedValue26,
    Question_27_SF36: this.state.selectedValue27,
    Question_28_SF36: this.state.selectedValue28,
    Question_29_SF36: this.state.selectedValue29,
    Question_30_SF36: this.state.selectedValue30,
    Question_31_SF36: this.state.selectedValue31,
    Question_32_SF36: this.state.selectedValue32,
    Question_33_SF36: this.state.selectedValue33,
    Question_34_SF36: this.state.selectedValue34,
    Question_35_SF36: this.state.selectedValue35,
    Question_36_SF36: this.state.selectedValue36,
    Physical_Functioning_Score_SF36:(Math.round((this.state.selectedValue3 + this.state.selectedValue4 + this.state.selectedValue5 + this.state.selectedValue6 + this.state.selectedValue7 + this.state.selectedValue8 + this.state.selectedValue9 + this.state.selectedValue10 + this.state.selectedValue11 + this.state.selectedValue12)*10/10)/10),
    Role_Limitations_Physical_Health_Score_SF36:(Math.round((this.state.selectedValue13 + this.state.selectedValue14 + this.state.selectedValue15 + this.state.selectedValue16)*10/4)/10),
    Role_Limitations_Emotional_Problems_Score_SF36:(Math.round((this.state.selectedValue17 + this.state.selectedValue18 + this.state.selectedValue19)*10/3)/10),
    Energy_Score_SF36:(Math.round((this.state.selectedValue23 + this.state.selectedValue27 + this.state.selectedValue29 + this.state.selectedValue31)*10/4)/10),
    Emotional_Well_Being_Score_SF36:(Math.round((this.state.selectedValue24 + this.state.selectedValue25 + this.state.selectedValue26 + this.state.selectedValue28 + this.state.selectedValue30)*10/5)/10),
    Social_Functioning_Score_SF36:(Math.round((this.state.selectedValue20 + this.state.selectedValue32)*10/2)/10),
    Pain_Score_SF36:(Math.round((this.state.selectedValue21 + this.state.selectedValue22)*10/2)/10),
    General_Health_Score_SF36:(Math.round((this.state.selectedValue1 + this.state.selectedValue33 + this.state.selectedValue34 + this.state.selectedValue35 + this.state.selectedValue36)*10/5)/10),
    Health_Change_Score_SF36:(Math.round((this.state.selectedValue2)*10)/10),        
  });
  this.setState({
    clinician_to_share_with: '',          
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.state.clinician_to_share_with + '.');   
    
} 
    
modifyData(score_id) {  
  const SF36ScoresRef = firebase.firestore();
  SF36ScoresRef.collection("sf36_scores").doc(String(score_id)).update({
    shared_with: "None"    
  });  
    alert('Score deleted.');     
    
} 
    
    
    
    
    
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("sf36_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent_email: this.state.user.email,       
    patient_name_to_send_score: this.state.patient_name_to_send_score,
    clinician_who_sent_name: this.state.clinician_name_to_send_score,        
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',
    clinician_who_sent: '',
    patient_name_to_send_score: '',
    clinician_name_to_send_score: '',  
  });
    if (this.state.patient_email_to_send_score !== ''){
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');
    }
    else {
    alert('Thank you for using OrthoPowerTools! Your invitation was not sent because you did not enter the patients email address. To send the invitation, please enter the patients email address at the top of the page in the section that states "Enter the patients email address here"');        
    }      
    
}      
  
    
    
    
 
    
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
myCallback1  = (dataFromChild) => {
this.setState({selectedValue1: dataFromChild});
}
myCallback1_text  = (dataFromChild) => {
this.setState({selectedValue1_text: dataFromChild});
}
myCallback2  = (dataFromChild) => {
this.setState({selectedValue2: dataFromChild});
}
myCallback2_text  = (dataFromChild) => {
this.setState({selectedValue2_text: dataFromChild});
}
myCallback3  = (dataFromChild) => {
this.setState({selectedValue3: dataFromChild});
}
myCallback3_text  = (dataFromChild) => {
this.setState({selectedValue3_text: dataFromChild});
}
myCallback4  = (dataFromChild) => {
this.setState({selectedValue4: dataFromChild});
}
myCallback4_text  = (dataFromChild) => {
this.setState({selectedValue4_text: dataFromChild});
}
myCallback5  = (dataFromChild) => {
this.setState({selectedValue5: dataFromChild});
}
myCallback5_text  = (dataFromChild) => {
this.setState({selectedValue5_text: dataFromChild});
}
myCallback6  = (dataFromChild) => {
this.setState({selectedValue6: dataFromChild});
}
myCallback6_text  = (dataFromChild) => {
this.setState({selectedValue6_text: dataFromChild});
}
myCallback7  = (dataFromChild) => {
this.setState({selectedValue7: dataFromChild});
}
myCallback7_text  = (dataFromChild) => {
this.setState({selectedValue7_text: dataFromChild});
}
myCallback8  = (dataFromChild) => {
this.setState({selectedValue8: dataFromChild});
}
myCallback8_text  = (dataFromChild) => {
this.setState({selectedValue8_text: dataFromChild});
}
myCallback9  = (dataFromChild) => {
this.setState({selectedValue9: dataFromChild});
}
myCallback9_text  = (dataFromChild) => {
this.setState({selectedValue9_text: dataFromChild});
}
myCallback10  = (dataFromChild) => {
this.setState({selectedValue10: dataFromChild});
}
myCallback10_text  = (dataFromChild) => {
this.setState({selectedValue10_text: dataFromChild});
}
myCallback11  = (dataFromChild) => {
this.setState({selectedValue11: dataFromChild});
}
myCallback11_text  = (dataFromChild) => {
this.setState({selectedValue11_text: dataFromChild});
}
myCallback12  = (dataFromChild) => {
this.setState({selectedValue12: dataFromChild});
}
myCallback12_text  = (dataFromChild) => {
this.setState({selectedValue12_text: dataFromChild});
}
myCallback13  = (dataFromChild) => {
this.setState({selectedValue13: dataFromChild});
}
myCallback13_text  = (dataFromChild) => {
this.setState({selectedValue13_text: dataFromChild});
}
myCallback14  = (dataFromChild) => {
this.setState({selectedValue14: dataFromChild});
}
myCallback14_text  = (dataFromChild) => {
this.setState({selectedValue14_text: dataFromChild});
}
myCallback15  = (dataFromChild) => {
this.setState({selectedValue15: dataFromChild});
}
myCallback15_text  = (dataFromChild) => {
this.setState({selectedValue15_text: dataFromChild});
}
myCallback16  = (dataFromChild) => {
this.setState({selectedValue16: dataFromChild});
}
myCallback16_text  = (dataFromChild) => {
this.setState({selectedValue16_text: dataFromChild});
}
myCallback17  = (dataFromChild) => {
this.setState({selectedValue17: dataFromChild});
}
myCallback17_text  = (dataFromChild) => {
this.setState({selectedValue17_text: dataFromChild});
}
myCallback18  = (dataFromChild) => {
this.setState({selectedValue18: dataFromChild});
}
myCallback18_text  = (dataFromChild) => {
this.setState({selectedValue18_text: dataFromChild});
}
myCallback19  = (dataFromChild) => {
this.setState({selectedValue19: dataFromChild});
}
myCallback19_text  = (dataFromChild) => {
this.setState({selectedValue19_text: dataFromChild});
}
myCallback20  = (dataFromChild) => {
this.setState({selectedValue20: dataFromChild});
}
myCallback20_text  = (dataFromChild) => {
this.setState({selectedValue20_text: dataFromChild});
}
myCallback21  = (dataFromChild) => {
this.setState({selectedValue21: dataFromChild});
}
myCallback21_text  = (dataFromChild) => {
this.setState({selectedValue21_text: dataFromChild});
}
myCallback22  = (dataFromChild) => {
this.setState({selectedValue22: dataFromChild});
}
myCallback22_text  = (dataFromChild) => {
this.setState({selectedValue22_text: dataFromChild});
}
myCallback23  = (dataFromChild) => {
this.setState({selectedValue23: dataFromChild});
}
myCallback23_text  = (dataFromChild) => {
this.setState({selectedValue23_text: dataFromChild});
}
myCallback24  = (dataFromChild) => {
this.setState({selectedValue24: dataFromChild});
}
myCallback24_text  = (dataFromChild) => {
this.setState({selectedValue24_text: dataFromChild});
}
myCallback25  = (dataFromChild) => {
this.setState({selectedValue25: dataFromChild});
}
myCallback25_text  = (dataFromChild) => {
this.setState({selectedValue25_text: dataFromChild});
}
myCallback26  = (dataFromChild) => {
this.setState({selectedValue26: dataFromChild});
}
myCallback26_text  = (dataFromChild) => {
this.setState({selectedValue26_text: dataFromChild});
}
myCallback27  = (dataFromChild) => {
this.setState({selectedValue27: dataFromChild});
}
myCallback27_text  = (dataFromChild) => {
this.setState({selectedValue27_text: dataFromChild});
}
myCallback28  = (dataFromChild) => {
this.setState({selectedValue28: dataFromChild});
}
myCallback28_text  = (dataFromChild) => {
this.setState({selectedValue28_text: dataFromChild});
}
myCallback29  = (dataFromChild) => {
this.setState({selectedValue29: dataFromChild});
}
myCallback29_text  = (dataFromChild) => {
this.setState({selectedValue29_text: dataFromChild});
}
myCallback30  = (dataFromChild) => {
this.setState({selectedValue30: dataFromChild});
}
myCallback30_text  = (dataFromChild) => {
this.setState({selectedValue30_text: dataFromChild});
}
myCallback31  = (dataFromChild) => {
this.setState({selectedValue31: dataFromChild});
}
myCallback31_text  = (dataFromChild) => {
this.setState({selectedValue31_text: dataFromChild});
}
myCallback32  = (dataFromChild) => {
this.setState({selectedValue32: dataFromChild});
}
myCallback32_text  = (dataFromChild) => {
this.setState({selectedValue32_text: dataFromChild});
}
myCallback33  = (dataFromChild) => {
this.setState({selectedValue33: dataFromChild});
}
myCallback33_text  = (dataFromChild) => {
this.setState({selectedValue33_text: dataFromChild});
}
myCallback34  = (dataFromChild) => {
this.setState({selectedValue34: dataFromChild});
}
myCallback34_text  = (dataFromChild) => {
this.setState({selectedValue34_text: dataFromChild});
}
myCallback35  = (dataFromChild) => {
this.setState({selectedValue35: dataFromChild});
}
myCallback35_text  = (dataFromChild) => {
this.setState({selectedValue35_text: dataFromChild});
}
myCallback36  = (dataFromChild) => {
this.setState({selectedValue36: dataFromChild});
}
myCallback36_text  = (dataFromChild) => {
this.setState({selectedValue36_text: dataFromChild});
}
 
  
    
    

    
render() {
    
    if (this.state.user === null){
        return(
            
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>                 
        <Link to="/" style={{padding:15}}>Home</Link>            
        </div>
      </header>     

      <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
        <h1>Welcome to OrthoPowerTools, a project of <a href="https://orthotoolkit.com/">OrthoToolKit!</a></h1>

        <p>This initiative strives to improve the way in which people understand and communicate their orthopaedic health by providing all the features of OrthoToolKit as well as an <b>easy-to-use system</b> for clinicians to collect, store, and analyze common outcome measures from their patients.</p>
      
      <br/>
      
        
      <p>To learn more, please visit our <Link to="/">home page</Link></p>
      

 
      

      </div>
    </div>  
            
            
            
        );
    }
    
  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>                 
        <Link to="/" style={{padding:15}}>Home</Link>            
        </div>
      </header>  
      

            <div style={{marginLeft:50, marginRight:50}}>
            <br/>
            <h2>Send Unique SF-36 Link To Your Patients</h2>
            <br/>
            <form onSubmit={this.handleSubmitSendInvite}>
      
      
            <h4>Please fill in the below three text inputs. When you click the 'Send Score Invite' button, an email will be sent to your patient that contains a unique link to a SF-36 score. When the patient finishes the survey and clicks submit, it will automatically be shared with you. An example email is listed below.</h4>
      
      
     
              <h4>Patient email address:</h4>                    
              <input type="text" name="patient_email_to_send_score" placeholder="Enter patient's email address here" onChange={this.handleChange} value={this.state.patient_email_to_send_score} /> 
      
              <h4>Patient given name:</h4>                          
              <input type="text" name="patient_name_to_send_score" placeholder="Enter patient's given name here (how name will be displayed on saved score)" onChange={this.handleChange} value={this.state.patient_name_to_send_score} /> 
      
              <h4>Your given name:</h4>                                
              <input type="text" name="clinician_name_to_send_score" placeholder="Enter the your name here (as you would like it to appear in the score invitation)" onChange={this.handleChange} value={this.state.clinician_name_to_send_score} />       
              <br />
      
          <button style = {{width: '100%'}}>Send Score Invite</button>       
      

            <p><h5>To: {this.state.patient_email_to_send_score}</h5></p>
            <p><h5>From: orthopowertools@gmail.com</h5></p>
            <p><h5>Subject: Provider Requested 36 Item Short Form Survey Completion</h5></p>
      
      
            <p><h5>Hello {this.state.patient_name_to_send_score},<br/><br/>One of your health care providers, {this.state.clinician_name_to_send_score}, has asked that you complete a 36 Item Short Form Survey (SF-36). The information in this survey is confidential and will only be shared with your provider. <br/><br/>Please click <u>here</u> to complete the survey.</h5></p><br/>Best regards,<br/>OrthoPowerTools`                      

     
                    
            </form>        
      
      
              <div className='user-profile'>
                 <img src={this.state.user.photoURL} />
              </div>
      
      
            <br/>
      
            <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
            {this.state.user ? (
                <span>
                <button onClick={this.logout}>Logout</button>
                </span>
            ) : (
                <StyledFirebaseAuth
                    uiConfig={this.uiConfig}
                    firebaseAuth={firebase.auth()}
                />
  
            )}  
            </div>

            </div>      
      

      
    </div>
  );
}
}
    
export default SF36_Send_Link;


