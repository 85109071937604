import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup, Table} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
//const jsPDF = require('jspdf');
import jsPDF from 'jspdf';
import {Link, Route} from "react-router-dom";
import mainLogo from'../orthopowertools.png';

 


class QuickDASH_Send_Link_Spanish extends PureComponent {
    
  constructor() {
    super();
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      scores_id: [], //this displays ID number for the scores saved for each clinician        
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual
        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to
      patient_name_to_send_score: '', //this is the variable where clinicians enter the given name of a patient they want to send a score to, which makes the email more personable
      clinician_name_to_send_score: '', //this is the variable where clinicians enter their own given name when they send  score to a patient, which makes the email more personable         
        
    //Calculator variables    
        selectedValue1: 0,
        selectedValue1_text: 'Sin dificultad',
        selectedValue2: 0,
        selectedValue2_text: 'Sin dificultad',
        selectedValue3: 0,
        selectedValue3_text: 'Sin dificultad',
        selectedValue4: 0,
        selectedValue4_text: 'Sin dificultad',
        selectedValue5: 0,
        selectedValue5_text: 'Sin dificultad',
        selectedValue6: 0,
        selectedValue6_text: 'Sin dificultad',
        selectedValue7: 0,
        selectedValue7_text: 'Nada',
        selectedValue8: 0,
        selectedValue8_text: 'Sin limitaciones',
        selectedValue9: 0,
        selectedValue9_text: 'Nada',
        selectedValue10: 0,
        selectedValue10_text: 'Nada',
        selectedValue11: 0,
        selectedValue11_text: 'Sin dificultad',     
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.modifyData = this.modifyData.bind(this);
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
      
     
      
  }
    
componentDidMount() {
    
    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const QuickDASHScoresRef = firebase.firestore();
  QuickDASHScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  QuickDASHScoresRef.collection("quickdash_scores").get().then(querySnapshot =>{
      
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id //this part is a weird flaw in the system where querySnapshot doesn't actually pull the doc ID, so you have to do it seperately and then combined the document data with each doc ID
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  })
              
        
}  else {
    
  const QuickDASHScoresRef = firebase.firestore();
  QuickDASHScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  QuickDASHScoresRef.collection("quickdash_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const QuickDASHScoresRef = firebase.firestore();
  QuickDASHScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  QuickDASHScoresRef.collection("quickdash_scores").get().then(querySnapshot =>{
      

    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
                       
        
} else {
    

    
  const QuickDASHScoresRef = firebase.firestore();
  QuickDASHScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  QuickDASHScoresRef.collection("quickdash_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value.replace('"','')
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const QuickDASHScoresRef = firebase.firestore();
  QuickDASHScoresRef.settings({
      timestampsInSnapshots: true
  });  
  const quickdash_score = QuickDASHScoresRef.collection("quickdash_scores").add({
    shared_with: this.state.clinician_to_share_with,
    user: this.state.user_id,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Spanish QuickDASH',
    Question_01_QuickDASH: this.state.selectedValue1,
    Question_02_QuickDASH: this.state.selectedValue2,
    Question_03_QuickDASH: this.state.selectedValue3,
    Question_04_QuickDASH: this.state.selectedValue4,
    Question_05_QuickDASH: this.state.selectedValue5,
    Question_06_QuickDASH: this.state.selectedValue6,
    Question_07_QuickDASH: this.state.selectedValue7,            
    Question_08_QuickDASH: this.state.selectedValue8,
    Question_09_QuickDASH: this.state.selectedValue9,
    Question_10_QuickDASH: this.state.selectedValue10,
    Question_11_QuickDASH: this.state.selectedValue11,
    QuickDASH_Score: ((this.state.selectedValue1 + this.state.selectedValue2 + this.state.selectedValue3 + this.state.selectedValue4 + this.state.selectedValue5 + this.state.selectedValue6 + this.state.selectedValue7 + this.state.selectedValue8 + this.state.selectedValue9 + this.state.selectedValue10 + this.state.selectedValue11)/11).toFixed(1),        
  });
  this.setState({
    clinician_to_share_with: '',        
  });
    if (this.state.clinician_to_share_with !== ''){
    alert('Español: ¡Gracias por usar OrthoPowerTools! ' + this.state.clinician_to_share_with.toLowerCase() + ' recibirá en breve un correo electrónico con respecto a su puntuación. Ahora puede cerrar esta alerta y toda la ventana. \n\nEnglish: Thank you for using OrthoPowerTools! ' + this.state.clinician_to_share_with.toLowerCase() + ' will shortly receive an email regarding your score. You may now close this alert and the entire window.');
    }
    else {
    alert('Español: ¡Gracias por usar OrthoPowerTools! Su envío no se compartió con ningún proveedor y, por lo tanto, no se guardó. Para compartir y guardar su puntuación, ingrese una dirección de correo electrónico en la sección que dice "Haga clic aquí para ingresar la dirección de correo electrónico del médico con el que le gustaría compartir esta encuesta". \n\nEnglish: Thank you for using OrthoPowerTools! Your submission was not shared with any provider and was therefore not saved. To share and save your score, please enter an email address in the section that states "Click here to enter the email address of the clinician who you would like to share this survey with".');        
    }     
    
} 
    
modifyData(score_id) {  
  const QuickDASHScoresRef = firebase.firestore();
  QuickDASHScoresRef.collection("quickdash_scores").doc(String(score_id)).update({
    shared_with: "None"    
  });  
    alert('Score deleted.');     
    
} 
    
    
    
    
    
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("quickdash_spanish_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent_email: this.state.user.email,       
    patient_name_to_send_score: this.state.patient_name_to_send_score,
    clinician_who_sent_name: this.state.clinician_name_to_send_score,        
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',
    clinician_who_sent: '',
    patient_name_to_send_score: '',
    clinician_name_to_send_score: '',  
  });
    if (this.state.patient_email_to_send_score !== ''){
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');
    }
    else {
    alert('Thank you for using OrthoPowerTools! Your invitation was not sent because you did not enter the patients email address. To send the invitation, please enter the patients email address at the top of the page in the section that states "Enter the patients email address here"');        
    }      
    
}      
  
    
    
    
 
    
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallback1 = (dataFromChild) => {
    this.setState({selectedValue1: dataFromChild});
    }
 
    myCallback1_text = (dataFromChild) => {
    this.setState({selectedValue1_text: dataFromChild});
    }
    
    myCallback2 = (dataFromChild) => {
    this.setState({selectedValue2: dataFromChild});
    }
    
    myCallback2_text = (dataFromChild) => {
    this.setState({selectedValue2_text: dataFromChild});
    }
    
    myCallback3 = (dataFromChild) => {
    this.setState({selectedValue3: dataFromChild});
    }
    
    myCallback3_text = (dataFromChild) => {
    this.setState({selectedValue3_text: dataFromChild});
    }    
    
    myCallback4 = (dataFromChild) => {
    this.setState({selectedValue4: dataFromChild});
    }
    
    myCallback4_text = (dataFromChild) => {
    this.setState({selectedValue4_text: dataFromChild});
    }  
    
    myCallback5 = (dataFromChild) => {
    this.setState({selectedValue5: dataFromChild});
    }
    
    myCallback5_text = (dataFromChild) => {
    this.setState({selectedValue5_text: dataFromChild});
    }    
    
    myCallback6 = (dataFromChild) => {
    this.setState({selectedValue6: dataFromChild});
    }
    
    myCallback6_text = (dataFromChild) => {
    this.setState({selectedValue6_text: dataFromChild});
    }      
    
    myCallback7 = (dataFromChild) => {
    this.setState({selectedValue7: dataFromChild});
    }
    
    myCallback7_text = (dataFromChild) => {
    this.setState({selectedValue7_text: dataFromChild});
    }    
    
    myCallback8 = (dataFromChild) => {
    this.setState({selectedValue8: dataFromChild});
    }
    
    myCallback8_text = (dataFromChild) => {
    this.setState({selectedValue8_text: dataFromChild});
    }      
    
    myCallback9 = (dataFromChild) => {
    this.setState({selectedValue9: dataFromChild});
    }
    
    myCallback9_text = (dataFromChild) => {
    this.setState({selectedValue9_text: dataFromChild});
    }    
    
    myCallback10 = (dataFromChild) => {
    this.setState({selectedValue10: dataFromChild});
    }
    
    myCallback10_text = (dataFromChild) => {
    this.setState({selectedValue10_text: dataFromChild});
    }

    myCallback11 = (dataFromChild) => {
    this.setState({selectedValue11: dataFromChild});
    }
    
    myCallback11_text = (dataFromChild) => {
    this.setState({selectedValue11_text: dataFromChild});
    }  
  
    
    

    
render() {
    
    if (this.state.user === null){
        return(
            
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>                 
        <Link to="/" style={{padding:15}}>Home</Link>            
        </div>
      </header>     

      <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
        <h1>Welcome to OrthoPowerTools, a project of <a href="https://orthotoolkit.com/">OrthoToolKit!</a></h1>

        <p>This initiative strives to improve the way in which people understand and communicate their orthopaedic health by providing all the features of OrthoToolKit as well as an <b>easy-to-use system</b> for clinicians to collect, store, and analyze common outcome measures from their patients.</p>
      
      <br/>
      
        
      <p>To learn more, please visit our <Link to="/">home page</Link></p>
      

 
      

      </div>
    </div>  
            
            
            
        );
    }
    
  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>                 
        <Link to="/" style={{padding:15}}>Home</Link>            
        </div>
      </header>  
      

            <div style={{marginLeft:50, marginRight:50}}>
            <br/>
            <h2>Send Unique Spanish Language QuickDASH Link To Your Patients</h2>
            <br/>
            <form onSubmit={this.handleSubmitSendInvite}>
      
      
            <h4>Please fill in the below three text inputs. When you click the 'Send Score Invite' button, an email will be sent to your patient that contains a unique link to a Spanish language QuickDASH survey. When the patient finishes the survey and clicks submit, an English translation will automatically be shared with you. An example email is listed below.</h4> 
      
      
     
              <h4>Patient email address:</h4>                    
              <input type="text" name="patient_email_to_send_score" placeholder="Enter patient's email address here" onChange={this.handleChange} value={this.state.patient_email_to_send_score} /> 
      
              <h4>Patient given name:</h4>                          
              <input type="text" name="patient_name_to_send_score" placeholder="Enter patient's given name here (how name will be displayed on saved score)" onChange={this.handleChange} value={this.state.patient_name_to_send_score} /> 
      
              <h4>Your given name:</h4>                                
              <input type="text" name="clinician_name_to_send_score" placeholder="Enter the your name here (as you would like it to appear in the score invitation)" onChange={this.handleChange} value={this.state.clinician_name_to_send_score} />       
              <br />
      
          <button style = {{width: '100%'}}>Send Score Invite</button>    
      
      
      
    <table style={{width:"100%"}}>
        <tr>    
            <td><span>
      
            <p><h4>Email text:</h4></p>
            <p><h5>To: {this.state.patient_email_to_send_score}</h5></p>
            <p><h5>From: orthopowertools@gmail.com</h5></p>
            <p><h5>Subject: QuickDASH Solicitada Por El Proveedor</h5></p>
      
      
            <p><h5>Hola {this.state.patient_name_to_send_score},<br/><br/>Uno de sus proveedores de atención médica, {this.state.clinician_name_to_send_score}, le ha pedido que complete una encuesta de QuickDASH. La información de esta encuesta es confidencial y solo se compartirá con su proveedor. <br/><br/>Haga clic <u> aquí </u> para completar la encuesta.</h5></p><br/>Atentamente,<br/>OrthoPowerTools 

            </span> </td>    
          
            <td><span>

            <p><h4>English translation:</h4></p>
            <p><h5>To: {this.state.patient_email_to_send_score}</h5></p>
            <p><h5>From: orthopowertools@gmail.com</h5></p>
            <p><h5>Subject: Provider Requested QuickDASH Completion</h5></p>
      
      
            <p><h5>Hello {this.state.patient_name_to_send_score},<br/><br/>One of your health care providers, {this.state.clinician_name_to_send_score}, has asked that you complete a QuickDASH survey. The information in this survey is confidential and will only be shared with your provider. <br/><br/>Please click <u>here</u> to complete the survey.</h5></p><br/>Best regards,<br/>OrthoPowerTools     

            </span> </td>
        </tr>
    </table>       
      
      
      
      

                 

     
                    
            </form>        
      
      
              <div className='user-profile'>
                 <img src={this.state.user.photoURL} />
              </div>
      
      
            <br/>
      
            <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
            {this.state.user ? (
                <span>
                <button onClick={this.logout}>Logout</button>
                </span>
            ) : (
                <StyledFirebaseAuth
                    uiConfig={this.uiConfig}
                    firebaseAuth={firebase.auth()}
                />
  
            )}  
            </div>

            </div>      
      

      
    </div>
  );
}
}
    
export default QuickDASH_Send_Link_Spanish;


