import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';

///////////////////////////////////////////Calculator Methods
    
class Buttons1 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent1(value);
if (value === 4)
this.props.callbackFromParent1_text("Ninguna dificultad con el trabajo habitual..");
if (value === 3)
this.props.callbackFromParent1_text("Un poco de dificultad con el trabajo habitual..");
if (value === 2)
this.props.callbackFromParent1_text("Dificultad moderada con el trabajo habitual..");
if (value === 1)
this.props.callbackFromParent1_text("Mucha dificultad con el trabajo habitual..");
if (value === 0)
this.props.callbackFromParent1_text("Dificultad extrema con el trabajo habitual..");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>1. Alguna parte de su trabajo habitual, quehaceres domésticos, o actividades escolares.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>1. Alguna parte de su trabajo habitual, quehaceres domésticos, o actividades escolares.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons2 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent2(value);
if (value === 4)
this.props.callbackFromParent2_text("Ninguna dificultad con los pasatiempos habituales..");
if (value === 3)
this.props.callbackFromParent2_text("Un poco de dificultad con los pasatiempos habituales..");
if (value === 2)
this.props.callbackFromParent2_text("Dificultad moderada con los pasatiempos habituales..");
if (value === 1)
this.props.callbackFromParent2_text("Mucha dificultad con los pasatiempos habituales..");
if (value === 0)
this.props.callbackFromParent2_text("Dificultad extrema con los pasatiempos habituales..");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>2. Sus pasatiempos usuales, actividades recreativas o deportivas.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>2. Sus pasatiempos usuales, actividades recreativas o deportivas.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons3 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent3(value);
if (value === 4)
this.props.callbackFromParent3_text("Ninguna dificultad para entrar o salir del baño");
if (value === 3)
this.props.callbackFromParent3_text("Un poco de dificultad para entrar o salir del baño");
if (value === 2)
this.props.callbackFromParent3_text("Dificultad moderada para entrar o salir del baño");
if (value === 1)
this.props.callbackFromParent3_text("Mucha dificultad para entrar o salir del baño");
if (value === 0)
this.props.callbackFromParent3_text("Dificultad extrema para entrar o salir del baño");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>3. Entrando o saliendo de la tina.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>3. Entrando o saliendo de la tina.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons4 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent4(value);
if (value === 4)
this.props.callbackFromParent4_text("Ninguna dificultad para caminar entre habitaciones");
if (value === 3)
this.props.callbackFromParent4_text("Un poco de dificultad para caminar entre habitaciones");
if (value === 2)
this.props.callbackFromParent4_text("Dificultad moderada para caminar entre habitaciones");
if (value === 1)
this.props.callbackFromParent4_text("Mucha dificultad para caminar entre habitaciones");
if (value === 0)
this.props.callbackFromParent4_text("Dificultad extrema para caminar entre habitaciones");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>4. Caminando de una habitación a otra.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>4. Caminando de una habitación a otra.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons5 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent5(value);
if (value === 4)
this.props.callbackFromParent5_text("Ninguna dificultad para ponerse los zapatos o los calcetines");
if (value === 3)
this.props.callbackFromParent5_text("Un poco de dificultad para ponerse los zapatos o los calcetines");
if (value === 2)
this.props.callbackFromParent5_text("Dificultad moderada para ponerse los zapatos o los calcetines");
if (value === 1)
this.props.callbackFromParent5_text("Mucha dificultad para ponerse los zapatos o los calcetines");
if (value === 0)
this.props.callbackFromParent5_text("Dificultad extrema para ponerse los zapatos o los calcetines");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>5. Poniéndose los zapatos o medias.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>5. Poniéndose los zapatos o medias.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons6 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent6(value);
if (value === 4)
this.props.callbackFromParent6_text("Ninguna dificultad para ponerse en cuclillas");
if (value === 3)
this.props.callbackFromParent6_text("Un poco de dificultad para ponerse en cuclillas");
if (value === 2)
this.props.callbackFromParent6_text("Dificultad moderada para ponerse en cuclillas");
if (value === 1)
this.props.callbackFromParent6_text("Mucha dificultad para ponerse en cuclillas");
if (value === 0)
this.props.callbackFromParent6_text("Dificultad extrema para ponerse en cuclillas");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>6. Poniéndose en cuclillas.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>6. Poniéndose en cuclillas.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons7 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent7(value);
if (value === 4)
this.props.callbackFromParent7_text("Ninguna dificultad para levantar un objeto del suelo");
if (value === 3)
this.props.callbackFromParent7_text("Un poco de dificultad para levantar un objeto del suelo");
if (value === 2)
this.props.callbackFromParent7_text("Dificultad moderada para levantar un objeto del suelo");
if (value === 1)
this.props.callbackFromParent7_text("Mucha dificultad para levantar un objeto del suelo");
if (value === 0)
this.props.callbackFromParent7_text("Dificultad extrema para levantar un objeto del suelo");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>7. Levantando un objeto, por ejemplo, una bolsa de compras de supermercado del piso.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>7. Levantando un objeto, por ejemplo, una bolsa de compras de supermercado del piso.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons8 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent8(value);
if (value === 4)
this.props.callbackFromParent8_text("Ninguna dificultad para realizar actividades ligeras en casa");
if (value === 3)
this.props.callbackFromParent8_text("Un poco de dificultad para realizar actividades ligeras en casa");
if (value === 2)
this.props.callbackFromParent8_text("Dificultad moderada para realizar actividades ligeras en casa");
if (value === 1)
this.props.callbackFromParent8_text("Mucha dificultad para realizar actividades ligeras en casa");
if (value === 0)
this.props.callbackFromParent8_text("Dificultad extrema para realizar actividades ligeras en casa");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>8. Realizando actividades ligeras en su casa.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>8. Realizando actividades ligeras en su casa.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons9 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent9(value);
if (value === 4)
this.props.callbackFromParent9_text("Ninguna dificultad para realizar actividades pesadas en casa");
if (value === 3)
this.props.callbackFromParent9_text("Un poco de dificultad para realizar actividades pesadas en casa");
if (value === 2)
this.props.callbackFromParent9_text("Dificultad moderada para realizar actividades pesadas en casa");
if (value === 1)
this.props.callbackFromParent9_text("Mucha dificultad para realizar actividades pesadas en casa");
if (value === 0)
this.props.callbackFromParent9_text("Dificultad extrema para realizar actividades pesadas en casa");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>9. Realizando actividades pesadas en su casa.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>9. Realizando actividades pesadas en su casa.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons10 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent10(value);
if (value === 4)
this.props.callbackFromParent10_text("Ninguna dificultad para entrar o salir de un coche");
if (value === 3)
this.props.callbackFromParent10_text("Un poco de dificultad para entrar o salir de un coche");
if (value === 2)
this.props.callbackFromParent10_text("Dificultad moderada para entrar o salir de un coche");
if (value === 1)
this.props.callbackFromParent10_text("Mucha dificultad para entrar o salir de un coche");
if (value === 0)
this.props.callbackFromParent10_text("Dificultad extrema para entrar o salir de un coche");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>10. Subiéndose o bajándose de un carro.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>10. Subiéndose o bajándose de un carro.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons11 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent11(value);
if (value === 4)
this.props.callbackFromParent11_text("Ninguna dificultad para caminar dos cuadras");
if (value === 3)
this.props.callbackFromParent11_text("Un poco de dificultad para caminar dos cuadras");
if (value === 2)
this.props.callbackFromParent11_text("Dificultad moderada para caminar dos cuadras");
if (value === 1)
this.props.callbackFromParent11_text("Mucha dificultad para caminar dos cuadras");
if (value === 0)
this.props.callbackFromParent11_text("Dificultad extrema para caminar dos cuadras");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>11. Caminando dos cuadras.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>11. Caminando dos cuadras.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons12 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent12(value);
if (value === 4)
this.props.callbackFromParent12_text("Ninguna dificultad para caminar una milla");
if (value === 3)
this.props.callbackFromParent12_text("Un poco de dificultad para caminar una milla");
if (value === 2)
this.props.callbackFromParent12_text("Dificultad moderada para caminar una milla");
if (value === 1)
this.props.callbackFromParent12_text("Mucha dificultad para caminar una milla");
if (value === 0)
this.props.callbackFromParent12_text("Dificultad extrema para caminar una milla");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>12. Caminando una milla.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>12. Caminando una milla.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons13 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent13(value);
if (value === 4)
this.props.callbackFromParent13_text("Ninguna dificultad para subir o bajar 10 escaleras");
if (value === 3)
this.props.callbackFromParent13_text("Un poco de dificultad para subir o bajar 10 escaleras");
if (value === 2)
this.props.callbackFromParent13_text("Dificultad moderada para subir o bajar 10 escaleras");
if (value === 1)
this.props.callbackFromParent13_text("Mucha dificultad para subir o bajar 10 escaleras");
if (value === 0)
this.props.callbackFromParent13_text("Dificultad extrema para subir o bajar 10 escaleras");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>13. Subiendo o bajando 10 peldaños de una escalera.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>13. Subiendo o bajando 10 peldaños de una escalera.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons14 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent14(value);
if (value === 4)
this.props.callbackFromParent14_text("Ninguna dificultad estando parado por una hora");
if (value === 3)
this.props.callbackFromParent14_text("Un poco de dificultad estando parado por una hora");
if (value === 2)
this.props.callbackFromParent14_text("Dificultad moderada estando parado por una hora");
if (value === 1)
this.props.callbackFromParent14_text("Mucha dificultad estando parado por una hora");
if (value === 0)
this.props.callbackFromParent14_text("Dificultad extrema estando parado por una hora");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>14. Estando parado por una hora.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>14. Estando parado por una hora.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons15 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent15(value);
if (value === 4)
this.props.callbackFromParent15_text("Ninguna dificultad estando sentado por una hora");
if (value === 3)
this.props.callbackFromParent15_text("Un poco de dificultad estando sentado por una hora");
if (value === 2)
this.props.callbackFromParent15_text("Dificultad moderada estando sentado por una hora");
if (value === 1)
this.props.callbackFromParent15_text("Mucha dificultad estando sentado por una hora");
if (value === 0)
this.props.callbackFromParent15_text("Dificultad extrema estando sentado por una hora");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>15. Estando sentado por una hora.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>15. Estando sentado por una hora.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons16 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent16(value);
if (value === 4)
this.props.callbackFromParent16_text("Ninguna dificultad corriendo sobre terreno plano");
if (value === 3)
this.props.callbackFromParent16_text("Un poco de dificultad corriendo sobre terreno plano");
if (value === 2)
this.props.callbackFromParent16_text("Dificultad moderada corriendo sobre terreno plano");
if (value === 1)
this.props.callbackFromParent16_text("Mucha dificultad corriendo sobre terreno plano");
if (value === 0)
this.props.callbackFromParent16_text("Dificultad extrema corriendo sobre terreno plano");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>16. Corriendo sobre terreno plano.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>16. Corriendo sobre terreno plano.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons17 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent17(value);
if (value === 4)
this.props.callbackFromParent17_text("Ninguna dificultad corriendo sobre terreno irregular");
if (value === 3)
this.props.callbackFromParent17_text("Un poco de dificultad corriendo sobre terreno irregular");
if (value === 2)
this.props.callbackFromParent17_text("Dificultad moderada corriendo sobre terreno irregular");
if (value === 1)
this.props.callbackFromParent17_text("Mucha dificultad corriendo sobre terreno irregular");
if (value === 0)
this.props.callbackFromParent17_text("Dificultad extrema corriendo sobre terreno irregular");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>17. Corriendo sobre terreno irregular.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>17. Corriendo sobre terreno irregular.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons18 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent18(value);
if (value === 4)
this.props.callbackFromParent18_text("Ninguna dificultad haciendo vueltas agudas mientras corre rápidamente");
if (value === 3)
this.props.callbackFromParent18_text("Un poco de dificultad haciendo vueltas agudas mientras corre rápidamente");
if (value === 2)
this.props.callbackFromParent18_text("Dificultad moderada haciendo vueltas agudas mientras corre rápidamente");
if (value === 1)
this.props.callbackFromParent18_text("Mucha dificultad haciendo vueltas agudas mientras corre rápidamente");
if (value === 0)
this.props.callbackFromParent18_text("Dificultad extrema haciendo vueltas agudas mientras corre rápidamente");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>18. Haciendo vueltas agudas mientras corre rápidamente.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>18. Haciendo vueltas agudas mientras corre rápidamente.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons19 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent19(value);
if (value === 4)
this.props.callbackFromParent19_text("Ninguna dificultad saltando");
if (value === 3)
this.props.callbackFromParent19_text("Un poco de dificultad saltando");
if (value === 2)
this.props.callbackFromParent19_text("Dificultad moderada saltando");
if (value === 1)
this.props.callbackFromParent19_text("Mucha dificultad saltando");
if (value === 0)
this.props.callbackFromParent19_text("Dificultad extrema saltando");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>19. Saltando.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>19. Saltando.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons20 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent20(value);
if (value === 4)
this.props.callbackFromParent20_text("Ninguna dificultad volteandose en la cama");
if (value === 3)
this.props.callbackFromParent20_text("Un poco de dificultad volteandose en la cama");
if (value === 2)
this.props.callbackFromParent20_text("Dificultad moderada volteandose en la cama");
if (value === 1)
this.props.callbackFromParent20_text("Mucha dificultad volteandose en la cama");
if (value === 0)
this.props.callbackFromParent20_text("Dificultad extrema volteandose en la cama");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>20. Volteandose en la cama.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>20. Volteandose en la cama.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ninguna dificultad (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Un poco de dificultad (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad moderada (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mucha dificultad (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dificultad extrema o incapaz de realizar la actividad (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}  

  


class LEFS_Link_Spanish extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to


        
    //Calculator variables    
    selectedValue1: 4,
    selectedValue1_text: 'Ninguna dificultad con el trabajo habitual..',

    selectedValue2: 4,
    selectedValue2_text: 'Ninguna dificultad con los pasatiempos habituales..',

    selectedValue3: 4,
    selectedValue3_text: 'Ninguna dificultad para entrar o salir del baño',

    selectedValue4: 4,
    selectedValue4_text: 'Ninguna dificultad para caminar entre habitaciones',

    selectedValue5: 4,
    selectedValue5_text: 'Ninguna dificultad para ponerse los zapatos o los calcetines',

    selectedValue6: 4,
    selectedValue6_text: 'Ninguna dificultad para ponerse en cuclillas',

    selectedValue7: 4,
    selectedValue7_text: 'Ninguna dificultad para levantar un objeto del suelo',

    selectedValue8: 4,
    selectedValue8_text: 'Ninguna dificultad para realizar actividades ligeras en casa',

    selectedValue9: 4,
    selectedValue9_text: 'Ninguna dificultad para realizar actividades pesadas en casa',

    selectedValue10: 4,
    selectedValue10_text: 'Ninguna dificultad para entrar o salir de un coche',

    selectedValue11: 4,
    selectedValue11_text: 'Ninguna dificultad para caminar dos cuadras',

    selectedValue12: 4,
    selectedValue12_text: 'Ninguna dificultad para caminar una milla',

    selectedValue13: 4,
    selectedValue13_text: 'Ninguna dificultad para subir o bajar 10 escaleras',

    selectedValue14: 4,
    selectedValue14_text: 'Ninguna dificultad estando parado por una hora',

    selectedValue15: 4,
    selectedValue15_text: 'Ninguna dificultad estando sentado por una hora',

    selectedValue16: 4,
    selectedValue16_text: 'Ninguna dificultad corriendo sobre terreno plano',

    selectedValue17: 4,
    selectedValue17_text: 'Ninguna dificultad corriendo sobre terreno irregular',

    selectedValue18: 4,
    selectedValue18_text: 'Ninguna dificultad haciendo vueltas agudas mientras corre rápidamente',

    selectedValue19: 4,
    selectedValue19_text: 'Ninguna dificultad saltando',

    selectedValue20: 4,
    selectedValue20_text: 'Ninguna dificultad volteandose en la cama ',     
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const LEFSScoresRef = firebase.firestore();
  LEFSScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  LEFSScoresRef.collection("lefs_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const LEFSScoresRef = firebase.firestore();
  LEFSScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  LEFSScoresRef.collection("lefs_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const LEFSScoresRef = firebase.firestore();
  LEFSScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  LEFSScoresRef.collection("lefs_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const LEFSScoresRef = firebase.firestore();
  LEFSScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  LEFSScoresRef.collection("lefs_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const LEFSScoresRef = firebase.firestore();
  LEFSScoresRef.settings({
      timestampsInSnapshots: true
  });  
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){    
  const lefs_score = LEFSScoresRef.collection("lefs_scores").doc(this.props.requestSurveyID).set({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Spanish Lower Extremity Functional Scale',
    Question_01_LEFS: this.state.selectedValue1,
    Question_02_LEFS: this.state.selectedValue2,
    Question_03_LEFS: this.state.selectedValue3,
    Question_04_LEFS: this.state.selectedValue4,
    Question_05_LEFS: this.state.selectedValue5,
    Question_06_LEFS: this.state.selectedValue6,
    Question_07_LEFS: this.state.selectedValue7,            
    Question_08_LEFS: this.state.selectedValue8,
    Question_09_LEFS: this.state.selectedValue9,
    Question_10_LEFS: this.state.selectedValue10,
    Question_11_LEFS: this.state.selectedValue11,
    Question_12_LEFS: this.state.selectedValue12,
    Question_13_LEFS: this.state.selectedValue13,
    Question_14_LEFS: this.state.selectedValue14,
    Question_15_LEFS: this.state.selectedValue15,
    Question_16_LEFS: this.state.selectedValue16,
    Question_17_LEFS: this.state.selectedValue17,
    Question_18_LEFS: this.state.selectedValue18,
    Question_19_LEFS: this.state.selectedValue19,
    Question_20_LEFS: this.state.selectedValue20,
    Total_Score_LEFS: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20),       
  });
}
else {
  const lefs_score = LEFSScoresRef.collection("lefs_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'Spanish Lower Extremity Functional Scale',
    Question_01_LEFS: this.state.selectedValue1,
    Question_02_LEFS: this.state.selectedValue2,
    Question_03_LEFS: this.state.selectedValue3,
    Question_04_LEFS: this.state.selectedValue4,
    Question_05_LEFS: this.state.selectedValue5,
    Question_06_LEFS: this.state.selectedValue6,
    Question_07_LEFS: this.state.selectedValue7,            
    Question_08_LEFS: this.state.selectedValue8,
    Question_09_LEFS: this.state.selectedValue9,
    Question_10_LEFS: this.state.selectedValue10,
    Question_11_LEFS: this.state.selectedValue11,
    Question_12_LEFS: this.state.selectedValue12,
    Question_13_LEFS: this.state.selectedValue13,
    Question_14_LEFS: this.state.selectedValue14,
    Question_15_LEFS: this.state.selectedValue15,
    Question_16_LEFS: this.state.selectedValue16,
    Question_17_LEFS: this.state.selectedValue17,
    Question_18_LEFS: this.state.selectedValue18,
    Question_19_LEFS: this.state.selectedValue19,
    Question_20_LEFS: this.state.selectedValue20,
    Total_Score_LEFS: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20),    
  });      
}     
  this.setState({
    clinician_to_share_with: '',
    user_id: '',      
  });
    alert('¡Gracias por usar OrthoPowerTools! Se recibió su envío y se compartirá con el siguiente proveedor: '+ this.props.clinicianSendingEmail +'. Ahora puede cerrar esta alerta y toda la ventana.');    
}
        
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("lefs_spanish_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallback1  = (dataFromChild) => {
    this.setState({selectedValue1: dataFromChild});
    }
    myCallback1_text  = (dataFromChild) => {
    this.setState({selectedValue1_text: dataFromChild});
    }
    myCallback2  = (dataFromChild) => {
    this.setState({selectedValue2: dataFromChild});
    }
    myCallback2_text  = (dataFromChild) => {
    this.setState({selectedValue2_text: dataFromChild});
    }
    myCallback3  = (dataFromChild) => {
    this.setState({selectedValue3: dataFromChild});
    }
    myCallback3_text  = (dataFromChild) => {
    this.setState({selectedValue3_text: dataFromChild});
    }
    myCallback4  = (dataFromChild) => {
    this.setState({selectedValue4: dataFromChild});
    }
    myCallback4_text  = (dataFromChild) => {
    this.setState({selectedValue4_text: dataFromChild});
    }
    myCallback5  = (dataFromChild) => {
    this.setState({selectedValue5: dataFromChild});
    }
    myCallback5_text  = (dataFromChild) => {
    this.setState({selectedValue5_text: dataFromChild});
    }
    myCallback6  = (dataFromChild) => {
    this.setState({selectedValue6: dataFromChild});
    }
    myCallback6_text  = (dataFromChild) => {
    this.setState({selectedValue6_text: dataFromChild});
    }
    myCallback7  = (dataFromChild) => {
    this.setState({selectedValue7: dataFromChild});
    }
    myCallback7_text  = (dataFromChild) => {
    this.setState({selectedValue7_text: dataFromChild});
    }
    myCallback8  = (dataFromChild) => {
    this.setState({selectedValue8: dataFromChild});
    }
    myCallback8_text  = (dataFromChild) => {
    this.setState({selectedValue8_text: dataFromChild});
    }
    myCallback9  = (dataFromChild) => {
    this.setState({selectedValue9: dataFromChild});
    }
    myCallback9_text  = (dataFromChild) => {
    this.setState({selectedValue9_text: dataFromChild});
    }
    myCallback10  = (dataFromChild) => {
    this.setState({selectedValue10: dataFromChild});
    }
    myCallback10_text  = (dataFromChild) => {
    this.setState({selectedValue10_text: dataFromChild});
    }     
    myCallback11  = (dataFromChild) => {
    this.setState({selectedValue11: dataFromChild});
    }
    myCallback11_text  = (dataFromChild) => {
    this.setState({selectedValue11_text: dataFromChild});
    }
    myCallback12  = (dataFromChild) => {
    this.setState({selectedValue12: dataFromChild});
    }
    myCallback12_text  = (dataFromChild) => {
    this.setState({selectedValue12_text: dataFromChild});
    }
    myCallback13  = (dataFromChild) => {
    this.setState({selectedValue13: dataFromChild});
    }
    myCallback13_text  = (dataFromChild) => {
    this.setState({selectedValue13_text: dataFromChild});
    }
    myCallback14  = (dataFromChild) => {
    this.setState({selectedValue14: dataFromChild});
    }
    myCallback14_text  = (dataFromChild) => {
    this.setState({selectedValue14_text: dataFromChild});
    }
    myCallback15  = (dataFromChild) => {
    this.setState({selectedValue15: dataFromChild});
    }
    myCallback15_text  = (dataFromChild) => {
    this.setState({selectedValue15_text: dataFromChild});
    }
    myCallback16  = (dataFromChild) => {
    this.setState({selectedValue16: dataFromChild});
    }
    myCallback16_text  = (dataFromChild) => {
    this.setState({selectedValue16_text: dataFromChild});
    }
    myCallback17  = (dataFromChild) => {
    this.setState({selectedValue17: dataFromChild});
    }
    myCallback17_text  = (dataFromChild) => {
    this.setState({selectedValue17_text: dataFromChild});
    }
    myCallback18  = (dataFromChild) => {
    this.setState({selectedValue18: dataFromChild});
    }
    myCallback18_text  = (dataFromChild) => {
    this.setState({selectedValue18_text: dataFromChild});
    }
    myCallback19  = (dataFromChild) => {
    this.setState({selectedValue19: dataFromChild});
    }
    myCallback19_text  = (dataFromChild) => {
    this.setState({selectedValue19_text: dataFromChild});
    }
    myCallback20  = (dataFromChild) => {
    this.setState({selectedValue20: dataFromChild});
    }
    myCallback20_text  = (dataFromChild) => {
    this.setState({selectedValue20_text: dataFromChild});
    }

  
    
    

    
render() { 

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>Escala Funcional De La Extremidad Inferior Calculadora</h1>
      
                <h4>Hola, {this.props.submitterName}, su médico, {this.props.clinicianSendingEmail}, le envió esta encuesta para ayudarlo a comprender mejor su salud. Haga clic en los botones correspondientes a continuación y luego haga clic en "Enviar Puntuación". Tenga en cuenta que su <u> privacidad digital se toma muy en serio </u> y que se han implementado varias tecnologías para proteger la privacidad y la integridad de sus datos, incluido el uso de cifrado SSL, almacenamiento compatible con HIPAA y servicios de autenticación de última generación. Sus datos <u>nunca se compartirán con nadie más que el médico asignado</u>.</h4>

            <form onSubmit={this.handleSubmit}>



                <StickyContainer>
                <Sticky>
                {({ style }) => <div className="sticky_style" style={style}><Well>Escala Funcional De La Extremidad Inferior: {this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20} / {80} = {(((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20)/(80)*100).toFixed(1))} %
                <p/>
                Gráfica Escala Funcional De La Extremidad Inferior: (%)
                <ProgressBar>
                <ProgressBar active now={100*((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20)/(80))}/>
                <ProgressBar active bsStyle="danger" now={100-(100*((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20)/(80)))}/>
                </ProgressBar>
                </Well></div>}
                </Sticky>
                <p />

                <br/>                      
                
                <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
                <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
                <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
                <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
                <Buttons5 callbackFromParent5={this.myCallback5} callbackFromParent5_text={this.myCallback5_text}/>
                <Buttons6 callbackFromParent6={this.myCallback6} callbackFromParent6_text={this.myCallback6_text}/>
                <Buttons7 callbackFromParent7={this.myCallback7} callbackFromParent7_text={this.myCallback7_text}/>
                <Buttons8 callbackFromParent8={this.myCallback8} callbackFromParent8_text={this.myCallback8_text}/>
                <Buttons9 callbackFromParent9={this.myCallback9} callbackFromParent9_text={this.myCallback9_text}/>
                <Buttons10 callbackFromParent10={this.myCallback10} callbackFromParent10_text={this.myCallback10_text}/>
                <Buttons11 callbackFromParent11={this.myCallback11} callbackFromParent11_text={this.myCallback11_text}/>
                <Buttons12 callbackFromParent12={this.myCallback12} callbackFromParent12_text={this.myCallback12_text}/>
                <Buttons13 callbackFromParent13={this.myCallback13} callbackFromParent13_text={this.myCallback13_text}/>
                <Buttons14 callbackFromParent14={this.myCallback14} callbackFromParent14_text={this.myCallback14_text}/>
                <Buttons15 callbackFromParent15={this.myCallback15} callbackFromParent15_text={this.myCallback15_text}/>
                <Buttons16 callbackFromParent16={this.myCallback16} callbackFromParent16_text={this.myCallback16_text}/>
                <Buttons17 callbackFromParent17={this.myCallback17} callbackFromParent17_text={this.myCallback17_text}/>
                <Buttons18 callbackFromParent18={this.myCallback18} callbackFromParent18_text={this.myCallback18_text}/>
                <Buttons19 callbackFromParent19={this.myCallback19} callbackFromParent19_text={this.myCallback19_text}/>
                <Buttons20 callbackFromParent20={this.myCallback20} callbackFromParent20_text={this.myCallback20_text}/>
                </StickyContainer>
                <br />
                    
              <button style = {{width: '100%'}}>Enviar Puntuación</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(LEFS_Link_Spanish);
