import React, { PureComponent} from "react";
import {Link} from "react-router-dom";
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';



class Navbar extends PureComponent {

  constructor() {
    super();
    this.state = {
      user: null        
    }
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);       
  }    
    
componentDidMount() {    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    } 
  });    
    
  const itemsRef = firebase.database().ref('items');
  itemsRef.on('value', (snapshot) => {
    let items = snapshot.val();
    let newState = [];
    for (let item in items) {
      newState.push({
        id: item,
        title: items[item].title,
        user: items[item].user
      });
    }
    this.setState({
      items: newState
    });
  });     
    
}      
    
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}      
      
    
render() {    
    return (    

    <div className='navbar'>        
      <header>
        <div className="wrapper">
          <h1>OrthoPowerTools</h1>
        
            <h1><Link to="/">Home</Link></h1>    
            <h1><Link to="/FunFoodFriends">Fun Food Friends </Link></h1>      
            <h1><Link to="/ODI">Oswestry Disability Index </Link></h1>         
        
          {this.state.user ?
            <button onClick={this.logout}>Logout</button>                
          :
            <button onClick={this.login}>Log In</button>              
          }
        </div>
      </header> 
    </div>    
        
        
    );
}
}
export default Navbar;


