import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
import {Bar} from "react-chartjs-2";


///////////////////////////////////////////Calculator Methods
    
class Buttons1 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent1(value);
if (value === 1)
this.props.callbackFromParent1_text("Very poor quality of life");
if (value === 2)
this.props.callbackFromParent1_text("Poor quality of life");
if (value === 3)
this.props.callbackFromParent1_text("Neither poor nor good quality of life");
if (value === 4)
this.props.callbackFromParent1_text("Good quality of life");
if (value === 5)
this.props.callbackFromParent1_text("Very good quality of life");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>1. How would you rate your quality of life?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very poor (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Poor (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither poor nor good (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Good (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very good (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>1. How would you rate your quality of life?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very poor (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Poor (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither poor nor good (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Good (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very good (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons2 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent2(value);
if (value === 1)
this.props.callbackFromParent2_text("Very dissatisfied with health");
if (value === 2)
this.props.callbackFromParent2_text("Dissatisfied with health");
if (value === 3)
this.props.callbackFromParent2_text("Neither satisfied nor dissatisfied with health");
if (value === 4)
this.props.callbackFromParent2_text("Satisfied with health");
if (value === 5)
this.props.callbackFromParent2_text("Very satisfied with health");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>2. How satisfied are you with your health?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>2. How satisfied are you with your health?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons3 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent3(value);
if (value === 1)
this.props.callbackFromParent3_text("Physical pain does not prevent doing needs");
if (value === 2)
this.props.callbackFromParent3_text("Physical pain prevents doing needs a little");
if (value === 3)
this.props.callbackFromParent3_text("Physical pain prevents doing needs a moderate amount");
if (value === 4)
this.props.callbackFromParent3_text("Physical pain very much prevents doing needs");
if (value === 5)
this.props.callbackFromParent3_text("Physical pain prevents doing needs an extreme amount");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>3. To what extent do you feel that physical pain prevents you from doing what you need to do?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A moderate amount (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very much (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} An extreme amount (1)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>3. To what extent do you feel that physical pain prevents you from doing what you need to do?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A moderate amount (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very much (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} An extreme amount (1)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons4 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent4(value);
if (value === 1)
this.props.callbackFromParent4_text("Medical treatment not needed to function");
if (value === 2)
this.props.callbackFromParent4_text("Medical treatment needed a little to function");
if (value === 3)
this.props.callbackFromParent4_text("Medical treatment needed a moderate amount to function");
if (value === 4)
this.props.callbackFromParent4_text("Medical treatment needed very much to function");
if (value === 5)
this.props.callbackFromParent4_text("Medical treatment needed an extreme amount to function");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>4. How much do you need any medical treatment to function in your daily life?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A moderate amount (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very much (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} An extreme amount (1)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>4. How much do you need any medical treatment to function in your daily life?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A moderate amount (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very much (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} An extreme amount (1)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons5 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent5(value);
if (value === 1)
this.props.callbackFromParent5_text("Do not enjoy life at all");
if (value === 2)
this.props.callbackFromParent5_text("Enjoy life a little");
if (value === 3)
this.props.callbackFromParent5_text("Enjoy life a moderate amount");
if (value === 4)
this.props.callbackFromParent5_text("Enjoy life very much");
if (value === 5)
this.props.callbackFromParent5_text("Enjoy life an extreme amount");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>5. How much do you enjoy life?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A moderate amount (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very much (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} An extreme amount (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>5. How much do you enjoy life?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A moderate amount (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very much (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} An extreme amount (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons6 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent6(value);
if (value === 1)
this.props.callbackFromParent6_text("Do not find life meaningful at all");
if (value === 2)
this.props.callbackFromParent6_text("Find life meaningful a little");
if (value === 3)
this.props.callbackFromParent6_text("Find life meaningful a moderate amount");
if (value === 4)
this.props.callbackFromParent6_text("Find life meaningful very much");
if (value === 5)
this.props.callbackFromParent6_text("Find life meaningful an extreme amount");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>6. To what extent do you feel your life to be meaningful?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A moderate amount (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very much (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} An extreme amount (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>6. To what extent do you feel your life to be meaningful?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A moderate amount (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very much (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} An extreme amount (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons7 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent7(value);
if (value === 1)
this.props.callbackFromParent7_text("Not at all able to concentrate");
if (value === 2)
this.props.callbackFromParent7_text("Able to concentrate a little");
if (value === 3)
this.props.callbackFromParent7_text("Able to concentrate a moderate amount");
if (value === 4)
this.props.callbackFromParent7_text("Very much able to concentrate");
if (value === 5)
this.props.callbackFromParent7_text("Extremely able to concentrate");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>7. How well are you able to concentrate?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A moderate amount (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very much (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Extremely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>7. How well are you able to concentrate?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A moderate amount (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very much (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Extremely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons8 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent8(value);
if (value === 1)
this.props.callbackFromParent8_text("Do not fell safe at all");
if (value === 2)
this.props.callbackFromParent8_text("Feel a little safe");
if (value === 3)
this.props.callbackFromParent8_text("Feel safe a moderate amount");
if (value === 4)
this.props.callbackFromParent8_text("Very much feel safe");
if (value === 5)
this.props.callbackFromParent8_text("Feel extremely safe");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>8. How safe do you feel in your daily life?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A moderate amount (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very much (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Extremely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>8. How safe do you feel in your daily life?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A moderate amount (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very much (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Extremely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons9 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent9(value);
if (value === 1)
this.props.callbackFromParent9_text("Physical environment is not at all healty");
if (value === 2)
this.props.callbackFromParent9_text("Physical environment is a little healthy");
if (value === 3)
this.props.callbackFromParent9_text("Physical environment is healthy a moderate amount");
if (value === 4)
this.props.callbackFromParent9_text("Physical environment is very much healthy");
if (value === 5)
this.props.callbackFromParent9_text("Physical environment is extremely healthy");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>9. How healthy is your physical environment?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A moderate amount (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very much (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Extremely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>9. How healthy is your physical environment?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A moderate amount (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very much (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Extremely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons10 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent10(value);
if (value === 1)
this.props.callbackFromParent10_text("Have enough energy for everyday life not at all");
if (value === 2)
this.props.callbackFromParent10_text("Have enough energy for everyday life a little");
if (value === 3)
this.props.callbackFromParent10_text("Have enough energy for everyday life moderately");
if (value === 4)
this.props.callbackFromParent10_text("Have enough energy for everyday life mostly");
if (value === 5)
this.props.callbackFromParent10_text("Have enough energy for everyday life completely");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>10. Do you have enough energy for everyday life?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderately (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mostly (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Completely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>10. Do you have enough energy for everyday life?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderately (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mostly (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Completely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons11 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent11(value);
if (value === 1)
this.props.callbackFromParent11_text("Able to accept bodily appearance not at all");
if (value === 2)
this.props.callbackFromParent11_text("Able to accept bodily appearance a little");
if (value === 3)
this.props.callbackFromParent11_text("Able to accept bodily appearance moderately");
if (value === 4)
this.props.callbackFromParent11_text("Able to accept bodily appearance mostly");
if (value === 5)
this.props.callbackFromParent11_text("Able to accept bodily appearance completely");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>11. Are you able to accept your bodily appearance?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderately (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mostly (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Completely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>11. Are you able to accept your bodily appearance?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderately (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mostly (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Completely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons12 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent12(value);
if (value === 1)
this.props.callbackFromParent12_text("Have enough money to meet needs not at all");
if (value === 2)
this.props.callbackFromParent12_text("Have you enough money to meet needs a little");
if (value === 3)
this.props.callbackFromParent12_text("Have you enough money to meet needs moderately");
if (value === 4)
this.props.callbackFromParent12_text("Have you enough money to meet needs mostly");
if (value === 5)
this.props.callbackFromParent12_text("Have you enough money to meet needs completely");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>12. Have you enough money to meet your needs?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderately (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mostly (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Completely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>12. Have you enough money to meet your needs?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderately (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mostly (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Completely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons13 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent13(value);
if (value === 1)
this.props.callbackFromParent13_text("Information needed for day-to-day life not available at all");
if (value === 2)
this.props.callbackFromParent13_text("Information needed for day-to-day life available a little");
if (value === 3)
this.props.callbackFromParent13_text("Information needed for day-to-day life moderately available");
if (value === 4)
this.props.callbackFromParent13_text("Information needed for day-to-day life mostly available");
if (value === 5)
this.props.callbackFromParent13_text("Information needed for day-to-day life available completely");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>13. How available to you is the information that you need in your day-to-day life?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderately (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mostly (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Completely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>13. How available to you is the information that you need in your day-to-day life?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderately (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mostly (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Completely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons14 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent14(value);
if (value === 1)
this.props.callbackFromParent14_text("Do not have opportunities for leisure activities at all");
if (value === 2)
this.props.callbackFromParent14_text("Have opportunities for leisure activities a little");
if (value === 3)
this.props.callbackFromParent14_text("Have opportunities for leisure activities moderately");
if (value === 4)
this.props.callbackFromParent14_text("Have opportunities for leisure activities mostly");
if (value === 5)
this.props.callbackFromParent14_text("Have opportunities for leisure activities completely");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>14. To what extent do you have the opportunity for leisure activities?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderately (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mostly (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Completely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>14. To what extent do you have the opportunity for leisure activities?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderately (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mostly (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Completely (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons15 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent15(value);
if (value === 1)
this.props.callbackFromParent15_text("Able to get around very poorly");
if (value === 2)
this.props.callbackFromParent15_text("Able to get around poorly");
if (value === 3)
this.props.callbackFromParent15_text("Able to get around neither poorly nor good");
if (value === 4)
this.props.callbackFromParent15_text("Able to get around good");
if (value === 5)
this.props.callbackFromParent15_text("Able to get around very good");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>15. How well are you able to get around?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very poor (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Poor (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither poor nor good (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Good (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very good (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>15. How well are you able to get around?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very poor (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Poor (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither poor nor good (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Good (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very good (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons16 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent16(value);
if (value === 1)
this.props.callbackFromParent16_text("Very dissatisfied with sleep");
if (value === 2)
this.props.callbackFromParent16_text("Dissatisfied with sleep");
if (value === 3)
this.props.callbackFromParent16_text("Neither satisfied nor dissatisfied with sleep");
if (value === 4)
this.props.callbackFromParent16_text("Satisfied with sleep");
if (value === 5)
this.props.callbackFromParent16_text("Very satisfied with sleep");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>16. How satisfied are you with your sleep?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>16. How satisfied are you with your sleep?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons17 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent17(value);
if (value === 1)
this.props.callbackFromParent17_text("Very dissatisfied with ability to perform daily living activities");
if (value === 2)
this.props.callbackFromParent17_text("Dissatisfied with ability to perform daily living activities");
if (value === 3)
this.props.callbackFromParent17_text("Neither satisfied nor dissatisfied with ability to perform daily living activities");
if (value === 4)
this.props.callbackFromParent17_text("Satisfied with ability to perform daily living activities");
if (value === 5)
this.props.callbackFromParent17_text("Very satisfied with ability to perform daily living activities");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>17. How satisfied are you with your ability to perform your daily living activities?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>17. How satisfied are you with your ability to perform your daily living activities?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons18 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent18(value);
if (value === 1)
this.props.callbackFromParent18_text("Very dissatisfied with capacity for work");
if (value === 2)
this.props.callbackFromParent18_text("Dissatisfied with capacity for work");
if (value === 3)
this.props.callbackFromParent18_text("Neither satisfied nor dissatisfied with capacity for work");
if (value === 4)
this.props.callbackFromParent18_text("Satisfied with capacity for work");
if (value === 5)
this.props.callbackFromParent18_text("Very satisfied with capacity for work");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>18. How satisfied are you with your capacity for work?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>18. How satisfied are you with your capacity for work?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons19 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent19(value);
if (value === 1)
this.props.callbackFromParent19_text("Very dissatisfied with self");
if (value === 2)
this.props.callbackFromParent19_text("Dissatisfied with self");
if (value === 3)
this.props.callbackFromParent19_text("Neither satisfied nor dissatisfied with self");
if (value === 4)
this.props.callbackFromParent19_text("Satisfied with self");
if (value === 5)
this.props.callbackFromParent19_text("Very satisfied with self");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>19. How satisfied are you with yourself?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>19. How satisfied are you with yourself?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons20 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent20(value);
if (value === 1)
this.props.callbackFromParent20_text("Very dissatisfied with personal relationships");
if (value === 2)
this.props.callbackFromParent20_text("Dissatisfied with personal relationships");
if (value === 3)
this.props.callbackFromParent20_text("Neither satisfied nor dissatisfied with personal relationships");
if (value === 4)
this.props.callbackFromParent20_text("Satisfied with personal relationships");
if (value === 5)
this.props.callbackFromParent20_text("Very satisfied with personal relationships");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>20. How satisfied are you with your personal relationships?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>20. How satisfied are you with your personal relationships?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons21 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent21(value);
if (value === 1)
this.props.callbackFromParent21_text("Very dissatisfied with sex life");
if (value === 2)
this.props.callbackFromParent21_text("Dissatisfied with sex life");
if (value === 3)
this.props.callbackFromParent21_text("Neither satisfied nor dissatisfied with sex life");
if (value === 4)
this.props.callbackFromParent21_text("Satisfied with sex life");
if (value === 5)
this.props.callbackFromParent21_text("Very satisfied with sex life");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>21. How satisfied are you with your sex life?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>21. How satisfied are you with your sex life?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons22 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent22(value);
if (value === 1)
this.props.callbackFromParent22_text("Very dissatisfied with support from friends");
if (value === 2)
this.props.callbackFromParent22_text("Dissatisfied with support from friends");
if (value === 3)
this.props.callbackFromParent22_text("Neither satisfied nor dissatisfied with support from friends");
if (value === 4)
this.props.callbackFromParent22_text("Satisfied with support from friends");
if (value === 5)
this.props.callbackFromParent22_text("Very satisfied with support from friends");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>22. How satisfied are you with the support you get from your friends?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>22. How satisfied are you with the support you get from your friends?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons23 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent23(value);
if (value === 1)
this.props.callbackFromParent23_text("Very dissatisfied with conditions of living place");
if (value === 2)
this.props.callbackFromParent23_text("Dissatisfied with conditions of living place");
if (value === 3)
this.props.callbackFromParent23_text("Neither satisfied nor dissatisfied with conditions of living place");
if (value === 4)
this.props.callbackFromParent23_text("Satisfied with conditions of living place");
if (value === 5)
this.props.callbackFromParent23_text("Very satisfied with conditions of living place");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>23. How satisfied are you with the conditions of your living place?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>23. How satisfied are you with the conditions of your living place?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons24 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent24(value);
if (value === 1)
this.props.callbackFromParent24_text("Very dissatisfied with access to health services");
if (value === 2)
this.props.callbackFromParent24_text("Dissatisfied with access to health services");
if (value === 3)
this.props.callbackFromParent24_text("Neither satisfied nor dissatisfied with access to health services");
if (value === 4)
this.props.callbackFromParent24_text("Satisfied with access to health services");
if (value === 5)
this.props.callbackFromParent24_text("Very satisfied with access to health services");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>24. How satisfied are you with your access to health services?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>24. How satisfied are you with your access to health services?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons25 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn = (value) => {
this.props.callbackFromParent25(value);
if (value === 1)
this.props.callbackFromParent25_text("Very dissatisfied with transport");
if (value === 2)
this.props.callbackFromParent25_text("Dissatisfied with transport");
if (value === 3)
this.props.callbackFromParent25_text("Neither satisfied nor dissatisfied with transport");
if (value === 4)
this.props.callbackFromParent25_text("Satisfied with transport");
if (value === 5)
this.props.callbackFromParent25_text("Very satisfied with transport");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>25. How satisfied are you with your transport?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>25. How satisfied are you with your transport?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very dissatisfied (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Dissatisfied (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Neither satisfied nor dissatisfied (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Satisfied (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very satisfied (5)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons26 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent26(value);
if (value === 1)
this.props.callbackFromParent26_text("Never have negative feelings");
if (value === 2)
this.props.callbackFromParent26_text("Seldom have negative feelings");
if (value === 3)
this.props.callbackFromParent26_text("Quite often have negative feelings");
if (value === 4)
this.props.callbackFromParent26_text("Very often have negative feelings");
if (value === 5)
this.props.callbackFromParent26_text("Always have negative feelings");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>26. How often do you have negative feelings such as blue mood, despair, anxiety, depression?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Never (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Seldom (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite often (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very often (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (1)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>26. How often do you have negative feelings such as blue mood, despair, anxiety, depression?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Never (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Seldom (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite often (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very often (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Always (1)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
  


class WHOQOLBREF_Link extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

        
        
        
        
    //Calculator variables    
        selectedValue1: 5,
        selectedValue1_text: 'Very good quality of life',

        selectedValue2: 5,
        selectedValue2_text: 'Very satisfied with health',

        selectedValue3: 1,
        selectedValue3_text: 'Physical pain does not prevent doing needs',

        selectedValue4: 1,
        selectedValue4_text: 'Medical treatment not needed to function',

        selectedValue5: 5,
        selectedValue5_text: 'Enjoy life an extreme amount',

        selectedValue6: 5,
        selectedValue6_text: 'Find life meaningful an extreme amount',

        selectedValue7: 5,
        selectedValue7_text: 'Extremely able to concentrate',

        selectedValue8: 5,
        selectedValue8_text: 'Feel extremely safe',

        selectedValue9: 5,
        selectedValue9_text: 'Physical environment is extremely healthy',

        selectedValue10: 5,
        selectedValue10_text: 'Have enough energy for everyday life completely',

        selectedValue11: 5,
        selectedValue11_text: 'Able to accept bodily appearance completely',

        selectedValue12: 5,
        selectedValue12_text: 'Have you enough money to meet needs completely',

        selectedValue13: 5,
        selectedValue13_text: 'Information needed for day-to-day life available completely',

        selectedValue14: 5,
        selectedValue14_text: 'Have opportunities for leisure activities completely',

        selectedValue15: 5,
        selectedValue15_text: 'Able to get around very good',

        selectedValue16: 5,
        selectedValue16_text: 'Very satisfied with sleep',

        selectedValue17: 5,
        selectedValue17_text: 'Very satisfied with ability to perform daily living activities',

        selectedValue18: 5,
        selectedValue18_text: 'Very satisfied with capacity for work',

        selectedValue19: 5,
        selectedValue19_text: 'Very satisfied with self',

        selectedValue20: 5,
        selectedValue20_text: 'Very satisfied with personal relationships',

        selectedValue21: 5,
        selectedValue21_text: 'Very satisfied with sex life',

        selectedValue22: 5,
        selectedValue22_text: 'Very satisfied with support from friends',

        selectedValue23: 5,
        selectedValue23_text: 'Very satisfied with conditions of living place',

        selectedValue24: 5,
        selectedValue24_text: 'Very satisfied with access to health services',

        selectedValue25: 5,
        selectedValue25_text: 'Very satisfied with transport',

        selectedValue26: 1,
        selectedValue26_text: 'Never have negative feelings',   
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
      
    this.transformedScore_0_100_1 = [0,6,6,13,13,19,19,25,31,31,38,38,44,44,50,56,56,63,63,69,69,75,81,81,88,88,94,94,100];
    this.transformedScore_4_20_1 = [4,5,5,6,6,7,7,8,9,9,10,10,11,11,12,13,13,14,14,15,15,16,17,17,18,18,19,19,20];
    this.transformedScore_0_100_2 = [0,6,6,13,19,19,25,31,31,38,44,44,50,56,56,63,69,69,75,81,81,88,94,94,100];
    this.transformedScore_4_20_2 = [4,5,5,6,7,7,8,9,9,10,11,11,12,13,13,14,15,15,16,17,17,18,19,19,20];
    this.transformedScore_0_100_3 = [0,6,19,25,31,44,50,56,69,75,81,94,100];
    this.transformedScore_4_20_3 = [4,5,7,8,9,11,12,13,15,16,17,19,20];
    this.transformedScore_0_100_4 = [0,6,6,13,13,19,19,25,25,31,31,38,38,44,44,50,50,56,56,63,63,69,69,75,75,81,81,88,88,94,94,100,100];
    this.transformedScore_4_20_4 = [4,5,5,6,6,7,7,8,8,9,9,10,10,11,11,12,12,13,13,14,14,15,15,16,16,17,17,18,18,19,19,20,20];      
           
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const WHOQOLBREFScoresRef = firebase.firestore();
  WHOQOLBREFScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  WHOQOLBREFScoresRef.collection("whoqolbref_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const WHOQOLBREFScoresRef = firebase.firestore();
  WHOQOLBREFScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  WHOQOLBREFScoresRef.collection("whoqolbref_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const WHOQOLBREFScoresRef = firebase.firestore();
  WHOQOLBREFScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  WHOQOLBREFScoresRef.collection("whoqolbref_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const WHOQOLBREFScoresRef = firebase.firestore();
  WHOQOLBREFScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  WHOQOLBREFScoresRef.collection("whoqolbref_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const WHOQOLBREFScoresRef = firebase.firestore();
  WHOQOLBREFScoresRef.settings({
      timestampsInSnapshots: true
  });  
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){        
  const whoqolbref_score = WHOQOLBREFScoresRef.collection("whoqolbref_scores").doc(this.props.requestSurveyID).set({       
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'WHO QOL BREF',
    Question_01_WHOQOLBREF: this.state.selectedValue1,
    Question_02_WHOQOLBREF: this.state.selectedValue2,
    Question_03_WHOQOLBREF: 6-this.state.selectedValue3,
    Question_04_WHOQOLBREF: 6-this.state.selectedValue4,
    Question_05_WHOQOLBREF: this.state.selectedValue5,
    Question_06_WHOQOLBREF: this.state.selectedValue6,
    Question_07_WHOQOLBREF: this.state.selectedValue7,            
    Question_08_WHOQOLBREF: this.state.selectedValue8,
    Question_09_WHOQOLBREF: this.state.selectedValue9,
    Question_10_WHOQOLBREF: this.state.selectedValue10,
    Question_11_WHOQOLBREF: this.state.selectedValue11,
    Question_12_WHOQOLBREF: this.state.selectedValue12,
    Question_13_WHOQOLBREF: this.state.selectedValue13,
    Question_14_WHOQOLBREF: this.state.selectedValue14,
    Question_15_WHOQOLBREF: this.state.selectedValue15,
    Question_16_WHOQOLBREF: this.state.selectedValue16,
    Question_17_WHOQOLBREF: this.state.selectedValue17,
    Question_18_WHOQOLBREF: this.state.selectedValue18,
    Question_19_WHOQOLBREF: this.state.selectedValue19,
    Question_20_WHOQOLBREF: this.state.selectedValue20,
    Question_21_WHOQOLBREF: this.state.selectedValue21,
    Question_22_WHOQOLBREF: this.state.selectedValue22,
    Question_23_WHOQOLBREF: this.state.selectedValue23,
    Question_24_WHOQOLBREF: this.state.selectedValue24,
    Question_25_WHOQOLBREF: this.state.selectedValue25,
    Question_26_WHOQOLBREF: 6-this.state.selectedValue26,
    Physical_Health_Score_WHOQOLBREF: (this.transformedScore_0_100_1[(6-this.state.selectedValue3)+(6-this.state.selectedValue4)+this.state.selectedValue10+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18-7]),  
    Psychological_Score_WHOQOLBREF: (this.transformedScore_0_100_2[this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue11+this.state.selectedValue19+(6-this.state.selectedValue26)-6]),
    Social_Relationships_Score_WHOQOLBREF: (this.transformedScore_0_100_3[this.state.selectedValue20+this.state.selectedValue21+this.state.selectedValue22-3]),
    Environment_Score_WHOQOLBREF: (this.transformedScore_0_100_4[this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue23+this.state.selectedValue24+this.state.selectedValue25-8]),    
  });
}
else {
  const whoqolbref_score = WHOQOLBREFScoresRef.collection("whoqolbref_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'WHO QOL BREF',
    Question_01_WHOQOLBREF: this.state.selectedValue1,
    Question_02_WHOQOLBREF: this.state.selectedValue2,
    Question_03_WHOQOLBREF: 6-this.state.selectedValue3,
    Question_04_WHOQOLBREF: 6-this.state.selectedValue4,
    Question_05_WHOQOLBREF: this.state.selectedValue5,
    Question_06_WHOQOLBREF: this.state.selectedValue6,
    Question_07_WHOQOLBREF: this.state.selectedValue7,            
    Question_08_WHOQOLBREF: this.state.selectedValue8,
    Question_09_WHOQOLBREF: this.state.selectedValue9,
    Question_10_WHOQOLBREF: this.state.selectedValue10,
    Question_11_WHOQOLBREF: this.state.selectedValue11,
    Question_12_WHOQOLBREF: this.state.selectedValue12,
    Question_13_WHOQOLBREF: this.state.selectedValue13,
    Question_14_WHOQOLBREF: this.state.selectedValue14,
    Question_15_WHOQOLBREF: this.state.selectedValue15,
    Question_16_WHOQOLBREF: this.state.selectedValue16,
    Question_17_WHOQOLBREF: this.state.selectedValue17,
    Question_18_WHOQOLBREF: this.state.selectedValue18,
    Question_19_WHOQOLBREF: this.state.selectedValue19,
    Question_20_WHOQOLBREF: this.state.selectedValue20,
    Question_21_WHOQOLBREF: this.state.selectedValue21,
    Question_22_WHOQOLBREF: this.state.selectedValue22,
    Question_23_WHOQOLBREF: this.state.selectedValue23,
    Question_24_WHOQOLBREF: this.state.selectedValue24,
    Question_25_WHOQOLBREF: this.state.selectedValue25,
    Question_26_WHOQOLBREF: 6-this.state.selectedValue26,
    Physical_Health_Score_WHOQOLBREF: (this.transformedScore_0_100_1[(6-this.state.selectedValue3)+(6-this.state.selectedValue4)+this.state.selectedValue10+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18-7]),  
    Psychological_Score_WHOQOLBREF: (this.transformedScore_0_100_2[this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue11+this.state.selectedValue19+(6-this.state.selectedValue26)-6]),
    Social_Relationships_Score_WHOQOLBREF: (this.transformedScore_0_100_3[this.state.selectedValue20+this.state.selectedValue21+this.state.selectedValue22-3]),
    Environment_Score_WHOQOLBREF: (this.transformedScore_0_100_4[this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue23+this.state.selectedValue24+this.state.selectedValue25-8]),     
  });      
}   
  this.setState({
    clinician_to_share_with: '',
    user_id: '',
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.props.clinicianSendingEmail + '. You may now close this alert and the entire window.');     
}      
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("whoqolbref_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
myCallback1  = (dataFromChild) => {
this.setState({selectedValue1: dataFromChild});
}
myCallback1_text  = (dataFromChild) => {
this.setState({selectedValue1_text: dataFromChild});
}
myCallback2  = (dataFromChild) => {
this.setState({selectedValue2: dataFromChild});
}
myCallback2_text  = (dataFromChild) => {
this.setState({selectedValue2_text: dataFromChild});
}
myCallback3  = (dataFromChild) => {
this.setState({selectedValue3: dataFromChild});
}
myCallback3_text  = (dataFromChild) => {
this.setState({selectedValue3_text: dataFromChild});
}
myCallback4  = (dataFromChild) => {
this.setState({selectedValue4: dataFromChild});
}
myCallback4_text  = (dataFromChild) => {
this.setState({selectedValue4_text: dataFromChild});
}
myCallback5  = (dataFromChild) => {
this.setState({selectedValue5: dataFromChild});
}
myCallback5_text  = (dataFromChild) => {
this.setState({selectedValue5_text: dataFromChild});
}
myCallback6  = (dataFromChild) => {
this.setState({selectedValue6: dataFromChild});
}
myCallback6_text  = (dataFromChild) => {
this.setState({selectedValue6_text: dataFromChild});
}
myCallback7  = (dataFromChild) => {
this.setState({selectedValue7: dataFromChild});
}
myCallback7_text  = (dataFromChild) => {
this.setState({selectedValue7_text: dataFromChild});
}
myCallback8  = (dataFromChild) => {
this.setState({selectedValue8: dataFromChild});
}
myCallback8_text  = (dataFromChild) => {
this.setState({selectedValue8_text: dataFromChild});
}
myCallback9  = (dataFromChild) => {
this.setState({selectedValue9: dataFromChild});
}
myCallback9_text  = (dataFromChild) => {
this.setState({selectedValue9_text: dataFromChild});
}
myCallback10  = (dataFromChild) => {
this.setState({selectedValue10: dataFromChild});
}
myCallback10_text  = (dataFromChild) => {
this.setState({selectedValue10_text: dataFromChild});
}
myCallback11  = (dataFromChild) => {
this.setState({selectedValue11: dataFromChild});
}
myCallback11_text  = (dataFromChild) => {
this.setState({selectedValue11_text: dataFromChild});
}
myCallback12  = (dataFromChild) => {
this.setState({selectedValue12: dataFromChild});
}
myCallback12_text  = (dataFromChild) => {
this.setState({selectedValue12_text: dataFromChild});
}
myCallback13  = (dataFromChild) => {
this.setState({selectedValue13: dataFromChild});
}
myCallback13_text  = (dataFromChild) => {
this.setState({selectedValue13_text: dataFromChild});
}
myCallback14  = (dataFromChild) => {
this.setState({selectedValue14: dataFromChild});
}
myCallback14_text  = (dataFromChild) => {
this.setState({selectedValue14_text: dataFromChild});
}
myCallback15  = (dataFromChild) => {
this.setState({selectedValue15: dataFromChild});
}
myCallback15_text  = (dataFromChild) => {
this.setState({selectedValue15_text: dataFromChild});
}
myCallback16  = (dataFromChild) => {
this.setState({selectedValue16: dataFromChild});
}
myCallback16_text  = (dataFromChild) => {
this.setState({selectedValue16_text: dataFromChild});
}
myCallback17  = (dataFromChild) => {
this.setState({selectedValue17: dataFromChild});
}
myCallback17_text  = (dataFromChild) => {
this.setState({selectedValue17_text: dataFromChild});
}
myCallback18  = (dataFromChild) => {
this.setState({selectedValue18: dataFromChild});
}
myCallback18_text  = (dataFromChild) => {
this.setState({selectedValue18_text: dataFromChild});
}
myCallback19  = (dataFromChild) => {
this.setState({selectedValue19: dataFromChild});
}
myCallback19_text  = (dataFromChild) => {
this.setState({selectedValue19_text: dataFromChild});
}
myCallback20  = (dataFromChild) => {
this.setState({selectedValue20: dataFromChild});
}
myCallback20_text  = (dataFromChild) => {
this.setState({selectedValue20_text: dataFromChild});
}
myCallback21  = (dataFromChild) => {
this.setState({selectedValue21: dataFromChild});
}
myCallback21_text  = (dataFromChild) => {
this.setState({selectedValue21_text: dataFromChild});
}
myCallback22  = (dataFromChild) => {
this.setState({selectedValue22: dataFromChild});
}
myCallback22_text  = (dataFromChild) => {
this.setState({selectedValue22_text: dataFromChild});
}
myCallback23  = (dataFromChild) => {
this.setState({selectedValue23: dataFromChild});
}
myCallback23_text  = (dataFromChild) => {
this.setState({selectedValue23_text: dataFromChild});
}
myCallback24  = (dataFromChild) => {
this.setState({selectedValue24: dataFromChild});
}
myCallback24_text  = (dataFromChild) => {
this.setState({selectedValue24_text: dataFromChild});
}
myCallback25  = (dataFromChild) => {
this.setState({selectedValue25: dataFromChild});
}
myCallback25_text  = (dataFromChild) => {
this.setState({selectedValue25_text: dataFromChild});
}
myCallback26  = (dataFromChild) => {
this.setState({selectedValue26: dataFromChild});
}
myCallback26_text  = (dataFromChild) => {
this.setState({selectedValue26_text: dataFromChild});
}
 

  
    
    

    
render() { 

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>World Health Organization QOL-BREF (WHOQOL-BREF) Calculator</h1>
                <h4>Hello, {this.props.submitterName}, your clinician, {this.props.clinicianSendingEmail}, has sent you this survey to help further understand your health. Please click the appropriate buttons below and then click "Submit Score". Please note that your <u>digital privacy is taken very seriously</u> and a number of technologies have been implemented to protect the privacy and integrity of your data, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Your data <u>will never be shared with anyone other than the assigned clinician</u>.</h4>

            <form onSubmit={this.handleSubmit}>


        Visual Score (%)
            <br />
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                        <Bar data = {{

          labels: ['Physical','Psychological','Social', 'Environment'],
          datasets: [
            {
              backgroundColor: ['rgba(13,60,85,0.8)', 'rgba(19,149,186,0.8)','rgba(241,108,32,0.8)','rgba(235,200,68,0.8)'],
              borderColor: 'rgba(0,0,0,0.5)',
              borderWidth: 1,
              hoverbackgroundColor: ['rgba(13,60,85,0.8)', 'rgba(19,149,186,0.8)','rgba(241,108,32,0.8)','rgba(235,200,68,0.8)'],
              hoverBorderColor: 'rgba(0,0,0,1)',
              data: [
        this.transformedScore_0_100_1[(6-this.state.selectedValue3)+(6-this.state.selectedValue4)+this.state.selectedValue10+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18-7],
        this.transformedScore_0_100_2[this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue11+this.state.selectedValue19+(6-this.state.selectedValue26)-6],
        this.transformedScore_0_100_3[this.state.selectedValue20+this.state.selectedValue21+this.state.selectedValue22-3],
        this.transformedScore_0_100_4[this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue23+this.state.selectedValue24+this.state.selectedValue25-8]
        ]
            }

          ]
        }
        } width={320} height={250} options={{ maintainAspectRatio: true, responsive: false, 

        legend: {
                 display: false
               },
               scales: {
                 yAxes: [{
                   ticks: {
                      max: 100,
                      min: 0,
                      stepSize: 20
                    }
                  }]
            }
        }
        } />

        </div>

        <StickyContainer>
        <Sticky>
        {({ style }) => <div className="sticky_style" style={style}><Well style={{height: 120}}>
        <b>Physical Health: {this.transformedScore_0_100_1[(6-this.state.selectedValue3)+(6-this.state.selectedValue4)+this.state.selectedValue10+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18-7]} / 100</b>
        <br/>
        <b>Psychological Health: {this.transformedScore_0_100_2[this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue11+this.state.selectedValue19+(6-this.state.selectedValue26)-6]} / 100</b>
        <br/>
        <b>Social Relationships: {this.transformedScore_0_100_3[this.state.selectedValue20+this.state.selectedValue21+this.state.selectedValue22-3]} / 100</b>
        <br/>
        <b>Environment: {this.transformedScore_0_100_4[this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue23+this.state.selectedValue24+this.state.selectedValue25-8]} / 100</b>
        </Well></div>}
        </Sticky>


                  
                  
                  
                  
                <p />

                <br/>                      
                

                <h4>Instructions
                </h4>
                <h4>This assessment asks how you feel about your quality of life, health, or other areas of your life. <b>Please answer all the questions</b>. If you are unsure about which response to give to a question, <b>please choose the one</b> that appears most appropriate. This can often be your first response.
                </h4>
                <h4>Please keep in mind your standards, hopes, pleasures and concerns. We ask that you think about your life <b>in the last two weeks</b>.
                </h4>
                <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
                <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
                <h4>The following questions ask about <b>how much</b> you have experienced certain things in the last two weeks.
                </h4>
                <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
                <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
                <Buttons5 callbackFromParent5={this.myCallback5} callbackFromParent5_text={this.myCallback5_text}/>
                <Buttons6 callbackFromParent6={this.myCallback6} callbackFromParent6_text={this.myCallback6_text}/>
                <Buttons7 callbackFromParent7={this.myCallback7} callbackFromParent7_text={this.myCallback7_text}/>
                <Buttons8 callbackFromParent8={this.myCallback8} callbackFromParent8_text={this.myCallback8_text}/>
                <Buttons9 callbackFromParent9={this.myCallback9} callbackFromParent9_text={this.myCallback9_text}/>
                <h4>The following questions ask about <b>how completely</b> you experience or were able to do certain things in the last two weeks.
                </h4>
                <Buttons10 callbackFromParent10={this.myCallback10} callbackFromParent10_text={this.myCallback10_text}/>
                <Buttons11 callbackFromParent11={this.myCallback11} callbackFromParent11_text={this.myCallback11_text}/>
                <Buttons12 callbackFromParent12={this.myCallback12} callbackFromParent12_text={this.myCallback12_text}/>
                <Buttons13 callbackFromParent13={this.myCallback13} callbackFromParent13_text={this.myCallback13_text}/>
                <Buttons14 callbackFromParent14={this.myCallback14} callbackFromParent14_text={this.myCallback14_text}/>
                <Buttons15 callbackFromParent15={this.myCallback15} callbackFromParent15_text={this.myCallback15_text}/>
                <h4>The following questions ask you to say how <b>good or satisfied</b> you have felt about various aspects of your life over the last two weeks.
                </h4>
                <Buttons16 callbackFromParent16={this.myCallback16} callbackFromParent16_text={this.myCallback16_text}/>
                <Buttons17 callbackFromParent17={this.myCallback17} callbackFromParent17_text={this.myCallback17_text}/>
                <Buttons18 callbackFromParent18={this.myCallback18} callbackFromParent18_text={this.myCallback18_text}/>
                <Buttons19 callbackFromParent19={this.myCallback19} callbackFromParent19_text={this.myCallback19_text}/>
                <Buttons20 callbackFromParent20={this.myCallback20} callbackFromParent20_text={this.myCallback20_text}/>
                <Buttons21 callbackFromParent21={this.myCallback21} callbackFromParent21_text={this.myCallback21_text}/>
                <Buttons22 callbackFromParent22={this.myCallback22} callbackFromParent22_text={this.myCallback22_text}/>
                <Buttons23 callbackFromParent23={this.myCallback23} callbackFromParent23_text={this.myCallback23_text}/>
                <Buttons24 callbackFromParent24={this.myCallback24} callbackFromParent24_text={this.myCallback24_text}/>
                <Buttons25 callbackFromParent25={this.myCallback25} callbackFromParent25_text={this.myCallback25_text}/>
                <h4>The following question refers to <b>how often</b> you have felt or experienced certain things in the last two weeks.
                </h4>
                <Buttons26 callbackFromParent26={this.myCallback26} callbackFromParent26_text={this.myCallback26_text}/>
                </StickyContainer>
 
                
                
                <br />
                    
              <button style = {{width: '100%'}}>Submit Score</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(WHOQOLBREF_Link);
