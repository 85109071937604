import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
//const jsPDF = require('jspdf');
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
//import queryString from 'query-string'; don't think I'll use this line

///////////////////////////////////////////Calculator Methods
class Buttons1 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 0}
    }
    

    someFn = (value) => {
    this.props.callbackFromParent1(value);
    if (value === 0)
        this.props.callbackFromParent1_text('Sin dificultad');
    if (value === 25)
        this.props.callbackFromParent1_text('Poco difícil');
    if (value === 50)
        this.props.callbackFromParent1_text('Moderadamente difícil');
    if (value === 75)
        this.props.callbackFromParent1_text('Muy difícil');
    if (value === 100)
        this.props.callbackFromParent1_text('Incapaz');
    this.setState({clicked: value});
    }

  render() {
	return (
    <div>
    <div class="hidden-xs">
    <h4>Abrir un frasco apretado ó nuevo.</h4>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin dificultad (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poco difícil (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente difícil (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy difícil (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Incapaz (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h4>Abrir un frasco apretado ó nuevo.</h4>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin dificultad (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poco difícil (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente difícil (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy difícil (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Incapaz (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}


class Buttons2 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 0}
    }
    

    someFn = (value) => {
    this.props.callbackFromParent2(value);
    if (value === 0)
        this.props.callbackFromParent2_text('Sin dificultad');
    if (value === 25)
        this.props.callbackFromParent2_text('Poco difícil');
    if (value === 50)
        this.props.callbackFromParent2_text('Moderadamente difícil');
    if (value === 75)
        this.props.callbackFromParent2_text('Muy difícil');
    if (value === 100)
        this.props.callbackFromParent2_text('Incapaz');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h4>Realizar quehaceres del hogar pesados (por ejemplo, lavar paredes, lavar pisos).</h4>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin dificultad (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poco difícil (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente difícil (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy difícil (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Incapaz (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h4>Realizar quehaceres del hogar pesados (por ejemplo, lavar paredes, lavar pisos).</h4>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin dificultad (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poco difícil (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente difícil (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy difícil (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Incapaz (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}


class Buttons3 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 0}
    }
    

    someFn = (value) => {
    this.props.callbackFromParent3(value);
    if (value === 0)
        this.props.callbackFromParent3_text('Sin dificultad');
    if (value === 25)
        this.props.callbackFromParent3_text('Poco difícil');
    if (value === 50)
        this.props.callbackFromParent3_text('Moderadamente difícil');
    if (value === 75)
        this.props.callbackFromParent3_text('Muy difícil');
    if (value === 100)
        this.props.callbackFromParent3_text('Incapaz');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h4>Cargar una bolsa de mandado ó un maletín.</h4>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin dificultad (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poco difícil (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente difícil (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy difícil (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Incapaz (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h4>Cargar una bolsa de mandado ó un maletín.</h4>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin dificultad (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poco difícil (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente difícil (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy difícil (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Incapaz (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons4 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 0}
    }
    

    someFn = (value) => {
    this.props.callbackFromParent4(value);
    if (value === 0)
        this.props.callbackFromParent4_text('Sin dificultad');
    if (value === 25)
        this.props.callbackFromParent4_text('Poco difícil');
    if (value === 50)
        this.props.callbackFromParent4_text('Moderadamente difícil');
    if (value === 75)
        this.props.callbackFromParent4_text('Muy difícil');
    if (value === 100)
        this.props.callbackFromParent4_text('Incapaz');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h4>Lavar su espalda.</h4>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin dificultad (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poco difícil (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente difícil (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy difícil (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Incapaz (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h4>Lavar su espalda.</h4>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin dificultad (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poco difícil (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente difícil (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy difícil (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Incapaz (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons5 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 0}
    }
    

    someFn = (value) => {
    this.props.callbackFromParent5(value);
    if (value === 0)
        this.props.callbackFromParent5_text('Sin dificultad');
    if (value === 25)
        this.props.callbackFromParent5_text('Poco difícil');
    if (value === 50)
        this.props.callbackFromParent5_text('Moderadamente difícil');
    if (value === 75)
        this.props.callbackFromParent5_text('Muy difícil');
    if (value === 100)
        this.props.callbackFromParent5_text('Incapaz');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h4>Utilizar un cuchillo para cortar comida.</h4>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin dificultad (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poco difícil (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente difícil (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy difícil (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Incapaz (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h4>Utilizar un cuchillo para cortar comida.</h4>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin dificultad (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poco difícil (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente difícil (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy difícil (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Incapaz (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons6 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 0}
    }
    

    someFn = (value) => {
    this.props.callbackFromParent6(value);
    if (value === 0)
        this.props.callbackFromParent6_text('Sin dificultad');
    if (value === 25)
        this.props.callbackFromParent6_text('Poco difícil');
    if (value === 50)
        this.props.callbackFromParent6_text('Moderadamente difícil');
    if (value === 75)
        this.props.callbackFromParent6_text('Muy difícil');
    if (value === 100)
        this.props.callbackFromParent6_text('Incapaz');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h4>Actividades recreativas en las que se absorbe algún tipo de fuerza o impacto a través de su brazo, hombro o mano (por ejemplo, jugar golf, martilleo, jugar tenis, etc.).</h4>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin dificultad (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poco difícil (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente difícil (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy difícil (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Incapaz (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h4>Actividades recreativas en las que se absorbe algún tipo de fuerza o impacto a través de su brazo, hombro o mano (por ejemplo, jugar golf, martilleo, jugar tenis, etc.).</h4>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin dificultad (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poco difícil (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente difícil (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy difícil (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Incapaz (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons7 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 0}
    }
    

    someFn = (value) => {
    this.props.callbackFromParent7(value);
    if (value === 0)
        this.props.callbackFromParent7_text('Nada');
    if (value === 25)
        this.props.callbackFromParent7_text('Ligeramente');
    if (value === 50)
        this.props.callbackFromParent7_text('Moderadamente');
    if (value === 75)
        this.props.callbackFromParent7_text('Bastante');
    if (value === 100)
        this.props.callbackFromParent7_text('Extremadamente (muchísimo)');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h3>Durante la semana pasada, ¿hasta que grado el problema de su brazo, hombro o mano interfirió con sus actividades sociales normales con su familia, amigos ó vecinos?</h3>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Nada (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Ligeramente (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Bastante (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Extremadamente (muchísimo) (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h3>Durante la semana pasada, ¿hasta que grado el problema de su brazo, hombro o mano interfirió con sus actividades sociales normales con su familia, amigos ó vecinos?</h3>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Nada (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Ligeramente (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Bastante (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Extremadamente (muchísimo) (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}


class Buttons8 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 0}
    }

    someFn = (value) => {
    this.props.callbackFromParent8(value);
    if (value === 0)
        this.props.callbackFromParent8_text('Sin limitaciones');
    if (value === 25)
        this.props.callbackFromParent8_text('Ligeramente limitado');
    if (value === 50)
        this.props.callbackFromParent8_text('Moderadamente limitado (mas o menos limitado)');
    if (value === 75)
        this.props.callbackFromParent8_text('Muy limitado');
    if (value === 100)
        this.props.callbackFromParent8_text('Incapaz');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h3>Durante la semana pasada ¿estuvo limitado en su trabajo ú otras actividades diarias como resultado de su problema en su brazo, hombro, o mano.</h3>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin limitaciones (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Ligeramente limitado (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente limitado (mas o menos limitado) (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy limitado (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Incapaz (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <h3>Durante la semana pasada ¿estuvo limitado en su trabajo ú otras actividades diarias como resultado de su problema en su brazo, hombro, o mano.</h3>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin limitaciones (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Ligeramente limitado (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente limitado (mas o menos limitado) (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy limitado (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Incapaz (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}


class Buttons9 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 0}
    }
    

    someFn = (value) => {
    this.props.callbackFromParent9(value);
    if (value === 0)
        this.props.callbackFromParent9_text('Nada');
    if (value === 25)
        this.props.callbackFromParent9_text('Leve');
    if (value === 50)
        this.props.callbackFromParent9_text('Moderado');
    if (value === 75)
        this.props.callbackFromParent9_text('Severo');
    if (value === 100)
        this.props.callbackFromParent9_text('Extemo');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h4>Dolor de brazo, hombro o mano.</h4>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Nada (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Leve (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderado (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Severo (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Extemo (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <h4>Dolor de brazo, hombro o mano.</h4>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Nada (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Leve (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderado (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Severo (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Extemo (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons10 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 0}
    }
    

    someFn = (value) => {
    this.props.callbackFromParent10(value);
    if (value === 0)
        this.props.callbackFromParent10_text('Nada');
    if (value === 25)
        this.props.callbackFromParent10_text('Leve');
    if (value === 50)
        this.props.callbackFromParent10_text('Moderado');
    if (value === 75)
        this.props.callbackFromParent10_text('Severo');
    if (value === 100)
        this.props.callbackFromParent10_text('Extemo');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h4>Hormigueo (alfileres y agujas) en su brazo, hombro o mano.</h4>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Nada (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Leve (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderado (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Severo (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Extemo (100)</ToggleButton>
   </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <h4>Hormigueo (alfileres y agujas) en su brazo, hombro o mano.</h4>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Nada (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Leve (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderado (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Severo (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Extemo (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons11 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 0}
    }
    

    someFn = (value) => {
    this.props.callbackFromParent11(value);
    if (value === 0)
        this.props.callbackFromParent11_text('Sin dificultad');
    if (value === 25)
        this.props.callbackFromParent11_text('Poco difícil');
    if (value === 50)
        this.props.callbackFromParent11_text('Moderadamente difícil');
    if (value === 75)
        this.props.callbackFromParent11_text('Muy difícil');
    if (value === 100)
        this.props.callbackFromParent11_text('Tan difícil que no puedo dormir');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h3>Durante la semana pasada, ¿cuánta dificultad ha tenido para dormir a causa del dolor en su brazo, hombro o mano?</h3>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin dificultad (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poco difícil (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente difícil (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy difícil (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Tan difícil que no puedo dormir (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
    <h3>Durante la semana pasada, ¿cuánta dificultad ha tenido para dormir a causa del dolor en su brazo, hombro o mano?</h3>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Sin dificultad (0)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poco difícil (25)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderadamente difícil (50)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Muy difícil (75)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Tan difícil que no puedo dormir (100)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}    
 


  


class QuickDASH_Link_Spanish extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

        
    //Calculator variables    
        selectedValue1: 0,
        selectedValue1_text: 'Sin dificultad',
        selectedValue2: 0,
        selectedValue2_text: 'Sin dificultad',
        selectedValue3: 0,
        selectedValue3_text: 'Sin dificultad',
        selectedValue4: 0,
        selectedValue4_text: 'Sin dificultad',
        selectedValue5: 0,
        selectedValue5_text: 'Sin dificultad',
        selectedValue6: 0,
        selectedValue6_text: 'Sin dificultad',
        selectedValue7: 0,
        selectedValue7_text: 'Nada',
        selectedValue8: 0,
        selectedValue8_text: 'Sin limitaciones',
        selectedValue9: 0,
        selectedValue9_text: 'Nada',
        selectedValue10: 0,
        selectedValue10_text: 'Nada',
        selectedValue11: 0,
        selectedValue11_text: 'Sin dificultad',         
        
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const QuickDASHScoresRef = firebase.firestore();
  QuickDASHScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  QuickDASHScoresRef.collection("quickdash_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const QuickDASHScoresRef = firebase.firestore();
  QuickDASHScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  QuickDASHScoresRef.collection("quickdash_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const QuickDASHScoresRef = firebase.firestore();
  QuickDASHScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  QuickDASHScoresRef.collection("quickdash_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const QuickDASHScoresRef = firebase.firestore();
  QuickDASHScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  QuickDASHScoresRef.collection("quickdash_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();
 
    
  const QuickDASHScoresRef = firebase.firestore();
  QuickDASHScoresRef.settings({
      timestampsInSnapshots: true
  });  
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){    
  const quickdash_score = QuickDASHScoresRef.collection("quickdash_scores").doc(this.props.requestSurveyID).set({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Spanish QuickDASH',
    Question_01_QuickDASH: this.state.selectedValue1,
    Question_02_QuickDASH: this.state.selectedValue2,
    Question_03_QuickDASH: this.state.selectedValue3,
    Question_04_QuickDASH: this.state.selectedValue4,
    Question_05_QuickDASH: this.state.selectedValue5,
    Question_06_QuickDASH: this.state.selectedValue6,
    Question_07_QuickDASH: this.state.selectedValue7,            
    Question_08_QuickDASH: this.state.selectedValue8,
    Question_09_QuickDASH: this.state.selectedValue9,
    Question_10_QuickDASH: this.state.selectedValue10,
    Question_11_QuickDASH: this.state.selectedValue11,
    QuickDASH_Score: ((this.state.selectedValue1 + this.state.selectedValue2 + this.state.selectedValue3 + this.state.selectedValue4 + this.state.selectedValue5 + this.state.selectedValue6 + this.state.selectedValue7 + this.state.selectedValue8 + this.state.selectedValue9 + this.state.selectedValue10 + this.state.selectedValue11)/11).toFixed(1),   
  });
}
else {
  const quickdash_score = QuickDASHScoresRef.collection("quickdash_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'Spanish QuickDASH',
    Question_01_QuickDASH: this.state.selectedValue1,
    Question_02_QuickDASH: this.state.selectedValue2,
    Question_03_QuickDASH: this.state.selectedValue3,
    Question_04_QuickDASH: this.state.selectedValue4,
    Question_05_QuickDASH: this.state.selectedValue5,
    Question_06_QuickDASH: this.state.selectedValue6,
    Question_07_QuickDASH: this.state.selectedValue7,            
    Question_08_QuickDASH: this.state.selectedValue8,
    Question_09_QuickDASH: this.state.selectedValue9,
    Question_10_QuickDASH: this.state.selectedValue10,
    Question_11_QuickDASH: this.state.selectedValue11,
    QuickDASH_Score: ((this.state.selectedValue1 + this.state.selectedValue2 + this.state.selectedValue3 + this.state.selectedValue4 + this.state.selectedValue5 + this.state.selectedValue6 + this.state.selectedValue7 + this.state.selectedValue8 + this.state.selectedValue9 + this.state.selectedValue10 + this.state.selectedValue11)/11).toFixed(1),    
  });      
}     
  this.setState({
    clinician_to_share_with: '',
    user_id: '',     
  });
    alert('¡Gracias por usar OrthoPowerTools! Se recibió su envío y se compartirá con el siguiente proveedor: '+ this.props.clinicianSendingEmail +'. Ahora puede cerrar esta alerta y toda la ventana.');    
}    
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("quickdash_spanish_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallback1 = (dataFromChild) => {
    this.setState({selectedValue1: dataFromChild});
    }
 
    myCallback1_text = (dataFromChild) => {
    this.setState({selectedValue1_text: dataFromChild});
    }
    
    myCallback2 = (dataFromChild) => {
    this.setState({selectedValue2: dataFromChild});
    }
    
    myCallback2_text = (dataFromChild) => {
    this.setState({selectedValue2_text: dataFromChild});
    }
    
    myCallback3 = (dataFromChild) => {
    this.setState({selectedValue3: dataFromChild});
    }
    
    myCallback3_text = (dataFromChild) => {
    this.setState({selectedValue3_text: dataFromChild});
    }    
    
    myCallback4 = (dataFromChild) => {
    this.setState({selectedValue4: dataFromChild});
    }
    
    myCallback4_text = (dataFromChild) => {
    this.setState({selectedValue4_text: dataFromChild});
    }  
    
    myCallback5 = (dataFromChild) => {
    this.setState({selectedValue5: dataFromChild});
    }
    
    myCallback5_text = (dataFromChild) => {
    this.setState({selectedValue5_text: dataFromChild});
    }    
    
    myCallback6 = (dataFromChild) => {
    this.setState({selectedValue6: dataFromChild});
    }
    
    myCallback6_text = (dataFromChild) => {
    this.setState({selectedValue6_text: dataFromChild});
    }      
    
    myCallback7 = (dataFromChild) => {
    this.setState({selectedValue7: dataFromChild});
    }
    
    myCallback7_text = (dataFromChild) => {
    this.setState({selectedValue7_text: dataFromChild});
    }    
    
    myCallback8 = (dataFromChild) => {
    this.setState({selectedValue8: dataFromChild});
    }
    
    myCallback8_text = (dataFromChild) => {
    this.setState({selectedValue8_text: dataFromChild});
    }      
    
    myCallback9 = (dataFromChild) => {
    this.setState({selectedValue9: dataFromChild});
    }
    
    myCallback9_text = (dataFromChild) => {
    this.setState({selectedValue9_text: dataFromChild});
    }    
    
    myCallback10 = (dataFromChild) => {
    this.setState({selectedValue10: dataFromChild});
    }
    
    myCallback10_text = (dataFromChild) => {
    this.setState({selectedValue10_text: dataFromChild});
    }

    myCallback11 = (dataFromChild) => {
    this.setState({selectedValue11: dataFromChild});
    }
    
    myCallback11_text = (dataFromChild) => {
    this.setState({selectedValue11_text: dataFromChild});
    }   
 

  
    
    

    
render() {     
    
  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>QuickDASH Calculadora</h1>
                <h4>Hola, {this.props.submitterName}, su médico, {this.props.clinicianSendingEmail}, le envió esta encuesta para ayudarlo a comprender mejor su salud. Haga clic en los botones correspondientes a continuación y luego haga clic en "Enviar Puntuación". Tenga en cuenta que su <u> privacidad digital se toma muy en serio </u> y que se han implementado varias tecnologías para proteger la privacidad y la integridad de sus datos, incluido el uso de cifrado SSL, almacenamiento compatible con HIPAA y servicios de autenticación de última generación. Sus datos <u>nunca se compartirán con nadie más que el médico asignado</u>.</h4>

            <form onSubmit={this.handleSubmit}>

        <br />
        <StickyContainer>
        <Sticky>
        {({ style }) => <div className="sticky_style" style={style}><Well bsSize="small">QuickDASH Puntaje: {

        ((this.state.selectedValue1 + this.state.selectedValue2 + this.state.selectedValue3 + this.state.selectedValue4 + this.state.selectedValue5 + this.state.selectedValue6 + this.state.selectedValue7 + this.state.selectedValue8 + this.state.selectedValue9 + this.state.selectedValue10 + this.state.selectedValue11)/11).toFixed(1)   


        } / {100
        } = {
        ((this.state.selectedValue1 + this.state.selectedValue2 + this.state.selectedValue3 + this.state.selectedValue4 + this.state.selectedValue5 + this.state.selectedValue6 + this.state.selectedValue7 + this.state.selectedValue8 + this.state.selectedValue9 + this.state.selectedValue10 + this.state.selectedValue11)/11).toFixed(1)   
        } %

        <br />      

        Gráfica QuickDASH Puntaje: (%)
        <ProgressBar>
        <ProgressBar active now={100-((this.state.selectedValue1 + this.state.selectedValue2 + this.state.selectedValue3 + this.state.selectedValue4 + this.state.selectedValue5 + this.state.selectedValue6 + this.state.selectedValue7 + this.state.selectedValue8 + this.state.selectedValue9 + this.state.selectedValue10 + this.state.selectedValue11)/11).toFixed(1)                         
                                  }/>
        <ProgressBar active bsStyle="danger" now={
            ((this.state.selectedValue1 + this.state.selectedValue2 + this.state.selectedValue3 + this.state.selectedValue4 + this.state.selectedValue5 + this.state.selectedValue6 + this.state.selectedValue7 + this.state.selectedValue8 + this.state.selectedValue9 + this.state.selectedValue10 + this.state.selectedValue11)/11).toFixed(1)     
        }/>
        </ProgressBar>
        </Well></div>}
        </Sticky>      
                                                                                    

       

        <br/>  
        <br/>                
	    <h3>Por favor califique su capacidad para realizar las siguientes actividades durante la semana pasada.</h3>
        <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
        <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
        <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
        <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
        <Buttons5 callbackFromParent5={this.myCallback5} callbackFromParent5_text={this.myCallback5_text}/>
        <Buttons6 callbackFromParent6={this.myCallback6} callbackFromParent6_text={this.myCallback6_text}/>
        <Buttons7 callbackFromParent7={this.myCallback7} callbackFromParent7_text={this.myCallback7_text}/>
        <Buttons8 callbackFromParent8={this.myCallback8} callbackFromParent8_text={this.myCallback8_text}/>
        <h3>Por favor califique la gravedad de los siguientes síntomas durante la semana pasada. </h3>
        <Buttons9 callbackFromParent9={this.myCallback9} callbackFromParent9_text={this.myCallback9_text}/>
        <Buttons10 callbackFromParent10={this.myCallback10} callbackFromParent10_text={this.myCallback10_text}/>
        <Buttons11 callbackFromParent11={this.myCallback11} callbackFromParent11_text={this.myCallback11_text}/>
        </StickyContainer>

        <br />
                    
        <button style = {{width: '100%'}}>Enviar Puntuación</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(QuickDASH_Link_Spanish);

