import React, { PureComponent } from 'react';
import {Link} from "react-router-dom";
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import mainLogo from'../orthopowertools.png';

class About_Us extends PureComponent {
    
  constructor() {
    super();
    this.state = {
      user: null        
    }
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);      
  }
    
componentDidMount() {
   
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    } 
  });    
    
}    
    
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}    
    
    

    
render() {    
  return (
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>    

        <Link to="/" style={{padding:15}}>Home</Link>            

        </div>
        
      
      
      </header>    


        <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
        <div style={{textAlign: "left"}}>
      
        <br/>
        <h3>About Us:</h3>
        <p/>   
        <br/>       
        <span><a href="https://orthotoolkit.com/">OrthoToolKit</a> and <Link to="/">OrthoPowerTools</Link> are the project of two medical students, now resident physicians, who recognize the important role that evidence-based medicine plays in improving patient care. We believe that using the most accurate and relevant information improves patient assessments and leads to more informed decision making.</span>
        <p/>
        <span >During our medical training, we discovered that many useful orthopaedic patient assessments and decision aids were either not available online or existed in a format that was challenging to use in a clinical setting.</span>
        <p/>
        <span >Therefore, we built <a href="https://orthotoolkit.com/">OrthoToolKit</a> to fulfill the educational and clinical need for a single website devoted to orthopaedic scores and calculators in an easy-to-use format. <a href="https://orthotoolkit.com/">OrthoToolKit</a> is designed and maintained such that the scores are:</span>
            <p/>
        <ul>
            <li><span >Presented in an interactive format that works well with both computers and mobile devices</span></li>
            <li><span >Offered as a downloadable and printable PDF</span></li>
            <li><span >Accompanied by a description of the tool as well as citations of the original and supporting literature</span></li>
            <li><span >Attributed to the appropriate authors and copyright holders</span></li>
            <li><span >Always free and easily accessible</span></li>
        </ul>
            <p/>
        <span>As a response to user requests, we then built <Link to="/">OrthoPowerTools</Link> as a HIPAA compliant initiative that strives to provide all the features of <a href="https://orthotoolkit.com/">OrthoToolKit</a> as well as:</span><p/>

                <ul><li><span >A system for remotely collecting, storing, and analyzing the results of a variety of patient reported outcomes </span></li></ul> 
            <p/>
        <span>We take the digital privacy of our clinician and patient users very seriously and have implemented a number of technologies to protect the privacy and integrity of the data submitted, including SSL encryption and the use of state-of-the-art authentication services. Submitted data is not shared with anyone other than the assigned clinician.</span>
        <p/>
        <span>Please feel free to send us feedback and suggestions via our <Link to="/contact-us/">contact page</Link>.</span>
        </div>
        </div>      
      
      
    </div>      
  );
}    
    
    
}
export default About_Us;
