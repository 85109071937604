import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';

///////////////////////////////////////////Calculator Methods
    
    
class Buttons1 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 40}
}
someFn (value) {
this.props.callbackFromParent1(value);
if (value === 40)
this.props.callbackFromParent1_text("No pain");
if (value === 30)
this.props.callbackFromParent1_text("Pain mild, occasional");
if (value === 20)
this.props.callbackFromParent1_text("Pain moderate, daily");
if (value === 0)
this.props.callbackFromParent1_text("Pain is severe, almost always present");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>1. Pain</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={40}>
<ToggleButton bsStyle='btn-custom' value={40}>{(this.state.clicked === 40) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} None (40)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={30}>{(this.state.clicked === 30) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mild, occasional (30)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={20}>{(this.state.clicked === 20) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate, daily (20)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Severe, almost always present (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>1. Pain</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={40}>
<ToggleButton bsStyle='btn-custom' value={40}>{(this.state.clicked === 40) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} None (40)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={30}>{(this.state.clicked === 30) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mild, occasional (30)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={20}>{(this.state.clicked === 20) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate, daily (20)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Severe, almost always present (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons2 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 10}
}
someFn (value) {
this.props.callbackFromParent2(value);
if (value === 10)
this.props.callbackFromParent2_text("No activity limitations");
if (value === 7)
this.props.callbackFromParent2_text("Limitations on recreational activities");
if (value === 4)
this.props.callbackFromParent2_text("Some limitations on daily and recreational activities");
if (value === 0)
this.props.callbackFromParent2_text("Severe limitations on daily and recreational activities");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>2. Activity limitations</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} None (10)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Limitations on recreational activities (7)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Some limitations on daily and recreational (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Severe limitations on daily and recreational activities (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>2. Activity limitations</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} None (10)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Limitations on recreational activities (7)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Some limitations on daily and recreational (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Severe limitations on daily and recreational activities (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons3 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 10}
}
someFn (value) {
this.props.callbackFromParent3(value);
if (value === 10)
this.props.callbackFromParent3_text("Can wear conventional shoes");
if (value === 5)
this.props.callbackFromParent3_text("Comfort footwear and shoe insert required");
if (value === 0)
this.props.callbackFromParent3_text("Modified shoes or brace required");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>3. Footwear requirements</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Can wear conventional shoes (10)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Comfort footwear and shoe insert required (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Modified shoes or brace required (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>3. Footwear requirements</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Can wear conventional shoes (10)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Comfort footwear and shoe insert required (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Modified shoes or brace required (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons4 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 10}
}
someFn (value) {
this.props.callbackFromParent4(value);
if (value === 10)
this.props.callbackFromParent4_text("Normal or mild restriction of MTP joint motion (75° or more)");
if (value === 5)
this.props.callbackFromParent4_text("Moderate restriction of MTP joint motion (30°-74°)");
if (value === 0)
this.props.callbackFromParent4_text("Severe restriction of MTP joint motion (less than 30°)");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>4. MTP joint motion (dorsiflexion plus plantarflexion)</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Normal or mild restriction (75° or more) (10)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate restriction (30°-74°) (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Severe restriction (less than 30°) (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>4. MTP joint motion (dorsiflexion plus plantarflexion)</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Normal or mild restriction (75° or more) (10)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate restriction (30°-74°) (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Severe restriction (less than 30°) (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons5 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn (value) {
this.props.callbackFromParent5(value);
if (value === 5)
this.props.callbackFromParent5_text("No restriction of IP joint motion");
if (value === 0)
this.props.callbackFromParent5_text("Severe restriction of IP joint motion (less than 10°)");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>5. IP joint motion (plantarflexion)</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No restriction (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Severe restriction (less than 10°) (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>5. IP joint motion (plantarflexion)</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No restriction (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Severe restriction (less than 10°) (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons6 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn (value) {
this.props.callbackFromParent6(value);
if (value === 5)
this.props.callbackFromParent6_text("Stable MTP-IP (all directions)");
if (value === 0)
this.props.callbackFromParent6_text("Unstable MTP-IP");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>6. MTP-IP stability (all directions)</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Stable (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Unstable (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>6. MTP-IP stability (all directions)</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Stable (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Unstable (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons7 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 5}
}
someFn (value) {
this.props.callbackFromParent7(value);
if (value === 5)
this.props.callbackFromParent7_text("No or asymptomatic callus or corn/clavus");
if (value === 0)
this.props.callbackFromParent7_text("Painful callus or corn/clavus");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>7. Callus or corn/clavus</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No or asymptomatic callus or corn/clavus (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Painful callus or corn/clavus (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>7. Callus or corn/clavus</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={5}>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No or asymptomatic callus or corn/clavus (5)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Painful callus or corn/clavus (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons8 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 15}
}
someFn (value) {
this.props.callbackFromParent8(value);
if (value === 15)
this.props.callbackFromParent8_text("Good, well aligned hallux");
if (value === 8)
this.props.callbackFromParent8_text("Fair, mild to moderate degree of malalignment of hallux");
if (value === 0)
this.props.callbackFromParent8_text("Poor, severe malalignment of hallux");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>8. Alignment</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={15}>
<ToggleButton bsStyle='btn-custom' value={15}>{(this.state.clicked === 15) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Good, well aligned (15)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={8}>{(this.state.clicked === 8) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Fair, mild to moderate degree of malalignment (8)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Poor, severe malalignment (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>8. Alignment</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={15}>
<ToggleButton bsStyle='btn-custom' value={15}>{(this.state.clicked === 15) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Good, well aligned (15)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={8}>{(this.state.clicked === 8) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Fair, mild to moderate degree of malalignment (8)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Poor, severe malalignment (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}   
  


class AOFASH_Link extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

        
        
        
        
    //Calculator variables    
        
    selectedValue1: 40,
    selectedValue1_text: 'No pain',

    selectedValue2: 10,
    selectedValue2_text: 'No activity limitations',

    selectedValue3: 10,
    selectedValue3_text: 'Can wear conventional shoes',

    selectedValue4: 10,
    selectedValue4_text: 'Normal or mild restriction of MTP joint motion (75° or more)',

    selectedValue5: 5,
    selectedValue5_text: 'No restriction of IP joint motion',

    selectedValue6: 5,
    selectedValue6_text: 'Stable MTP-IP (all directions)',

    selectedValue7: 5,
    selectedValue7_text: 'No or asymptomatic callus or corn/clavus',

    selectedValue8: 15,
    selectedValue8_text: 'Good, well aligned hallux',  
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const AOFASHScoresRef = firebase.firestore();
  AOFASHScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  AOFASHScoresRef.collection("aofash_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const AOFASHScoresRef = firebase.firestore();
  AOFASHScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  AOFASHScoresRef.collection("aofash_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const AOFASHScoresRef = firebase.firestore();
  AOFASHScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  AOFASHScoresRef.collection("aofash_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const AOFASHScoresRef = firebase.firestore();
  AOFASHScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  AOFASHScoresRef.collection("aofash_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const AOFASHScoresRef = firebase.firestore();
  AOFASHScoresRef.settings({
      timestampsInSnapshots: true
  });  
    
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){    
  const aofash_score = AOFASHScoresRef.collection("aofash_scores").doc(this.props.requestSurveyID).set({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'AOFAS Hallux',
    Question_01_AOFASH: this.state.selectedValue1,
    Question_02_AOFASH: this.state.selectedValue2,
    Question_03_AOFASH: this.state.selectedValue3,
    Question_04_AOFASH: this.state.selectedValue4,
    Question_05_AOFASH: this.state.selectedValue5,
    Question_06_AOFASH: this.state.selectedValue6,
    Question_07_AOFASH: this.state.selectedValue7,            
    Question_08_AOFASH: this.state.selectedValue8,
    Total_Score_AOFASH: (Number(this.state.selectedValue1) + Number(this.state.selectedValue2) + Number(this.state.selectedValue3) + Number(this.state.selectedValue4) + Number(this.state.selectedValue5) + Number(this.state.selectedValue6) + Number(this.state.selectedValue7) + Number(this.state.selectedValue8)),        
  });
}
else {
  const aofash_score = AOFASHScoresRef.collection("aofash_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'AOFAS Hallux',
    Question_01_AOFASH: this.state.selectedValue1,
    Question_02_AOFASH: this.state.selectedValue2,
    Question_03_AOFASH: this.state.selectedValue3,
    Question_04_AOFASH: this.state.selectedValue4,
    Question_05_AOFASH: this.state.selectedValue5,
    Question_06_AOFASH: this.state.selectedValue6,
    Question_07_AOFASH: this.state.selectedValue7,            
    Question_08_AOFASH: this.state.selectedValue8,
    Total_Score_AOFASH: (Number(this.state.selectedValue1) + Number(this.state.selectedValue2) + Number(this.state.selectedValue3) + Number(this.state.selectedValue4) + Number(this.state.selectedValue5) + Number(this.state.selectedValue6) + Number(this.state.selectedValue7) + Number(this.state.selectedValue8)),   
  });      
}    
    
  this.setState({
    clinician_to_share_with: '',
    user_id: '',      
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.props.clinicianSendingEmail + '. You may now close this alert and the entire window.');   
    
}
    
    
    
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("aofash_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallback1  = (dataFromChild) => {
    this.setState({selectedValue1: dataFromChild});
    }
    myCallback1_text  = (dataFromChild) => {
    this.setState({selectedValue1_text: dataFromChild});
    }
    myCallback2  = (dataFromChild) => {
    this.setState({selectedValue2: dataFromChild});
    }
    myCallback2_text  = (dataFromChild) => {
    this.setState({selectedValue2_text: dataFromChild});
    }
    myCallback3  = (dataFromChild) => {
    this.setState({selectedValue3: dataFromChild});
    }
    myCallback3_text  = (dataFromChild) => {
    this.setState({selectedValue3_text: dataFromChild});
    }
    myCallback4  = (dataFromChild) => {
    this.setState({selectedValue4: dataFromChild});
    }
    myCallback4_text  = (dataFromChild) => {
    this.setState({selectedValue4_text: dataFromChild});
    }
    myCallback5  = (dataFromChild) => {
    this.setState({selectedValue5: dataFromChild});
    }
    myCallback5_text  = (dataFromChild) => {
    this.setState({selectedValue5_text: dataFromChild});
    }
    myCallback6  = (dataFromChild) => {
    this.setState({selectedValue6: dataFromChild});
    }
    myCallback6_text  = (dataFromChild) => {
    this.setState({selectedValue6_text: dataFromChild});
    }
    myCallback7  = (dataFromChild) => {
    this.setState({selectedValue7: dataFromChild});
    }
    myCallback7_text  = (dataFromChild) => {
    this.setState({selectedValue7_text: dataFromChild});
    }
    myCallback8  = (dataFromChild) => {
    this.setState({selectedValue8: dataFromChild});
    }
    myCallback8_text  = (dataFromChild) => {
    this.setState({selectedValue8_text: dataFromChild});
    }


  
    
    

    
render() { 

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>AOFAS Hallux MTP-IP Calculator</h1>
                <h4>Hello, {this.props.submitterName}, your clinician, {this.props.clinicianSendingEmail}, has sent you this survey to help further understand your health. Please click the appropriate buttons below and then click "Submit Score". Please note that your <u>digital privacy is taken very seriously</u> and a number of technologies have been implemented to protect the privacy and integrity of your data, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Your data <u>will never be shared with anyone other than the assigned clinician</u>.</h4>

            <form onSubmit={this.handleSubmit}>


        <StickyContainer>
          <Sticky>
            {({ style }) => <div className="sticky_style" style={style}><Well bsSize="small">AOFAS Hallux MTP-IP Score: {(this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8)} / {100} = {((((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8))/(100)*100).toFixed(1))} % <br/>
            Graphical AOFAS Hallux MTP-IP Score: (%)
            <ProgressBar>
            <ProgressBar active now={100*(((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8))/(100))}/>
            <ProgressBar active bsStyle="danger" now={100-(100*(((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8))/(100)))}/>
            </ProgressBar>
            </Well>
            </div>}

          </Sticky>      


                <p />

                <br/>                      
                <h4>Pain (40 points)</h4>
                <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
                <h4>Function (45 points)</h4>
                <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
                <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
                <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
                <Buttons5 callbackFromParent5={this.myCallback5} callbackFromParent5_text={this.myCallback5_text}/>
                <Buttons6 callbackFromParent6={this.myCallback6} callbackFromParent6_text={this.myCallback6_text}/>
                <Buttons7 callbackFromParent7={this.myCallback7} callbackFromParent7_text={this.myCallback7_text}/>
                <h4>Alignment (15 points)</h4>
                <Buttons8 callbackFromParent8={this.myCallback8} callbackFromParent8_text={this.myCallback8_text}/>
                </StickyContainer>
                <br />
                    
              <button style = {{width: '100%'}}>Submit Score</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(AOFASH_Link);
