import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
//const jsPDF = require('jspdf');
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
//import queryString from 'query-string'; don't think I'll use this line

///////////////////////////////////////////Calculator Methods

class Buttons1 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent1(value);
if (value === 0)
this.props.callbackFromParent1_text("I have no pain at the moment");
if (value === 1)
this.props.callbackFromParent1_text("The pain is very mild at the moment");
if (value === 2)
this.props.callbackFromParent1_text("The pain is moderate at the moment");
if (value === 3)
this.props.callbackFromParent1_text("The pain is fairly severe at the moment");
if (value === 4)
this.props.callbackFromParent1_text("The pain is very severe at the moment");
if (value === 5)
this.props.callbackFromParent1_text("The pain is the worst imaginable at the moment");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Section 1: Pain Intensity</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have no pain at the moment</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain is very mild at the moment</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain is moderate at the moment</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain is fairly severe at the moment</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain is very severe at the moment</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain is the worst imaginable at the moment</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Section 1: Pain Intensity</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have no pain at the moment</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain is very mild at the moment</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain is moderate at the moment</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain is fairly severe at the moment</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain is very severe at the moment</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain is the worst imaginable at the moment</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons2 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent2(value);
if (value === 0)
this.props.callbackFromParent2_text("I can look after myself normally without causing extra pain");
if (value === 1)
this.props.callbackFromParent2_text("I can look after myself normally but it causes extra pain");
if (value === 2)
this.props.callbackFromParent2_text("It is painful to look after myself and I am slow and careful");
if (value === 3)
this.props.callbackFromParent2_text("I need some help but can manage most of my personal care");
if (value === 4)
this.props.callbackFromParent2_text("I need help every day in most aspects of self care");
if (value === 5)
this.props.callbackFromParent2_text("I do not get dressed, I wash with difficulty, and stay in bed");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Section 2: Personal Care (Washing, Dressing, etc.)</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can look after myself normally without causing extra pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can look after myself normally but it causes extra pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} It is painful to look after myself and I am slow and careful</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I need some help but can manage most of my personal care</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I need help every day in most aspects of self care</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do not get dressed, I wash with difficulty, and stay in bed</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Section 2: Personal Care (Washing, Dressing, etc.)</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can look after myself normally without causing extra pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can look after myself normally but it causes extra pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} It is painful to look after myself and I am slow and careful</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I need some help but can manage most of my personal care</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I need help every day in most aspects of self care</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do not get dressed, I wash with difficulty, and stay in bed</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons3 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent3(value);
if (value === 0)
this.props.callbackFromParent3_text("I can lift heavy weights without extra pain");
if (value === 1)
this.props.callbackFromParent3_text("I can lift heavy weights but it gives extra pain");
if (value === 2)
this.props.callbackFromParent3_text("Pain prevents me lifting heavy weights off the floor, but I can manage if they are conveniently placed");
if (value === 3)
this.props.callbackFromParent3_text("Pain prevents me from lifting heavy weights, but I can manage light to medium weights if they are conveniently positioned");
if (value === 4)
this.props.callbackFromParent3_text("I can lift very light weights");
if (value === 5)
this.props.callbackFromParent3_text("I cannot lift or carry anything");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Section 3: Lifting</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can lift heavy weights without extra pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can lift heavy weights but it gives extra pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me lifting heavy weights off the floor, but I can manage if they are conveniently placed, for example, on a table</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from lifting heavy weights, but I can manage light to medium weights if they are conveniently positioned</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can lift very light weights</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot lift or carry anything</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Section 3: Lifting</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can lift heavy weights without extra pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can lift heavy weights but it gives extra pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me lifting heavy weights off the floor, but I can manage if they are conveniently placed, for example, on a table</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from lifting heavy weights, but I can manage light to medium weights if they are conveniently positioned</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can lift very light weights</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot lift or carry anything</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons4 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent4(value);
if (value === 0)
this.props.callbackFromParent4_text("I can read as much as I want to with no pain in my neck");
if (value === 1)
this.props.callbackFromParent4_text("I can read as much as I want to with slight pain in my neck");
if (value === 2)
this.props.callbackFromParent4_text("I can read as much as I want to with moderate pain in my neck");
if (value === 3)
this.props.callbackFromParent4_text("I can't read as much as I want to because of moderate pain in my neck");
if (value === 4)
this.props.callbackFromParent4_text("I can hardly read at all because of severe pain in my neck");
if (value === 5)
this.props.callbackFromParent4_text("I cannot read at all");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Section 4: Reading</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can read as much as I want to with no pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can read as much as I want to with slight pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can read as much as I want to with moderate pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can't read as much as I want to because of moderate pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can hardly read at all because of severe pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot read at all</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Section 4: Reading</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can read as much as I want to with no pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can read as much as I want to with slight pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can read as much as I want to with moderate pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can't read as much as I want to because of moderate pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can hardly read at all because of severe pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot read at all</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons5 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent5(value);
if (value === 0)
this.props.callbackFromParent5_text("I have no headaches at all");
if (value === 1)
this.props.callbackFromParent5_text("I have slight headaches, which come infrequently");
if (value === 2)
this.props.callbackFromParent5_text("I have moderate headaches, which come infrequently");
if (value === 3)
this.props.callbackFromParent5_text("I have moderate headaches, which come frequently");
if (value === 4)
this.props.callbackFromParent5_text("I have severe headaches, which come frequently");
if (value === 5)
this.props.callbackFromParent5_text("I have headaches almost all the time");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Section 5: Headaches</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have no headaches at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have slight headaches, which come infrequently</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have moderate headaches, which come infrequently</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have moderate headaches, which come frequently</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have severe headaches, which come frequently</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have headaches almost all the time</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Section 5: Headaches</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have no headaches at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have slight headaches, which come infrequently</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have moderate headaches, which come infrequently</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have moderate headaches, which come frequently</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have severe headaches, which come frequently</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have headaches almost all the time</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons6 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent6(value);
if (value === 0)
this.props.callbackFromParent6_text("I can concentrate fully when I want to with no difficulty");
if (value === 1)
this.props.callbackFromParent6_text("I can concentrate fully when I want to with slight difficulty");
if (value === 2)
this.props.callbackFromParent6_text("I have a fair degree of difficulty in concentrating when I want to");
if (value === 3)
this.props.callbackFromParent6_text("I have a lot of difficulty in concentrating when I want to");
if (value === 4)
this.props.callbackFromParent6_text("I have a great deal of difficulty in concentrating when I want to");
if (value === 5)
this.props.callbackFromParent6_text("I cannot concentrate at all");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Section 6: Concentration</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can concentrate fully when I want to with no difficulty</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can concentrate fully when I want to with slight difficulty</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have a fair degree of difficulty in concentrating when I want to</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have a lot of difficulty in concentrating when I want to</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have a great deal of difficulty in concentrating when I want to</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot concentrate at all</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Section 6: Concentration</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can concentrate fully when I want to with no difficulty</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can concentrate fully when I want to with slight difficulty</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have a fair degree of difficulty in concentrating when I want to</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have a lot of difficulty in concentrating when I want to</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have a great deal of difficulty in concentrating when I want to</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot concentrate at all</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons7 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent7(value);
if (value === 0)
this.props.callbackFromParent7_text("I can do as much work as I want to");
if (value === 1)
this.props.callbackFromParent7_text("I can only do my usual work, but no more");
if (value === 2)
this.props.callbackFromParent7_text("I can do most of my usual work, but no more");
if (value === 3)
this.props.callbackFromParent7_text("I cannot do my usual work");
if (value === 4)
this.props.callbackFromParent7_text("I can hardly do any work at all");
if (value === 5)
this.props.callbackFromParent7_text("I can't do any work at all");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Section 7: Work</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can do as much work as I want to</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can only do my usual work, but no more</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can do most of my usual work, but no more</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot do my usual work</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can hardly do any work at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can't do any work at all</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Section 7: Work</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can do as much work as I want to</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can only do my usual work, but no more</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can do most of my usual work, but no more</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot do my usual work</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can hardly do any work at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can't do any work at all</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons8 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent8(value);
if (value === 0)
this.props.callbackFromParent8_text("I can drive my car without any neck pain");
if (value === 1)
this.props.callbackFromParent8_text("I can drive my car as long as I want with slight pain in my neck");
if (value === 2)
this.props.callbackFromParent8_text("I can drive my car as long as I want with moderate pain in my neck");
if (value === 3)
this.props.callbackFromParent8_text("I cannot drive my car as long as I want because of moderate pain in my neck");
if (value === 4)
this.props.callbackFromParent8_text("I can hardly drive at all because of severe pain in my neck");
if (value === 5)
this.props.callbackFromParent8_text("I cannot drive my car at all");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Section 8: Driving</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can drive my car without any neck pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can drive my car as long as I want with slight pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can drive my car as long as I want with moderate pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot drive my car as long as I want because of moderate pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can hardly drive at all because of severe pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot drive my car at all</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Section 8: Driving</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can drive my car without any neck pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can drive my car as long as I want with slight pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can drive my car as long as I want with moderate pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot drive my car as long as I want because of moderate pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can hardly drive at all because of severe pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot drive my car at all</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons9 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent9(value);
if (value === 0)
this.props.callbackFromParent9_text("I have no trouble sleeping");
if (value === 1)
this.props.callbackFromParent9_text("My sleep is slightly disturbed (less than 1 hr sleepless)");
if (value === 2)
this.props.callbackFromParent9_text("My sleep is mildly disturbed (1-2 hrs sleepless)");
if (value === 3)
this.props.callbackFromParent9_text("My sleep is moderately disturbed (2-3 hrs sleepless)");
if (value === 4)
this.props.callbackFromParent9_text("My sleep is greatly disturbed (3-5 hrs sleepless)");
if (value === 5)
this.props.callbackFromParent9_text("My sleep is completely disturbed (5-7 hrs sleepless)");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Section 9: Sleeping</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have no trouble sleeping</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My sleep is slightly disturbed (less than 1 hr sleepless)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My sleep is mildly disturbed (1-2 hrs sleepless)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My sleep is moderately disturbed (2-3 hrs sleepless)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My sleep is greatly disturbed (3-5 hrs sleepless)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My sleep is completely disturbed (5-7 hrs sleepless)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Section 9: Sleeping</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have no trouble sleeping</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My sleep is slightly disturbed (less than 1 hr sleepless)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My sleep is mildly disturbed (1-2 hrs sleepless)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My sleep is moderately disturbed (2-3 hrs sleepless)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My sleep is greatly disturbed (3-5 hrs sleepless)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My sleep is completely disturbed (5-7 hrs sleepless)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons10 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent10(value);
if (value === 0)
this.props.callbackFromParent10_text("I am able to engage in all my recreation activities with no neck pain at all");
if (value === 1)
this.props.callbackFromParent10_text("I am able to engage in all my recreation activities, with some pain in my neck");
if (value === 2)
this.props.callbackFromParent10_text("I am able to engage in most, but not all of my usual recreation activities because of pain in my neck");
if (value === 3)
this.props.callbackFromParent10_text("I am able to engage in a few of my usual recreation activities because of pain in my neck");
if (value === 4)
this.props.callbackFromParent10_text("I can hardly do any recreation activities because of pain in my neck");
if (value === 5)
this.props.callbackFromParent10_text("I can't do any recreation activities at all");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Section 10. Recreation</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I am able to engage in all my recreation activities with no neck pain at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I am able to engage in all my recreation activities, with some pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I am able to engage in most, but not all of my usual recreation activities because of pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I am able to engage in a few of my usual recreation activities because of pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can hardly do any recreation activities becasue of pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can't do any recreation activities at all</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Section 10. Recreation</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I am able to engage in all my recreation activities with no neck pain at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I am able to engage in all my recreation activities, with some pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I am able to engage in most, but not all of my usual recreation activities because of pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I am able to engage in a few of my usual recreation activities because of pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can hardly do any recreation activities becasue of pain in my neck</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can't do any recreation activities at all</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
    


  


class NDI_Link extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

    //Calculator variables    
    selectedValue1: 0,
    selectedValue1_text: 'I have no pain at the moment',

    selectedValue2: 0,
    selectedValue2_text: 'I can look after myself normally without causing extra pain',

    selectedValue3: 0,
    selectedValue3_text: 'I can lift heavy weights without extra pain',

    selectedValue4: 0,
    selectedValue4_text: 'I can read as much as I want to with no pain in my neck',

    selectedValue5: 0,
    selectedValue5_text: 'I have no headaches at all',

    selectedValue6: 0,
    selectedValue6_text: 'I can concentrate fully when I want to with no difficulty',

    selectedValue7: 0,
    selectedValue7_text: 'I can do as much work as I want to',

    selectedValue8: 0,
    selectedValue8_text: 'I can drive my car without any neck pain',

    selectedValue9: 0,
    selectedValue9_text: 'I have no trouble sleeping',

    selectedValue10: 0,
    selectedValue10_text: 'I am able to engage in all my recreation activities with no neck pain at all',      
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  NDIScoresRef.collection("ndi_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  NDIScoresRef.collection("ndi_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  NDIScoresRef.collection("ndi_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  NDIScoresRef.collection("ndi_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });  
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){    
  const ndi_score = NDIScoresRef.collection("ndi_scores").doc(this.props.requestSurveyID).set({
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Neck Disability Index',      
    Question_01_NDI: this.state.selectedValue1,
    Question_02_NDI: this.state.selectedValue2,
    Question_03_NDI: this.state.selectedValue3,
    Question_04_NDI: this.state.selectedValue4,
    Question_05_NDI: this.state.selectedValue5,
    Question_06_NDI: this.state.selectedValue6,
    Question_07_NDI: this.state.selectedValue7,            
    Question_08_NDI: this.state.selectedValue8,
    Question_09_NDI: this.state.selectedValue9,
    Question_10_NDI: this.state.selectedValue10,
    Total_Score_NDI: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10),     
  });
}
else {
  const ndi_score = NDIScoresRef.collection("ndi_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'Neck Disability Index',      
    Question_01_NDI: this.state.selectedValue1,
    Question_02_NDI: this.state.selectedValue2,
    Question_03_NDI: this.state.selectedValue3,
    Question_04_NDI: this.state.selectedValue4,
    Question_05_NDI: this.state.selectedValue5,
    Question_06_NDI: this.state.selectedValue6,
    Question_07_NDI: this.state.selectedValue7,            
    Question_08_NDI: this.state.selectedValue8,
    Question_09_NDI: this.state.selectedValue9,
    Question_10_NDI: this.state.selectedValue10,
    Total_Score_NDI: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10),  
  });      
}     
  this.setState({
    clinician_to_share_with: '',
    user_id: '',        
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.props.clinicianSendingEmail + '. You may now close this alert and the entire window.');   
}
    

    
    
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("ndi_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallback1  = (dataFromChild) => {
    this.setState({selectedValue1: dataFromChild});
    }
    myCallback1_text  = (dataFromChild) => {
    this.setState({selectedValue1_text: dataFromChild});
    }
    myCallback2  = (dataFromChild) => {
    this.setState({selectedValue2: dataFromChild});
    }
    myCallback2_text  = (dataFromChild) => {
    this.setState({selectedValue2_text: dataFromChild});
    }
    myCallback3  = (dataFromChild) => {
    this.setState({selectedValue3: dataFromChild});
    }
    myCallback3_text  = (dataFromChild) => {
    this.setState({selectedValue3_text: dataFromChild});
    }
    myCallback4  = (dataFromChild) => {
    this.setState({selectedValue4: dataFromChild});
    }
    myCallback4_text  = (dataFromChild) => {
    this.setState({selectedValue4_text: dataFromChild});
    }
    myCallback5  = (dataFromChild) => {
    this.setState({selectedValue5: dataFromChild});
    }
    myCallback5_text  = (dataFromChild) => {
    this.setState({selectedValue5_text: dataFromChild});
    }
    myCallback6  = (dataFromChild) => {
    this.setState({selectedValue6: dataFromChild});
    }
    myCallback6_text  = (dataFromChild) => {
    this.setState({selectedValue6_text: dataFromChild});
    }
    myCallback7  = (dataFromChild) => {
    this.setState({selectedValue7: dataFromChild});
    }
    myCallback7_text  = (dataFromChild) => {
    this.setState({selectedValue7_text: dataFromChild});
    }
    myCallback8  = (dataFromChild) => {
    this.setState({selectedValue8: dataFromChild});
    }
    myCallback8_text  = (dataFromChild) => {
    this.setState({selectedValue8_text: dataFromChild});
    }
    myCallback9  = (dataFromChild) => {
    this.setState({selectedValue9: dataFromChild});
    }
    myCallback9_text  = (dataFromChild) => {
    this.setState({selectedValue9_text: dataFromChild});
    }
    myCallback10  = (dataFromChild) => {
    this.setState({selectedValue10: dataFromChild});
    }
    myCallback10_text  = (dataFromChild) => {
    this.setState({selectedValue10_text: dataFromChild});
    }     
  

  
    
    

    
render() { 

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>Neck Disability Index Calculator</h1>
                <h4>Hello, {this.props.submitterName}, your clinician, {this.props.clinicianSendingEmail}, has sent you this survey to help further understand your health. Please click the appropriate buttons below and then click "Submit Score". Please note that your <u>digital privacy is taken very seriously</u> and a number of technologies have been implemented to protect the privacy and integrity of your data, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Your data <u>will never be shared with anyone other than the assigned clinician</u>.</h4>

            <form onSubmit={this.handleSubmit}>



                <StickyContainer>
                <Sticky>
                {({ style }) => <div className="sticky_style" style={style}><Well bsSize="small">Neck Disability Index: {(this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10)} / {50
                } = {((((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10))/(50
                )*100).toFixed(1))} % <br/>
                Graphical Neck Disability Index: (%)
                <ProgressBar>
                <ProgressBar active now={100-(100*(((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10))/(50
                )))}/>
                <ProgressBar active bsStyle="danger" now={(100*(((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10))/(50
                )))}/>
                </ProgressBar>
                </Well></div>}
                </Sticky>
                <p />
                <br/>
                <br/>                     
                    
                <h4>Instructions: This questionnaire has been designed to give your therapist information as to how your neck pain has affected your ability to manage in everyday life. Please answer every question by placing a mark in the one box that best describes your condition today. We realize you may feel that two of the statements may describe your condition, but please mark only the box that most closely describes your current condition. 
                </h4>
  
                <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
                <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
                <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
                <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
                <Buttons5 callbackFromParent5={this.myCallback5} callbackFromParent5_text={this.myCallback5_text}/>
                <Buttons6 callbackFromParent6={this.myCallback6} callbackFromParent6_text={this.myCallback6_text}/>
                <Buttons7 callbackFromParent7={this.myCallback7} callbackFromParent7_text={this.myCallback7_text}/>
                <Buttons8 callbackFromParent8={this.myCallback8} callbackFromParent8_text={this.myCallback8_text}/>
                <Buttons9 callbackFromParent9={this.myCallback9} callbackFromParent9_text={this.myCallback9_text}/>
                <Buttons10 callbackFromParent10={this.myCallback10} callbackFromParent10_text={this.myCallback10_text}/>
                </StickyContainer>
                <br />
                    
              <button style = {{width: '100%'}}>Submit Score</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(NDI_Link);
