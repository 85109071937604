import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Slider, {createSliderWithTooltip} from 'rc-slider';

///////////////////////////////////////////Calculator Methods
const SliderWithTooltip = createSliderWithTooltip(Slider);

const wrapperStyle = {'max-width': '400px', 'margin-left': 'auto', 'margin-right': 'auto', 'margin-top': 40, 'margin-bottom': 60 };

const marks1 = {
		0: <strong>0<br />Worst pain</strong>,
		10: <strong>10<br />No pain</strong>,
	};

class Slider1 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 10}
	}

	someFn = (value) => {
		this.props.callbackFromParentS1(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>1. My usual hip pain is</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={10} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks1} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}
class Buttons1 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 10}
}
someFn = (value) => {
this.props.callbackFromParent1(value);
if (value === 0)
this.props.callbackFromParent1_text("Unable to lie on sore hip");
if (value === 2)
this.props.callbackFromParent1_text("Lie on sore hip for 5 to 15 minutes");
if (value === 5)
this.props.callbackFromParent1_text("Lie on sore hip for 15 to 30 minutes");
if (value === 7)
this.props.callbackFromParent1_text("Lie on sore hip for 30 minutes to 1 hour");
if (value === 10)
this.props.callbackFromParent1_text("Lie on sore hip for longer than 1 hour");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>2. I can lie on my sore hip</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I am unable to lie on my sore side at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} For 5 to 15 minutes, then I have to move</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} For 15 to 30 minutes, then I have to move</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} For 30 minutes to 1 hour, then I have to move</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} For longer than 1 hour</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>2. I can lie on my sore hip</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I am unable to lie on my sore side at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} For 5 to 15 minutes, then I have to move</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} For 15 to 30 minutes, then I have to move</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} For 30 minutes to 1 hour, then I have to move</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} For longer than 1 hour</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons2 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 10}
}
someFn = (value) => {
this.props.callbackFromParent2(value);
if (value === 0)
this.props.callbackFromParent2_text("Cannot use stairs at all");
if (value === 2)
this.props.callbackFromParent2_text("Stairs one step at a time with use of banister");
if (value === 5)
this.props.callbackFromParent2_text("Stairs normally with use of banister");
if (value === 7)
this.props.callbackFromParent2_text("Stairs normally with some hip pain");
if (value === 10)
this.props.callbackFromParent2_text("Stairs normally with no hip pain");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>3. Walking up or down one flight of stairs</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot use stairs at all because of hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I use stairs one step at a time and holding onto a banister because of hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can use stairs normally holding onto a banister because of hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can use stairs normally with some hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can use stairs normally with no hip pain</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>3. Walking up or down one flight of stairs</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot use stairs at all because of hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I use stairs one step at a time and holding onto a banister because of hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can use stairs normally holding onto a banister because of hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can use stairs normally with some hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can use stairs normally with no hip pain</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons3 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 10}
}
someFn = (value) => {
this.props.callbackFromParent3(value);
if (value === 0)
this.props.callbackFromParent3_text("Cannot navigate a slope or ramp at all");
if (value === 2)
this.props.callbackFromParent3_text("Significant difficulty navigating a slope or ramp");
if (value === 5)
this.props.callbackFromParent3_text("Some difficulty navigating a slope or ramp");
if (value === 7)
this.props.callbackFromParent3_text("Navigate a slope or ramp with slight hip pain");
if (value === 10)
this.props.callbackFromParent3_text("Navigate a slope or ramp with no hip pain");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>4. Walking up or down a ramp or slope</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot walk up or down a slope or ramp because of hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have significant difficulty negotiating slopes or ramps because of hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have some difficulty walking up and down a slope or ramp because of hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can walk normally up and down a slope or ramp with slight hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can walk normally up and down a slope or ramp with no hip pain</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>4. Walking up or down a ramp or slope</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot walk up or down a slope or ramp because of hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have significant difficulty negotiating slopes or ramps because of hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have some difficulty walking up and down a slope or ramp because of hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can walk normally up and down a slope or ramp with slight hip pain</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can walk normally up and down a slope or ramp with no hip pain</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons4 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 10}
}
someFn = (value) => {
this.props.callbackFromParent4(value);
if (value === 0)
this.props.callbackFromParent4_text("After sitting, have to stand for more than 20 sec before walking");
if (value === 2)
this.props.callbackFromParent4_text("After sitting, have to stand for less than 20 sec before walking");
if (value === 5)
this.props.callbackFromParent4_text("After sitting, have to stand for a moment before walking");
if (value === 7)
this.props.callbackFromParent4_text("After sitting, walking is difficult for a few steps");
if (value === 10)
this.props.callbackFromParent4_text("After sitting, walking is not a problem");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>5. After sitting for 30 minutes, moving to standing and then walking is</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have to stand still for more than 20 seconds before I walk</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have to stand still for less than 20 seconds before I walk</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have to stand still for a moment or two before I walk</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Difficult for a few steps</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not a problem</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>5. After sitting for 30 minutes, moving to standing and then walking is</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have to stand still for more than 20 seconds before I walk</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have to stand still for less than 20 seconds before I walk</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have to stand still for a moment or two before I walk</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Difficult for a few steps</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not a problem</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons5 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 10}
}
someFn = (value) => {
this.props.callbackFromParent5(value);
if (value === 0)
this.props.callbackFromParent5_text("Because of hip pain, no work in my house or garden");
if (value === 2)
this.props.callbackFromParent5_text("Because of hip pain, limited work in my house but I do not garden");
if (value === 5)
this.props.callbackFromParent5_text("Because of hip pain, limited work in my house and garden");
if (value === 7)
this.props.callbackFromParent5_text("Because of hip pain, 30 to 6- min bursts of work in my house and garden");
if (value === 10)
this.props.callbackFromParent5_text("Can work in house and garden for an hour or more");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>6. Work about the house or garden (or similar activity)</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Because of hip pain, I do not do any work in my house or garden</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Because of hip pain, I do limited work in my house but I do not garden</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Because of hip pain, I do very limited work in my house and garden</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Because of hip pain, I can work in my house and garden in 30 to 60 min bursts</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can work in my house and garden for an hour or more</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>6. Work about the house or garden (or similar activity)</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Because of hip pain, I do not do any work in my house or garden</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Because of hip pain, I do limited work in my house but I do not garden</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Because of hip pain, I do very limited work in my house and garden</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Because of hip pain, I can work in my house and garden in 30 to 60 min bursts</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can work in my house and garden for an hour or more</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}




class Buttons6 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 10}
}
someFn = (value) => {
this.props.callbackFromParent6(value);
if (value === 0)
this.props.callbackFromParent6_text("No, I am unable to exercise, I don't want to or I don't have time");
if (value === 4)
this.props.callbackFromParent6_text("Significantly less exercise than I used to");
if (value === 7)
this.props.callbackFromParent6_text("Somewhat less exercise than I used to");
if (value === 10)
this.props.callbackFromParent6_text("Yes, I can exercise as I used to");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>7. Are you currently taking part in regular exercise, physical activity, or sport?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No, I am unable to exercise, I don't want to or I don't have time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Significantly less than I used to</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Somewhat less than I used to</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes, I can exercise as I used to</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>7. Are you currently taking part in regular exercise, physical activity, or sport?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No, I am unable to exercise, I don't want to or I don't have time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Significantly less than I used to</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Somewhat less than I used to</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes, I can exercise as I used to</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}

class Buttons7 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 30}
}
someFn = (value) => {
this.props.callbackFromParent7(value);
if (value === 6)
this.props.callbackFromParent7_text("I do not undertake any extra activity on my legs, I only move about the house");
if (value === 12)
this.props.callbackFromParent7_text("I do less than 10 minutes");
if (value === 18)
this.props.callbackFromParent7_text("I do 10-19 minutes");
if (value === 24)
this.props.callbackFromParent7_text("I do 20-29 minutes");
if (value === 30)
this.props.callbackFromParent7_text("I do more than 30 minutes");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>8a. If you have no pain while undertaking walking, shopping, running, or other weight bearing type exercise, how much of this activity do you do each day?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={30}>
<ToggleButton bsStyle='btn-custom' value={6}>{(this.state.clicked === 6) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do not undertake any extra activity on my legs, I only move about the house</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={12}>{(this.state.clicked === 12) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do less than 10 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={18}>{(this.state.clicked === 18) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do 10-19 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={24}>{(this.state.clicked === 24) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do 20-29 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={30}>{(this.state.clicked === 30) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do more than 30 minutes</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>8a. If you have no pain while undertaking walking, shopping, running, or other weight bearing type exercise, how much of this activity do you do each day?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={30}>
<ToggleButton bsStyle='btn-custom' value={6}>{(this.state.clicked === 6) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do not undertake any extra activity on my legs, I only move about the house</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={12}>{(this.state.clicked === 12) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do less than 10 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={18}>{(this.state.clicked === 18) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do 10-19 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={24}>{(this.state.clicked === 24) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do 20-29 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={30}>{(this.state.clicked === 30) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do more than 30 minutes</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}

class Buttons8 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 20}
}
someFn = (value) => {
this.props.callbackFromParent8(value);
if (value === 0)
this.props.callbackFromParent8_text("I do not undertake any extra activity on my legs, I only move about the house");
if (value === 5)
this.props.callbackFromParent8_text("I do less than 10 minutes");
if (value === 10)
this.props.callbackFromParent8_text("I do 10-19 minutes");
if (value === 15)
this.props.callbackFromParent8_text("I do 20-29 minutes");
if (value === 20)
this.props.callbackFromParent8_text("I do more than 30 minutes");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>8b. If you have some pain with exercise, but it does not stop you from walking, shopping, running, or other weight bearing type exercise, how much of this actiivty do you do each day?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={20}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do not undertake any extra activity on my legs, I only move about the house</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do less than 10 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do 10-19 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={15}>{(this.state.clicked === 15) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do 20-29 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={20}>{(this.state.clicked === 20) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do more than 30 minutes</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>8b. If you have some pain with exercise, but it does not stop you from walking, shopping, running, or other weight bearing type exercise, how much of this actiivty do you do each day?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={20}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do not undertake any extra activity on my legs, I only move about the house</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do less than 10 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do 10-19 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={15}>{(this.state.clicked === 15) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do 20-29 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={20}>{(this.state.clicked === 20) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do more than 30 minutes</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons9 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 10}
}
someFn = (value) => {
this.props.callbackFromParent9(value);
if (value === 0)
this.props.callbackFromParent9_text("I do not undertake any extra activity on my legs, I only move about the house");
if (value === 2)
this.props.callbackFromParent9_text("I do less than 10 minutes");
if (value === 5)
this.props.callbackFromParent9_text("I do 10-19 minutes");
if (value === 7)
this.props.callbackFromParent9_text("I do 20-29 minutes");
if (value === 10)
this.props.callbackFromParent9_text("I do more than 30 minutes");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>8c. If you have pain that is so severe that it will stop you from walking, shopping, running, or other weight bearing exercise, how much of this activity do you each day?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do not undertake any extra activity on my legs, I only move about the house</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do less than 10 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do 10-19 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do 20-29 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do more than 30 minutes</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>8c. If you have pain that is so severe that it will stop you from walking, shopping, running, or other weight bearing exercise, how much of this activity do you each day?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do not undertake any extra activity on my legs, I only move about the house</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do less than 10 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do 10-19 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do 20-29 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do more than 30 minutes</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}


class Buttons0 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 7}
}
someFn = (value) => {
this.props.callbackFromParent0(value);
if (value === 7){
this.props.callbackFromParent0_text("I have no pain");
this.props.callbackFromParent7_adjust(1);
this.props.callbackFromParent7(30);    
this.props.callbackFromParent8_adjust(0.001);
this.props.callbackFromParent8(20);     
this.props.callbackFromParent9_adjust(0.001); 
this.props.callbackFromParent9(10);     
}
if (value === 8){
this.props.callbackFromParent0_text("I have some pain but it does not stop me");
this.props.callbackFromParent7_adjust(0.001);
this.props.callbackFromParent7(30);     
this.props.callbackFromParent8_adjust(1); 
this.props.callbackFromParent8(20);    
this.props.callbackFromParent9_adjust(0.001); 
this.props.callbackFromParent9(10);     
}
if (value === 9){
this.props.callbackFromParent0_text("The pain stops me from completing the activity");
this.props.callbackFromParent7_adjust(0.001);  
this.props.callbackFromParent7(30);     
this.props.callbackFromParent8_adjust(0.001);
this.props.callbackFromParent8(20);    
this.props.callbackFromParent9_adjust(1);     
this.props.callbackFromParent9(10);     
    
}    
   
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Does your current hp pain affect your ability to undertake weigh bearing activities? (e.g. walking, shopping, running, squats, lunges)</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={7}>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have no pain</ToggleButton>    
<ToggleButton bsStyle='btn-custom' value={8}>{(this.state.clicked === 8) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have some pain but it does not stop me</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={9}>{(this.state.clicked === 9) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain stops me from completing the activity</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Does your current hp pain affect your ability to undertake weigh bearing activities? (e.g. walking, shopping, running, squats, lunges)</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={7}>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have no pain</ToggleButton>    
<ToggleButton bsStyle='btn-custom' value={8}>{(this.state.clicked === 8) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have some pain but it does not stop me</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={9}>{(this.state.clicked === 9) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain stops me from completing the activity</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}




   
 


  


class VISAG_Link extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

        
        
        
        
    //Calculator variables    
        selectedValueS1: 10,
        selectedValueS1_text: 'Usual hip pain is: ',

        selectedValue1: 10,
        selectedValue1_text: 'Lie on sore hip for longer than 1 hour',

        selectedValue2: 10,
        selectedValue2_text: 'Stairs normally with no hip pain',

        selectedValue3: 10,
        selectedValue3_text: 'Navigate a slope or ramp with no hip pain',

        selectedValue4: 10,
        selectedValue4_text: 'After sitting, walking is not a problem',

        selectedValue5: 10,
        selectedValue5_text: 'Can work in house and garden for an hour or more',

        selectedValue6: 10,
        selectedValue6_text: 'Yes, I can exercase as I used to',
        
        selectedValue7: 30,
        selectedValue7_text: 'I do more than 30 minutes"',

        selectedValue8: 20,
        selectedValue8_text: 'I do more than 30 minutes"',

        selectedValue9: 10,
        selectedValue9_text: 'I do more than 30 minutes"', 
        
        selectedValue0: 7,
        selectedValue0_text: 'I have no pain',
        selectedValue7_adjust:1,
        selectedValue8_adjust:0.001,
        selectedValue9_adjust:0.001,
           
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const VISAGScoresRef = firebase.firestore();
  VISAGScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  VISAGScoresRef.collection("visag_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const VISAGScoresRef = firebase.firestore();
  VISAGScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  VISAGScoresRef.collection("visag_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const VISAGScoresRef = firebase.firestore();
  VISAGScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  VISAGScoresRef.collection("visag_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const VISAGScoresRef = firebase.firestore();
  VISAGScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  VISAGScoresRef.collection("visag_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const VISAGScoresRef = firebase.firestore();
  VISAGScoresRef.settings({
      timestampsInSnapshots: true
  });  
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){        
  const visag_score = VISAGScoresRef.collection("visag_scores").doc(this.props.requestSurveyID).set({            
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Victorian Institute of Sports Assessment Greater Trochanter',
    Question_01_VISAG: this.state.selectedValueS1,
    Question_02_VISAG: this.state.selectedValue1,
    Question_03_VISAG: this.state.selectedValue2,
    Question_04_VISAG: this.state.selectedValue3,
    Question_05_VISAG: this.state.selectedValue4,
    Question_06_VISAG: this.state.selectedValue5,
    Question_07_VISAG: this.state.selectedValue6,            
    Question_08_VISAG: parseFloat((this.state.selectedValue7*this.state.selectedValue7_adjust).toFixed(0)),
    Question_09_VISAG: parseFloat((this.state.selectedValue8*this.state.selectedValue8_adjust).toFixed(0)),
    Question_10_VISAG: parseFloat((this.state.selectedValue9*this.state.selectedValue9_adjust).toFixed(0)),
    Question_0_VISAG: this.state.selectedValue0,      
    Total_Score_VISAG: parseFloat((this.state.selectedValueS1+this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7*this.state.selectedValue7_adjust+this.state.selectedValue8*this.state.selectedValue8_adjust+this.state.selectedValue9*this.state.selectedValue9_adjust).toFixed(0)),   
  });
}
else {
  const visag_score = VISAGScoresRef.collection("visag_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'Victorian Institute of Sports Assessment Greater Trochanter',
    Question_01_VISAG: this.state.selectedValueS1,
    Question_02_VISAG: this.state.selectedValue1,
    Question_03_VISAG: this.state.selectedValue2,
    Question_04_VISAG: this.state.selectedValue3,
    Question_05_VISAG: this.state.selectedValue4,
    Question_06_VISAG: this.state.selectedValue5,
    Question_07_VISAG: this.state.selectedValue6,            
    Question_08_VISAG: parseFloat((this.state.selectedValue7*this.state.selectedValue7_adjust).toFixed(0)),
    Question_09_VISAG: parseFloat((this.state.selectedValue8*this.state.selectedValue8_adjust).toFixed(0)),
    Question_10_VISAG: parseFloat((this.state.selectedValue9*this.state.selectedValue9_adjust).toFixed(0)),
    Question_0_VISAG: this.state.selectedValue0,      
    Total_Score_VISAG: parseFloat((this.state.selectedValueS1+this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7*this.state.selectedValue7_adjust+this.state.selectedValue8*this.state.selectedValue8_adjust+this.state.selectedValue9*this.state.selectedValue9_adjust).toFixed(0)),       
  });      
}       
  this.setState({
    clinician_to_share_with: '',
    user_id: '',
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.props.clinicianSendingEmail + '. You may now close this alert and the entire window.');    
}     
    
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("visag_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallbackS1  = (dataFromChild) => {
    this.setState({selectedValueS1: dataFromChild});
    }
    myCallback1  = (dataFromChild) => {
    this.setState({selectedValue1: dataFromChild});
    }
    myCallback1_text  = (dataFromChild) => {
    this.setState({selectedValue1_text: dataFromChild});
    }
    myCallback2  = (dataFromChild) => {
    this.setState({selectedValue2: dataFromChild});
    }
    myCallback2_text  = (dataFromChild) => {
    this.setState({selectedValue2_text: dataFromChild});
    }
    myCallback3  = (dataFromChild) => {
    this.setState({selectedValue3: dataFromChild});
    }
    myCallback3_text  = (dataFromChild) => {
    this.setState({selectedValue3_text: dataFromChild});
    }
    myCallback4  = (dataFromChild) => {
    this.setState({selectedValue4: dataFromChild});
    }
    myCallback4_text  = (dataFromChild) => {
    this.setState({selectedValue4_text: dataFromChild});
    }
    myCallback5  = (dataFromChild) => {
    this.setState({selectedValue5: dataFromChild});
    }
    myCallback5_text  = (dataFromChild) => {
    this.setState({selectedValue5_text: dataFromChild});
    }
    myCallback6  = (dataFromChild) => {
    this.setState({selectedValue6: dataFromChild});
    }
    myCallback6_text  = (dataFromChild) => {
    this.setState({selectedValue6_text: dataFromChild});
    }
    myCallback7  = (dataFromChild) => {
    this.setState({selectedValue7: dataFromChild});
    }
    myCallback7_text  = (dataFromChild) => {
    this.setState({selectedValue7_text: dataFromChild});
    }
    myCallback8  = (dataFromChild) => {
    this.setState({selectedValue8: dataFromChild});
    }
    myCallback8_text  = (dataFromChild) => {
    this.setState({selectedValue8_text: dataFromChild});
    }
    myCallback9  = (dataFromChild) => {
    this.setState({selectedValue9: dataFromChild});
    }
    myCallback9_text  = (dataFromChild) => {
    this.setState({selectedValue9_text: dataFromChild});
    }
    myCallback0  = (dataFromChild) => {
    this.setState({selectedValue0: dataFromChild});
    }
    myCallback0_text  = (dataFromChild) => {
    this.setState({selectedValue0_text: dataFromChild});
    } 
    myCallback7_adjust  = (dataFromChild) => {
    this.setState({selectedValue7_adjust: dataFromChild});
    } 
    myCallback8_adjust  = (dataFromChild) => {
    this.setState({selectedValue8_adjust: dataFromChild});
    } 
    myCallback9_adjust  = (dataFromChild) => {
    this.setState({selectedValue9_adjust: dataFromChild});
    }        
  
    
    

    
render() { 
    
    const selectedValue0 = this.state.selectedValue0;
    let button;
    if (this.state.selectedValue0 == 7){
        button = <Buttons7 callbackFromParent7={this.myCallback7} callbackFromParent7_text={this.myCallback7_text}/>
    } else if (this.state.selectedValue0 == 8){
        button = <Buttons8 callbackFromParent8={this.myCallback8} callbackFromParent8_text={this.myCallback8_text}/>
    } else {
        button = <Buttons9 callbackFromParent9={this.myCallback9} callbackFromParent9_text={this.myCallback9_text}/>
    }       

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>Victorian Institute of Sports Assessment - Greater Trochanter Calculator</h1>
                <h4>Hello, {this.props.submitterName}, your clinician, {this.props.clinicianSendingEmail}, has sent you this survey to help further understand your health. Please click the appropriate buttons below and then click "Submit Score". Please note that your <u>digital privacy is taken very seriously</u> and a number of technologies have been implemented to protect the privacy and integrity of your data, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Your data <u>will never be shared with anyone other than the assigned clinician</u>.</h4>

            <form onSubmit={this.handleSubmit}>



                <StickyContainer>
                  <Sticky>
                    {({ style }) => <div className="sticky_style" style={style}><Well bsSize="small">Total VISA-G Score: {((this.state.selectedValueS1+this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7*this.state.selectedValue7_adjust+this.state.selectedValue8*this.state.selectedValue8_adjust+this.state.selectedValue9*this.state.selectedValue9_adjust).toFixed(0))} / {100
                    } = {((((this.state.selectedValueS1+this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7*this.state.selectedValue7_adjust+this.state.selectedValue8*this.state.selectedValue8_adjust+this.state.selectedValue9*this.state.selectedValue9_adjust))/(100
                    )*100).toFixed(0))} %
                    <p/>
                    Graphical Total VISA-G Score: (%)
                    <ProgressBar>
                    <ProgressBar active now={(100*((((this.state.selectedValueS1+this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7*this.state.selectedValue7_adjust+this.state.selectedValue8*this.state.selectedValue8_adjust+this.state.selectedValue9*this.state.selectedValue9_adjust).toFixed(0))/(100
                    ))))}/>
                    <ProgressBar active bsStyle="danger" now={100-(100*((((this.state.selectedValueS1+this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7*this.state.selectedValue7_adjust+this.state.selectedValue8*this.state.selectedValue8_adjust+this.state.selectedValue9*this.state.selectedValue9_adjust).toFixed(0)))/(100
                    )))}/>
                    </ProgressBar>    
                    </Well>
                    </div>}

                  </Sticky>
                <p />
                    
                <br/>
                <br/>                      

                    <h4>Instructions: In this questionnaire, the term pain refers specifically to pain in the hip region.
                    </h4>
                    <Slider1 callbackFromParentS1={this.myCallbackS1}/>
                    <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
                    <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
                    <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
                    <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
                    <Buttons5 callbackFromParent5={this.myCallback5} callbackFromParent5_text={this.myCallback5_text}/>
                    <Buttons6 callbackFromParent6={this.myCallback6} callbackFromParent6_text={this.myCallback6_text}/>
                    <Buttons0 callbackFromParent0={this.myCallback0} callbackFromParent0_text={this.myCallback0_text}
                    callbackFromParent7_adjust={this.myCallback7_adjust} callbackFromParent7={this.myCallback7}
                    callbackFromParent8_adjust={this.myCallback8_adjust} callbackFromParent8={this.myCallback8}
                    callbackFromParent9_adjust={this.myCallback9_adjust} callbackFromParent9={this.myCallback9}
                    /> 
                    {button}                       
                        
                </StickyContainer> 

                <br />
                    
              <button style = {{width: '100%'}}>Submit Score</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(VISAG_Link);
