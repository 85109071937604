import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup, Table} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
//const jsPDF = require('jspdf');
import jsPDF from 'jspdf';
import {Link, Route} from "react-router-dom";
import mainLogo from'../orthopowertools.png';

 


class SF12_Send_Link extends PureComponent {
    
  constructor() {
    super();
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      scores_id: [], //this displays ID number for the scores saved for each clinician        
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual
        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to
      patient_name_to_send_score: '', //this is the variable where clinicians enter the given name of a patient they want to send a score to, which makes the email more personable
      clinician_name_to_send_score: '', //this is the variable where clinicians enter their own given name when they send  score to a patient, which makes the email more personable         
        
    //Calculator variables    
    selectedValue1: 0,
    selectedValue1_text: 'Pain Intensity: Tolerate pain without medications',

    selectedValue2: 0,
    selectedValue2_text: 'Personal Care: I can take care of myself normally',

    selectedValue3: 0,
    selectedValue3_text: 'Lifting: I can lift heavy weights without increased pain',

    selectedValue4: 0,
    selectedValue4_text: 'Walking: Pain does not prevent me from walking',

    selectedValue5: 0,
    selectedValue5_text: 'Sitting: I can sit in any chair as long as I like',

    selectedValue6: 0,
    selectedValue6_text: 'Standing: I can stand as long as I want without increased pain',

    selectedValue7: 0,
    selectedValue7_text: 'Sleeping: Pain does not prevent me from sleeping well',

    selectedValue8: 0,
    selectedValue8_text: 'Social Life: Normal and does not increase pain',

    selectedValue9: 0,
    selectedValue9_text: 'Traveling: Can travel anywhere without increased pain',

    selectedValue10: 0,
    selectedValue10_text: 'Employment/Homemaking: Normal activities do not cause pain',        
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.modifyData = this.modifyData.bind(this);
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
      
     
      
  }
    
componentDidMount() {
    
    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const SF12ScoresRef = firebase.firestore();
  SF12ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF12ScoresRef.collection("sf12_scores").get().then(querySnapshot =>{
      
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id //this part is a weird flaw in the system where querySnapshot doesn't actually pull the doc ID, so you have to do it seperately and then combined the document data with each doc ID
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  })
              
        
}  else {
    
  const SF12ScoresRef = firebase.firestore();
  SF12ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF12ScoresRef.collection("sf12_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const SF12ScoresRef = firebase.firestore();
  SF12ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF12ScoresRef.collection("sf12_scores").get().then(querySnapshot =>{
      

    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
                       
        
} else {
    

    
  const SF12ScoresRef = firebase.firestore();
  SF12ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF12ScoresRef.collection("sf12_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value.replace('"','')
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const SF12ScoresRef = firebase.firestore();
  SF12ScoresRef.settings({
      timestampsInSnapshots: true
  });  
  const sf12_score = SF12ScoresRef.collection("sf12_scores").add({
    shared_with: this.state.clinician_to_share_with,
    user: this.state.user_id,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'SF12',
    Question_01_SF12: this.state.selectedValue1,
    Question_02_SF12: this.state.selectedValue2,
    Question_03_SF12: this.state.selectedValue3,
    Question_04_SF12: this.state.selectedValue4,
    Question_05_SF12: this.state.selectedValue5,
    Question_06_SF12: this.state.selectedValue6,
    Question_07_SF12: this.state.selectedValue7,            
    Question_08_SF12: this.state.selectedValue8,
    Question_09_SF12: this.state.selectedValue9,
    Question_10_SF12: this.state.selectedValue10,
    Question_11_SF12: this.state.selectedValue11,
    Question_12_SF12: this.state.selectedValue12,
    Physical_Score_SF12: ((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+56.57706).toFixed(5)), 
    Mental_Score_SF12: ((this.state.point1[String(this.state.selectedValue1.toFixed(5))]+this.state.point2[String(this.state.selectedValue2.toFixed(5))]+this.state.point3[String(this.state.selectedValue3.toFixed(5))]+this.state.point4[String(this.state.selectedValue4.toFixed(5))]+this.state.point5[String(this.state.selectedValue5.toFixed(5))]+this.state.point6[String(this.state.selectedValue6.toFixed(5))]+this.state.point7[String(this.state.selectedValue7.toFixed(5))]+this.state.point8[String(this.state.selectedValue8.toFixed(5))]+this.state.point9[String(this.state.selectedValue9.toFixed(5))]+this.state.point10[String(this.state.selectedValue10.toFixed(5))]+this.state.point11[String(this.state.selectedValue11.toFixed(5))]+this.state.point12[String(this.state.selectedValue12.toFixed(5))]+60.75781).toFixed(5)),       
  });
  this.setState({
    clinician_to_share_with: '',
    user_id: '',
    selectedValue1: 0,
    selectedValue2: 0,
    selectedValue3: 0,
    selectedValue4: 0,
    selectedValue5: 0,
    selectedValue6: 0,
    selectedValue7: 0,
    selectedValue8: 0,
    selectedValue9: 0,                  
    selectedValue10: 0,
    selectedValue11: 0,
    selectedValue12: 0,            
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.state.clinician_to_share_with + '.');   
    
} 
    
modifyData(score_id) {  
  const SF12ScoresRef = firebase.firestore();
  SF12ScoresRef.collection("sf12_scores").doc(String(score_id)).update({
    shared_with: "None"    
  });  
    alert('Score deleted.');     
    
} 
    
    
    
    
    
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("sf12_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent_email: this.state.user.email,       
    patient_name_to_send_score: this.state.patient_name_to_send_score,
    clinician_who_sent_name: this.state.clinician_name_to_send_score,        
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',
    clinician_who_sent: '',
    patient_name_to_send_score: '',
    clinician_name_to_send_score: '',  
  });
    if (this.state.patient_email_to_send_score !== ''){
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');
    }
    else {
    alert('Thank you for using OrthoPowerTools! Your invitation was not sent because you did not enter the patients email address. To send the invitation, please enter the patients email address at the top of the page in the section that states "Enter the patients email address here"');        
    }      
    
}      
  
    
    
    
 
    
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
   myCallbackS1  = (dataFromChild) => {
    this.setState({selectedValueS1: dataFromChild});
    }
    myCallbackS2  = (dataFromChild) => {
    this.setState({selectedValueS2: dataFromChild});
    }
    myCallbackS3  = (dataFromChild) => {
    this.setState({selectedValueS3: dataFromChild});
    }
    myCallbackS4  = (dataFromChild) => {
    this.setState({selectedValueS4: dataFromChild});
    }
    myCallbackS5  = (dataFromChild) => {
    this.setState({selectedValueS5: dataFromChild});
    }
    myCallbackS6  = (dataFromChild) => {
    this.setState({selectedValueS6: dataFromChild});
    }
    myCallbackS7  = (dataFromChild) => {
    this.setState({selectedValueS7: dataFromChild});
    }
    myCallbackS8  = (dataFromChild) => {
    this.setState({selectedValueS8: dataFromChild});
    }
    myCallbackS9  = (dataFromChild) => {
    this.setState({selectedValueS9: dataFromChild});
    }
    myCallbackS10  = (dataFromChild) => {
    this.setState({selectedValueS10: dataFromChild});
    }
    myCallbackS11  = (dataFromChild) => {
    this.setState({selectedValueS11: dataFromChild});
    }
    myCallbackS12  = (dataFromChild) => {
    this.setState({selectedValueS12: dataFromChild});
    }
    myCallbackS13  = (dataFromChild) => {
    this.setState({selectedValueS13: dataFromChild});
    }  
  
    
    

    
render() {
    
    if (this.state.user === null){
        return(
            
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>                 
        <Link to="/" style={{padding:15}}>Home</Link>            
        </div>
      </header>     

      <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
        <h1>Welcome to OrthoPowerTools, a project of <a href="https://orthotoolkit.com/">OrthoToolKit!</a></h1>

        <p>This initiative strives to improve the way in which people understand and communicate their orthopaedic health by providing all the features of OrthoToolKit as well as an <b>easy-to-use system</b> for clinicians to collect, store, and analyze common outcome measures from their patients.</p>
      
      <br/>
      
        
      <p>To learn more, please visit our <Link to="/">home page</Link></p>
      

 
      

      </div>
    </div>  
            
            
            
        );
    }
    
  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>                 
        <Link to="/" style={{padding:15}}>Home</Link>            
        </div>
      </header>  
      

            <div style={{marginLeft:50, marginRight:50}}>
            <br/>
            <h2>Send Unique SF-12 Link To Your Patients</h2>
            <br/>
            <form onSubmit={this.handleSubmitSendInvite}>
      
      
            <h4>Please fill in the below three text inputs. When you click the 'Send Score Invite' button, an email will be sent to your patient that contains a unique link to a SF-12 score. When the patient finishes the survey and clicks submit, it will automatically be shared with you. An example email is listed below.</h4>
      
      
     
              <h4>Patient email address:</h4>                    
              <input type="text" name="patient_email_to_send_score" placeholder="Enter patient's email address here" onChange={this.handleChange} value={this.state.patient_email_to_send_score} /> 
      
              <h4>Patient given name:</h4>                          
              <input type="text" name="patient_name_to_send_score" placeholder="Enter patient's given name here (how name will be displayed on saved score)" onChange={this.handleChange} value={this.state.patient_name_to_send_score} /> 
      
              <h4>Your given name:</h4>                                
              <input type="text" name="clinician_name_to_send_score" placeholder="Enter the your name here (as you would like it to appear in the score invitation)" onChange={this.handleChange} value={this.state.clinician_name_to_send_score} />       
              <br />
      
          <button style = {{width: '100%'}}>Send Score Invite</button>       
      

            <p><h5>To: {this.state.patient_email_to_send_score}</h5></p>
            <p><h5>From: orthopowertools@gmail.com</h5></p>
            <p><h5>Subject: Provider Requested 12 Item Short Form Survey Completion</h5></p>
      
      
            <p><h5>Hello {this.state.patient_name_to_send_score},<br/><br/>One of your health care providers, {this.state.clinician_name_to_send_score}, has asked that you complete a 12 Item Short Form Survey (SF-12). The information in this survey is confidential and will only be shared with your provider. <br/><br/>Please click <u>here</u> to complete the survey.</h5></p><br/>Best regards,<br/>OrthoPowerTools`                      

     
                    
            </form>        
      
      
              <div className='user-profile'>
                 <img src={this.state.user.photoURL} />
              </div>
      
      
            <br/>
      
            <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
            {this.state.user ? (
                <span>
                <button onClick={this.logout}>Logout</button>
                </span>
            ) : (
                <StyledFirebaseAuth
                    uiConfig={this.uiConfig}
                    firebaseAuth={firebase.auth()}
                />
  
            )}  
            </div>

            </div>      
      

      
    </div>
  );
}
}
    
export default SF12_Send_Link;


