import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Slider, {createSliderWithTooltip} from 'rc-slider';

///////////////////////////////////////////Calculator Methods   
const SliderWithTooltip = createSliderWithTooltip(Slider);

const wrapperStyle = {'max-width': '400px', 'margin-left': 'auto', 'margin-right': 'auto', 'margin-top': 40, 'margin-bottom': 60 };

const marks1 = {
		0: <strong>0<br />No affect at all</strong>,
		10: <strong>10<br />Severely affects my life</strong>,
	};

class Slider1 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS1(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>1. How much does your illness affect your life?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks1} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks2 = {
		0: <strong>0<br />A very short time</strong>,
		10: <strong>10<br />Forever</strong>,
	};

class Slider2 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS2(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>2. How long do you think your illness will continue?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks2} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks3 = {
		0: <strong>0<br />Absolutely no control</strong>,
		10: <strong>10<br />Extreme amount of control</strong>,
	};

class Slider3 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 10}
	}

	someFn = (value) => {
		this.props.callbackFromParentS3(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>3. How much control do you feel you have over your illness?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={10} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks3} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks4 = {
		0: <strong>0<br />Not at all</strong>,
		10: <strong>10<br />Extremely helpful</strong>,
	};

class Slider4 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 10}
	}

	someFn = (value) => {
		this.props.callbackFromParentS4(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>4. How much do you think your treatment can help your illness?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={10} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks4} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks5 = {
		0: <strong>0<br />No symptoms at all</strong>,
		10: <strong>10<br />Many severe symptoms</strong>,
	};

class Slider5 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS5(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>5. How much do you experience symptoms from your illness?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks5} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks6 = {
		0: <strong>0<br />Not at all concerned</strong>,
		10: <strong>10<br />Extremely concerned</strong>,
	};

class Slider6 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS6(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>6. How concerned are you about your illness?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks6} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks7 = {
		0: <strong>0<br />Don't understand at all</strong>,
		10: <strong>10<br />Understand very clearly</strong>,
	};

class Slider7 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 10}
	}

	someFn = (value) => {
		this.props.callbackFromParentS7(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>7. How well do you feel you understand your illness?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={10} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks7} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks8 = {
		0: <strong>0<br />Not at all affected emotionally</strong>,
		10: <strong>10<br />Extremely affected emotionally</strong>,
	};

class Slider8 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS8(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>8. How much does your illness affect you emotionally?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks8} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	} 


  


class BIPQ_Link extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

        
        
        
        
    //Calculator variables    
        selectedValueS1: 0,
        selectedValueS1_text: 'How much does illness affect your life: ',

        selectedValueS2: 0,
        selectedValueS2_text: 'How long do you think your illness will continue: ',

        selectedValueS3: 10,
        selectedValueS3_text: 'How much control do you feel you have over your illness: ',

        selectedValueS4: 10,
        selectedValueS4_text: 'How much do you think your treatment can help: ',

        selectedValueS5: 0,
        selectedValueS5_text: 'How much do you experience symptoms from your illness: ',

        selectedValueS6: 0,
        selectedValueS6_text: 'How concerned are you about your illness: ',

        selectedValueS7: 10,
        selectedValueS7_text: 'How well do you feel you understand your illness: ',

        selectedValueS8: 0,
        selectedValueS8_text: 'How much does your illness affect you emotionally: ',
      
        first_cause: '', //free text responses for this score
        second_cause: '', //free text responses for this score
        third_cause: '', //free text responses for this score           
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  BIPQScoresRef.collection("bipq_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  BIPQScoresRef.collection("bipq_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  BIPQScoresRef.collection("bipq_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  BIPQScoresRef.collection("bipq_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });  
    
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){    
  const bipq_score = BIPQScoresRef.collection("bipq_scores").doc(this.props.requestSurveyID).set({            
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Brief Illness Perception Questionnaire',
    Question_01_BIPQ: this.state.selectedValueS1,
    Question_02_BIPQ: this.state.selectedValueS2,
    Question_03_BIPQ: this.state.selectedValueS3,
    Question_04_BIPQ: this.state.selectedValueS4,
    Question_05_BIPQ: this.state.selectedValueS5,
    Question_06_BIPQ: this.state.selectedValueS6,
    Question_07_BIPQ: this.state.selectedValueS7,            
    Question_08_BIPQ: this.state.selectedValueS8,
    First_Cause_BIPQ: this.state.first_cause,
    Second_Cause_BIPQ: this.state.second_cause,
    Third_Cause_BIPQ: this.state.third_cause,      
    Total_Score_BIPQ:((this.state.selectedValueS1+this.state.selectedValueS2+(10-this.state.selectedValueS3)+(10-this.state.selectedValueS4)+this.state.selectedValueS5+this.state.selectedValueS6+(10-this.state.selectedValueS7)+this.state.selectedValueS8)),   
  });
}
else {
  const bipq_score = BIPQScoresRef.collection("bipq_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'Brief Illness Perception Questionnaire',
    Question_01_BIPQ: this.state.selectedValueS1,
    Question_02_BIPQ: this.state.selectedValueS2,
    Question_03_BIPQ: this.state.selectedValueS3,
    Question_04_BIPQ: this.state.selectedValueS4,
    Question_05_BIPQ: this.state.selectedValueS5,
    Question_06_BIPQ: this.state.selectedValueS6,
    Question_07_BIPQ: this.state.selectedValueS7,            
    Question_08_BIPQ: this.state.selectedValueS8,
    First_Cause_BIPQ: this.state.first_cause,
    Second_Cause_BIPQ: this.state.second_cause,
    Third_Cause_BIPQ: this.state.third_cause,      
    Total_Score_BIPQ:((this.state.selectedValueS1+this.state.selectedValueS2+(10-this.state.selectedValueS3)+(10-this.state.selectedValueS4)+this.state.selectedValueS5+this.state.selectedValueS6+(10-this.state.selectedValueS7)+this.state.selectedValueS8)),    
  });      
}   
    
  this.setState({
    clinician_to_share_with: '',
    user_id: '',
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.props.clinicianSendingEmail + '. You may now close this alert and the entire window.');   
    
} 
    

  
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("bipq_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
 
    myCallbackS1  = (dataFromChild) => {
    this.setState({selectedValueS1: dataFromChild});
    }
    myCallbackS2  = (dataFromChild) => {
    this.setState({selectedValueS2: dataFromChild});
    }
    myCallbackS3  = (dataFromChild) => {
    this.setState({selectedValueS3: dataFromChild});
    }
    myCallbackS4  = (dataFromChild) => {
    this.setState({selectedValueS4: dataFromChild});
    }
    myCallbackS5  = (dataFromChild) => {
    this.setState({selectedValueS5: dataFromChild});
    }
    myCallbackS6  = (dataFromChild) => {
    this.setState({selectedValueS6: dataFromChild});
    }
    myCallbackS7  = (dataFromChild) => {
    this.setState({selectedValueS7: dataFromChild});
    }
    myCallbackS8  = (dataFromChild) => {
    this.setState({selectedValueS8: dataFromChild});
    }
  
    
    

    
render() { 

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>Brief Illness Perception Questionnaire (BIPQ) Calculator</h1>
                <h4>Hello, {this.props.submitterName}, your clinician, {this.props.clinicianSendingEmail}, has sent you this survey to help further understand your health. Please click the appropriate buttons below and then click "Submit Score". Please note that your <u>digital privacy is taken very seriously</u> and a number of technologies have been implemented to protect the privacy and integrity of your data, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Your data <u>will never be shared with anyone other than the assigned clinician</u>.</h4>

            <form onSubmit={this.handleSubmit}>



            <StickyContainer>
            <Sticky>
            {({ style }) => <div className="sticky_style" style={style}><Well bsSize="small">

            Brief Illness Perception Questionnaire (BIPQ) Score: {((this.state.selectedValueS1+this.state.selectedValueS2+(10-this.state.selectedValueS3)+(10-this.state.selectedValueS4)+this.state.selectedValueS5+this.state.selectedValueS6+(10-this.state.selectedValueS7)+this.state.selectedValueS8)).toFixed(0)} / {80} = {((((this.state.selectedValueS1+this.state.selectedValueS2+(10-this.state.selectedValueS3)+(10-this.state.selectedValueS4)+this.state.selectedValueS5+this.state.selectedValueS6+(10-this.state.selectedValueS7)+this.state.selectedValueS8))/(80)*100).toFixed(1))} % <br /> 

            Graphical Brief Illness Perception Questionnaire (BIPQ) Score: (%)
            <ProgressBar>
            <ProgressBar active now={100-(100*(((this.state.selectedValueS1+this.state.selectedValueS2+(10-this.state.selectedValueS3)+(10-this.state.selectedValueS4)+this.state.selectedValueS5+this.state.selectedValueS6+(10-this.state.selectedValueS7)+this.state.selectedValueS8))/(80)))}/>
            <ProgressBar active bsStyle="danger" now={(100*(((this.state.selectedValueS1+this.state.selectedValueS2+(10-this.state.selectedValueS3)+(10-this.state.selectedValueS4)+this.state.selectedValueS5+this.state.selectedValueS6+(10-this.state.selectedValueS7)+this.state.selectedValueS8))/(80)))}/>
            </ProgressBar>    



            </Well>    
            </div>}
            </Sticky>
      
      
      
                <p />
                    
                <br/>
                <br/>  
                <br/>
                <br/>                  
                    <h4>Instructions: For the following questions, please indicate the number that best corresponds to your views
                    </h4>
                    <Slider1 callbackFromParentS1={this.myCallbackS1}/>
                    <br />
                    <Slider2 callbackFromParentS2={this.myCallbackS2}/>
                    <br />
                    <Slider3 callbackFromParentS3={this.myCallbackS3}/>
                    <br />
                    <Slider4 callbackFromParentS4={this.myCallbackS4}/>
                    <br />
                    <Slider5 callbackFromParentS5={this.myCallbackS5}/>
                    <br />
                    <Slider6 callbackFromParentS6={this.myCallbackS6}/>
                    <br />
                    <Slider7 callbackFromParentS7={this.myCallbackS7}/>
                    <br />
                    <Slider8 callbackFromParentS8={this.myCallbackS8}/>
                    <br />
              <h4>Please list in rank-order the three most important factors that you believe caused your illness:</h4>
              <input type="text" name="first_cause" placeholder="Click here to enter your first cause" onChange={this.handleChange} value={this.state.first_cause} maxLength= "100"/>   
              <input type="text" name="second_cause" placeholder="Click here to enter your second cause" onChange={this.handleChange} value={this.state.second_cause} maxLength= "100"/>   
              <input type="text" name="third_cause" placeholder="Click here to enter your third cause" onChange={this.handleChange} value={this.state.third_cause} maxLength= "100"/>   
               
                        
                </StickyContainer>


                <br />
                    
              <button style = {{width: '100%'}}>Submit Score</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(BIPQ_Link);
