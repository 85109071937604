import React, { PureComponent } from 'react';
import {Link} from "react-router-dom";
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import mainLogo from'../orthopowertools.png';

class FAQ extends PureComponent {
    
  constructor() {
    super();
    this.state = {
      user: null        
    }
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);      
  }
    
componentDidMount() {
   
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    } 
  });    
    
}    
    
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}    
    
    

    
render() {    
  return (
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>    

        <Link to="/" style={{padding:15}}>Home</Link>            

        </div>
        
      
      
      </header>      


        <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>

        <br/>
            <div style={{textAlign: "left"}}>
            <h3>Frequently Asked Questions:</h3>
            <p/>   
            <br/> 
            <h4>What is the privacy policy of OrthoPowerTools?</h4>
            <p/>       
            <span>We take the <u>digital privacy</u> of our clinician and patient users <u>very seriously</u> and have implemented a number of technologies to protect the privacy and integrity of the data submitted, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Submitted data will <u>never be shared with anyone other than the assigned clinician</u>.
            </span>      
            <p/>   
            <br/>       
            <h4>Can I use a score on OrthoPowerTools for my medical practice or for my research project?</h4>
            <p/>       
            <span>We envision OrthoPowerTools as being useful in a wide variety of contexts and have worked to ensure that the tools and information available on the website are non-proprietary. This means that OrthoPowerTools scores should be useable in most routine clinical and research contexts. However, by using OrthoPowerTools, you agree to abide by your local rules and regulations regarding health care information. Additionally, it is always possible we may have to limit what scores are available for certain purposes in the future. Please write to us if you have any questions or concerns (orthopowertools@gmail.com).
            </span>
            <p/>  
            <br/>
            <h4>Does OrthopowerTools have a monthly fee?</h4>
            <p/>          
            <span>If, after experimenting with OrthoPowerTools, you find it helpful for you and your patients, we would ask for your support of the site via the price of a cup of coffee ($5) a month. We ask for this help simply so that we can make the site sustainable as well as continue to add more scores and features. You can support the site <u><a href="https://checkout.square.site/merchant/2T1C9EJ1W7NJ7/checkout/FB3TXIC7T63YUZMKWYPZVUES" target="_blank">here</a></u> and we thank you in advance!</span>  
            <p/>  
            <br/>    
            <h4>Can you add a new score to OrthoPowerTools.com?</h4>
            <p/>       
            <span>We are always looking to add new scores to OrthoPowerTools! If you have a particular score you’d like for us to add, please write to us with details about the score you’d like to see included (orthopowertools@gmail.com).
            </span>  
            <p/> 
            <br/>       
            <h4>How can I reference OrthoPowerTools score in my research project?</h4>
            <p/>       
            <span>In addition to the original literature reference for the score, you can cite OrthoPowerTools as follows (example for the Oswestry Disability Index):
            <p/>
            Free online Oswestry Disability Index (ODI) score calculator. OrthoPowerTools. http://orthopowertools.com/odi/. Accessed: Jan 1, {(new Date().getFullYear())}.
            <p/>
            If you do use OrthoPowerTools in your work or research, we’d love to hear about it! Feel free to write to us with a citation for your own paper, or send a copy of your publication (orthopowertools@gmail.com).
            </span>      
            <p/>
            <br/>   
            <h4>I found an error on an OrthoPowerTools score. How can I report this?
            </h4>
            <p/>       
            <span>We strive to make OrthoPowerTools the most accurate resources for medical scores. If you identify an error, or have any questions about the accuracy of OrthoPowerTools content, please e-mail us with your concern (orthopowertools@gmail.com).
            </span>  
            <p/> 
            <br/>      
            <h4>I’m having issues with site speed or other website performance issues.
            </h4>
            <p/>       
            <span>OrthoPowerTools runs best on modern browsers like updated versions of Chrome, Firefox, and Safari. If you’re experiencing website performance issues, we first recommend trying a different browser. If you continue to experience website performance issues, please contact us with the specifics of the problem (orthopowertools@gmail.com). To help us diagnose your problem, please include the details of the specific page you were visiting and the browser you were using.
            </span>  
            <p/>  
            <br/>       
            <h4>Do you have a mobile phone application or desktop application available?
            </h4>
            <p/>       
            <span>There is currently no mobile phone application or desktop version of OrthoPowerTools available. However, the score collection part of the site does function well in both phone and desktop web browsers and patients can easily use their mobile devices to complete scores in a waiting area. That being said, we are interested in offering both mobile and desktop application versions in the future. Please check back soon!
            </span>  
            <p/> 
            <br/>       
            <h4>Can you translate an OrthoPowerTools score into my language?
            </h4>
            <p/>       
            <span>In many cases, the only validated version of a score is in English. This can limit our ability to offer versions in other languages. However, if there is a particular score you’d like translated to another language, please write to us with the name of the score and the language for translation (orthopowertools@gmail.com).
            </span>  
            <p/>  
            <br/>      
            <h4>I loved using OrthoPowerTools! How can I help with this site?
            </h4>
            <p/>       
            <span>One of the greatest thanks we can receive is when you tell your friends or colleagues about OrthoPowerTools. If you’d like to get involved in contributing content and developing OrthoPowerTools, please write to us explaining what you’d like to improve (orthopowertools@gmail.com).
            </span>  
            <p/>                

            </div>
      
      
      </div>
      
    </div>      
  );
}    
    
    
}
export default FAQ;
