import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
//const jsPDF = require('jspdf');
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
//import queryString from 'query-string'; don't think I'll use this line

///////////////////////////////////////////Calculator Methods

class Buttons1 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent1(value);
if (value === 1)
this.props.callbackFromParent1_text("Strongly disagree that I'm afraid that I might injure myself if I exercise");
if (value === 2)
this.props.callbackFromParent1_text("Disagree that I'm afraid that I might injure myself if I exercise");
if (value === 3)
this.props.callbackFromParent1_text("Agree that I'm afraid that I might injure myself if I exercise");
if (value === 4)
this.props.callbackFromParent1_text("Strongly agree that I'm afraid that I might injure myself if I exercise");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>1. I{"'"}m afraid that I might injure myself if I exercise.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>1. I{"'"}m afraid that I might injure myself if I exercise.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons2 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent2(value);
if (value === 1)
this.props.callbackFromParent2_text("Strongly disagree that if I were to try to overcome it, my pain would increase");
if (value === 2)
this.props.callbackFromParent2_text("Disagree that if I were to try to overcome it, my pain would increase");
if (value === 3)
this.props.callbackFromParent2_text("Agree that if I were to try to overcome it, my pain would increase");
if (value === 4)
this.props.callbackFromParent2_text("Strongly agree that if I were to try to overcome it, my pain would increase");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>2. If I were to try to overcome it, my pain would increase.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>2. If I were to try to overcome it, my pain would increase.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons3 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent3(value);
if (value === 1)
this.props.callbackFromParent3_text("Strongly disagree that my body is telling me that something is wrong");
if (value === 2)
this.props.callbackFromParent3_text("Disagree that my body is telling me that something is wrong");
if (value === 3)
this.props.callbackFromParent3_text("Agree that my body is telling me that something is wrong");
if (value === 4)
this.props.callbackFromParent3_text("Strongly agree that my body is telling me that something is wrong");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>3. My body is telling me I have something dangerously wrong.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>3. My body is telling me I have something dangerously wrong.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons4 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent4(value);
if (value === 4)
this.props.callbackFromParent4_text("Strongly disagree that my pain would probably be relieved with exercise");
if (value === 3)
this.props.callbackFromParent4_text("Disagree that my pain would probably be relieved with exercise");
if (value === 2)
this.props.callbackFromParent4_text("Agree that my pain would probably be relieved with exercise");
if (value === 1)
this.props.callbackFromParent4_text("Strongly agree that my pain would probably be relieved with exercise");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>4. My pain would probably be relieved if I were to exercise.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (1)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>4. My pain would probably be relieved if I were to exercise.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (1)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons5 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent5(value);
if (value === 1)
this.props.callbackFromParent5_text("Strongly disagree that people aren\'t taking my medical condition seriously enough");
if (value === 2)
this.props.callbackFromParent5_text("Disagree that people aren\'t taking my medical condition seriously enough");
if (value === 3)
this.props.callbackFromParent5_text("Agree that people aren\'t taking my medical condition seriously enough");
if (value === 4)
this.props.callbackFromParent5_text("Strongly agree that people aren\'t taking my medical condition seriously enough");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>5. People aren{"'"}t taking my medical condition seriously enough.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>5. People aren{"'"}t taking my medical condition seriously enough.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons6 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent6(value);
if (value === 1)
this.props.callbackFromParent6_text("Strongly disagree that my accident has put my body at risk for the rest of my life");
if (value === 2)
this.props.callbackFromParent6_text("Disagree that my accident has put my body at risk for the rest of my life");
if (value === 3)
this.props.callbackFromParent6_text("Agree that my accident has put my body at risk for the rest of my life");
if (value === 4)
this.props.callbackFromParent6_text("Strongly agree that my accident has put my body at risk for the rest of my life");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>6. My accident has put my body at risk for the rest of my life.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>6. My accident has put my body at risk for the rest of my life.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons7 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent7(value);
if (value === 1)
this.props.callbackFromParent7_text("Strongly disagree that pain always means I have injured my body");
if (value === 2)
this.props.callbackFromParent7_text("Disagree that pain always means I have injured my body");
if (value === 3)
this.props.callbackFromParent7_text("Agree that pain always means I have injured my body");
if (value === 4)
this.props.callbackFromParent7_text("Strongly agree that pain always means I have injured my body");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>7. Pain always means I have injured my body.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>7. Pain always means I have injured my body.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons8 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent8(value);
if (value === 4)
this.props.callbackFromParent8_text("Strongly disagree that just because something aggravates my pain does not mean it is dangerous");
if (value === 3)
this.props.callbackFromParent8_text("Disagree that just because something aggravates my pain does not mean it is dangerous");
if (value === 2)
this.props.callbackFromParent8_text("Agree that just because something aggravates my pain does not mean it is dangerous");
if (value === 1)
this.props.callbackFromParent8_text("Strongly agree that just because something aggravates my pain does not mean it is dangerous");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>8. Just because something aggravates my pain does not mean it is dangerous.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (1)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>8. Just because something aggravates my pain does not mean it is dangerous.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (1)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons9 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent9(value);
if (value === 1)
this.props.callbackFromParent9_text("Strongly disagree that I am afraid that I might injure myself accidentally");
if (value === 2)
this.props.callbackFromParent9_text("Disagree that just because something aggravates my pain does not mean it is dangerous");
if (value === 3)
this.props.callbackFromParent9_text("Agree that just because something aggravates my pain does not mean it is dangerous");
if (value === 4)
this.props.callbackFromParent9_text("Strongly agree that just because something aggravates my pain does not mean it is dangerous");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>9. I am afraid that I might injure myself accidentally.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>9. I am afraid that I might injure myself accidentally.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons10 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent10(value);
if (value === 1)
this.props.callbackFromParent10_text("Strongly disagree that being careful to not make any unnecessary movements is safest to prevent pain from worsening");
if (value === 2)
this.props.callbackFromParent10_text("Disagree that being careful to not make any unnecessary movements is safest to prevent pain from worsening");
if (value === 3)
this.props.callbackFromParent10_text("Agree that being careful to not make any unnecessary movements is safest to prevent pain from worsening");
if (value === 4)
this.props.callbackFromParent10_text("Strongly agree that being careful to not make any unnecessary movements is safest to prevent pain from worsening");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>10. Simply being careful that I do not make any unnecessary movements is the safest thing I can do to prevent my pain from worsening.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>10. Simply being careful that I do not make any unnecessary movements is the safest thing I can do to prevent my pain from worsening.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons11 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent11(value);
if (value === 1)
this.props.callbackFromParent11_text("Strongly disagree that I wouldn\'t have this much pain if there weren\'t something potentially dangerous going on in my body");
if (value === 2)
this.props.callbackFromParent11_text("Disagree that I wouldn\'t have this much pain if there weren\'t something potentially dangerous going on in my body");
if (value === 3)
this.props.callbackFromParent11_text("Agree that I wouldn\'t have this much pain if there weren\'t something potentially dangerous going on in my body");
if (value === 4)
this.props.callbackFromParent11_text("Strongly agree that I wouldn\'t have this much pain if there weren\'t something potentially dangerous going on in my body");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>11. I wouldn{"'"}t have this much pain if there weren{"'"}t something potentially dangerous going on in my body.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>11. I wouldn{"'"}t have this much pain if there weren{"'"}t something potentially dangerous going on in my body.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons12 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent12(value);
if (value === 4)
this.props.callbackFromParent12_text("Strongly disagree that although my condition is painful, I would be better off if I were physically active");
if (value === 3)
this.props.callbackFromParent12_text("Disagree that although my condition is painful, I would be better off if I were physically active");
if (value === 2)
this.props.callbackFromParent12_text("Agree that although my condition is painful, I would be better off if I were physically active");
if (value === 1)
this.props.callbackFromParent12_text("Strongly agree that although my condition is painful, I would be better off if I were physically active");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>12. Although my condition is painful, I would be better off if I were physically active.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (1)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>12. Although my condition is painful, I would be better off if I were physically active.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (1)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons13 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent13(value);
if (value === 1)
this.props.callbackFromParent13_text("Strongly disagree that pain lets me know when to stop exercising so that I don\'t injure myself");
if (value === 2)
this.props.callbackFromParent13_text("Disagree that pain lets me know when to stop exercising so that I don\'t injure myself");
if (value === 3)
this.props.callbackFromParent13_text("Agree that pain lets me know when to stop exercising so that I don\'t injure myself");
if (value === 4)
this.props.callbackFromParent13_text("Strongly agree that pain lets me know when to stop exercising so that I don\'t injure myself");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>13. Pain lets me know when to stop exercising so that I don{"'"}t injure myself.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>13. Pain lets me know when to stop exercising so that I don{"'"}t injure myself.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons14 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent14(value);
if (value === 1)
this.props.callbackFromParent14_text("Strongly disagree that it\'s really not safe for a person with a condition like mine to be physically active");
if (value === 2)
this.props.callbackFromParent14_text("Disagree that it\'s really not safe for a person with a condition like mine to be physically active");
if (value === 3)
this.props.callbackFromParent14_text("Agree that it\'s really not safe for a person with a condition like mine to be physically active");
if (value === 4)
this.props.callbackFromParent14_text("Strongly agree that it\'s really not safe for a person with a condition like mine to be physically active");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>14. It{"'"}s really not safe for a person with a condition like mine to be physically active.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>14. It{"'"}s really not safe for a person with a condition like mine to be physically active.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons15 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent15(value);
if (value === 1)
this.props.callbackFromParent15_text("Strongly disagree that I can\'t do all the things normal people do because it\'s too easy for me to get injured");
if (value === 2)
this.props.callbackFromParent15_text("Disagree that I can\'t do all the things normal people do because it\'s too easy for me to get injured");
if (value === 3)
this.props.callbackFromParent15_text("Agree that I can\'t do all the things normal people do because it\'s too easy for me to get injured");
if (value === 4)
this.props.callbackFromParent15_text("Strongly agree that I can\'t do all the things normal people do because it\'s too easy for me to get injured");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>15. I can{"'"}t do all the things normal people do because it{"'"}s too easy for me to get injured</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>15. I can{"'"}t do all the things normal people do because it{"'"}s too easy for me to get injured</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons16 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent16(value);
if (value === 4)
this.props.callbackFromParent16_text("Strongly disagree that even though something is causing me a lot of pain, I don\'t think it\'s actually dangerous");
if (value === 3)
this.props.callbackFromParent16_text("Disagree that even though something is causing me a lot of pain, I don\'t think it\'s actually dangerous");
if (value === 2)
this.props.callbackFromParent16_text("Agree that even though something is causing me a lot of pain, I don\'t think it\'s actually dangerous");
if (value === 1)
this.props.callbackFromParent16_text("Strongly agree that even though something is causing me a lot of pain, I don\'t think it\'s actually dangerous");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>16. Even though something is causing me a lot of pain, I don{"'"}t think it{"'"}s actually dangerous</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (1)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>16. Even though something is causing me a lot of pain, I don{"'"}t think it{"'"}s actually dangerous</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (1)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons17 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 1}
}
someFn = (value) => {
this.props.callbackFromParent17(value);
if (value === 1)
this.props.callbackFromParent17_text("Strongly disagree that no one should have to exercise when he/she is in pain");
if (value === 2)
this.props.callbackFromParent17_text("Disagree that no one should have to exercise when he/she is in pain");
if (value === 3)
this.props.callbackFromParent17_text("Agree that no one should have to exercise when he/she is in pain");
if (value === 4)
this.props.callbackFromParent17_text("Strongly agree that no one should have to exercise when he/she is in pain");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>17. No one should have to exercise when he/she is in pain</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>17. No one should have to exercise when he/she is in pain</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly disagree (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Disagree (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Agree (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Strongly agree (4)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
    


  


class TSK_Link extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

    //Calculator variables    
    selectedValue1: 1,
    selectedValue1_text: 'Strongly disagree that I\'m afraid that I might injure myself if I exercise',

    selectedValue2: 1,
    selectedValue2_text: 'Strongly disagree that if I were to try to overcome it, my pain would increase',

    selectedValue3: 1,
    selectedValue3_text: 'Strongly disagree that my body is telling me that something is wrong',

    selectedValue4: 1,
    selectedValue4_text: 'Strongly disagree that my pain would probably be relieved with exercise',

    selectedValue5: 1,
    selectedValue5_text: 'Strongly disagree that people aren\'t taking my medical condition seriously enough',

    selectedValue6: 1,
    selectedValue6_text: 'Strongly disagree that my accident has put my body at risk for the rest of my life',

    selectedValue7: 1,
    selectedValue7_text: 'Strongly disagree that pain always means I have injured my body',

    selectedValue8: 1,
    selectedValue8_text: 'Strongly agree that just because something aggravates my pain does not mean it is dangerous',

    selectedValue9: 1,
    selectedValue9_text: 'Strongly disagree that I am afraid that I might injure myself accidentally',

    selectedValue10: 1,
    selectedValue10_text: 'Strongly disagree that being careful to not make any unnecessary movements is safest to prevent pain from worsening',

    selectedValue11: 1,
    selectedValue11_text: 'Strongly disagree that I wouldn\'t have this much pain if there weren\'t something potentially dangerous going on in my body',

    selectedValue12: 1,
    selectedValue12_text: 'Strongly agree that although my condition is painful, I would be better off if I were physically active',

    selectedValue13: 1,
    selectedValue13_text: 'Strongly disagree that pain lets me know when to stop exercising so that I don\'t injure myself',

    selectedValue14: 1,
    selectedValue14_text: 'Strongly disagree that it\'s really not safe for a person with a condition like mine to be physically active',

    selectedValue15: 1,
    selectedValue15_text: 'Strongly disagree that I can\'t do all the things normal people do because it\'s too easy for me to get injured',

    selectedValue16: 1,
    selectedValue16_text: 'Strongly agree that even though something is causing me a lot of pain, I don\'t think it\'s actually dangerous',

    selectedValue17: 1,
    selectedValue17_text: 'Strongly disagree that no one should have to exercise when he/she is in pain',      
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const TSKScoresRef = firebase.firestore();
  TSKScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  TSKScoresRef.collection("tsk_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const TSKScoresRef = firebase.firestore();
  TSKScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  TSKScoresRef.collection("tsk_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const TSKScoresRef = firebase.firestore();
  TSKScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  TSKScoresRef.collection("tsk_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const TSKScoresRef = firebase.firestore();
  TSKScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  TSKScoresRef.collection("tsk_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const TSKScoresRef = firebase.firestore();
  TSKScoresRef.settings({
      timestampsInSnapshots: true
  });  
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){    
  const tsk_score = TSKScoresRef.collection("tsk_scores").doc(this.props.requestSurveyID).set({
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Tampa Scale',      
    Question_01_TSK: this.state.selectedValue1,
    Question_02_TSK: this.state.selectedValue2,
    Question_03_TSK: this.state.selectedValue3,
    Question_04_TSK: this.state.selectedValue4,
    Question_05_TSK: this.state.selectedValue5,
    Question_06_TSK: this.state.selectedValue6,
    Question_07_TSK: this.state.selectedValue7,            
    Question_08_TSK: this.state.selectedValue8,
    Question_09_TSK: this.state.selectedValue9,
    Question_10_TSK: this.state.selectedValue10,
    Question_11_TSK: this.state.selectedValue11,
    Question_12_TSK: this.state.selectedValue12,
    Question_13_TSK: this.state.selectedValue13,
    Question_14_TSK: this.state.selectedValue14,
    Question_15_TSK: this.state.selectedValue15,
    Question_16_TSK: this.state.selectedValue16,
    Question_17_TSK: this.state.selectedValue17,
    Total_Score_TSK: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17),    
  });
}
else {
  const tsk_score = TSKScoresRef.collection("tsk_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'Tampa Scale',      
    Question_01_TSK: this.state.selectedValue1,
    Question_02_TSK: this.state.selectedValue2,
    Question_03_TSK: this.state.selectedValue3,
    Question_04_TSK: this.state.selectedValue4,
    Question_05_TSK: this.state.selectedValue5,
    Question_06_TSK: this.state.selectedValue6,
    Question_07_TSK: this.state.selectedValue7,            
    Question_08_TSK: this.state.selectedValue8,
    Question_09_TSK: this.state.selectedValue9,
    Question_10_TSK: this.state.selectedValue10,
    Question_11_TSK: this.state.selectedValue11,
    Question_12_TSK: this.state.selectedValue12,
    Question_13_TSK: this.state.selectedValue13,
    Question_14_TSK: this.state.selectedValue14,
    Question_15_TSK: this.state.selectedValue15,
    Question_16_TSK: this.state.selectedValue16,
    Question_17_TSK: this.state.selectedValue17,
    Total_Score_TSK: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17),  
  });      
}     
  this.setState({
    clinician_to_share_with: '',
    user_id: '',        
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.props.clinicianSendingEmail + '. You may now close this alert and the entire window.');   
}
    

    
    
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("tsk_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallback1  = (dataFromChild) => {
    this.setState({selectedValue1: dataFromChild});
    }
    myCallback1_text  = (dataFromChild) => {
    this.setState({selectedValue1_text: dataFromChild});
    }
    myCallback2  = (dataFromChild) => {
    this.setState({selectedValue2: dataFromChild});
    }
    myCallback2_text  = (dataFromChild) => {
    this.setState({selectedValue2_text: dataFromChild});
    }
    myCallback3  = (dataFromChild) => {
    this.setState({selectedValue3: dataFromChild});
    }
    myCallback3_text  = (dataFromChild) => {
    this.setState({selectedValue3_text: dataFromChild});
    }
    myCallback4  = (dataFromChild) => {
    this.setState({selectedValue4: dataFromChild});
    }
    myCallback4_text  = (dataFromChild) => {
    this.setState({selectedValue4_text: dataFromChild});
    }
    myCallback5  = (dataFromChild) => {
    this.setState({selectedValue5: dataFromChild});
    }
    myCallback5_text  = (dataFromChild) => {
    this.setState({selectedValue5_text: dataFromChild});
    }
    myCallback6  = (dataFromChild) => {
    this.setState({selectedValue6: dataFromChild});
    }
    myCallback6_text  = (dataFromChild) => {
    this.setState({selectedValue6_text: dataFromChild});
    }
    myCallback7  = (dataFromChild) => {
    this.setState({selectedValue7: dataFromChild});
    }
    myCallback7_text  = (dataFromChild) => {
    this.setState({selectedValue7_text: dataFromChild});
    }
    myCallback8  = (dataFromChild) => {
    this.setState({selectedValue8: dataFromChild});
    }
    myCallback8_text  = (dataFromChild) => {
    this.setState({selectedValue8_text: dataFromChild});
    }
    myCallback9  = (dataFromChild) => {
    this.setState({selectedValue9: dataFromChild});
    }
    myCallback9_text  = (dataFromChild) => {
    this.setState({selectedValue9_text: dataFromChild});
    }
    myCallback10  = (dataFromChild) => {
    this.setState({selectedValue10: dataFromChild});
    }
    myCallback10_text  = (dataFromChild) => {
    this.setState({selectedValue10_text: dataFromChild});
    }     
    myCallback11  = (dataFromChild) => {
    this.setState({selectedValue11: dataFromChild});
    }
    myCallback11_text  = (dataFromChild) => {
    this.setState({selectedValue11_text: dataFromChild});
    }
    myCallback12  = (dataFromChild) => {
    this.setState({selectedValue12: dataFromChild});
    }
    myCallback12_text  = (dataFromChild) => {
    this.setState({selectedValue12_text: dataFromChild});
    }
    myCallback13  = (dataFromChild) => {
    this.setState({selectedValue13: dataFromChild});
    }
    myCallback13_text  = (dataFromChild) => {
    this.setState({selectedValue13_text: dataFromChild});
    }
    myCallback14  = (dataFromChild) => {
    this.setState({selectedValue14: dataFromChild});
    }
    myCallback14_text  = (dataFromChild) => {
    this.setState({selectedValue14_text: dataFromChild});
    }
    myCallback15  = (dataFromChild) => {
    this.setState({selectedValue15: dataFromChild});
    }
    myCallback15_text  = (dataFromChild) => {
    this.setState({selectedValue15_text: dataFromChild});
    }
    myCallback16  = (dataFromChild) => {
    this.setState({selectedValue16: dataFromChild});
    }
    myCallback16_text  = (dataFromChild) => {
    this.setState({selectedValue16_text: dataFromChild});
    }
    myCallback17  = (dataFromChild) => {
    this.setState({selectedValue17: dataFromChild});
    }
    myCallback17_text  = (dataFromChild) => {
    this.setState({selectedValue17_text: dataFromChild});
    }  

  
    
    

    
render() { 

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>Tampa Scale for Kinesiophobia</h1>
                <h4>Hello, {this.props.submitterName}, your clinician, {this.props.clinicianSendingEmail}, has sent you this survey to help further understand your health. Please click the appropriate buttons below and then click "Submit Score". Please note that your <u>digital privacy is taken very seriously</u> and a number of technologies have been implemented to protect the privacy and integrity of your data, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Your data <u>will never be shared with anyone other than the assigned clinician</u>.</h4>

            <form onSubmit={this.handleSubmit}>



                <StickyContainer>
                <Sticky>
                {({ style }) => <div className="sticky_style" style={style}><Well bsSize="small">Tampa Scale for Kinesiophobia: {(this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17)} <br/>
                Graphical Tampa Scale for Kinesiophobia: (%)
                <ProgressBar>
                <ProgressBar active now={100-100*((((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17)-17))/(51))}/>
                <ProgressBar active bsStyle="danger" now={100*((((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17)-17))/(51))}/>
                </ProgressBar>
                </Well></div>}
                </Sticky>
                <p />
                <br/>
                <br/>                     
  
                <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
                <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
                <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
                <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
                <Buttons5 callbackFromParent5={this.myCallback5} callbackFromParent5_text={this.myCallback5_text}/>
                <Buttons6 callbackFromParent6={this.myCallback6} callbackFromParent6_text={this.myCallback6_text}/>
                <Buttons7 callbackFromParent7={this.myCallback7} callbackFromParent7_text={this.myCallback7_text}/>
                <Buttons8 callbackFromParent8={this.myCallback8} callbackFromParent8_text={this.myCallback8_text}/>
                <Buttons9 callbackFromParent9={this.myCallback9} callbackFromParent9_text={this.myCallback9_text}/>
                <Buttons10 callbackFromParent10={this.myCallback10} callbackFromParent10_text={this.myCallback10_text}/>
                <Buttons11 callbackFromParent11={this.myCallback11} callbackFromParent11_text={this.myCallback11_text}/>
                <Buttons12 callbackFromParent12={this.myCallback12} callbackFromParent12_text={this.myCallback12_text}/>
                <Buttons13 callbackFromParent13={this.myCallback13} callbackFromParent13_text={this.myCallback13_text}/>
                <Buttons14 callbackFromParent14={this.myCallback14} callbackFromParent14_text={this.myCallback14_text}/>
                <Buttons15 callbackFromParent15={this.myCallback15} callbackFromParent15_text={this.myCallback15_text}/>
                <Buttons16 callbackFromParent16={this.myCallback16} callbackFromParent16_text={this.myCallback16_text}/>
                <Buttons17 callbackFromParent17={this.myCallback17} callbackFromParent17_text={this.myCallback17_text}/>
                </StickyContainer>
                <br />
                    
              <button style = {{width: '100%'}}>Submit Score</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(TSK_Link);
