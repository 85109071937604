import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Slider, {createSliderWithTooltip} from 'rc-slider';

///////////////////////////////////////////Calculator Methods
    
const SliderWithTooltip = createSliderWithTooltip(Slider);

const wrapperStyle = {'max-width': '400px', 'margin-left': 'auto', 'margin-right': 'auto', 'margin-top': 40, 'margin-bottom': 60 };

const marks1 = {
		0: <div style={{ whiteSpace: 'nowrap'}}><strong>0<br />No pain</strong></div>,
		10: <div style={{ whiteSpace: 'nowrap'}}><strong>10<br />Unbearable pain</strong></div>,
	};



class Slider1 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS1(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>1. Choose a number that best describes your pain.</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} step = {0.1} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks1} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	} 


  


class VAS_Link extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

        
        
        
        
    //Calculator variables    
    selectedValueS1: 0,
    selectedValueS1_text: 'Pain score (range: 0, no pain - 10, unbearable pain): ', 
           
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const VASScoresRef = firebase.firestore();
  VASScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  VASScoresRef.collection("vas_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const VASScoresRef = firebase.firestore();
  VASScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  VASScoresRef.collection("vas_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const VASScoresRef = firebase.firestore();
  VASScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  VASScoresRef.collection("vas_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const VASScoresRef = firebase.firestore();
  VASScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  VASScoresRef.collection("vas_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const VASScoresRef = firebase.firestore();
  VASScoresRef.settings({
      timestampsInSnapshots: true
  });
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){    
  const vas_score = VASScoresRef.collection("vas_scores").doc(this.props.requestSurveyID).set({            
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'VAS',
    Question_01_VAS: this.state.selectedValueS1,
    Total_Score_VAS: this.state.selectedValueS1, 
  });
}
else {
  const vas_score = VASScoresRef.collection("vas_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'VAS',
    Question_01_VAS: this.state.selectedValueS1,
    Total_Score_VAS: this.state.selectedValueS1,  
  });      
} 
    
  this.setState({
    clinician_to_share_with: '',
    user_id: '',
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.props.clinicianSendingEmail + '. You may now close this alert and the entire window.');   
    
}
    
     
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("vas_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
 
    myCallbackS1  = (dataFromChild) => {
    this.setState({selectedValueS1: dataFromChild});
    }

  
    
    

    
render() { 

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>Visual Analogue Scale (VAS) Calculator</h1>
                <h4>Hello, {this.props.submitterName}, your clinician, {this.props.clinicianSendingEmail}, has sent you this survey to help further understand your health. Please click the appropriate buttons below and then click "Submit Score". Please note that your <u>digital privacy is taken very seriously</u> and a number of technologies have been implemented to protect the privacy and integrity of your data, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Your data <u>will never be shared with anyone other than the assigned clinician</u>.</h4>

            <form onSubmit={this.handleSubmit}>



            <StickyContainer>
            <Sticky>
            {({ style }) => <div className="sticky_style" style={style}><Well bsSize="small">

            Visual Analogue Scale (VAS) Score: {this.state.selectedValueS1} / {10} = {((this.state.selectedValueS1*10).toFixed(1))} % <br />   

            Graphical Visual Analogue Scale (VAS) Score: (%)
            <ProgressBar>
            <ProgressBar active now={100-(10*this.state.selectedValueS1)}/>
            <ProgressBar active bsStyle="danger" now={(10*this.state.selectedValueS1)}/>
            </ProgressBar>    



            </Well>    
            </div>}
            </Sticky>
      
      
      
                <p />
                    
                <br/>
                <br/> 
                <br/>
                <br/>                  
                    <h4>Instructions: Move the marker on the line to the position which best describes your pain.
                    </h4>
                    <Slider1 callbackFromParentS1={this.myCallbackS1}/>

               
                        
                </StickyContainer>


                <br />
                    
              <button style = {{width: '100%'}}>Submit Score</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(VAS_Link);
