import React from 'react';
import {Link} from "react-router-dom";

const Footer = () => (
  <footer>
        <div>
                <div class="spacer"></div>
                <div class="footer"></div>
                <div class="spacer"></div>
                <footer class="footer-2">
                <Link to="/About-Us">About Us </Link> | <Link to="/Contact-Us">Contact Us </Link> | <Link to="/FAQ">FAQs </Link><br/>
        The tools listed on this website do not substitute for the informed opinion of a licensed physician or other health care provider.<br/>
        All scores should be re-checked. Please see our full <Link to="/Terms-Of-Use">Terms of Use </Link>.<br/>
        © OrthoToolKit | {(new Date().getFullYear())}<br/>
        </footer>
        </div>
  </footer>
);

export default Footer;
