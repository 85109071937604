import firebase from 'firebase'

const config = {
    apiKey: "AIzaSyDWSyeRdijcaLsC8kHhxqN2ulnjsC-aRGg",
    authDomain: "orthopowertoolsdemo.firebaseapp.com",
    databaseURL: "https://orthopowertoolsdemo.firebaseio.com",
    projectId: "orthopowertoolsdemo",
    storageBucket: "orthopowertoolsdemo.appspot.com",
    messagingSenderId: "68658797351",
    appId: "1:68658797351:web:7ddd2cbf905414efb6a702",
    measurementId: "G-BFVCP0M2ES"
};

firebase.initializeApp(config);
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export default firebase;