import React, { PureComponent } from 'react';
import {Link} from "react-router-dom";
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import mainLogo from'../orthopowertools.png';

class Terms_Of_Use extends PureComponent {
    
  constructor() {
    super();
    this.state = {
      user: null        
    }
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);      
  }
    
componentDidMount() {
   
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    } 
  });    
    
}    
    
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}    
    
    

    
render() {    
  return (
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>    

        <Link to="/" style={{padding:15}}>Home</Link>            

        </div>
        
      
      
      </header>       


        <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>

        <br/>
            <div style={{textAlign: "left"}}>
            <h4>Terms of Use:</h4>
            <p/>    
            <span>Please read these Terms of Use. The use of this website constitutes your acceptance of the terms and conditions set out herein. If you do not agree with these Terms of Use, please do not use this website.</span>
            <p/>
            <span>McEck LLC, orthotoolkit.com, and orthopowertools.com provide users with many resources, including but not limited to, websites and their contents, tools, and links to other websites. Through these Terms of Use, the words "Website" or "Site" refers to orthotoolkit.com and orthopowertools.com. Throughout these Terms of Use, the words "we", "us", "our", "orthotoolkit.com", "orthopowertools.com" and "McEck" refer to McEck LLC, and any of its direct and indirect subsidiaries. "You" or "user" refers to any person accessing and/or using any of the services</span>
            <p/>
            <span>This site is intended as an educational tool for medical researchers and health care professionals. Medical researchers and health care professionals should exercise their judgement regarding the information and the weight that can be placed on that information that this website’s content can provide. Non-medical professionals use this website at their own risk. The content on this website does not constitute medical advice. Non-medical professionals should contact a physician or other licensed health care professional for medical advice including but not limited to questions, concerns and/or decision making.</span>
            <p/>
            <span>This website provides information only and the use of this information is strictly at your own risk. We do not provide medical advice nor do we provide medical or diagnostic services.The content of the website is one approach to orthopaedic conditions and does not represent all approaches or necessarily the standard approach. The information presented on this website and information on websites that are listed here is believed to be accurate. However, accuracy and timeliness of information cannot be guaranteed by either the site owners or content owners. Therefore, users should consult a variety of sources and should recheck all scores. The availability of information on the website does not constitute an endorsement.</span>
            <p/>  
            <span>Information obtained from the website and decisions made using this content are the responsibility of the user. Users will not hold the website liable for any direct, indirect, incidental, consequential, or special damages and/or injury (including death) from use of tools or information in any manner. Users will not hold the owners or publishers that created and distributed these tools liable or responsible for any direct, indirect, incidental, consequential, or special damages and/or injury (including death) from the use of tools or information in any manner. Users will neither make a claim nor take any action against the site or against the owners or publishers of any information provided on the site or owners of websites linked to on the site.</span>
            <p/>   
            <span>Website design is the copyright of McEck LLC and may not be copied without written permission. Scores are owned by the authors of the scores and no commercial use is permitted without the approval of the authors. All material on the websites is owned by Maceck LLC and no use is permitted without the approval of the authors. The website authors, tool authors and publishers retain all rights to the use of copyright material on this website.</span>
            <p/>  
            <span>The safety and privacy of content submitted using the forms on this website cannot be absolutely guaranteed, although we take measures against third party access. Information collected on this website may be used to improve the quality of tools and services offered on this website. We reserve the right to monitor the sources of traffic to our website, for example, by IP address, or usage pattern.</span>
            <p/>   
            <span>It is prohibited to use automated scripts, robots, scrapers, or other algorithms to extract information from this website. It is prohibited to deep link or frame this website from other sites. It is prohibited access this site in such a way that disrupts or degrades the services here. It is prohibited to extract the private information of the creators or users of this websites. We retain the right to prosecute all violations of these conditions to the fullest extent of the law.</span>
            <p/>    
            <span>We reserve the right to change, modify, add, or remove these Terms of Use at anytime. Users remain bound by any changes by virtue of their continued use of our services. All changes, modifications, and additions to the website and its content shall be subject to these Terms of Use. Please check these terms regularly for any modifications.</span>
            <p/>  

            <h4>Specific Terms of Use for OrthoPowerTools:</h4>   
            <p/>    
            <span>In addition to the aforementioned conditions, the following information applies to OrthoPowerTools. <p/></span>
            <span>OrthoPowerTools serves as a record that strives to enable patients to more easily share information regarding their orthopaedic health care with their health care providers. We will not knowingly share patient health information with anyone other then designated and enrolled health care providers. We also will not knowingly share clinician personal information. The de-identified data collected on this site may be used at a later time for educational and research purposes. The safety and privacy of content submitted on this website cannot be absolutely guaranteed, although we take measures against third party access. The data stored in the site may be irrevocably lost and we encourage users as well as clinicians to download their data regularly and store it on their own secure file systems. As health care data rules and regulations vary amongst countries, this record is only intended to be used by patients users and health care providers in the United States of America, at this time. </span>
            <p/>  

            <h4>Copyright:</h4>
            <p/>     
            <span>References to source material is provided when available, and the intellectual property of the tool remains that of the creator. Our modification and transformation of these tools into dynamic, interactive, online-scoring calculators, we believe constitutes fair use. If you feel we have violated your copyright, please contact us via e-mail and we will remove the inappropriate content.</span>
            <p/>         

            </div>

      
      
      
      
      
      
      </div>
      
    </div>      
  );
}    
    
    
}
export default Terms_Of_Use;
