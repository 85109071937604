import React, { PureComponent } from 'react';
import {Link} from "react-router-dom";
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"

import mainLogo from'../orthopowertools.png';

class Home extends PureComponent {
    
  constructor() {
    super();
    this.state = {
      user: null,
        

        
    }
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this); 
      

  }
    
uiConfig = {
    signInFlow: "popup",
    signInOptions:[
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.TwitterAuthProvider.PROVIDER_ID
        
    ],
    callbacks: {
        signInSuccess: () => false
    }
}        
    
componentDidMount() {
   
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    } 
  });    
    
}    
    
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
} 
    

    
render() {
    if (this.state.user === null){
        return(
            
            <div className='app'>
              <header>
                <div className="wrapper">
                <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link> 
                <Link to="/" style={{padding:15}}>Home</Link>            
            
                </div>
              </header>     

              <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
            
                <h1>Welcome to OrthoPowerTools, a project of <a href="https://orthotoolkit.com/">OrthoToolKit!</a></h1>
         
            

            <br/>


            <table style={{width:"100%"}}>
                <tr>    
                    <td><span><strong><u>Scores For Patients To Complete:</u></strong></span> </td>
                    <td><span><strong><u>Clinician Sign In:</u></strong></span> </td>
                </tr>
            
                <tr>    
                    <td>
                        <p>Use of the below links allow any patient to complete a survey and share it with a clinician. Some clinicians have placed these links on their websites while others with computers in their waiting area have used them to gather PROM scores just prior to a patient's appointment. Of note, the patient must have the clinician's email address available so that the score may be routed correctly.</p>         
                    </td>
                    <td>
                        <p>Clinicians should sign in below to view all scores that have been shared with them and send unique survey request links to a patient's email address. Clinicians have used the survey request portion to allow patients to complete scores at home prior to an in-person visit as well as to gather information during virtual sessions.</p>
                    </td>
                </tr>               

                <tr>    
                    <td>
                    <p><u>English Language Calculators</u></p>  
                    <p><Link to="/ACLRSI">ACL Return to Sport after Injury (ACL-RSI) Calculator</Link></p>  
                    <p><Link to="/AOFASH">AOFAS Hallux MTP-IP Score (AOFASH) Calculator</Link></p> 
                    <p><Link to="/BIPQ">Brief Illness Perception Questionnaire (BIPQ) Calculator</Link></p>     
                    <p><Link to="/DHI">Dizziness Handicap Inventory (DHI) Calculator</Link></p> 
                    <p><Link to="/FAAM">Foot and Ankle Ability Measure (FAAM) Calculator</Link></p> 
                    <p><Link to="/HOOS12">Hip disability and Osteoarthritis Outcome Score 12 (HOOS-12) Calculator</Link></p>
                    <p><Link to="/KOOS12">Knee injury and Osteoarthritis Outcome Score 12 (KOOS-12) Calculator</Link></p> 
                    <p><Link to="/LEFS">Lower Extremity Functional Scale (LEFS) Calculator</Link></p>
                    <p><Link to="/NDI">Neck Disability Index (NDI) Calculator</Link></p>                                 
                    <p><Link to="/ODI">modified Oswestry Disability Index (mODI) Calculator</Link></p>            
                    <p><Link to="/QUICKDASH">Quick Disabilities of the Arm, Shoulder and Hand (QuickDASH) Calculator</Link></p>                         
                    <p><Link to="/SF12">12-Item Short Form Survey (SF-12) Calculator</Link></p>             
                    <p><Link to="/SF36">36-Item Short Form Survey (SF-36) Calculator</Link></p>             
                    <p><Link to="/SPADI">Shoulder Pain and Disability Index (SPADI) Calculator </Link></p>
                    <p><Link to="/TSK">Tampa Scale for Kinesiophobia (TSK) Calculator </Link></p>
                    <p><Link to="/VAS">Visual Analogue Scale (VAS) Calculator </Link></p>
                    <p><Link to="/VISAA">Victorian Institute of Sports Assessment - Achilles (VISA-A) Calculator </Link></p> 
                    <p><Link to="/VISAG">Victorian Institute of Sports Assessment - Greater Trochanter (VISA-G) Calculator </Link></p>                         
                    <p><Link to="/VISAH">Victorian Institute of Sports Assessment - Proximal Hamstring Tendon (VISA-H) Calculator </Link></p>                          
                    <p><Link to="/VISAP">Victorian Institute of Sports Assessment - Patellar Tendon (VISA-P) Calculator </Link></p>                        
                    <p><Link to="/WHOQOLBREF">World Health Organization Quality of Life Abbreviated Survey (WHOQOL-BREF) Calculator</Link></p> 
                    <p><u>Spanish Language Calculators (Saved with English Translation)</u></p> 
                    <p><Link to="/NDI-Spanish">Neck Disability Index (NDI) Calculator (Saved With English Translation)</Link></p>                         
                    <p><Link to="/LEFS-Spanish">Lower Extremity Functional Scale (LEFS) Calculator (Saved With English Translation)</Link></p>  
                    <p><Link to="/QUICKDASH-Spanish">Quick Disabilities of the Arm, Shoulder and Hand (QuickDASH) Calculator (Saved With English Translation)</Link></p>                         
                    <p><Link to="/SPADI-Spanish">Shoulder Pain and Disability Index (SPADI) Calculator (Saved With English Translation)</Link></p>  
                    <p><u>Italian Language Calculators (Saved with English Translation)</u></p> 
                    <p><Link to="/NDI-Italian">Neck Disability Index (NDI) Calculator (Saved With English Translation)</Link></p>                         
            
                    </td>
                    <td style={{verticalAlign: 'top'}}>
                    {this.state.user ? (
                        <span>
                        <div>Signed In!</div>
                        <button onClick={this.logout}>Logout</button>
                        <h3>Welcome {firebase.auth().currentUser.displayName}</h3>
                        </span>
                    ) : (
                        <StyledFirebaseAuth
                            uiConfig={this.uiConfig}
                            firebaseAuth={firebase.auth()}
                        />



                    )}
                    </td>        
                </tr>  
            
                <tr>    
                    <td></td>
                </tr>  
            
                <tr>    
                    <td> </td>
                </tr>              
           
            
                <tr>
                    <td><p></p></td>

                </tr>


            </table>



                    


 
      

      </div>
    </div>              
            
            
            
            );
    }
    
    
  return (
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>    

        <Link to="/" style={{padding:15}}>Home</Link>            

        </div>
        
      
      
      </header>     

      <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
      
      <br/>      

            <table style={{width:"100%"}}>
                <tr>    
                    <td><span><strong><u>Click the Below Link to Email A Unique Score Request To A Patient:</u></strong></span> </td>                     
                    <td><span><strong><u>Click the Below Links To View Scores Shared With You:</u></strong></span> </td>
                </tr>
      
                <tr>    
                    <td><p><Link to="/Send-Email-Request">Send a Score Request Link via Email</Link></p> </td>      
                    <td><p><Link to="/ACLRSI-Results">View ACL-RSI Results </Link></p></td>
                </tr>
      
                <tr>    
                    <td><span><strong><u>Click the Below Link to Text Message A Unique Score Request To A Patient (US + Canada):</u></strong></span> </td>                   
                    <td><p><Link to="/AOFASH-Results">View AOFAS Hallux Results </Link></p></td>
                </tr>

                <tr>    
                    <td><p><Link to="/Send-Text-Request">Send a Score Request Link via Text Message</Link></p> </td>       
                    <td><p><Link to="/BIPQ-Results">View BIPQ Results</Link></p></td>
                </tr>      
      
                <tr>  
                    <td><p></p></td>      
                    <td><p><Link to="/DHI-Results">View DHI Results</Link></p></td>
                </tr>
      
                <tr>  
                    <td><p></p></td>      
                    <td><p><Link to="/FAAM-Results">View FAAM Results</Link></p></td>
                </tr>

                <tr>  
                    <td><p></p></td>      
                    <td><p><Link to="/HOOS12-Results">View HOOS-12 Results</Link></p></td>
                </tr>

                <tr>  
                    <td><p></p></td>      
                    <td><p><Link to="/KOOS12-Results">View KOOS-12 Results</Link></p></td>
                </tr>

                <tr>    
                    <td><p></p></td>      
                    <td><p><Link to="/LEFS-Results">View LEFS Results</Link></p></td>
                </tr>
      

                <tr>    
                    <td><p></p></td>      
                    <td><p><Link to="/NDI-Results">View NDI Results</Link></p></td>
                </tr>

                <tr>    
                    <td><p></p></td>
                    <td><p><Link to="/ODI-Results">View mODI Results</Link></p></td>
                </tr>

                <tr>    
                    <td><p></p></td>
                    <td><p><Link to="/QUICKDASH-Results">View QuickDASH Results</Link></p></td>
                </tr>

                <tr>    
                    <td><p></p></td>
                    <td><p><Link to="/SF12-Results">View SF-12 Results</Link></p></td>
                </tr>

                <tr>    
                    <td><p></p></td>
                    <td><p><Link to="/SF36-Results">View SF-36 Results</Link></p></td>
                </tr>
      
                <tr>    
                    <td><p></p></td>
                    <td><p><Link to="/SPADI-Results">View SPADI Results</Link></p></td>
                </tr> 

                <tr>    
                    <td><p></p></td>
                    <td><p><Link to="/TSK-Results">View TSK Results</Link></p></td>
                </tr> 

                <tr>    
                    <td><p></p></td>
                    <td><p><Link to="/VAS-Results">View VAS Results</Link></p></td>
                </tr> 

                <tr>    
                    <td><p></p></td>
                    <td><p><Link to="/VISAA-Results">View VISA-A Results</Link></p></td>
                </tr>  

                <tr>    
                    <td><p></p></td>
                    <td><p><Link to="/VISAG-Results">View VISA-G Results</Link></p></td>
                </tr> 

                <tr>    
                    <td><p></p></td>
                    <td><p><Link to="/VISAH-Results">View VISA-H Results</Link></p></td>
                </tr>  

                <tr>    
                    <td><p></p></td>      
                    <td><p><Link to="/VISAP-Results">View VISA-P Results</Link></p></td>
                </tr>  


                <tr>    
                    <td><p></p></td>      
                    <td><p><Link to="/WHOQOLBREF-Results">View WHOQOL-BREF Results</Link></p></td>
                </tr>  




            </table>  

      
      
      
      <br/>
                        
                <p>Please do <u>send us an email</u> (<a href="mailto:orthopowertools@gmail.com">orthopowertools@gmail.com</a>) if you would like any <u>additional scores or features to be made available</u> and we would be happy to work on implementing them!</p>       
                              
      

             <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
            {this.state.user ? (
                <span>
                <button onClick={this.logout}>Logout</button>
                </span>
            ) : (
                <StyledFirebaseAuth
                    uiConfig={this.uiConfig}
                    firebaseAuth={firebase.auth()}
                />
  
            )}  
            </div>

      

      </div>
    </div>      
  );
}    
    
    
}
export default Home;

{/*

//Below was removed on 2/21/21 from the not signed in home page to make it appear simpler. I added the privacy part to the frequently asked questions page


                <p>We built this HIPAA compliant site to make collecting and analyzing patient reported outcomes <u>easier</u> and <u>more efficient</u> for you. Features include:</p>

                <p>1. A system that allows individuals to <b>remotely complete</b> patient reported outcome surveys and then <b>confidentially share</b> it with the their clinician<br/>2. Clinicians can then log-in to <b>assess</b> their patients <b>progress</b> throughout their rehabilitation</p>
            
                <p>We take the <u>digital privacy</u> of our clinician and patient users <u>very seriously</u> and have implemented a number of technologies to protect the privacy and integrity of the data submitted, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Submitted data will <u>never be shared with anyone other than the assigned clinician</u>.</p>
            
                <p>Please do <u>send us an email</u> (<a href="mailto:orthopowertools@gmail.com">orthopowertools@gmail.com</a>) if you would like any <u>additional scores or features to be made available</u> and we would be happy to work on implementing them!</p>   

//Below is the link that I used to have on the home page to link to the actual ODI-Link Page
                    <td><p><Link to="/ODI">Oswestry Disability Index </Link> and <Link to="/ODI-Link/:submitter_name/:clinician_sending_email">Submitter Oswestry Disability Index </Link></p> </td>
                    
                    
//Below is the header code when I had a log-out in the header, which required a second css wrapper to get alignment nice

      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>                
            <div className="wrapper2">
                <Link to="/" style={{padding:15}}>Home</Link>            
              {this.state.user ?       
                <button onClick={this.logout}>Logout</button>                
              :
                <button onClick={this.login}>Log In</button>              
              }
            </div>
        </div>
      </header>     

                    
            
            
//Below is the code for displaying the new scores I've added (LEFS, SF12, and SPADI)

                    <p><Link to="/LEFS">Lower Extremity Functal Scale (LEFS)</Link></p> 
                    <p><Link to="/SF12">12-Item Short Form Survey (SF-12)</Link></p>             
                    <p><Link to="/SPADI">Shoulder Pain and Disability Index (SPADI) </Link></p>

                <tr>    
                    <td><p><Link to="/LEFS-Send-Link">Lower Extremity Functional Scale</Link></p> </td>
                    <td><p><Link to="/LEFS-Results">Lower Extremity Functional Scale</Link></p></td>
                </tr>  
                <tr>    
                    <td><p><Link to="/SF12-Send-Link">12-Item Short Form Survey</Link></p> </td>
                    <td><p><Link to="/SF12-Results">12-Item Short Form Survey</Link></p></td>
                </tr>

                <tr>    
                    <td><p><Link to="/SPADI-Send-Link">Shoulder Pain and Disability Index</Link></p> </td>
                    <td><p><Link to="/SPADI-Results">Shoulder Pain and Disability Index</Link></p></td>
                </tr>
                    
                    
*/}
{/*
//old home page before making send test and send email into one page each...
            <table style={{width:"100%"}}>
                <tr>    
                    <td><span><strong><u>Click the Below Links to Email A Unique Score Request To A Patient:</u></strong></span> </td>
                    <td><span><strong><u>Click the Below Links To View Scores Shared With You:</u></strong></span> </td>
                </tr>
      
                <tr>    
                    <td><p><Link to="/ACLRSI-Send-Link">Send an ACL-RSI Link</Link></p> </td>
                    <td><p><Link to="/ACLRSI-Results">View ACL-RSI Results </Link></p></td>
                </tr>
      
                <tr>    
                    <td><p><Link to="/AOFASH-Send-Link">Send an AOFAS Hallux Link</Link></p> </td>
                    <td><p><Link to="/AOFASH-Results">View AOFAS Hallux Results </Link></p></td>
                </tr>

                <tr>    
                    <td><p><Link to="/BIPQ-Send-Link">Send a BIPQ Link</Link></p> </td>
                    <td><p><Link to="/BIPQ-Results">View BIPQ Results</Link></p></td>
                </tr>      
      
                <tr>    
                    <td><p><Link to="/DHI-Send-Link">Send a DHI Link</Link></p> </td>
                    <td><p><Link to="/DHI-Results">View DHI Results</Link></p></td>
                </tr>
      
                <tr>    
                    <td><p><Link to="/FAAM-Send-Link">Send a FAAM Link</Link></p> </td>
                    <td><p><Link to="/FAAM-Results">View FAAM Results</Link></p></td>
                </tr>

                <tr>    
                    <td><p><Link to="/HOOS12-Send-Link">Send a HOOS-12 Link</Link></p> </td>
                    <td><p><Link to="/HOOS12-Results">View HOOS-12 Results</Link></p></td>
                </tr>

                <tr>    
                    <td><p><Link to="/KOOS12-Send-Link">Send KOOS-12 Link</Link></p> </td>
                    <td><p><Link to="/KOOS12-Results">View KOOS-12 Results</Link></p></td>
                </tr>

                <tr>    
                    <td><p><Link to="/LEFS-Send-Link">Send an English LEFS Link</Link> / <Link to="/LEFS-Send-Link-Spanish">Send a Spanish LEFS Link (Saved With English Translation)</Link></p> </td>
                    <td><p><Link to="/LEFS-Results">View LEFS Results</Link></p></td>
                </tr>
      

                <tr>    
                    <td><p><Link to="/NDI-Send-Link">Send an English NDI Link</Link> / <Link to="/NDI-Send-Link-Spanish">Send a Spanish NDI Link (Saved With English Translation)</Link></p></td>
                    <td><p><Link to="/NDI-Results">View NDI Results</Link></p></td>
                </tr>

                <tr>    
                    <td><p><Link to="/ODI-Send-Link">Send a mODI Link</Link></p> </td>
                    <td><p><Link to="/ODI-Results">View mODI Results</Link></p></td>
                </tr>

                <tr>    
                    <td><p><Link to="/QUICKDASH-Send-Link">Send an English QuickDASH Link</Link> / <Link to="/QUICKDASH-Send-Link-Spanish">Send a Spanish QuickDASH Link (Saved With English Translation)</Link></p> </td>
                    <td><p><Link to="/QUICKDASH-Results">View QuickDASH Results</Link></p></td>
                </tr>

                <tr>    
                    <td><p><Link to="/SF12-Send-Link">Send a SF-12 Link</Link></p> </td>
                    <td><p><Link to="/SF12-Results">View SF-12 Results</Link></p></td>
                </tr>

                <tr>    
                    <td><p><Link to="/SF36-Send-Link">Send a SF-36 Link</Link></p> </td>
                    <td><p><Link to="/SF36-Results">View SF-36 Results</Link></p></td>
                </tr>
      
                <tr>    
                    <td><p><Link to="/SPADI-Send-Link">Send an English SPADI Link</Link> / <Link to="/SPADI-Send-Link-Spanish">Send a Spanish SPADI Link (Saved With English Translation)</Link></p> </td>
                    <td><p><Link to="/SPADI-Results">View SPADI Results</Link></p></td>
                </tr> 

                <tr>    
                    <td><p><Link to="/VISAA-Send-Link">Send a VISA-A Link</Link></p> </td>
                    <td><p><Link to="/VISAA-Results">View VISA-A Results</Link></p></td>
                </tr>  

                <tr>    
                    <td><p><Link to="/VISAG-Send-Link">Send a VISA-G Link</Link></p> </td>
                    <td><p><Link to="/VISAG-Results">View VISA-G Results</Link></p></td>
                </tr> 

                <tr>    
                    <td><p><Link to="/VISAH-Send-Link">Send a VISA-H Link</Link></p> </td>
                    <td><p><Link to="/VISAH-Results">View VISA-H Results</Link></p></td>
                </tr>  

                <tr>    
                    <td><p><Link to="/VISAP-Send-Link">Send a VISA-P Link</Link></p> </td>
                    <td><p><Link to="/VISAP-Results">View VISA-P Results</Link></p></td>
                </tr>  


                <tr>    
                    <td><p><Link to="/WHOQOLBREF-Send-Link">Send a WHOQOL-BREF Link</Link></p> </td>
                    <td><p><Link to="/WHOQOLBREF-Results">View WHOQOL-BREF Results</Link></p></td>
                </tr>  

                <tr>    
                    <td><span><strong><u>Place The Below Generic Links On Your Website:</u></strong></span> </td>
                    <td> </td>    
                </tr>

                <tr>    
                    <td>
                        <p>Your patient must have your email address available so that the score may be routed correctly.</p>         
                    </td>
                    <td> </td> 
                </tr>               

                <tr>    
                    <td>
                    <p><u>English Language Calculators</u></p>                              
                    <p><Link to="/ACLRSI">ACL-RSI Calculator: https://orthopowertools.com/ACLRSI</Link></p>  
                    <p><Link to="/AOFASH">AOFAS Hallux Calculator: https://orthopowertools.com/AOFASH</Link></p>
                    <p><Link to="/BIPQ">BIPQ Calculator: https://orthopowertools.com/BIPQ</Link></p> 
                    <p><Link to="/DHI">DHI Calculator: https://orthopowertools.com/DHI</Link></p> 
                    <p><Link to="/FAAM">FAAM Calculator: https://orthopowertools.com/FAAM</Link></p> 
                    <p><Link to="/HOOS12">HOOS-12 Calculator: https://orthopowertools.com/HOOS12</Link></p>
                    <p><Link to="/KOOS12">KOOS-12 Calculator: https://orthopowertools.com/KOOS12</Link></p> 
                    <p><Link to="/LEFS">LEFS Calculator: https://orthopowertools.com/LEFS</Link></p>
                    <p><Link to="/NDI">NDI Calculator: https://orthopowertools.com/NDI</Link></p> 
                    <p><Link to="/ODI">mODI Calculator: https://orthopowertools.com/ODI</Link></p>            
                    <p><Link to="/QUICKDASH">QuickDASH Calculator: https://orthopowertools.com/QUICKDASH</Link></p> 
                    <p><Link to="/SF12">SF-12 Calculator: https://orthopowertools.com/SF12</Link></p>             
                    <p><Link to="/SF36">SF-36 Calculator: https://orthopowertools.com/SF36</Link></p>             
                    <p><Link to="/SPADI">SPADI Calculator: https://orthopowertools.com/SPADI </Link></p> 
                    <p><Link to="/VISAA">VISA-A Calculator: https://orthopowertools.com/VISAA </Link></p> 
                    <p><Link to="/VISAG">VISA-G Calculator: https://orthopowertools.com/VISAG </Link></p> 
                    <p><Link to="/VISAH">VISA-H Calculator: https://orthopowertools.com/VISAH </Link></p> 
                    <p><Link to="/VISAP">VISA-P Calculator: https://orthopowertools.com/VISAP </Link></p> 
                    <p><Link to="/WHOQOLBREF">WHOQOL-BREF Calculator: https://orthopowertools.com/WHOQOLBREF</Link></p>
                    <p><u>Spanish Language Calculators (Saved with English Translation)</u></p> 
                    <p><Link to="/LEFS-Spanish">LEFS Calculator (Saved With English Translation): https://orthopowertools.com/LEFS-Spanish</Link></p>
                    <p><Link to="/NDI-Spanish">NDI Calculator (Saved With English Translation): https://orthopowertools.com/NDI-Spanish</Link></p>
                    <p><Link to="/QUICKDASH-Spanish">QuickDASH Calculator (Saved With English Translation): https://orthopowertools.com/QUICKDASH-Spanish</Link></p>
                    <p><Link to="/SPADI-Spanish">SPADI Calculator (Saved With English Translation): https://orthopowertools.com/SPADI-Spanish </Link></p> 
                    </td>       
                    <td> </td> 
                </tr>  

            </table>
*/}