import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Slider, {createSliderWithTooltip} from 'rc-slider';

///////////////////////////////////////////Calculator Methods
const SliderWithTooltip = createSliderWithTooltip(Slider);

const wrapperStyle = {'max-width': '400px', 'margin-left': 'auto', 'margin-right': 'auto', 'margin-top': 40, 'margin-bottom': 60 };

const marks1 = {
		0: <strong><br />0 minutes</strong>,
		10: <strong><br />100 minutes</strong>,
	};

class Slider1 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 10}
	}

	someFn = (value) => {
		this.props.callbackFromParentS1(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>1. For how many minutes can you sit pain free?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={10} onAfterChange = {(value) => this.someFn(value)} tipProps= {{visible: 'true', placement: "top", prefixCls: ''}} tipFormatter = {value => null} marks = {marks1} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks2 = {
		0: <strong><br />Severe strong pain</strong>,
		10: <strong><br />No pain</strong>,
	};

class Slider2 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 10}
	}

	someFn = (value) => {
		this.props.callbackFromParentS2(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>2. Do you have pain walking downstairs with a normal gait cycle?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={10} onAfterChange = {(value) => this.someFn(value)} tipProps= {{visible: 'true', placement: "top", prefixCls: ''}} tipFormatter = {value => null} marks = {marks2} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks3 = {
		0: <strong><br />Severe strong pain</strong>,
		10: <strong><br />No pain</strong>,
	};

class Slider3 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 10}
	}

	someFn = (value) => {
		this.props.callbackFromParentS3(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>3. Do you have pain at the knee with full active non-weightbearing knee extension?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={10} onAfterChange = {(value) => this.someFn(value)} tipProps= {{visible: 'true', placement: "top", prefixCls: ''}} tipFormatter = {value => null} marks = {marks3} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks4 = {
		0: <strong><br />Severe strong pain</strong>,
		10: <strong><br />No pain</strong>,
	};

class Slider4 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 10}
	}

	someFn = (value) => {
		this.props.callbackFromParentS4(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>4. Do you have pain when doing a full weight bearing lunge?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={10} onAfterChange = {(value) => this.someFn(value)} tipProps= {{visible: 'true', placement: "top", prefixCls: ''}} tipFormatter = {value => null} marks = {marks4} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks5 = {
		0: <strong><br />Unable</strong>,
		10: <strong><br />No Problems</strong>,
	};

class Slider5 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 10}
	}

	someFn = (value) => {
		this.props.callbackFromParentS5(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>5. Do you have problems squatting?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={10} onAfterChange = {(value) => this.someFn(value)} tipProps= {{visible: 'true', placement: "top", prefixCls: ''}} tipFormatter = {value => null} marks = {marks5} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks6 = {
		0: <strong><br />Strong severe pain/unable</strong>,
		10: <strong><br />No pain</strong>,
	};

class Slider6 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 10}
	}

	someFn = (value) => {
		this.props.callbackFromParentS6(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>6. Do you have pain during or immediately after doing 10 single leg hops?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={10} onAfterChange = {(value) => this.someFn(value)} tipProps= {{visible: 'true', placement: "top", prefixCls: ''}} tipFormatter = {value => null} marks = {marks6} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}
class Buttons1 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 10}
}
someFn = (value) => {
this.props.callbackFromParent1(value);
if (value === 0)
this.props.callbackFromParent1_text("Not currently undertaking sport");
if (value === 4)
this.props.callbackFromParent1_text("Modified training, modified competition in sport");
if (value === 7)
this.props.callbackFromParent1_text("Full training, full competition in sport but decreased level");
if (value === 10)
this.props.callbackFromParent1_text("Competing at the same or higher level in sport");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>7. Are you currently undertaking sport or other physical activity?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Modified training, modified competition</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Full training, full competition but not at same level as when symptoms began</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Competing at the same or higher level as when symptoms began</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>7. Are you currently undertaking sport or other physical activity?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Modified training, modified competition</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Full training, full competition but not at same level as when symptoms began</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Competing at the same or higher level as when symptoms began</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons2 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 30}
}
someFn = (value) => {
this.props.callbackFromParent2(value);
if (value === 0)
this.props.callbackFromParent2_text("No pain while undertaking sport for 0 minutes");
if (value === 7)
this.props.callbackFromParent2_text("No pain while undertaking sport for 1-5 minutes");
if (value === 14)
this.props.callbackFromParent2_text("No pain while undertaking sport for 6-10 minutes");
if (value === 21)
this.props.callbackFromParent2_text("No pain while undertaking sport for 7-15 minutes");
if (value === 30)
this.props.callbackFromParent2_text("No pain while undertaking sport for more than 15 minutes");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>8a. If you have no pain while undertaking sport, for how long can you train or practice?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={30}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 1-5 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={14}>{(this.state.clicked === 14) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 6-10 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={21}>{(this.state.clicked === 21) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 7-15 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={30}>{(this.state.clicked === 30) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} More than 15 minutes</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>8a. If you have no pain while undertaking sport, for how long can you train or practice?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={30}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 1-5 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={14}>{(this.state.clicked === 14) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 6-10 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={21}>{(this.state.clicked === 21) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 7-15 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={30}>{(this.state.clicked === 30) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} More than 15 minutes</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons3 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 20}
}
someFn = (value) => {
this.props.callbackFromParent3(value);
if (value === 0)
this.props.callbackFromParent3_text("Some pain while undertaking sport for 0 minutes");
if (value === 4)
this.props.callbackFromParent3_text("Some pain while undertaking sport for 1-5 minutes");
if (value === 10)
this.props.callbackFromParent3_text("Some pain while undertaking sport for 6-10 minutes");
if (value === 14)
this.props.callbackFromParent3_text("Some pain while undertaking sport for 7-15 minutes");
if (value === 20)
this.props.callbackFromParent3_text("Some pain while undertaking sport for more than 15 minutes");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>8b. If you have some pain while undertaking sport, but it does not stop you from completing your training or practice, for how long can you train or practice?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={20}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 1-5 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 6-10 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={14}>{(this.state.clicked === 14) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 7-15 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={20}>{(this.state.clicked === 20) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} More than 15 minutes</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>8b. If you have some pain while undertaking sport, but it does not stop you from completing your training or practice, for how long can you train or practice?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={20}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 1-5 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 6-10 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={14}>{(this.state.clicked === 14) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 7-15 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={20}>{(this.state.clicked === 20) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} More than 15 minutes</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons4 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 10}
}
someFn = (value) => {
this.props.callbackFromParent4(value);
if (value === 0)
this.props.callbackFromParent4_text("Pain that prevents participation in sports for 0 minutes");
if (value === 2)
this.props.callbackFromParent4_text("Pain that prevents participation in sports for 1-5 minutes");
if (value === 5)
this.props.callbackFromParent4_text("Pain that prevents participation in sports for 6-10 minutes");
if (value === 7)
this.props.callbackFromParent4_text("Pain that prevents participation in sports for 7-15 minutes");
if (value === 10)
this.props.callbackFromParent4_text("Pain that prevents participation in sports for more than 15 minutes");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>8c. If you have pain that stops you from completing your training or practice in sports, for how long can you train or practice?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 1-5 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 6-10 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 7-15 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} More than 15 minutes</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>8c. If you have pain that stops you from completing your training or practice in sports, for how long can you train or practice?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 1-5 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 6-10 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={7}>{(this.state.clicked === 7) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} 7-15 minutes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} More than 15 minutes</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}


class Buttons0 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 8}
}
someFn = (value) => {
this.props.callbackFromParent0(value);
if (value === 8){
this.props.callbackFromParent0_text("I have no pain");
this.props.callbackFromParent2_adjust(1);
this.props.callbackFromParent2(30);    
this.props.callbackFromParent3_adjust(0.001);
this.props.callbackFromParent3(20);     
this.props.callbackFromParent4_adjust(0.001); 
this.props.callbackFromParent4(10);     
}
if (value === 9){
this.props.callbackFromParent0_text("I have some pain but it does not stop me");
this.props.callbackFromParent2_adjust(0.001);
this.props.callbackFromParent2(30);     
this.props.callbackFromParent3_adjust(1); 
this.props.callbackFromParent3(20);    
this.props.callbackFromParent4_adjust(0.001); 
this.props.callbackFromParent4(10);     
}
if (value === 10){
this.props.callbackFromParent0_text("The pain stops me from completing the activity");
this.props.callbackFromParent2_adjust(0.001);  
this.props.callbackFromParent2(30);     
this.props.callbackFromParent3_adjust(0.001);
this.props.callbackFromParent3(20);    
this.props.callbackFromParent4_adjust(1);     
this.props.callbackFromParent4(10);     
    
}    
   
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Do you have pain while undertaking sport?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={8}>
<ToggleButton bsStyle='btn-custom' value={8}>{(this.state.clicked === 8) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have no pain</ToggleButton>    
<ToggleButton bsStyle='btn-custom' value={9}>{(this.state.clicked === 9) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have some pain but it does not stop me</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain stops me from completing the activity</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Do you have pain while undertaking sport?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={8}>
<ToggleButton bsStyle='btn-custom' value={8}>{(this.state.clicked === 8) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have no pain</ToggleButton>    
<ToggleButton bsStyle='btn-custom' value={9}>{(this.state.clicked === 9) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have some pain but it does not stop me</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={10}>{(this.state.clicked === 10) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain stops me from completing the activity</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}


    
 


  


class VISAP_Link extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

        
        
        
        
    //Calculator variables    
        selectedValueS1: 10,
        selectedValueS1_text: 'Minutes of sitting pain free: ',

        selectedValueS2: 10,
        selectedValueS2_text: 'Pain when walking downstairs with a normal gait cycle: ',

        selectedValueS3: 10,
        selectedValueS3_text: 'Pain at the knee with full active non-weightbearing knee extension: ',

        selectedValueS4: 10,
        selectedValueS4_text: 'Pain when doing a full weight bearing lunge: ',

        selectedValueS5: 10,
        selectedValueS5_text: 'Problems squatting: ',

        selectedValueS6: 10,
        selectedValueS6_text: 'Pain during or immediately after doing 10 single leg hops: ',

        selectedValue1: 10,
        selectedValue1_text: 'Competing at the same or higher level in sport',

        selectedValue2: 30,
        selectedValue2_text: 'No pain while undertaking sport for more than 15 minutes',

        selectedValue3: 20,
        selectedValue3_text: 'Some pain while undertaking sport for more than 15 minutes',

        selectedValue4: 10,
        selectedValue4_text: 'Pain that prevents participation in sports for more than 15 minutes', 
        
        selectedValue0: 8,
        selectedValue0_text: 'I have no pain',
        selectedValue2_adjust:1,
        selectedValue3_adjust:0.001,
        selectedValue4_adjust:0.001,
           
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const VISAPScoresRef = firebase.firestore();
  VISAPScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  VISAPScoresRef.collection("visap_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const VISAPScoresRef = firebase.firestore();
  VISAPScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  VISAPScoresRef.collection("visap_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const VISAPScoresRef = firebase.firestore();
  VISAPScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  VISAPScoresRef.collection("visap_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const VISAPScoresRef = firebase.firestore();
  VISAPScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  VISAPScoresRef.collection("visap_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const VISAPScoresRef = firebase.firestore();
  VISAPScoresRef.settings({
      timestampsInSnapshots: true
  });  
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){        
  const visap_score = VISAPScoresRef.collection("visap_scores").doc(this.props.requestSurveyID).set({            
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Victorian Institute of Sports Assessment Patellar Tendon',
    Question_01_VISAP: this.state.selectedValueS1,
    Question_02_VISAP: this.state.selectedValueS2,
    Question_03_VISAP: this.state.selectedValueS3,
    Question_04_VISAP: this.state.selectedValueS4,
    Question_05_VISAP: this.state.selectedValueS5,
    Question_06_VISAP: this.state.selectedValueS6,
    Question_07_VISAP: this.state.selectedValue1,            
    Question_08_VISAP: parseFloat((this.state.selectedValue2*this.state.selectedValue2_adjust).toFixed(0)),
    Question_09_VISAP: parseFloat((this.state.selectedValue3*this.state.selectedValue3_adjust).toFixed(0)),
    Question_10_VISAP: parseFloat((this.state.selectedValue4*this.state.selectedValue4_adjust).toFixed(0)),
    Question_0_VISAP: this.state.selectedValue0,      
    Total_Score_VISAP: parseFloat((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValue1+this.state.selectedValue2*this.state.selectedValue2_adjust+this.state.selectedValue3*this.state.selectedValue3_adjust+this.state.selectedValue4*this.state.selectedValue4_adjust).toFixed(0)),     
  });
}
else {
  const visap_score = VISAPScoresRef.collection("visap_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'Victorian Institute of Sports Assessment Patellar Tendon',
    Question_01_VISAP: this.state.selectedValueS1,
    Question_02_VISAP: this.state.selectedValueS2,
    Question_03_VISAP: this.state.selectedValueS3,
    Question_04_VISAP: this.state.selectedValueS4,
    Question_05_VISAP: this.state.selectedValueS5,
    Question_06_VISAP: this.state.selectedValueS6,
    Question_07_VISAP: this.state.selectedValue1,            
    Question_08_VISAP: parseFloat((this.state.selectedValue2*this.state.selectedValue2_adjust).toFixed(0)),
    Question_09_VISAP: parseFloat((this.state.selectedValue3*this.state.selectedValue3_adjust).toFixed(0)),
    Question_10_VISAP: parseFloat((this.state.selectedValue4*this.state.selectedValue4_adjust).toFixed(0)),
    Question_0_VISAP: this.state.selectedValue0,      
    Total_Score_VISAP: parseFloat((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValue1+this.state.selectedValue2*this.state.selectedValue2_adjust+this.state.selectedValue3*this.state.selectedValue3_adjust+this.state.selectedValue4*this.state.selectedValue4_adjust).toFixed(0)),        
  });      
}     
  this.setState({
    clinician_to_share_with: '',
    user_id: '',
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.props.clinicianSendingEmail + '. You may now close this alert and the entire window.');   
}     
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("visap_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallbackS1  = (dataFromChild) => {
    this.setState({selectedValueS1: dataFromChild});
    }
    myCallbackS2  = (dataFromChild) => {
    this.setState({selectedValueS2: dataFromChild});
    }
    myCallbackS3  = (dataFromChild) => {
    this.setState({selectedValueS3: dataFromChild});
    }
    myCallbackS4  = (dataFromChild) => {
    this.setState({selectedValueS4: dataFromChild});
    }
    myCallbackS5  = (dataFromChild) => {
    this.setState({selectedValueS5: dataFromChild});
    }
    myCallbackS6  = (dataFromChild) => {
    this.setState({selectedValueS6: dataFromChild});
    }
    myCallback1  = (dataFromChild) => {
    this.setState({selectedValue1: dataFromChild});
    }
    myCallback1_text  = (dataFromChild) => {
    this.setState({selectedValue1_text: dataFromChild});
    }
    myCallback2  = (dataFromChild) => {
    this.setState({selectedValue2: dataFromChild});
    }
    myCallback2_text  = (dataFromChild) => {
    this.setState({selectedValue2_text: dataFromChild});
    }
    myCallback3  = (dataFromChild) => {
    this.setState({selectedValue3: dataFromChild});
    }
    myCallback3_text  = (dataFromChild) => {
    this.setState({selectedValue3_text: dataFromChild});
    }
    myCallback4  = (dataFromChild) => {
    this.setState({selectedValue4: dataFromChild});
    }
    myCallback4_text  = (dataFromChild) => {
    this.setState({selectedValue4_text: dataFromChild});
    }
    myCallback0  = (dataFromChild) => {
    this.setState({selectedValue0: dataFromChild});
    }
    myCallback0_text  = (dataFromChild) => {
    this.setState({selectedValue0_text: dataFromChild});
    } 
    myCallback2_adjust  = (dataFromChild) => {
    this.setState({selectedValue2_adjust: dataFromChild});
    } 
    myCallback3_adjust  = (dataFromChild) => {
    this.setState({selectedValue3_adjust: dataFromChild});
    } 
    myCallback4_adjust  = (dataFromChild) => {
    this.setState({selectedValue4_adjust: dataFromChild});
    }         
  
    
    

    
render() { 
    
    const selectedValue0 = this.state.selectedValue0;
    let button;
    if (this.state.selectedValue0 == 8){
        button = <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
    } else if (this.state.selectedValue0 == 9){
        button = <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
    } else {
        button = <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
    }       

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>Victorian Institute of Sports Assessment - Patellar Tendon Calculator</h1>
                <h4>Hello, {this.props.submitterName}, your clinician, {this.props.clinicianSendingEmail}, has sent you this survey to help further understand your health. Please click the appropriate buttons below and then click "Submit Score". Please note that your <u>digital privacy is taken very seriously</u> and a number of technologies have been implemented to protect the privacy and integrity of your data, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Your data <u>will never be shared with anyone other than the assigned clinician</u>.</h4>

            <form onSubmit={this.handleSubmit}>



                <StickyContainer>
                  <Sticky>
                    {({ style }) => <div className="sticky_style" style={style}><Well bsSize="small">Total VISA-P Score: {((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValue1+this.state.selectedValue2*this.state.selectedValue2_adjust+this.state.selectedValue3*this.state.selectedValue3_adjust+this.state.selectedValue4*this.state.selectedValue4_adjust).toFixed(0))} / {100
                    } = {((((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValue1+this.state.selectedValue2*this.state.selectedValue2_adjust+this.state.selectedValue3*this.state.selectedValue3_adjust+this.state.selectedValue4*this.state.selectedValue4_adjust))/(100
                    )*100).toFixed(0))} %
                    <p/>
                    Graphical Total VISA-P Score: (%)
                    <ProgressBar>
                    <ProgressBar active now={(100*((((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValue1+this.state.selectedValue2*this.state.selectedValue2_adjust+this.state.selectedValue3*this.state.selectedValue3_adjust+this.state.selectedValue4*this.state.selectedValue4_adjust).toFixed(0))/(100
                    ))))}/>
                    <ProgressBar active bsStyle="danger" now={100-(100*((((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValue1+this.state.selectedValue2*this.state.selectedValue2_adjust+this.state.selectedValue3*this.state.selectedValue3_adjust+this.state.selectedValue4*this.state.selectedValue4_adjust).toFixed(0)))/(100
                    )))}/>
                    </ProgressBar>    
                    </Well>
                    </div>}

                  </Sticky>
                <p />
                    
                <br/>
                <br/>                      

                    <h4>Instructions: In this questionnaire, the term pain refers specifically to pain in the patellar tendon region.
                    </h4>
                    <Slider1 callbackFromParentS1={this.myCallbackS1}/>
                    <Slider2 callbackFromParentS2={this.myCallbackS2}/>
                    <Slider3 callbackFromParentS3={this.myCallbackS3}/>
                    <Slider4 callbackFromParentS4={this.myCallbackS4}/>
                    <Slider5 callbackFromParentS5={this.myCallbackS5}/>
                    <Slider6 callbackFromParentS6={this.myCallbackS6}/>
                    <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
                    <Buttons0 callbackFromParent0={this.myCallback0} callbackFromParent0_text={this.myCallback0_text}
                    callbackFromParent2_adjust={this.myCallback2_adjust} callbackFromParent2={this.myCallback2}
                    callbackFromParent3_adjust={this.myCallback3_adjust} callbackFromParent3={this.myCallback3}
                    callbackFromParent4_adjust={this.myCallback4_adjust} callbackFromParent4={this.myCallback4}
                    /> 
                    {button}                       
                        
                </StickyContainer> 

                <br />
                    
              <button style = {{width: '100%'}}>Submit Score</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(VISAP_Link);
