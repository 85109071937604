import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Slider, {createSliderWithTooltip} from 'rc-slider';

///////////////////////////////////////////Calculator Methods
    
const SliderWithTooltip = createSliderWithTooltip(Slider);

const wrapperStyle = {'max-width': '400px', 'margin-left': 'auto', 'margin-right': 'auto', 'margin-top': 40, 'margin-bottom': 60 };

const marks1 = {
		0: <strong>0<br />no pain</strong>,
		10: <strong>10<br />worst pain</strong>,
	};

class Slider1 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS1(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>1. At its worst?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks1} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks2 = {
		0: <strong>0<br />no pain</strong>,
		10: <strong>10<br />worst pain</strong>,
	};

class Slider2 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS2(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>2. When lying on the involved side?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks2} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks3 = {
		0: <strong>0<br />no pain</strong>,
		10: <strong>10<br />worst pain</strong>,
	};

class Slider3 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS3(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>3. Reaching for something on a high shelf?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks3} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks4 = {
		0: <strong>0<br />no pain</strong>,
		10: <strong>10<br />worst pain</strong>,
	};

class Slider4 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS4(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>4. Touching the back of your neck?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks4} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks5 = {
		0: <strong>0<br />no pain</strong>,
		10: <strong>10<br />worst pain</strong>,
	};

class Slider5 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS5(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>5. Pushing with the involved arm?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks5} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks6 = {
		0: <strong>0<br />no difficulty</strong>,
		10: <strong>10<br />most difficulty</strong>,
	};

class Slider6 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS6(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>6. Washing your hair?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks6} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks7 = {
		0: <strong>0<br />no difficulty</strong>,
		10: <strong>10<br />most difficulty</strong>,
	};

class Slider7 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS7(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>7. Washing your back?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks7} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks8 = {
		0: <strong>0<br />no difficulty</strong>,
		10: <strong>10<br />most difficulty</strong>,
	};

class Slider8 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS8(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>8. Putting on an undershirt or jumper?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks8} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks9 = {
		0: <strong>0<br />no difficulty</strong>,
		10: <strong>10<br />most difficulty</strong>,
	};

class Slider9 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS9(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>9. Putting on a shirt that buttons down the front?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks9} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks10 = {
		0: <strong>0<br />no difficulty</strong>,
		10: <strong>10<br />most difficulty</strong>,
	};

class Slider10 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS10(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>10. Putting on your pants?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks10} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks11 = {
		0: <strong>0<br />no difficulty</strong>,
		10: <strong>10<br />most difficulty</strong>,
	};

class Slider11 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS11(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>11. Placing an object on a high shelf?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks11} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks12 = {
		0: <strong>0<br />no difficulty</strong>,
		10: <strong>10<br />most difficulty</strong>,
	};

class Slider12 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS12(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>12. Carrying a heavy object of 10 pounds (4.5 kilograms)?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks12} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks13 = {
		0: <strong>0<br />no difficulty</strong>,
		10: <strong>10<br />most difficulty</strong>,
	};

class Slider13 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS13(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>13. Removing something from your back pocket?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks13} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}  
    
    
 


  


class SPADI_Link extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

        
        
        
        
    //Calculator variables    
        selectedValueS1: 0,
        selectedValueS1_text: 'Pain at its worst: ',

        selectedValueS2: 0,
        selectedValueS2_text: 'Pain when lying on the involved side: ',

        selectedValueS3: 0,
        selectedValueS3_text: 'Pain reaching for something on a high shelf: ',

        selectedValueS4: 0,
        selectedValueS4_text: 'Pain touching the back of neck: ',

        selectedValueS5: 0,
        selectedValueS5_text: 'Pain pushing with the involved arm: ',

        selectedValueS6: 0,
        selectedValueS6_text: 'Difficulty washing hair: ',

        selectedValueS7: 0,
        selectedValueS7_text: 'Difficulty washing back: ',

        selectedValueS8: 0,
        selectedValueS8_text: 'Difficulty putting on undershirt or jumper: ',

        selectedValueS9: 0,
        selectedValueS9_text: 'Difficulty putting on a shirt that buttons down the front: ',

        selectedValueS10: 0,
        selectedValueS10_text: 'Difficulty putting on pants: ',

        selectedValueS11: 0,
        selectedValueS11_text: 'Difficulty pacing an object on a high shelf: ',

        selectedValueS12: 0,
        selectedValueS12_text: 'Difficulty carrying a heavy object: ',

        selectedValueS13: 0,
        selectedValueS13_text: 'Difficulty removing something from back pocket: ',             
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const SPADIScoresRef = firebase.firestore();
  SPADIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SPADIScoresRef.collection("spadi_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const SPADIScoresRef = firebase.firestore();
  SPADIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SPADIScoresRef.collection("spadi_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const SPADIScoresRef = firebase.firestore();
  SPADIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SPADIScoresRef.collection("spadi_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const SPADIScoresRef = firebase.firestore();
  SPADIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SPADIScoresRef.collection("spadi_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const SPADIScoresRef = firebase.firestore();
  SPADIScoresRef.settings({
      timestampsInSnapshots: true
  });  
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){        
  const spadi_score = SPADIScoresRef.collection("spadi_scores").doc(this.props.requestSurveyID).set({            
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Shoulder Pain and Disability Index',
    Question_01_SPADI: this.state.selectedValueS1,
    Question_02_SPADI: this.state.selectedValueS2,
    Question_03_SPADI: this.state.selectedValueS3,
    Question_04_SPADI: this.state.selectedValueS4,
    Question_05_SPADI: this.state.selectedValueS5,
    Question_06_SPADI: this.state.selectedValueS6,
    Question_07_SPADI: this.state.selectedValueS7,            
    Question_08_SPADI: this.state.selectedValueS8,
    Question_09_SPADI: this.state.selectedValueS9,
    Question_10_SPADI: this.state.selectedValueS10,
    Question_11_SPADI: this.state.selectedValueS11,
    Question_12_SPADI: this.state.selectedValueS12,
    Question_13_SPADI: this.state.selectedValueS13,
    Total_Score_SPADI: (((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValueS7+this.state.selectedValueS8+this.state.selectedValueS9+this.state.selectedValueS10+this.state.selectedValueS11+this.state.selectedValueS12+this.state.selectedValueS13))/(130)*100).toFixed(1),     
  });
}
else {
  const spadi_score = SPADIScoresRef.collection("spadi_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'Shoulder Pain and Disability Index',
    Question_01_SPADI: this.state.selectedValueS1,
    Question_02_SPADI: this.state.selectedValueS2,
    Question_03_SPADI: this.state.selectedValueS3,
    Question_04_SPADI: this.state.selectedValueS4,
    Question_05_SPADI: this.state.selectedValueS5,
    Question_06_SPADI: this.state.selectedValueS6,
    Question_07_SPADI: this.state.selectedValueS7,            
    Question_08_SPADI: this.state.selectedValueS8,
    Question_09_SPADI: this.state.selectedValueS9,
    Question_10_SPADI: this.state.selectedValueS10,
    Question_11_SPADI: this.state.selectedValueS11,
    Question_12_SPADI: this.state.selectedValueS12,
    Question_13_SPADI: this.state.selectedValueS13,
    Total_Score_SPADI: (((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValueS7+this.state.selectedValueS8+this.state.selectedValueS9+this.state.selectedValueS10+this.state.selectedValueS11+this.state.selectedValueS12+this.state.selectedValueS13))/(130)*100).toFixed(1),         
  });      
}      
  this.setState({
    clinician_to_share_with: '',
    user_id: '',
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.props.clinicianSendingEmail + '. You may now close this alert and the entire window.');   
}  
    
      
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("spadi_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
 
    myCallbackS1  = (dataFromChild) => {
    this.setState({selectedValueS1: dataFromChild});
    }
    myCallbackS2  = (dataFromChild) => {
    this.setState({selectedValueS2: dataFromChild});
    }
    myCallbackS3  = (dataFromChild) => {
    this.setState({selectedValueS3: dataFromChild});
    }
    myCallbackS4  = (dataFromChild) => {
    this.setState({selectedValueS4: dataFromChild});
    }
    myCallbackS5  = (dataFromChild) => {
    this.setState({selectedValueS5: dataFromChild});
    }
    myCallbackS6  = (dataFromChild) => {
    this.setState({selectedValueS6: dataFromChild});
    }
    myCallbackS7  = (dataFromChild) => {
    this.setState({selectedValueS7: dataFromChild});
    }
    myCallbackS8  = (dataFromChild) => {
    this.setState({selectedValueS8: dataFromChild});
    }
    myCallbackS9  = (dataFromChild) => {
    this.setState({selectedValueS9: dataFromChild});
    }
    myCallbackS10  = (dataFromChild) => {
    this.setState({selectedValueS10: dataFromChild});
    }
    myCallbackS11  = (dataFromChild) => {
    this.setState({selectedValueS11: dataFromChild});
    }
    myCallbackS12  = (dataFromChild) => {
    this.setState({selectedValueS12: dataFromChild});
    }
    myCallbackS13  = (dataFromChild) => {
    this.setState({selectedValueS13: dataFromChild});
    }
  
    
    

    
render() { 

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>Shoulder Pain and Disability Index Calculator</h1>
                <h4>Hello, {this.props.submitterName}, your clinician, {this.props.clinicianSendingEmail}, has sent you this survey to help further understand your health. Please click the appropriate buttons below and then click "Submit Score". Please note that your <u>digital privacy is taken very seriously</u> and a number of technologies have been implemented to protect the privacy and integrity of your data, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Your data <u>will never be shared with anyone other than the assigned clinician</u>.</h4>

            <form onSubmit={this.handleSubmit}>



                <StickyContainer>
                  <Sticky>
                    {({ style }) => <div className="sticky_style" style={style}><Well bsSize="small">Total SPADI Score: {(this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValueS7+this.state.selectedValueS8+this.state.selectedValueS9+this.state.selectedValueS10+this.state.selectedValueS11+this.state.selectedValueS12+this.state.selectedValueS13)} / {130
                    } = {((((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValueS7+this.state.selectedValueS8+this.state.selectedValueS9+this.state.selectedValueS10+this.state.selectedValueS11+this.state.selectedValueS12+this.state.selectedValueS13))/(130
                    )*100).toFixed(1))} %
                    <p/>
                    Graphical Total SPADI Score: (%)
                    <ProgressBar>
                    <ProgressBar active now={100-(100*(((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValueS7+this.state.selectedValueS8+this.state.selectedValueS9+this.state.selectedValueS10+this.state.selectedValueS11+this.state.selectedValueS12+this.state.selectedValueS13))/(130
                    )))}/>
                    <ProgressBar active bsStyle="danger" now={(100*(((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValueS7+this.state.selectedValueS8+this.state.selectedValueS9+this.state.selectedValueS10+this.state.selectedValueS11+this.state.selectedValueS12+this.state.selectedValueS13))/(130
                    )))}/>
                    </ProgressBar>    
                    </Well>
                    </div>}

                  </Sticky> 
                <p />
                    
                <br/>
                <br/>                      

                    <h3>Pain scale
                    </h3>
                    <h4>How severe is your pain?
                    </h4>
                    <h5>Circle the number that best describes your pain where: 0 = no pain and 10 = the worst pain imaginable
                    </h5>    
                    <Slider1 callbackFromParentS1={this.myCallbackS1}/>
                    <Slider2 callbackFromParentS2={this.myCallbackS2}/>
                    <Slider3 callbackFromParentS3={this.myCallbackS3}/>
                    <Slider4 callbackFromParentS4={this.myCallbackS4}/>
                    <Slider5 callbackFromParentS5={this.myCallbackS5}/>
                    <br /><Well bsSize="small">Total Pain Score: {(this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5)} / {50
                    } = {((((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5))/(50
                    )*100).toFixed(1))} %</Well>

                    <h3>Disability scale
                    </h3>
                    <h4>How much difficulty do you have? 
                    </h4>
                    <h5>Circle the number that best describes your experience where: 0 = no difficulty and 10 = so difficult it requires help
                    </h5>    
                    <Slider6 callbackFromParentS6={this.myCallbackS6}/>
                    <Slider7 callbackFromParentS7={this.myCallbackS7}/>
                    <Slider8 callbackFromParentS8={this.myCallbackS8}/>
                    <Slider9 callbackFromParentS9={this.myCallbackS9}/>
                    <Slider10 callbackFromParentS10={this.myCallbackS10}/>
                    <Slider11 callbackFromParentS11={this.myCallbackS11}/>
                    <Slider12 callbackFromParentS12={this.myCallbackS12}/>
                    <Slider13 callbackFromParentS13={this.myCallbackS13}/>                        
                        
                </StickyContainer> 

                <br />
                    
              <button style = {{width: '100%'}}>Submit Score</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(SPADI_Link);
