import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
//const jsPDF = require('jspdf');
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
//import queryString from 'query-string'; don't think I'll use this line

///////////////////////////////////////////Calculator Methods
    

    
    
class Buttons1 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent1(value);
if (value === 0)
this.props.callbackFromParent1_text("Pain Intensity: Tolerate pain without medications");
if (value === 1)
this.props.callbackFromParent1_text("Pain Intensity: Can mange without medications");
if (value === 2)
this.props.callbackFromParent1_text("Pain Intensity: Medications provide complete relief");
if (value === 3)
this.props.callbackFromParent1_text("Pain Intensity: Medications provide moderate relief");
if (value === 4)
this.props.callbackFromParent1_text("Pain Intensity: Medications provide little relief");
if (value === 5)
this.props.callbackFromParent1_text("Pain Intensity: Medications provide no relief");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Pain Intensity</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can tolerate the pain I have without having to use pain medication.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain is bad, but I can manage without having to take pain medication.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain medication provides me with complete relief from pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain medication provides me with moderate relief from pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain medication provides me with little relief from pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain medication has no effect on my pain.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Pain Intensity</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can tolerate the pain I have without having to use pain medication.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} The pain is bad, but I can manage without having to take pain medication.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain medication provides me with complete relief from pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain medication provides me with moderate relief from pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain medication provides me with little relief from pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain medication has no effect on my pain.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons2 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent2(value);
if (value === 0)
this.props.callbackFromParent2_text("Personal Care: I can take care of myself normally");
if (value === 1)
this.props.callbackFromParent2_text("Personal Care: I can take care of myself normally it increases my pain");
if (value === 2)
this.props.callbackFromParent2_text("Personal Care: I am slow and careful and it is painful");
if (value === 3)
this.props.callbackFromParent2_text("Personal Care: I need help but can manage most of my care");
if (value === 4)
this.props.callbackFromParent2_text("Personal Care: I need help in most aspects of my care");
if (value === 5)
this.props.callbackFromParent2_text("Personal Care: I do not get dressed, wash with difficulty, and stay in bed");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Personal Care (e.g., Washing, Dressing)</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can take care of myself normally without causing increased pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can take care of myself normally, but it increases my pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} It is painful to take care of myself, and I am slow and careful.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I need help, but I am able to manage most of my personal care.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I need help every day in most aspects of my care.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do not get dressed, I wash with difficulty, and I stay in bed. </ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Personal Care (e.g., Washing, Dressing)</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can take care of myself normally without causing increased pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can take care of myself normally, but it increases my pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} It is painful to take care of myself, and I am slow and careful.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I need help, but I am able to manage most of my personal care.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I need help every day in most aspects of my care.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I do not get dressed, I wash with difficulty, and I stay in bed. </ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons3 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent3(value);
if (value === 0)
this.props.callbackFromParent3_text("Lifting: I can lift heavy weights without increased pain");
if (value === 1)
this.props.callbackFromParent3_text("Lifting: I can lift heavy weights but it increases pain");
if (value === 2)
this.props.callbackFromParent3_text("Lifting: I can lift heavy weights if they are conveniently positioned");
if (value === 3)
this.props.callbackFromParent3_text("Lifting: I can lift light weights if they are conveniently positioned");
if (value === 4)
this.props.callbackFromParent3_text("Lifting: I can lift only very light weights");
if (value === 5)
this.props.callbackFromParent3_text("Lifting: I cannot lift anything at all");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Lifting</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can lift heavy weights without increased pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can lift heavy weights, but it causes increased pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from lifting heavy weights off the floor, but I can manage if the weights are conveniently positioned (e.g., on a table).</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from lifting heavy weights, but I can manage light to medium weights if they are conveniently positioned.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can lift only very light weights.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot lift or carry anything at all.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Lifting</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can lift heavy weights without increased pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can lift heavy weights, but it causes increased pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from lifting heavy weights off the floor, but I can manage if the weights are conveniently positioned (e.g., on a table).</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from lifting heavy weights, but I can manage light to medium weights if they are conveniently positioned.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can lift only very light weights.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I cannot lift or carry anything at all.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons4 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent4(value);
if (value === 0)
this.props.callbackFromParent4_text("Walking: Pain does not prevent me from walking");
if (value === 1)
this.props.callbackFromParent4_text("Walking: Pain prevents me from walking more than 1 mile");
if (value === 2)
this.props.callbackFromParent4_text("Walking: Pain prevents me from walking more than 1/2 mile");
if (value === 3)
this.props.callbackFromParent4_text("Walking: Pain prevents me from walking more than 1/4 mile");
if (value === 4)
this.props.callbackFromParent4_text("Walking: I can walk only with crutches or a cane");
if (value === 5)
this.props.callbackFromParent4_text("Walking: I am in bed most of the time and have to crawl to the toilet");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Walking</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain does not prevent me from walking any distance.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from walking more than 1 mile. (1 mile = 1.6 km).</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from walking more than 1/2 mile.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from walking more than 1/4 mile.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can walk only with crutches or a cane.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I am in bed most of the time and have to crawl to the toilet.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Walking</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain does not prevent me from walking any distance.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from walking more than 1 mile. (1 mile = 1.6 km).</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from walking more than 1/2 mile.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from walking more than 1/4 mile.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can walk only with crutches or a cane.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I am in bed most of the time and have to crawl to the toilet.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons5 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent5(value);
if (value === 0)
this.props.callbackFromParent5_text("Sitting: I can sit in any chair as long as I like");
if (value === 1)
this.props.callbackFromParent5_text("Sitting: I can sit in my favorite chair as long as I like");
if (value === 2)
this.props.callbackFromParent5_text("Sitting: Pain prevents me from sitting for more than 1 hour");
if (value === 3)
this.props.callbackFromParent5_text("Sitting: Pain prevents me from sitting for more than 1/2 hour");
if (value === 4)
this.props.callbackFromParent5_text("Sitting: Pain prevents me from sitting for more than 10 minutes");
if (value === 5)
this.props.callbackFromParent5_text("Sitting: Pain prevents me from sitting at all");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Sitting</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can sit in any chair as long as I like.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can only sit in my favorite chair as long as I like.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from sitting for more than 1 hour.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from sitting for more than 1/2 hour.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from sitting for more than 10 minutes.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from sitting at all.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Sitting</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can sit in any chair as long as I like.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can only sit in my favorite chair as long as I like.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from sitting for more than 1 hour.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from sitting for more than 1/2 hour.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from sitting for more than 10 minutes.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from sitting at all.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons6 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent6(value);
if (value === 0)
this.props.callbackFromParent6_text("Standing: I can stand as long as I want without increased pain");
if (value === 1)
this.props.callbackFromParent6_text("Standing: I can stand as long as I want with increased pain");
if (value === 2)
this.props.callbackFromParent6_text("Standing: Pain prevents me from standing for more than 1 hour");
if (value === 3)
this.props.callbackFromParent6_text("Standing: Pain prevents me from standing for more than 1/2 hour");
if (value === 4)
this.props.callbackFromParent6_text("Standing: Pain prevents me from standing for more than 10 minutes");
if (value === 5)
this.props.callbackFromParent6_text("Standing: Pain prevents me from standing at all");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Standing</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can stand as long as I want without increased pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can stand as long as I want, but it increases my pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from standing for more than 1 hour.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from standing for more than 1/2 hour.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from standing for more than 10 minutes.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from standing at all.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Standing</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can stand as long as I want without increased pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can stand as long as I want, but it increases my pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from standing for more than 1 hour.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from standing for more than 1/2 hour.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from standing for more than 10 minutes.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from standing at all.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons7 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent7(value);
if (value === 0)
this.props.callbackFromParent7_text("Sleeping: Pain does not prevent me from sleeping well");
if (value === 1)
this.props.callbackFromParent7_text("Sleeping: I can sleep well only with pain medication");
if (value === 2)
this.props.callbackFromParent7_text("Sleeping: Even with pain medication, I sleep less than 6 hours");
if (value === 3)
this.props.callbackFromParent7_text("Sleeping: Even with pain medication, I sleep less than 4 hours");
if (value === 4)
this.props.callbackFromParent7_text("Sleeping: Even with pain medication, I sleep less than 2 hours");
if (value === 5)
this.props.callbackFromParent7_text("Sleeping: Pain prevents me from sleeping at all");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Sleeping</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain does not prevent me from sleeping well.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can sleep well only by using pain medication.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Even when I take medication, I sleep less than 6 hours.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Even when I take medication, I sleep less than 4 hours.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Even when I take medication, I sleep less than 2 hours.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from sleeping at all.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Sleeping</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain does not prevent me from sleeping well.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can sleep well only by using pain medication.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Even when I take medication, I sleep less than 6 hours.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Even when I take medication, I sleep less than 4 hours.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Even when I take medication, I sleep less than 2 hours.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from sleeping at all.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons8 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent8(value);
if (value === 0)
this.props.callbackFromParent8_text("Social Life: Normal and does not increase pain");
if (value === 1)
this.props.callbackFromParent8_text("Social Life: Normal but does increase pain");
if (value === 2)
this.props.callbackFromParent8_text("Social Life: Pain prevents from doing energetic activities");
if (value === 3)
this.props.callbackFromParent8_text("Social Life: Pain prevents from going out very often");
if (value === 4)
this.props.callbackFromParent8_text("Social Life: Pain restricts my social life to home");
if (value === 5)
this.props.callbackFromParent8_text("Social Life: I hardly have a social life because of pain");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Social Life</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My social life is normal and does not increase my pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My social life is normal, but it increases my level of pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from participating in more energetic activities (e.g., sports, dancing).</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from going out very often.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain has restricted my social life to my home.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have hardly any social life because of my pain.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Social Life</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My social life is normal and does not increase my pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My social life is normal, but it increases my level of pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from participating in more energetic activities (e.g., sports, dancing).</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from going out very often.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain has restricted my social life to my home.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I have hardly any social life because of my pain.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons9 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent9(value);
if (value === 0)
this.props.callbackFromParent9_text("Traveling: Can travel anywhere without increased pain");
if (value === 1)
this.props.callbackFromParent9_text("Traveling: Can travel anywhere but with increased pain");
if (value === 2)
this.props.callbackFromParent9_text("Traveling:  Pain restricts travel over 2 hours");
if (value === 3)
this.props.callbackFromParent9_text("Traveling: Pain restricts travel over 1 hour");
if (value === 4)
this.props.callbackFromParent9_text("Traveling: Pain restricts travel to short necessary journeys under 1/2 hour");
if (value === 5)
this.props.callbackFromParent9_text("Traveling: Pain restricts travel to health care visits");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Traveling</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can travel anywhere without increased pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can travel anywhere, but it increases my pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My pain restricts my travel over 2 hours.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My pain restricts my travel over 1 hour.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My pain restricts my travel to short necessary journeys under 1/2 hour.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My pain prevents all travel except for visits to the physician or therapist or hospital. </ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Traveling</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can travel anywhere without increased pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can travel anywhere, but it increases my pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My pain restricts my travel over 2 hours.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My pain restricts my travel over 1 hour.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My pain restricts my travel to short necessary journeys under 1/2 hour.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My pain prevents all travel except for visits to the physician or therapist or hospital. </ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons10 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent10(value);
if (value === 0)
this.props.callbackFromParent10_text("Employment/Homemaking: Normal activities do not cause pain");
if (value === 1)
this.props.callbackFromParent10_text("Employment/Homemaking: Normal activities do cause pain but can do them");
if (value === 2)
this.props.callbackFromParent10_text("Employment/Homemaking:  Pain prevents more physically stressful activities");
if (value === 3)
this.props.callbackFromParent10_text("Employment/Homemaking: Pain prevents anything but light duties");
if (value === 4)
this.props.callbackFromParent10_text("Employment /Homemaking: Pain prevents even light duties");
if (value === 5)
this.props.callbackFromParent10_text("Employment/Homemaking: Pain prevents any job or homemaking chores");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Employment/Homemaking</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My normal homemaking/job activities do not cause pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My normal homemaking/job activities increase my pain, but I can still perform all that is required of me. </ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can perform most of my homemaking/job duties, but pain prevents me from performing more physically stressful activities (e.g., lifting, vacuuming). </ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from doing anything but light duties.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from doing even light duties.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from performing any job/homemaking chores. </ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Employment/Homemaking</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My normal homemaking/job activities do not cause pain.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} My normal homemaking/job activities increase my pain, but I can still perform all that is required of me. </ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can perform most of my homemaking/job duties, but pain prevents me from performing more physically stressful activities (e.g., lifting, vacuuming). </ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from doing anything but light duties.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from doing even light duties.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Pain prevents me from performing any job/homemaking chores. </ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}  


  


class ODI extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

        
        
        
        
    //Calculator variables    
    selectedValue1: 0,
    selectedValue1_text: 'Pain Intensity: Tolerate pain without medications',

    selectedValue2: 0,
    selectedValue2_text: 'Personal Care: I can take care of myself normally',

    selectedValue3: 0,
    selectedValue3_text: 'Lifting: I can lift heavy weights without increased pain',

    selectedValue4: 0,
    selectedValue4_text: 'Walking: Pain does not prevent me from walking',

    selectedValue5: 0,
    selectedValue5_text: 'Sitting: I can sit in any chair as long as I like',

    selectedValue6: 0,
    selectedValue6_text: 'Standing: I can stand as long as I want without increased pain',

    selectedValue7: 0,
    selectedValue7_text: 'Sleeping: Pain does not prevent me from sleeping well',

    selectedValue8: 0,
    selectedValue8_text: 'Social Life: Normal and does not increase pain',

    selectedValue9: 0,
    selectedValue9_text: 'Traveling: Can travel anywhere without increased pain',

    selectedValue10: 0,
    selectedValue10_text: 'Employment/Homemaking: Normal activities do not cause pain',        
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const ODIScoresRef = firebase.firestore();
  ODIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  ODIScoresRef.collection("odi_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const ODIScoresRef = firebase.firestore();
  ODIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  ODIScoresRef.collection("odi_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const ODIScoresRef = firebase.firestore();
  ODIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  ODIScoresRef.collection("odi_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const ODIScoresRef = firebase.firestore();
  ODIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  ODIScoresRef.collection("odi_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const ODIScoresRef = firebase.firestore();
  ODIScoresRef.settings({
      timestampsInSnapshots: true
  });
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){    
  const odi_score = ODIScoresRef.collection("odi_scores").doc(this.props.requestSurveyID).set({
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'modified Oswestry Disability Index',      
    Question_01_ODI: this.state.selectedValue1,
    Question_02_ODI: this.state.selectedValue2,
    Question_03_ODI: this.state.selectedValue3,
    Question_04_ODI: this.state.selectedValue4,
    Question_05_ODI: this.state.selectedValue5,
    Question_06_ODI: this.state.selectedValue6,
    Question_07_ODI: this.state.selectedValue7,            
    Question_08_ODI: this.state.selectedValue8,
    Question_09_ODI: this.state.selectedValue9,
    Question_10_ODI: this.state.selectedValue10,
    Total_Score_ODI: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10),     
  });
}
else {
  const odi_score = ODIScoresRef.collection("odi_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'modified Oswestry Disability Index',      
    Question_01_ODI: this.state.selectedValue1,
    Question_02_ODI: this.state.selectedValue2,
    Question_03_ODI: this.state.selectedValue3,
    Question_04_ODI: this.state.selectedValue4,
    Question_05_ODI: this.state.selectedValue5,
    Question_06_ODI: this.state.selectedValue6,
    Question_07_ODI: this.state.selectedValue7,            
    Question_08_ODI: this.state.selectedValue8,
    Question_09_ODI: this.state.selectedValue9,
    Question_10_ODI: this.state.selectedValue10,
    Total_Score_ODI: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10),    
  });      
}     
  this.setState({
    clinician_to_share_with: '',
    user_id: '',        
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.props.clinicianSendingEmail + '. You may now close this alert and the entire window.');      
}  
    
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("odi_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallback1  = (dataFromChild) => {
    this.setState({selectedValue1: dataFromChild});
    }
    myCallback1_text  = (dataFromChild) => {
    this.setState({selectedValue1_text: dataFromChild});
    }
    myCallback2  = (dataFromChild) => {
    this.setState({selectedValue2: dataFromChild});
    }
    myCallback2_text  = (dataFromChild) => {
    this.setState({selectedValue2_text: dataFromChild});
    }
    myCallback3  = (dataFromChild) => {
    this.setState({selectedValue3: dataFromChild});
    }
    myCallback3_text  = (dataFromChild) => {
    this.setState({selectedValue3_text: dataFromChild});
    }
    myCallback4  = (dataFromChild) => {
    this.setState({selectedValue4: dataFromChild});
    }
    myCallback4_text  = (dataFromChild) => {
    this.setState({selectedValue4_text: dataFromChild});
    }
    myCallback5  = (dataFromChild) => {
    this.setState({selectedValue5: dataFromChild});
    }
    myCallback5_text  = (dataFromChild) => {
    this.setState({selectedValue5_text: dataFromChild});
    }
    myCallback6  = (dataFromChild) => {
    this.setState({selectedValue6: dataFromChild});
    }
    myCallback6_text  = (dataFromChild) => {
    this.setState({selectedValue6_text: dataFromChild});
    }
    myCallback7  = (dataFromChild) => {
    this.setState({selectedValue7: dataFromChild});
    }
    myCallback7_text  = (dataFromChild) => {
    this.setState({selectedValue7_text: dataFromChild});
    }
    myCallback8  = (dataFromChild) => {
    this.setState({selectedValue8: dataFromChild});
    }
    myCallback8_text  = (dataFromChild) => {
    this.setState({selectedValue8_text: dataFromChild});
    }
    myCallback9  = (dataFromChild) => {
    this.setState({selectedValue9: dataFromChild});
    }
    myCallback9_text  = (dataFromChild) => {
    this.setState({selectedValue9_text: dataFromChild});
    }
    myCallback10  = (dataFromChild) => {
    this.setState({selectedValue10: dataFromChild});
    }
    myCallback10_text  = (dataFromChild) => {
    this.setState({selectedValue10_text: dataFromChild});
    }     
  

  
    
    

    
render() { 

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>Modified Oswestry Low Back Pain Disability Questionnaire Calculator</h1>
                <h4>Hello, {this.props.submitterName}, your clinician, {this.props.clinicianSendingEmail}, has sent you this survey to help further understand your health. Please click the appropriate buttons below and then click "Submit Score". Please note that your <u>digital privacy is taken very seriously</u> and a number of technologies have been implemented to protect the privacy and integrity of your data, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Your data <u>will never be shared with anyone other than the assigned clinician</u>.</h4>

            <form onSubmit={this.handleSubmit}>



                <StickyContainer>
                <Sticky>
                {({ style }) => <div className="sticky_style" style={style}><Well bsSize="small">Modified Oswestry Low Back Pain Disability Questionnaire: {(this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10)} / {50
                } = {((((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10))/(50
                )*100).toFixed(1))} % <br/>
                Graphical Modified Oswestry Low Back Pain Disability Questionnaire: (%)
                <ProgressBar>
                <ProgressBar active now={100-(100*(((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10))/(50
                )))}/>
                <ProgressBar active bsStyle="danger" now={(100*(((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10))/(50
                )))}/>
                </ProgressBar>
                </Well></div>}
                </Sticky>
                <p />
                <br/>
                <br/>                     
                    
                <h4>Instructions: This questionnaire has been designed to give your therapist information as to how your back pain has affected your ability to manage in everyday life. Please answer every question by placing a mark in the one box that best describes your condition today. We realize you may feel that two of the statements may describe your condition, but please mark only the box that most closely describes your current condition. 
                </h4>
  
                <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
                <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
                <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
                <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
                <Buttons5 callbackFromParent5={this.myCallback5} callbackFromParent5_text={this.myCallback5_text}/>
                <Buttons6 callbackFromParent6={this.myCallback6} callbackFromParent6_text={this.myCallback6_text}/>
                <Buttons7 callbackFromParent7={this.myCallback7} callbackFromParent7_text={this.myCallback7_text}/>
                <Buttons8 callbackFromParent8={this.myCallback8} callbackFromParent8_text={this.myCallback8_text}/>
                <Buttons9 callbackFromParent9={this.myCallback9} callbackFromParent9_text={this.myCallback9_text}/>
                <Buttons10 callbackFromParent10={this.myCallback10} callbackFromParent10_text={this.myCallback10_text}/>
                </StickyContainer>
                <br />
                    
              <button style = {{width: '100%'}}>Submit Score</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(ODI);


{/*


handleSubmit(e) {
  e.preventDefault();  
  const ODIScoresRef = firebase.firestore();
  ODIScoresRef.settings({
      timestampsInSnapshots: true
  });  
  const odi_score = ODIScoresRef.collection("odi_scores").add({
    shared_with: this.state.clinician_to_share_with,
    user: this.state.user_id,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    Question_01_ODI: this.state.selectedValue1,
    Question_02_ODI: this.state.selectedValue2,
    Question_03_ODI: this.state.selectedValue3,
    Question_04_ODI: this.state.selectedValue4,
    Question_05_ODI: this.state.selectedValue5,
    Question_06_ODI: this.state.selectedValue6,
    Question_07_ODI: this.state.selectedValue7,            
    Question_08_ODI: this.state.selectedValue8,
    Question_09_ODI: this.state.selectedValue9,
    Question_10_ODI: this.state.selectedValue10,
    Total_Score_ODI: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10),     
  });
  this.setState({
    clinician_to_share_with: '',
    user_id: '',
    selectedValue1: 0,
    selectedValue2: 0,
    selectedValue3: 0,
    selectedValue4: 0,
    selectedValue5: 0,
    selectedValue6: 0,
    selectedValue7: 0,
    selectedValue8: 0,
    selectedValue9: 0,                  
    selectedValue10: 0,        
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.state.clinician_to_share_with + '.');   
    
}  

*/}