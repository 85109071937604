import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
//const jsPDF = require('jspdf');
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
//import queryString from 'query-string'; don't think I'll use this line

///////////////////////////////////////////Calculator Methods

class Buttons1 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent1(value);
if (value === 0)
this.props.callbackFromParent1_text("No tengo dolor en este momento");
if (value === 1)
this.props.callbackFromParent1_text("El dolor es muy leve en este momento");
if (value === 2)
this.props.callbackFromParent1_text("The pain is moderate at the moment");
if (value === 3)
this.props.callbackFromParent1_text("The pain is fairly severe at the moment");
if (value === 4)
this.props.callbackFromParent1_text("The pain is very severe at the moment");
if (value === 5)
this.props.callbackFromParent1_text("The pain is the worst imaginable at the moment");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Pregunta I: Intensidad Del Dolor de Cuello</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No tengo dolor en este momento</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor es muy leve en este momento</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor es moderado en este momento</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor es fuerte en este momento</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor es muy fuerte en este momento</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} En este momento el dolor es el peor que uno se puede imaginar</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Pregunta I: Intensidad Del Dolor de Cuello</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No tengo dolor en este momento</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor es muy leve en este momento</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor es moderado en este momento</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor es fuerte en este momento</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor es muy fuerte en este momento</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} En este momento el dolor es el peor que uno se puede imaginar</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons2 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent2(value);
if (value === 0)
this.props.callbackFromParent2_text("Puedo cuidarme con normalidad sin que me aumente el dolor");
if (value === 1)
this.props.callbackFromParent2_text("Puedo cuidarme con normalidad, pero esto me aumenta el dolor");
if (value === 2)
this.props.callbackFromParent2_text("Cuidarme me duele de forma que tengo que hacerlo despacio y con cuidado");
if (value === 3)
this.props.callbackFromParent2_text("Aunque necesito alguna ayuda, me las arreglo para casi todos mis cuidados");
if (value === 4)
this.props.callbackFromParent2_text("Todos los días necesito ayuda para la mayor parte de mis cuidados");
if (value === 5)
this.props.callbackFromParent2_text("No puedo vestirme, me lavo con dificultad y me quedo en la cama");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Pregunta II: Cuidados Personales (Lavarse, Vestirse, etc.)</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo cuidarme con normalidad sin que me aumente el dolor</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo cuidarme con normalidad, pero esto me aumenta el dolor</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Cuidarme me duele de forma que tengo que hacerlo despacio y con cuidado</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Aunque necesito alguna ayuda, me las arreglo para casi todos mis cuidados</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Todos los días necesito ayuda para la mayor parte de mis cuidados</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo vestirme, me lavo con dificultad y me quedo en la cama</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Pregunta II: Cuidados Personales (Lavarse, Vestirse, etc.)</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo cuidarme con normalidad sin que me aumente el dolor</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo cuidarme con normalidad, pero esto me aumenta el dolor</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Cuidarme me duele de forma que tengo que hacerlo despacio y con cuidado</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Aunque necesito alguna ayuda, me las arreglo para casi todos mis cuidados</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Todos los días necesito ayuda para la mayor parte de mis cuidados</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo vestirme, me lavo con dificultad y me quedo en la cama</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons3 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent3(value);
if (value === 0)
this.props.callbackFromParent3_text("Puedo levantar objetos pesados sin aumento del dolor");
if (value === 1)
this.props.callbackFromParent3_text("Puedo levantar objetos pesados, pero me aumenta el dolor");
if (value === 2)
this.props.callbackFromParent3_text("El dolor me impide levantar objetos pesados del suelo, pero lo puedo hacer si están colocados en un sitio fácil como, por ejemplo, en una mesa");
if (value === 3)
this.props.callbackFromParent3_text("El dolor me impide levantar objetos pesados del suelo, pero puedo levantar objetos medianos o ligeros si están colocados en un sitio fácil");
if (value === 4)
this.props.callbackFromParent3_text("Sólo puedo levantar objetos muy ligeros");
if (value === 5)
this.props.callbackFromParent3_text("No puedo levantar ni llevar ningún tipo de peso");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Pregunta III: Levantar Pesos</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo levantar objetos pesados sin aumento del dolor</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo levantar objetos pesados, pero me aumenta el dolor</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor me impide levantar objetos pesados del suelo, pero lo puedo hacer si están colocados en un sitio fácil como, por ejemplo, en una mesa</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor me impide levantar objetos pesados del suelo, pero puedo levantar objetos medianos o ligeros si están colocados en un sitio fácil</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sólo puedo levantar objetos muy ligeros</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo levantar ni llevar ningún tipo de peso</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Pregunta III: Levantar Pesos</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo levantar objetos pesados sin aumento del dolor</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo levantar objetos pesados, pero me aumenta el dolor</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor me impide levantar objetos pesados del suelo, pero lo puedo hacer si están colocados en un sitio fácil como, por ejemplo, en una mesa</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor me impide levantar objetos pesados del suelo, pero puedo levantar objetos medianos o ligeros si están colocados en un sitio fácil</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sólo puedo levantar objetos muy ligeros</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo levantar ni llevar ningún tipo de peso</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons4 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent4(value);
if (value === 0)
this.props.callbackFromParent4_text("Puedo leer todo lo que quiera sin que me duela el cuello");
if (value === 1)
this.props.callbackFromParent4_text("Puedo leer todo lo que quiera con un dolor leve en el cuello");
if (value === 2)
this.props.callbackFromParent4_text("Puedo leer todo lo que quiera con un dolor moderado en el cuello");
if (value === 3)
this.props.callbackFromParent4_text("No puedo leer todo lo que quiero debido a un dolor moderado en el cuello");
if (value === 4)
this.props.callbackFromParent4_text("Apenas puedo leer por el gran dolor que me produce en el cuello");
if (value === 5)
this.props.callbackFromParent4_text("No puedo leer nada en absoluto");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Pregunta IV: Lectura</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo leer todo lo que quiera sin que me duela el cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo leer todo lo que quiera con un dolor leve en el cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo leer todo lo que quiera con un dolor moderado en el cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo leer todo lo que quiero debido a un dolor moderado en el cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Apenas puedo leer por el gran dolor que me produce en el cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo leer nada en absoluto</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Pregunta IV: Lectura</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo leer todo lo que quiera sin que me duela el cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo leer todo lo que quiera con un dolor leve en el cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo leer todo lo que quiera con un dolor moderado en el cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo leer todo lo que quiero debido a un dolor moderado en el cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Apenas puedo leer por el gran dolor que me produce en el cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo leer nada en absoluto</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons5 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent5(value);
if (value === 0)
this.props.callbackFromParent5_text("No tengo ningún dolor de cabeza");
if (value === 1)
this.props.callbackFromParent5_text("A veces tengo un pequeño dolor de cabeza");
if (value === 2)
this.props.callbackFromParent5_text("A veces tengo un dolor moderado de cabeza");
if (value === 3)
this.props.callbackFromParent5_text("Con frecuencia tengo un dolor moderado de cabeza");
if (value === 4)
this.props.callbackFromParent5_text("Con frecuencia tengo un dolor fuerte de cabeza");
if (value === 5)
this.props.callbackFromParent5_text("Tengo dolor de cabeza casi continuo");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Pregunta V: Dolor de Cabeza</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No tengo ningún dolor de cabeza</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A veces tengo un pequeño dolor de cabeza</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A veces tengo un dolor moderado de cabeza</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Con frecuencia tengo un dolor moderado de cabeza</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Con frecuencia tengo un dolor fuerte de cabeza</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Tengo dolor de cabeza casi continuo</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Pregunta V: Dolor de Cabeza</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No tengo ningún dolor de cabeza</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A veces tengo un pequeño dolor de cabeza</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A veces tengo un dolor moderado de cabeza</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Con frecuencia tengo un dolor moderado de cabeza</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Con frecuencia tengo un dolor fuerte de cabeza</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Tengo dolor de cabeza casi continuo</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons6 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent6(value);
if (value === 0)
this.props.callbackFromParent6_text("Me concentro totalmente en algo cuando quiero sin dificultad");
if (value === 1)
this.props.callbackFromParent6_text("Me concentro totalmente en algo cuando quiero con alguna dificultad");
if (value === 2)
this.props.callbackFromParent6_text("Tengo alguna dificultad para concentrarme cuando quiero");
if (value === 3)
this.props.callbackFromParent6_text("Tengo bastante dificultad para concentrarme cuando quiero");
if (value === 4)
this.props.callbackFromParent6_text("Tengo mucha dificultad para concentrarme cuando quiero");
if (value === 5)
this.props.callbackFromParent6_text("No puedo concentrarme nunca");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Pregunta VI: Concentrarse en Algo</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Me concentro totalmente en algo cuando quiero sin dificultad</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Me concentro totalmente en algo cuando quiero con alguna dificultad</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Tengo alguna dificultad para concentrarme cuando quiero</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Tengo bastante dificultad para concentrarme cuando quiero</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Tengo mucha dificultad para concentrarme cuando quiero</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo concentrarme nunca</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Pregunta VI: Concentrarse en Algo</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Me concentro totalmente en algo cuando quiero sin dificultad</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Me concentro totalmente en algo cuando quiero con alguna dificultad</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Tengo alguna dificultad para concentrarme cuando quiero</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Tengo bastante dificultad para concentrarme cuando quiero</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Tengo mucha dificultad para concentrarme cuando quiero</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo concentrarme nunca</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons7 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent7(value);
if (value === 0)
this.props.callbackFromParent7_text("Puedo trabajar todo lo que quiero");
if (value === 1)
this.props.callbackFromParent7_text("Puedo hacer mi trabajo habitual, pero no más");
if (value === 2)
this.props.callbackFromParent7_text("Puedo hacer casi todo mi trabajo habitual, pero no más");
if (value === 3)
this.props.callbackFromParent7_text("No puedo hacer mi trabajo habitual");
if (value === 4)
this.props.callbackFromParent7_text("A duras penas puedo hacer algún tipo de trabajo");
if (value === 5)
this.props.callbackFromParent7_text("No puedo trabajar en nada");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Pregunta VII: Trabajo y Actividades Habituales</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo trabajar todo lo que quiero</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo hacer mi trabajo habitual, pero no más</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo hacer casi todo mi trabajo habitual, pero no más</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo hacer mi trabajo habitual</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A duras penas puedo hacer algún tipo de trabajo</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo trabajar en nada</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Pregunta VII: Trabajo y Actividades Habituales</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo trabajar todo lo que quiero</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo hacer mi trabajo habitual, pero no más</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo hacer casi todo mi trabajo habitual, pero no más</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo hacer mi trabajo habitual</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A duras penas puedo hacer algún tipo de trabajo</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo trabajar en nada</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons8 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent8(value);
if (value === 0)
this.props.callbackFromParent8_text("Puedo conducir sin dolor de cuello");
if (value === 1)
this.props.callbackFromParent8_text("Puedo conducir todo lo que quiero, pero con un ligero dolor de cuello");
if (value === 2)
this.props.callbackFromParent8_text("Puedo conducir todo lo que quiero, pero con un moderado dolor de cuello");
if (value === 3)
this.props.callbackFromParent8_text("No puedo conducir todo lo que quiero debido al dolor de cuello");
if (value === 4)
this.props.callbackFromParent8_text("Apenas puedo conducir debido al intenso dolor de cuello");
if (value === 5)
this.props.callbackFromParent8_text("I cannot drive my car at all");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Pregunta VIII: Conducción de Vehículos</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo conducir sin dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo conducir todo lo que quiero, pero con un ligero dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo conducir todo lo que quiero, pero con un moderado dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo conducir todo lo que quiero debido al dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Apenas puedo conducir debido al intenso dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo conducir nada por el dolor de cuello</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Pregunta VIII: Conducción de Vehículos</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo conducir sin dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo conducir todo lo que quiero, pero con un ligero dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo conducir todo lo que quiero, pero con un moderado dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo conducir todo lo que quiero debido al dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Apenas puedo conducir debido al intenso dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo conducir nada por el dolor de cuello</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons9 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent9(value);
if (value === 0)
this.props.callbackFromParent9_text("No tengo ningún problema para dormir");
if (value === 1)
this.props.callbackFromParent9_text("El dolor de cuello me hace perder menos de 1 hora de sueño cada noche");
if (value === 2)
this.props.callbackFromParent9_text("El dolor de cuello me hace perder de 1 a 2 horas de sueño cada noche");
if (value === 3)
this.props.callbackFromParent9_text("El dolor de cuello me hace perder de 2 a 3 horas de sueño cada noche");
if (value === 4)
this.props.callbackFromParent9_text("El dolor de cuello me hace perder de 3 a 5 horas de sueño cada noche");
if (value === 5)
this.props.callbackFromParent9_text("El dolor de cuello me hace perder de 5 a 7 horas de sueño cada noche");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Pregunta IX: Sueño</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No tengo ningún problema para dormir</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor de cuello me hace perder menos de 1 hora de sueño cada noche</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor de cuello me hace perder de 1 a 2 horas de sueño cada noche</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor de cuello me hace perder de 2 a 3 horas de sueño cada noche</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor de cuello me hace perder de 3 a 5 horas de sueño cada noche</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor de cuello me hace perder de 5 a 7 horas de sueño cada noche</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Pregunta IX: Sueño</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No tengo ningún problema para dormir</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor de cuello me hace perder menos de 1 hora de sueño cada noche</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor de cuello me hace perder de 1 a 2 horas de sueño cada noche</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor de cuello me hace perder de 2 a 3 horas de sueño cada noche</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor de cuello me hace perder de 3 a 5 horas de sueño cada noche</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} El dolor de cuello me hace perder de 5 a 7 horas de sueño cada noche</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons10 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent10(value);
if (value === 0)
this.props.callbackFromParent10_text("Puedo hacer todas mis actividades de ocio sin dolor de cuello");
if (value === 1)
this.props.callbackFromParent10_text("Puedo hacer todas mis actividades de ocio con algún dolor de cuello");
if (value === 2)
this.props.callbackFromParent10_text("No puedo hacer algunas de mis actividades de ocio por el dolor de cuello");
if (value === 3)
this.props.callbackFromParent10_text("Sólo puedo hacer unas pocas actividades de ocio por el dolor del cuello");
if (value === 4)
this.props.callbackFromParent10_text("Apenas puedo hacer las cosas que me gustan debido al dolor del cuello");
if (value === 5)
this.props.callbackFromParent10_text("No puedo realizar ninguna actividad de ocio");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Pregunta X: Actividades de Ocio</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo hacer todas mis actividades de ocio sin dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo hacer todas mis actividades de ocio con algún dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo hacer algunas de mis actividades de ocio por el dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sólo puedo hacer unas pocas actividades de ocio por el dolor del cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Apenas puedo hacer las cosas que me gustan debido al dolor del cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} I can't do any recreation activities at all</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Pregunta X: Actividades de Ocio</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo hacer todas mis actividades de ocio sin dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Puedo hacer todas mis actividades de ocio con algún dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo hacer algunas de mis actividades de ocio por el dolor de cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Sólo puedo hacer unas pocas actividades de ocio por el dolor del cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Apenas puedo hacer las cosas que me gustan debido al dolor del cuello</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No puedo realizar ninguna actividad de ocio</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
    


  


class NDI_Link_Spanish extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

    //Calculator variables    
    selectedValue1: 0,
    selectedValue1_text: 'No tengo dolor en este momento',

    selectedValue2: 0,
    selectedValue2_text: 'Puedo cuidarme con normalidad sin que me aumente el dolor',

    selectedValue3: 0,
    selectedValue3_text: 'Puedo levantar objetos pesados sin aumento del dolor',

    selectedValue4: 0,
    selectedValue4_text: 'Puedo leer todo lo que quiera sin que me duela el cuello',

    selectedValue5: 0,
    selectedValue5_text: 'No tengo ningún dolor de cabeza',

    selectedValue6: 0,
    selectedValue6_text: 'Me concentro totalmente en algo cuando quiero sin dificultad',

    selectedValue7: 0,
    selectedValue7_text: 'Puedo trabajar todo lo que quiero',

    selectedValue8: 0,
    selectedValue8_text: 'Puedo conducir sin dolor de cuello',

    selectedValue9: 0,
    selectedValue9_text: 'No tengo ningún problema para dormir',

    selectedValue10: 0,
    selectedValue10_text: 'Puedo hacer todas mis actividades de ocio sin dolor de cuello',      
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  NDIScoresRef.collection("ndi_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  NDIScoresRef.collection("ndi_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  NDIScoresRef.collection("ndi_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  NDIScoresRef.collection("ndi_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });  
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){    
  const ndi_score = NDIScoresRef.collection("ndi_scores").doc(this.props.requestSurveyID).set({
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Spanish Neck Disability Index',      
    Question_01_NDI: this.state.selectedValue1,
    Question_02_NDI: this.state.selectedValue2,
    Question_03_NDI: this.state.selectedValue3,
    Question_04_NDI: this.state.selectedValue4,
    Question_05_NDI: this.state.selectedValue5,
    Question_06_NDI: this.state.selectedValue6,
    Question_07_NDI: this.state.selectedValue7,            
    Question_08_NDI: this.state.selectedValue8,
    Question_09_NDI: this.state.selectedValue9,
    Question_10_NDI: this.state.selectedValue10,
    Total_Score_NDI: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10),     
  });
}
else {
  const ndi_score = NDIScoresRef.collection("ndi_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'Spanish Neck Disability Index',      
    Question_01_NDI: this.state.selectedValue1,
    Question_02_NDI: this.state.selectedValue2,
    Question_03_NDI: this.state.selectedValue3,
    Question_04_NDI: this.state.selectedValue4,
    Question_05_NDI: this.state.selectedValue5,
    Question_06_NDI: this.state.selectedValue6,
    Question_07_NDI: this.state.selectedValue7,            
    Question_08_NDI: this.state.selectedValue8,
    Question_09_NDI: this.state.selectedValue9,
    Question_10_NDI: this.state.selectedValue10,
    Total_Score_NDI: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10),  
  });      
}     
  this.setState({
    clinician_to_share_with: '',
    user_id: '',        
  });
    alert('¡Gracias por usar OrthoPowerTools! Se recibió su envío y se compartirá con el siguiente proveedor: '+ this.props.clinicianSendingEmail +'. Ahora puede cerrar esta alerta y toda la ventana.');    
}      
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("ndi_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallback1  = (dataFromChild) => {
    this.setState({selectedValue1: dataFromChild});
    }
    myCallback1_text  = (dataFromChild) => {
    this.setState({selectedValue1_text: dataFromChild});
    }
    myCallback2  = (dataFromChild) => {
    this.setState({selectedValue2: dataFromChild});
    }
    myCallback2_text  = (dataFromChild) => {
    this.setState({selectedValue2_text: dataFromChild});
    }
    myCallback3  = (dataFromChild) => {
    this.setState({selectedValue3: dataFromChild});
    }
    myCallback3_text  = (dataFromChild) => {
    this.setState({selectedValue3_text: dataFromChild});
    }
    myCallback4  = (dataFromChild) => {
    this.setState({selectedValue4: dataFromChild});
    }
    myCallback4_text  = (dataFromChild) => {
    this.setState({selectedValue4_text: dataFromChild});
    }
    myCallback5  = (dataFromChild) => {
    this.setState({selectedValue5: dataFromChild});
    }
    myCallback5_text  = (dataFromChild) => {
    this.setState({selectedValue5_text: dataFromChild});
    }
    myCallback6  = (dataFromChild) => {
    this.setState({selectedValue6: dataFromChild});
    }
    myCallback6_text  = (dataFromChild) => {
    this.setState({selectedValue6_text: dataFromChild});
    }
    myCallback7  = (dataFromChild) => {
    this.setState({selectedValue7: dataFromChild});
    }
    myCallback7_text  = (dataFromChild) => {
    this.setState({selectedValue7_text: dataFromChild});
    }
    myCallback8  = (dataFromChild) => {
    this.setState({selectedValue8: dataFromChild});
    }
    myCallback8_text  = (dataFromChild) => {
    this.setState({selectedValue8_text: dataFromChild});
    }
    myCallback9  = (dataFromChild) => {
    this.setState({selectedValue9: dataFromChild});
    }
    myCallback9_text  = (dataFromChild) => {
    this.setState({selectedValue9_text: dataFromChild});
    }
    myCallback10  = (dataFromChild) => {
    this.setState({selectedValue10: dataFromChild});
    }
    myCallback10_text  = (dataFromChild) => {
    this.setState({selectedValue10_text: dataFromChild});
    }     
  

  
    
    

    
render() { 

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>El Índice de Discapacidad Cervical Calculadora</h1>
                <h4>Hola, {this.props.submitterName}, su médico, {this.props.clinicianSendingEmail}, le envió esta encuesta para ayudarlo a comprender mejor su salud. Haga clic en los botones correspondientes a continuación y luego haga clic en "Enviar Puntuación". Tenga en cuenta que su <u> privacidad digital se toma muy en serio </u> y que se han implementado varias tecnologías para proteger la privacidad y la integridad de sus datos, incluido el uso de cifrado SSL, almacenamiento compatible con HIPAA y servicios de autenticación de última generación. Sus datos <u>nunca se compartirán con nadie más que el médico asignado</u>.</h4>

            <form onSubmit={this.handleSubmit}>



                <StickyContainer>
                <Sticky>
                {({ style }) => <div className="sticky_style" style={style}><Well bsSize="small">El Índice de Discapacidad Cervical: {(this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10)} / {50
                } = {((((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10))/(50
                )*100).toFixed(1))} % <br/>
                Gráfica El Índice de Discapacidad Cervical: (%)
                <ProgressBar>
                <ProgressBar active now={100-(100*(((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10))/(50
                )))}/>
                <ProgressBar active bsStyle="danger" now={(100*(((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10))/(50
                )))}/>
                </ProgressBar>
                </Well></div>}
                </Sticky>
                <p />
                <br/>
                <br/>                     
                    
                <h4>Este cuestionario ha sido diseñado para aportarnos información sobre cuánto interfiere el dolor de cuello en sus actividades cotidianas. Todas las secciones y frases se refieren exclusivamente a las limitaciones por el dolor de cuello que está padeciendo actualmente (no a las que haya podido padecer en fases previas más o menos intensas que la actual) 
                </h4>
  
                <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
                <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
                <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
                <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
                <Buttons5 callbackFromParent5={this.myCallback5} callbackFromParent5_text={this.myCallback5_text}/>
                <Buttons6 callbackFromParent6={this.myCallback6} callbackFromParent6_text={this.myCallback6_text}/>
                <Buttons7 callbackFromParent7={this.myCallback7} callbackFromParent7_text={this.myCallback7_text}/>
                <Buttons8 callbackFromParent8={this.myCallback8} callbackFromParent8_text={this.myCallback8_text}/>
                <Buttons9 callbackFromParent9={this.myCallback9} callbackFromParent9_text={this.myCallback9_text}/>
                <Buttons10 callbackFromParent10={this.myCallback10} callbackFromParent10_text={this.myCallback10_text}/>
                </StickyContainer>
                <br />
                    
              <button style = {{width: '100%'}}>Enviar Puntuación</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(NDI_Link_Spanish);
