import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
//const jsPDF = require('jspdf');
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
//import queryString from 'query-string'; don't think I'll use this line
import {Polar} from "react-chartjs-2";


///////////////////////////////////////////Calculator Methods


class Buttons1 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent1(value);
    if (value === 100)
        this.props.callbackFromParent1_text('Excellent general health');
    if (value === 75)
        this.props.callbackFromParent1_text('Very good general health');
    if (value === 50)
        this.props.callbackFromParent1_text('Good general health');
    if (value === 25)
        this.props.callbackFromParent1_text('Fair general health');
    if (value === 0)
        this.props.callbackFromParent1_text('Poor general health');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h4>1. In general, would you say your health is:</h4>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={10}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Excellent (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Very good (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Good (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Fair (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poor (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h4>1. In general, would you say your health is:</h4>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={1}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Excellent (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Very good (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Good (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Fair (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Poor (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
  </div>
  );
  }
}

class Buttons2 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent2(value);
    if (value === 100)
        this.props.callbackFromParent2_text('Much better health than one year ago');
    if (value === 75)
        this.props.callbackFromParent2_text('Somewhat better health than one year ago');
    if (value === 50)
        this.props.callbackFromParent2_text('About the same health as one year ago');
    if (value === 25)
        this.props.callbackFromParent2_text('Somewhat worse health than one year ago');
    if (value === 0)
        this.props.callbackFromParent2_text('Much worse health than one year ago');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>2. <b>Compared to one year ago</b>, how would you rate your health in general <b>now</b>?</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={3}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Much better than one year ago (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Somewhat better than one year ago (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} About the same (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Somewhat worse now than one year ago (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Much worse than one year ago (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>2. <b>Compared to one year ago</b>, how would you rate your health in general <b>now</b>?</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={3}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Much better than one year ago (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Somewhat better than one year ago (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} About the same (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Somewhat worse now than one year ago (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Much worse than one year ago (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
  </div>
  );
  }
}


class Buttons3 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }
    

    someFn (value) {
    this.props.callbackFromParent3(value);
    if (value === 100)
        this.props.callbackFromParent3_text('Vigorous activites not limited');
    if (value === 50)
        this.props.callbackFromParent3_text('Vigorous activites limited a little');
    if (value === 0)
        this.props.callbackFromParent3_text('Vigorous activites limited a lot');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>3. <b>Vigorous activities</b>, such as running, lifting heavy objects, participating in strenuous sports.</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>3. <b>Vigorous activities</b>, such as running, lifting heavy objects, participating in strenuous sports.</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons4 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }
    

    someFn (value) {
    this.props.callbackFromParent4(value);
    if (value === 100)
        this.props.callbackFromParent4_text('Moderate activities not limited');
    if (value === 50)
        this.props.callbackFromParent4_text('Moderate activities limited a little');
    if (value === 0)
        this.props.callbackFromParent4_text('Moderate activities limited a lot');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>4. <b>Moderate activities</b>, such as moving a table, pushing a vacuum cleaner, bowling, or playing golf</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>4. <b>Moderate activities</b>, such as moving a table, pushing a vacuum cleaner, bowling, or playing golf</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons5 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent5(value);
    if (value === 100)
        this.props.callbackFromParent5_text('Lifting or carrying groceries limited not limited');
    if (value === 50)
        this.props.callbackFromParent5_text('Lifting or carrying groceries limited a little');
    if (value === 0)
        this.props.callbackFromParent5_text('Lifting or carrying groceries limited a lot');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>5. Lifting or carrying groceries</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>5. Lifting or carrying groceries</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons6 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }
    

    someFn (value) {
    this.props.callbackFromParent6(value);
    if (value === 100)
        this.props.callbackFromParent6_text('Climbing several flights of stairs not limited');
    if (value === 50)
        this.props.callbackFromParent6_text('Climbing several flights of stairs limited a little');
    if (value === 0)
        this.props.callbackFromParent6_text('Climbing several flights of stairs limited a lot');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>6. Climbing <b>several</b> flights of stairs.</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>6. Climbing <b>several</b> flights of stairs.</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons7 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }
    

    someFn (value) {
    this.props.callbackFromParent7(value);
    if (value === 100)
        this.props.callbackFromParent7_text('Climbing one flight of stairs not limited');
    if (value === 50)
        this.props.callbackFromParent7_text('Climbing one flight of stairs limited a little');
    if (value === 0)
        this.props.callbackFromParent7_text('Climbing one flight of stairs limited a lot');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>7. Climbing <b>one</b> flight of stairs.</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>7. Climbing <b>one</b> flight of stairs.</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons8 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }
    

    someFn (value) {
    this.props.callbackFromParent8(value);
    if (value === 100)
        this.props.callbackFromParent8_text('Bending/kneeling/stooping not limited');
    if (value === 50)
        this.props.callbackFromParent8_text('Bending/kneeling/stooping limited a little');
    if (value === 0)
        this.props.callbackFromParent8_text('Bending/kneeling/stooping limited a lot');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>8. Bending, kneeling, or stooping</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>8. Bending, kneeling, or stooping</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons9 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }
    

    someFn (value) {
    this.props.callbackFromParent9(value);
    if (value === 100)
        this.props.callbackFromParent9_text('Walking more than one mile not limited');
    if (value === 50)
        this.props.callbackFromParent9_text('Walking more than one mile limited a little');
    if (value === 0)
        this.props.callbackFromParent9_text('Walking more than one mile limited a lot');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>9. Walking <b>more than a mile</b></h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>9. Walking <b>more than a mile</b></h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons10 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }
    

    someFn (value) {
    this.props.callbackFromParent10(value);
    if (value === 100)
        this.props.callbackFromParent10_text('Walking several blocks not limited');
    if (value === 50)
        this.props.callbackFromParent10_text('Walking several blocks limited a little');
    if (value === 0)
        this.props.callbackFromParent10_text('Walking several blocks limited a lot');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>10. Walking <b>several blocks</b></h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>10. Walking <b>several blocks</b></h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons11 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }
    

    someFn (value) {
    this.props.callbackFromParent11(value);
    if (value === 100)
        this.props.callbackFromParent11_text('Walking one block not limited');
    if (value === 50)
        this.props.callbackFromParent11_text('Walking one block limited a little');
    if (value === 0)
        this.props.callbackFromParent11_text('Walking one block limited a lot');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>11. Walking <b>one block</b></h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>11. Walking <b>one block</b></h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons12 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }
    

    someFn (value) {
    this.props.callbackFromParent12(value);
    if (value === 100)
        this.props.callbackFromParent12_text('Bathing or dressing self not limited');
    if (value === 50)
        this.props.callbackFromParent12_text('Bathing or dressing limited a little');
    if (value === 0)
        this.props.callbackFromParent12_text('Bathing or dressing limited a lot');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>12. Bathing or dressing yourself.</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>12. Bathing or dressing yourself.</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a lot (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes, limited a little (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No, not limited at all (3)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons13 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }
    

    someFn (value) {
    this.props.callbackFromParent13(value);
    if (value === 100)
        this.props.callbackFromParent13_text('No decrease in time spent on work due to physical health');
    if (value === 0)
        this.props.callbackFromParent13_text('Decrease in time spent on work due to physical health');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <h5>13. Cut down the <b>amount of time</b> you spent on work or other activities.</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No (2)</ToggleButton>
    </ToggleButtonGroup>
	</div>
  );
  }
}




class Buttons14 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent14(value);
    if (value === 100)
        this.props.callbackFromParent14_text('Has not accomplished less than would like due to physical health');
    if (value === 0)
        this.props.callbackFromParent14_text('Has accomplished less than would like due to physical health');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <h5>14. <b>Accomplished less</b> than you would like</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No (2)</ToggleButton>
    </ToggleButtonGroup>
	</div>
  );
  }
}

class Buttons15 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent15(value);
    if (value === 100)
        this.props.callbackFromParent15_text('Was not limited in work due to physical health');
    if (value === 0)
        this.props.callbackFromParent15_text('Was limited in work due to physical health');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <h5>15. <b>Were limited in the kind</b> of work or other activities.</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No (2)</ToggleButton>
    </ToggleButtonGroup>
	</div>
  );
  }
}


class Buttons16 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent16(value);
    if (value === 100)
        this.props.callbackFromParent16_text('No difficulty performing work due to physical health');
    if (value === 0)
        this.props.callbackFromParent16_text('Difficulty performing work due to physical health');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <h5>16. Had <b>difficulty</b> performing the work or other activities (for example, it took extra effort).</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No (2)</ToggleButton>
    </ToggleButtonGroup>
	</div>
  );
  }
}


class Buttons17 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent17(value);
    if (value === 100)
        this.props.callbackFromParent17_text('No decrease in time spent on work due to emotional problems');
    if (value === 0)
        this.props.callbackFromParent17_text('Decrease in time spent on work due to emotional problems');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <h5>17. Cut down the <b>amount of time</b> you spent on work or other activities.</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No (2)</ToggleButton>
    </ToggleButtonGroup>
	</div>
  );
  }
}

class Buttons18 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent18(value);
    if (value === 100)
        this.props.callbackFromParent18_text('Has not accomplished less than would like due to emotional problems');
    if (value === 0)
        this.props.callbackFromParent18_text('Has accomplished less than would like due to emotional problems');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <h5>18. <b>Accomplished less</b> than you would like.</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No (2)</ToggleButton>
    </ToggleButtonGroup>
	</div>
  );
  }
}

class Buttons19 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent19(value);
    if (value === 100)
        this.props.callbackFromParent19_text('No difference in carefulness of work due to emotional problems');
    if (value === 0)
        this.props.callbackFromParent19_text('Difference in carefulness of work due to emotional problems');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <h5>19. Didn't do work or other activities as <b>carefully</b> as usual.</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Yes (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} No (2)</ToggleButton>
    </ToggleButtonGroup>
	</div>
  );
  }
}

class Buttons20 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent20(value);
    if (value === 100)
        this.props.callbackFromParent20_text('No interference of health or emotional problems with social activities');
    if (value === 75)
        this.props.callbackFromParent20_text('Slight interference of health or emotional problems with social activities');
    if (value === 50)
        this.props.callbackFromParent20_text('Moderate interference of health or emotional problems with social activities');
    if (value === 25)
        this.props.callbackFromParent20_text('Quite a bit of interference of health or emotional problems with social activities');
    if (value === 0)
        this.props.callbackFromParent20_text('Extreme interference of health or emotional problems with social activities');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>20. During the <b>past 4 weeks</b>, to what extent has your physical health or emotional problems interfered with your normal social activities with family, friends, neighbors, or groups?</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={3}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Not at all (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Slightly (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderately (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Quite a bit (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Extremely (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>20. During the <b>past 4 weeks</b>, to what extent has your physical health or emotional problems interfered with your normal social activities with family, friends, neighbors, or groups?</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={3}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Not at all (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Slightly (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderately (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Quite a bit (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Extremely (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}


class Buttons21 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent21(value);
    if (value === 100)
        this.props.callbackFromParent21_text('No bodily pain');
    if (value === 80)
        this.props.callbackFromParent21_text('Very mild bodily pain');
    if (value === 60)
        this.props.callbackFromParent21_text('Mild bodily pain');
    if (value === 40)
        this.props.callbackFromParent21_text('Moderate bodily pain');
    if (value === 20)
        this.props.callbackFromParent21_text('Severe bodily pain');
    if (value === 0)
        this.props.callbackFromParent21_text('Very severe bodily pain');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>21. How much <b>bodily</b> pain have you had during the <b>past 4 weeks</b>?</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={3}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Very mild (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mild (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderate (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Severe (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Very severe (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>21. How much <b>bodily</b> pain have you had during the <b>past 4 weeks</b>?</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={3}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Very mild (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mild (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderate (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Severe (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Very severe (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}


class Buttons22 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent22(value);
    if (value === 100)
        this.props.callbackFromParent22_text('No interference of pain with work');
    if (value === 75)
        this.props.callbackFromParent22_text('A little bit of interference of pain with work');
    if (value === 50)
        this.props.callbackFromParent22_text('Moderate interference of pain with work');
    if (value === 25)
        this.props.callbackFromParent22_text('Quite a bit of interference of pain with work');
    if (value === 0)
        this.props.callbackFromParent22_text('Extreme interference of pain with work');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>22. During the <b>past 4 weeks</b>, how much did pain interfere with your normal work (including both work outside the home and housework)?</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={3}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Not at all (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little bit (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderately (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Quite a bit (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Extremely (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>22. During the <b>past 4 weeks</b>, how much did pain interfere with your normal work (including both work outside the home and housework)?</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={3}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Not at all (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little bit (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Moderately (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Quite a bit (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Extremely (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}


class Buttons23 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent23(value);
    if (value === 100)
        this.props.callbackFromParent23_text('Always feels full of pep');
    if (value === 80)
        this.props.callbackFromParent23_text('Most of the time felt full of pep');
    if (value === 60)
        this.props.callbackFromParent23_text('A good bit of the time felt full of pep');
    if (value === 40)
        this.props.callbackFromParent23_text('Felt full of pep some of the time');
    if (value === 20)
        this.props.callbackFromParent23_text('Felt full of pep a little of the time');
    if (value === 0)
        this.props.callbackFromParent23_text('Never feels full of pep');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>23. Did you feel full of pep?</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>23. Did you feel full of pep?</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons24 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent24(value);
    if (value === 100)
        this.props.callbackFromParent24_text('Never a nervous person');
    if (value === 80)
        this.props.callbackFromParent24_text('A nervous person a little of the time');
    if (value === 60)
        this.props.callbackFromParent24_text('A nervous person some of the time');
    if (value === 40)
        this.props.callbackFromParent24_text('A nervous person a good bit of the time');
    if (value === 20)
        this.props.callbackFromParent24_text('A nervous person most of the time');
    if (value === 0)
        this.props.callbackFromParent24_text('Always a nervous person');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>24. Have you been a nervous person?</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>24. Have you been a nervous person?</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons25 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent25(value);
    if (value === 100)
        this.props.callbackFromParent25_text('Never felt so down in the dumps that nothing could cheer up');
    if (value === 80)
        this.props.callbackFromParent25_text('Felt so down in the dumps that nothing could cheer up a little of the time');
    if (value === 60)
        this.props.callbackFromParent25_text('Felt so down in the dumps that nothing could cheer up some of the time');
    if (value === 40)
        this.props.callbackFromParent25_text('Felt so down in the dumps that nothing could cheer up a good bit of the time');
    if (value === 20)
        this.props.callbackFromParent25_text('Felt so down in the dumps that nothing could cheer most of the time');
    if (value === 0)
        this.props.callbackFromParent25_text('Always felt so down in the dumps that nothing could cheer up');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>25. Have you felt so down in the dumps that nothing could cheer your up?</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>25. Have you felt so down in the dumps that nothing could cheer your up?</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}


class Buttons26 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent26(value);
    if (value === 100)
        this.props.callbackFromParent26_text('Always felt calm and peaceful');
    if (value === 80)
        this.props.callbackFromParent26_text('Felt calm and peaceful most of the time');
    if (value === 60)
        this.props.callbackFromParent26_text('Felt calm and peaceful a good bit of the time');
    if (value === 40)
        this.props.callbackFromParent26_text('Felt calm and peaceful some of the time');
    if (value === 20)
        this.props.callbackFromParent26_text('Felt calm and peaceful a little of the time');
    if (value === 0)
        this.props.callbackFromParent26_text('Never felt calm and peaceful');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>26. Have you felt calm and peaceful?</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>26. Have you felt calm and peaceful?</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}


class Buttons27 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent27(value);
    if (value === 100)
        this.props.callbackFromParent27_text('Always had a lot of energy');
    if (value === 80)
        this.props.callbackFromParent27_text('Had a lot of energy most of the time');
    if (value === 60)
        this.props.callbackFromParent27_text('Had a lot of energy a good bit of the time');
    if (value === 40)
        this.props.callbackFromParent27_text('Had a lot of energy some of the time');
    if (value === 20)
        this.props.callbackFromParent27_text('Had a lot of energy a little of the time');
    if (value === 0)
        this.props.callbackFromParent27_text('Never had a lot of energy');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>27. Did you have a lot of energy?</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>27. Did you have a lot of energy?</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons28 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent28(value);
    if (value === 100)
        this.props.callbackFromParent28_text('Never felt downhearted and blue');
    if (value === 80)
        this.props.callbackFromParent28_text('Felt downhearted and blue a little of the time');
    if (value === 60)
        this.props.callbackFromParent28_text('Felt downhearted and blue some of the time');
    if (value === 40)
        this.props.callbackFromParent28_text('Felt downhearted and blue a good bit of the time');
    if (value === 20)
        this.props.callbackFromParent28_text('Felt downhearted and blue most of the time');
    if (value === 0)
        this.props.callbackFromParent28_text('Always felt downhearted and blue');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>28. Have you felt downhearted and blue?</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>28. Have you felt downhearted and blue?</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons29 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
	this.props.callbackFromParent29(value);
    if (value === 100)
        this.props.callbackFromParent29_text('Never felt worn out');
    if (value === 80)
        this.props.callbackFromParent29_text('Felt worn out a little of the time');
    if (value === 60)
        this.props.callbackFromParent29_text('Felt worn out some of the time');
    if (value === 40)
        this.props.callbackFromParent29_text('Felt worn out a good bit of the time');
    if (value === 20)
        this.props.callbackFromParent29_text('Felt worn out most of the time');
    if (value === 0)
        this.props.callbackFromParent29_text('Always felt worn out');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>29. Did you feel worn out?</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>29. Did you feel worn out?</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons30 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent30(value);
    if (value === 100)
        this.props.callbackFromParent30_text('Always was a happy person');
    if (value === 80)
        this.props.callbackFromParent30_text('Was a happy person most of the time');
    if (value === 60)
        this.props.callbackFromParent30_text('Was a happy person a good bit of the time');
    if (value === 40)
        this.props.callbackFromParent30_text('Was a happy person some of the time');
    if (value === 20)
        this.props.callbackFromParent30_text('Was a happy person a little of the time');
    if (value === 0)
        this.props.callbackFromParent30_text('Never was a happy person');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>30. Have you been a happy person?</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">
    <h5>30. Have you been a happy person?</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons31 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent31(value);
    if (value === 100)
        this.props.callbackFromParent31_text('Never felt tired');
    if (value === 80)
        this.props.callbackFromParent31_text('Felt tired a little of the time');
    if (value === 60)
        this.props.callbackFromParent31_text('Felt tired some of the time');
    if (value === 40)
        this.props.callbackFromParent31_text('Felt tired a good bit of the time');
    if (value === 20)
        this.props.callbackFromParent31_text('Felt tired most of the time');
    if (value === 0)
        this.props.callbackFromParent31_text('Always felt tired');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>31. Did you feel tired?</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">    
    <h5>31. Did you feel tired?</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={20}> {(this.state.clicked === 20) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={40}> {(this.state.clicked === 40) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A good bit of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={60}> {(this.state.clicked === 60) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={80}> {(this.state.clicked === 80) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (5)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (6)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons32 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent32(value);
    if (value === 100)
        this.props.callbackFromParent32_text('Physical health or emotional problems did not interfere with social activities');
    if (value === 75)
        this.props.callbackFromParent32_text('Physical health or emotional problems interfered with social activities a little of the time');
    if (value === 50)
        this.props.callbackFromParent32_text('Physical health or emotional problems interfered with social activities some of the time');
    if (value === 25)
        this.props.callbackFromParent32_text('Physical health or emotional problems interfered with social activities most of the time');
    if (value === 0)
        this.props.callbackFromParent32_text('Physical health or emotional problems interfered with social activities all of the time');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>32. During the past <b>4 weeks</b>, how much of the time has <b>your physical health or emotional problems</b> interfered with your social activities (like visiting with friends, relatives, etc.)?</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">    
    <h5>32. During the past <b>4 weeks</b>, how much of the time has <b>your physical health or emotional problems</b> interfered with your social activities (like visiting with friends, relatives, etc.)?</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} All of the time (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Most of the time (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Some of the time (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} A little of the time (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} None of the time (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons33 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent33(value);
    if (value === 100)
        this.props.callbackFromParent33_text('"I seem to get sick a little easier than other people" -- definitely false');
    if (value === 75)
        this.props.callbackFromParent33_text('"I seem to get sick a little easier than other people" -- mostly false');
    if (value === 50)
        this.props.callbackFromParent33_text('"I seem to get sick a little easier than other people" -- don\'t know');
    if (value === 25)
        this.props.callbackFromParent33_text('"I seem to get sick a little easier than other people" -- mostly true');
    if (value === 0)
        this.props.callbackFromParent33_text('"I seem to get sick a little easier than other people" -- definitely true');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>33. I seem to get sick a little easier than other people</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely true (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly true (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Don't know (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly false (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely false (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">    
    <h5>33. I seem to get sick a little easier than other people</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely true (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly true (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Don't know (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly false (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely false (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons34 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent34(value);
    if (value === 100)
        this.props.callbackFromParent34_text('I am as healthy as anybody I know" -- definitely true');
    if (value === 75)
        this.props.callbackFromParent34_text('"I am as healthy as anybody I know" -- mostly true');
    if (value === 50)
        this.props.callbackFromParent34_text('"I am as healthy as anybody I know" -- don\'t know');
    if (value === 25)
        this.props.callbackFromParent34_text('"I am as healthy as anybody I know" -- mostly false');
    if (value === 0)
        this.props.callbackFromParent34_text('"I am as healthy as anybody I know" -- definitely false');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>34. I am as healthy as anybody I know</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely true (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly true (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Don't know (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly false (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely false (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">    
    <h5>34. I am as healthy as anybody I know</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely true (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly true (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Don't know (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly false (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely false (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons35 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent35(value);
    if (value === 100)
        this.props.callbackFromParent35_text('"I expect my health to get worse" -- definitely false');
    if (value === 75)
        this.props.callbackFromParent35_text('"I expect my health to get worse" -- mostly false');
    if (value === 50)
        this.props.callbackFromParent35_text('"I expect my health to get worse" -- don\'t know');
    if (value === 25)
        this.props.callbackFromParent35_text('"I expect my health to get worse" -- mostly true');
    if (value === 0)
        this.props.callbackFromParent35_text('"I expect my health to get worse" -- definitely true');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>35. I expect my health to get worse</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely true (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly true (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Don't know (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly false (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely false (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">    
    <h5>35. I expect my health to get worse</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely true (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly true (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Don't know (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly false (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely false (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}

class Buttons36 extends PureComponent {

    constructor(){
         super();
         this.state= {clicked: 100}
    }

    someFn (value) {
    this.props.callbackFromParent36(value);
    if (value === 100)
        this.props.callbackFromParent36_text('"My health is excellent" -- definitely true');
    if (value === 75)
        this.props.callbackFromParent36_text('"My health is excellent" -- mostly true');
    if (value === 50)
        this.props.callbackFromParent36_text('"My health is excellent" -- don\'t know');
    if (value === 25)
        this.props.callbackFromParent36_text('"My health is excellent" -- mostly false');
    if (value === 0)
        this.props.callbackFromParent36_text('"My health is excellent" -- definitely false');
    this.setState({clicked: value});
    }

  render() {
    return (
    <div>
    <div class="hidden-xs">
    <h5>36. My health is excellent</h5>
    <ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely true (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly true (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Don't know (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly false (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely false (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
    <div class="visible-xs">    
    <h5>36. My health is excellent</h5>
    <ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={100}>
    <ToggleButton bsStyle="btn-custom" value={100}> {(this.state.clicked === 100) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely true (1)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={75}> {(this.state.clicked === 75) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly true (2)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={50}> {(this.state.clicked === 50) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Don't know (3)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={25}> {(this.state.clicked === 25) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Mostly false (4)</ToggleButton>
    <ToggleButton bsStyle="btn-custom" value={0}> {(this.state.clicked === 0) ? <Glyphicon glyph="check" /> : <Glyphicon glyph="unchecked" />} Definitely false (5)</ToggleButton>
    </ToggleButtonGroup>
    </div>
	</div>
  );
  }
}    





  


class SF36_Link extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

        
        
        
        
    //Calculator variables    

    selectedValue1: 100,
    selectedValue1_text: 'Excellent general health',
    selectedValue2: 100,
    selectedValue2_text: 'Much better health than one year ago',
    selectedValue3: 100,
    selectedValue3_text: 'Vigorous activites not limited',
    selectedValue4: 100,
    selectedValue4_text: 'Moderate activities not limited',
    selectedValue5: 100,
    selectedValue5_text: 'Lifting or carrying groceries not limited',
    selectedValue6: 100,
    selectedValue6_text: 'Climbing several flights of stairs not limited',
    selectedValue7: 100,
    selectedValue7_text: 'Climbing one flight of stairs not limited',
    selectedValue8: 100,
    selectedValue8_text: 'Bending/kneeling/stooping not limited',
    selectedValue9: 100,
    selectedValue9_text: 'Walking more than one mile not limited',
    selectedValue10: 100,
    selectedValue10_text: 'Walking several blocks not limited',
    selectedValue11: 100,
    selectedValue11_text: 'Walking one block not limited',
    selectedValue12: 100,
    selectedValue12_text: 'Bathing or dressing self not limited',
    selectedValue13: 100,
    selectedValue13_text: 'No decrease in time spent on work due to physical health',
    selectedValue14: 100,
    selectedValue14_text: 'Has not accomplished less than would like due to physical health',
    selectedValue15: 100,
    selectedValue15_text: 'Was not limited in work due to physical health',
    selectedValue16: 100,
    selectedValue16_text: 'No difficulty performing work due to physical health',
    selectedValue17: 100,
    selectedValue17_text: 'No decrease in time spent on work due to emotional problems',
    selectedValue18: 100,
    selectedValue18_text: 'Has not accomplished less than would like due to emotional problems',
    selectedValue19: 100,
    selectedValue19_text: 'No difference in carefulness of work due to emotional problems',
    selectedValue20: 100,
    selectedValue20_text: 'No interference of health or emotional problems with social activities',
    selectedValue21: 100,
    selectedValue21_text: 'No bodily pain',
    selectedValue22: 100,
    selectedValue22_text: 'No interference of pain with work',
    selectedValue23: 100,
    selectedValue23_text: 'Always feels full of pep',
    selectedValue24: 100,
    selectedValue24_text: 'Never a nervous person',
    selectedValue25: 100,
    selectedValue25_text: 'Never felt so down in the dumps that nothing could cheer up',
    selectedValue26: 100,
    selectedValue26_text: 'Always felt calm and peaceful',
    selectedValue27: 100,
    selectedValue27_text: 'Always had a lot of energy',
    selectedValue28: 100,
    selectedValue28_text: 'Never felt downhearted and blue',
    selectedValue29: 100,
    selectedValue29_text: 'Never felt worn out',
    selectedValue30: 100,
    selectedValue30_text: 'Always was a happy person',
    selectedValue31: 100,
    selectedValue31_text: 'Never felt tired',
    selectedValue32: 100,
    selectedValue32_text: 'Physical health or emotional problems did not interfere with social activities',
    selectedValue33: 100,
    selectedValue33_text: '"I seem to get sick a little easier than other people" -- definitely false',
    selectedValue34: 100,
    selectedValue34_text: '"I am as healthy as anybody I know" -- definitely true',
    selectedValue35: 100,
    selectedValue35_text: '"I expect my health to get worse" -- definitely false',
    selectedValue36: 100,
    selectedValue36_text: '"My health is excellent" -- definitely true',                
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const SF36ScoresRef = firebase.firestore();
  SF36ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF36ScoresRef.collection("sf36_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const SF36ScoresRef = firebase.firestore();
  SF36ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF36ScoresRef.collection("sf36_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const SF36ScoresRef = firebase.firestore();
  SF36ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF36ScoresRef.collection("sf36_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const SF36ScoresRef = firebase.firestore();
  SF36ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF36ScoresRef.collection("sf36_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();    
  const SF36ScoresRef = firebase.firestore();
  SF36ScoresRef.settings({
      timestampsInSnapshots: true
  }); 
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){        
  const sf36_score = SF36ScoresRef.collection("sf36_scores").doc(this.props.requestSurveyID).set({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'SF36',
    Question_01_SF36: this.state.selectedValue1,
    Question_02_SF36: this.state.selectedValue2,
    Question_03_SF36: this.state.selectedValue3,
    Question_04_SF36: this.state.selectedValue4,
    Question_05_SF36: this.state.selectedValue5,
    Question_06_SF36: this.state.selectedValue6,
    Question_07_SF36: this.state.selectedValue7,            
    Question_08_SF36: this.state.selectedValue8,
    Question_09_SF36: this.state.selectedValue9,
    Question_10_SF36: this.state.selectedValue10,
    Question_11_SF36: this.state.selectedValue11,
    Question_12_SF36: this.state.selectedValue12,
    Question_13_SF36: this.state.selectedValue13,
    Question_14_SF36: this.state.selectedValue14,
    Question_15_SF36: this.state.selectedValue15,
    Question_16_SF36: this.state.selectedValue16,
    Question_17_SF36: this.state.selectedValue17,
    Question_18_SF36: this.state.selectedValue18,
    Question_19_SF36: this.state.selectedValue19,
    Question_20_SF36: this.state.selectedValue20,
    Question_21_SF36: this.state.selectedValue21,
    Question_22_SF36: this.state.selectedValue22,
    Question_23_SF36: this.state.selectedValue23,
    Question_24_SF36: this.state.selectedValue24,
    Question_25_SF36: this.state.selectedValue25,
    Question_26_SF36: this.state.selectedValue26,
    Question_27_SF36: this.state.selectedValue27,
    Question_28_SF36: this.state.selectedValue28,
    Question_29_SF36: this.state.selectedValue29,
    Question_30_SF36: this.state.selectedValue30,
    Question_31_SF36: this.state.selectedValue31,
    Question_32_SF36: this.state.selectedValue32,
    Question_33_SF36: this.state.selectedValue33,
    Question_34_SF36: this.state.selectedValue34,
    Question_35_SF36: this.state.selectedValue35,
    Question_36_SF36: this.state.selectedValue36,
    Physical_Functioning_Score_SF36:(Math.round((this.state.selectedValue3 + this.state.selectedValue4 + this.state.selectedValue5 + this.state.selectedValue6 + this.state.selectedValue7 + this.state.selectedValue8 + this.state.selectedValue9 + this.state.selectedValue10 + this.state.selectedValue11 + this.state.selectedValue12)*10/10)/10),
    Role_Limitations_Physical_Health_Score_SF36:(Math.round((this.state.selectedValue13 + this.state.selectedValue14 + this.state.selectedValue15 + this.state.selectedValue16)*10/4)/10),
    Role_Limitations_Emotional_Problems_Score_SF36:(Math.round((this.state.selectedValue17 + this.state.selectedValue18 + this.state.selectedValue19)*10/3)/10),
    Energy_Score_SF36:(Math.round((this.state.selectedValue23 + this.state.selectedValue27 + this.state.selectedValue29 + this.state.selectedValue31)*10/4)/10),
    Emotional_Well_Being_Score_SF36:(Math.round((this.state.selectedValue24 + this.state.selectedValue25 + this.state.selectedValue26 + this.state.selectedValue28 + this.state.selectedValue30)*10/5)/10),
    Social_Functioning_Score_SF36:(Math.round((this.state.selectedValue20 + this.state.selectedValue32)*10/2)/10),
    Pain_Score_SF36:(Math.round((this.state.selectedValue21 + this.state.selectedValue22)*10/2)/10),
    General_Health_Score_SF36:(Math.round((this.state.selectedValue1 + this.state.selectedValue33 + this.state.selectedValue34 + this.state.selectedValue35 + this.state.selectedValue36)*10/5)/10),
    Health_Change_Score_SF36:(Math.round((this.state.selectedValue2)*10)/10),       
  });
}
else {
  const sf36_score = SF36ScoresRef.collection("sf36_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'SF36',
    Question_01_SF36: this.state.selectedValue1,
    Question_02_SF36: this.state.selectedValue2,
    Question_03_SF36: this.state.selectedValue3,
    Question_04_SF36: this.state.selectedValue4,
    Question_05_SF36: this.state.selectedValue5,
    Question_06_SF36: this.state.selectedValue6,
    Question_07_SF36: this.state.selectedValue7,            
    Question_08_SF36: this.state.selectedValue8,
    Question_09_SF36: this.state.selectedValue9,
    Question_10_SF36: this.state.selectedValue10,
    Question_11_SF36: this.state.selectedValue11,
    Question_12_SF36: this.state.selectedValue12,
    Question_13_SF36: this.state.selectedValue13,
    Question_14_SF36: this.state.selectedValue14,
    Question_15_SF36: this.state.selectedValue15,
    Question_16_SF36: this.state.selectedValue16,
    Question_17_SF36: this.state.selectedValue17,
    Question_18_SF36: this.state.selectedValue18,
    Question_19_SF36: this.state.selectedValue19,
    Question_20_SF36: this.state.selectedValue20,
    Question_21_SF36: this.state.selectedValue21,
    Question_22_SF36: this.state.selectedValue22,
    Question_23_SF36: this.state.selectedValue23,
    Question_24_SF36: this.state.selectedValue24,
    Question_25_SF36: this.state.selectedValue25,
    Question_26_SF36: this.state.selectedValue26,
    Question_27_SF36: this.state.selectedValue27,
    Question_28_SF36: this.state.selectedValue28,
    Question_29_SF36: this.state.selectedValue29,
    Question_30_SF36: this.state.selectedValue30,
    Question_31_SF36: this.state.selectedValue31,
    Question_32_SF36: this.state.selectedValue32,
    Question_33_SF36: this.state.selectedValue33,
    Question_34_SF36: this.state.selectedValue34,
    Question_35_SF36: this.state.selectedValue35,
    Question_36_SF36: this.state.selectedValue36,
    Physical_Functioning_Score_SF36:(Math.round((this.state.selectedValue3 + this.state.selectedValue4 + this.state.selectedValue5 + this.state.selectedValue6 + this.state.selectedValue7 + this.state.selectedValue8 + this.state.selectedValue9 + this.state.selectedValue10 + this.state.selectedValue11 + this.state.selectedValue12)*10/10)/10),
    Role_Limitations_Physical_Health_Score_SF36:(Math.round((this.state.selectedValue13 + this.state.selectedValue14 + this.state.selectedValue15 + this.state.selectedValue16)*10/4)/10),
    Role_Limitations_Emotional_Problems_Score_SF36:(Math.round((this.state.selectedValue17 + this.state.selectedValue18 + this.state.selectedValue19)*10/3)/10),
    Energy_Score_SF36:(Math.round((this.state.selectedValue23 + this.state.selectedValue27 + this.state.selectedValue29 + this.state.selectedValue31)*10/4)/10),
    Emotional_Well_Being_Score_SF36:(Math.round((this.state.selectedValue24 + this.state.selectedValue25 + this.state.selectedValue26 + this.state.selectedValue28 + this.state.selectedValue30)*10/5)/10),
    Social_Functioning_Score_SF36:(Math.round((this.state.selectedValue20 + this.state.selectedValue32)*10/2)/10),
    Pain_Score_SF36:(Math.round((this.state.selectedValue21 + this.state.selectedValue22)*10/2)/10),
    General_Health_Score_SF36:(Math.round((this.state.selectedValue1 + this.state.selectedValue33 + this.state.selectedValue34 + this.state.selectedValue35 + this.state.selectedValue36)*10/5)/10),
    Health_Change_Score_SF36:(Math.round((this.state.selectedValue2)*10)/10),       
  });      
}  
  this.setState({
    clinician_to_share_with: '',
    user_id: '',     
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.props.clinicianSendingEmail + '. You may now close this alert and the entire window.');   
}  
    
     
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("sf36_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
myCallback1  = (dataFromChild) => {
this.setState({selectedValue1: dataFromChild});
}
myCallback1_text  = (dataFromChild) => {
this.setState({selectedValue1_text: dataFromChild});
}
myCallback2  = (dataFromChild) => {
this.setState({selectedValue2: dataFromChild});
}
myCallback2_text  = (dataFromChild) => {
this.setState({selectedValue2_text: dataFromChild});
}
myCallback3  = (dataFromChild) => {
this.setState({selectedValue3: dataFromChild});
}
myCallback3_text  = (dataFromChild) => {
this.setState({selectedValue3_text: dataFromChild});
}
myCallback4  = (dataFromChild) => {
this.setState({selectedValue4: dataFromChild});
}
myCallback4_text  = (dataFromChild) => {
this.setState({selectedValue4_text: dataFromChild});
}
myCallback5  = (dataFromChild) => {
this.setState({selectedValue5: dataFromChild});
}
myCallback5_text  = (dataFromChild) => {
this.setState({selectedValue5_text: dataFromChild});
}
myCallback6  = (dataFromChild) => {
this.setState({selectedValue6: dataFromChild});
}
myCallback6_text  = (dataFromChild) => {
this.setState({selectedValue6_text: dataFromChild});
}
myCallback7  = (dataFromChild) => {
this.setState({selectedValue7: dataFromChild});
}
myCallback7_text  = (dataFromChild) => {
this.setState({selectedValue7_text: dataFromChild});
}
myCallback8  = (dataFromChild) => {
this.setState({selectedValue8: dataFromChild});
}
myCallback8_text  = (dataFromChild) => {
this.setState({selectedValue8_text: dataFromChild});
}
myCallback9  = (dataFromChild) => {
this.setState({selectedValue9: dataFromChild});
}
myCallback9_text  = (dataFromChild) => {
this.setState({selectedValue9_text: dataFromChild});
}
myCallback10  = (dataFromChild) => {
this.setState({selectedValue10: dataFromChild});
}
myCallback10_text  = (dataFromChild) => {
this.setState({selectedValue10_text: dataFromChild});
}
myCallback11  = (dataFromChild) => {
this.setState({selectedValue11: dataFromChild});
}
myCallback11_text  = (dataFromChild) => {
this.setState({selectedValue11_text: dataFromChild});
}
myCallback12  = (dataFromChild) => {
this.setState({selectedValue12: dataFromChild});
}
myCallback12_text  = (dataFromChild) => {
this.setState({selectedValue12_text: dataFromChild});
}
myCallback13  = (dataFromChild) => {
this.setState({selectedValue13: dataFromChild});
}
myCallback13_text  = (dataFromChild) => {
this.setState({selectedValue13_text: dataFromChild});
}
myCallback14  = (dataFromChild) => {
this.setState({selectedValue14: dataFromChild});
}
myCallback14_text  = (dataFromChild) => {
this.setState({selectedValue14_text: dataFromChild});
}
myCallback15  = (dataFromChild) => {
this.setState({selectedValue15: dataFromChild});
}
myCallback15_text  = (dataFromChild) => {
this.setState({selectedValue15_text: dataFromChild});
}
myCallback16  = (dataFromChild) => {
this.setState({selectedValue16: dataFromChild});
}
myCallback16_text  = (dataFromChild) => {
this.setState({selectedValue16_text: dataFromChild});
}
myCallback17  = (dataFromChild) => {
this.setState({selectedValue17: dataFromChild});
}
myCallback17_text  = (dataFromChild) => {
this.setState({selectedValue17_text: dataFromChild});
}
myCallback18  = (dataFromChild) => {
this.setState({selectedValue18: dataFromChild});
}
myCallback18_text  = (dataFromChild) => {
this.setState({selectedValue18_text: dataFromChild});
}
myCallback19  = (dataFromChild) => {
this.setState({selectedValue19: dataFromChild});
}
myCallback19_text  = (dataFromChild) => {
this.setState({selectedValue19_text: dataFromChild});
}
myCallback20  = (dataFromChild) => {
this.setState({selectedValue20: dataFromChild});
}
myCallback20_text  = (dataFromChild) => {
this.setState({selectedValue20_text: dataFromChild});
}
myCallback21  = (dataFromChild) => {
this.setState({selectedValue21: dataFromChild});
}
myCallback21_text  = (dataFromChild) => {
this.setState({selectedValue21_text: dataFromChild});
}
myCallback22  = (dataFromChild) => {
this.setState({selectedValue22: dataFromChild});
}
myCallback22_text  = (dataFromChild) => {
this.setState({selectedValue22_text: dataFromChild});
}
myCallback23  = (dataFromChild) => {
this.setState({selectedValue23: dataFromChild});
}
myCallback23_text  = (dataFromChild) => {
this.setState({selectedValue23_text: dataFromChild});
}
myCallback24  = (dataFromChild) => {
this.setState({selectedValue24: dataFromChild});
}
myCallback24_text  = (dataFromChild) => {
this.setState({selectedValue24_text: dataFromChild});
}
myCallback25  = (dataFromChild) => {
this.setState({selectedValue25: dataFromChild});
}
myCallback25_text  = (dataFromChild) => {
this.setState({selectedValue25_text: dataFromChild});
}
myCallback26  = (dataFromChild) => {
this.setState({selectedValue26: dataFromChild});
}
myCallback26_text  = (dataFromChild) => {
this.setState({selectedValue26_text: dataFromChild});
}
myCallback27  = (dataFromChild) => {
this.setState({selectedValue27: dataFromChild});
}
myCallback27_text  = (dataFromChild) => {
this.setState({selectedValue27_text: dataFromChild});
}
myCallback28  = (dataFromChild) => {
this.setState({selectedValue28: dataFromChild});
}
myCallback28_text  = (dataFromChild) => {
this.setState({selectedValue28_text: dataFromChild});
}
myCallback29  = (dataFromChild) => {
this.setState({selectedValue29: dataFromChild});
}
myCallback29_text  = (dataFromChild) => {
this.setState({selectedValue29_text: dataFromChild});
}
myCallback30  = (dataFromChild) => {
this.setState({selectedValue30: dataFromChild});
}
myCallback30_text  = (dataFromChild) => {
this.setState({selectedValue30_text: dataFromChild});
}
myCallback31  = (dataFromChild) => {
this.setState({selectedValue31: dataFromChild});
}
myCallback31_text  = (dataFromChild) => {
this.setState({selectedValue31_text: dataFromChild});
}
myCallback32  = (dataFromChild) => {
this.setState({selectedValue32: dataFromChild});
}
myCallback32_text  = (dataFromChild) => {
this.setState({selectedValue32_text: dataFromChild});
}
myCallback33  = (dataFromChild) => {
this.setState({selectedValue33: dataFromChild});
}
myCallback33_text  = (dataFromChild) => {
this.setState({selectedValue33_text: dataFromChild});
}
myCallback34  = (dataFromChild) => {
this.setState({selectedValue34: dataFromChild});
}
myCallback34_text  = (dataFromChild) => {
this.setState({selectedValue34_text: dataFromChild});
}
myCallback35  = (dataFromChild) => {
this.setState({selectedValue35: dataFromChild});
}
myCallback35_text  = (dataFromChild) => {
this.setState({selectedValue35_text: dataFromChild});
}
myCallback36  = (dataFromChild) => {
this.setState({selectedValue36: dataFromChild});
}
myCallback36_text  = (dataFromChild) => {
this.setState({selectedValue36_text: dataFromChild});
}

  

  
    
    

    
render() { 
     
const data = {
  datasets: [{
    data: [
      Math.round((this.state.selectedValue3 + this.state.selectedValue4 + this.state.selectedValue5 + this.state.selectedValue6 + this.state.selectedValue7 + this.state.selectedValue8 + this.state.selectedValue9 + this.state.selectedValue10 + this.state.selectedValue11 + this.state.selectedValue12)*10/10)/10,
      Math.round((this.state.selectedValue13 + this.state.selectedValue14 + this.state.selectedValue15 + this.state.selectedValue16)*10/4)/10,
      Math.round((this.state.selectedValue17 + this.state.selectedValue18 + this.state.selectedValue19)*10/3)/10,
      Math.round((this.state.selectedValue23 + this.state.selectedValue27 + this.state.selectedValue29 + this.state.selectedValue31)*10/4)/10,
      Math.round((this.state.selectedValue24 + this.state.selectedValue25 + this.state.selectedValue26 + this.state.selectedValue28 + this.state.selectedValue30)*10/5)/10,
      Math.round((this.state.selectedValue20 + this.state.selectedValue32)*10/2)/10, 
      Math.round((this.state.selectedValue21 + this.state.selectedValue22)*10/2)/10,
      Math.round((this.state.selectedValue1 + this.state.selectedValue33 + this.state.selectedValue34 + this.state.selectedValue35 + this.state.selectedValue36)*10/5)/10,
      Math.round((this.state.selectedValue2)*10)/10,        
    ],
    backgroundColor: [
      '#d26060',
      '#7cd260',
      '#5E8DD2',
      '#d260d2',
      '#d29960',
      '#8899A6',
      "#60d2d2", 
      "#000000",
      "#9960d2",
    ],
    label: 'My dataset' // for legend
  }],
  labels: [
    'Physical functioning',
    'Role limitations (physical)',
    'Role limitations (emotional)',
    'Energy/fatigue',
    'Emotional well-being',
    'Social functioning',
    'Pain',
    'General health',
    'Health change',
  ],
  
};
    
    
  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>SF-36 Calculator</h1>
                <h4>Hello, {this.props.submitterName}, your clinician, {this.props.clinicianSendingEmail}, has sent you this survey to help further understand your health. Please click the appropriate buttons below and then click "Submit Score". Please note that your <u>digital privacy is taken very seriously</u> and a number of technologies have been implemented to protect the privacy and integrity of your data, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Your data <u>will never be shared with anyone other than the assigned clinician</u>.</h4>

            <form onSubmit={this.handleSubmit}>

                SF-36 Visual Score (%)
                <br />
      <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
      <div class="chart-container" style={{width:"60%", display: 'flex', justifyContent:'center', alignItems:'center'}} >  
      <Polar data={data}

        width={320} height={250}  


               options={{
                   responsive: false,
                   maintainAspectRatio: true,
                    legend: {
                             display: false
                           },                        
                   animation: {
                      animateRotate: true,
                      animateScale: true,                       
                   },
                   scale:{
                       ticks: {
                       display: true,
                       z:1,
                       },
                       gridLines:{
                           display: true,
                           circular: true,
                           z: 1,
                       }
                   }
                }}
        />
    </div>
    </div>

    <br/>


        <Well bsSize="small"><b>Physical functioning</b>: {Math.round((this.state.selectedValue3 + this.state.selectedValue4 + this.state.selectedValue5 + this.state.selectedValue6 + this.state.selectedValue7 + this.state.selectedValue8 + this.state.selectedValue9 + this.state.selectedValue10 + this.state.selectedValue11 + this.state.selectedValue12)*10/10)/10} % <br />
        <b>Role limitations due to physical health</b>: {Math.round((this.state.selectedValue13 + this.state.selectedValue14 + this.state.selectedValue15 + this.state.selectedValue16)*10/4)/10} % <br />
        <b>Role limitations due to emotional problems</b>: {Math.round((this.state.selectedValue17 + this.state.selectedValue18 + this.state.selectedValue19)*10/3)/10} % <br />
        <b>Energy/fatigue</b>: {Math.round((this.state.selectedValue23 + this.state.selectedValue27 + this.state.selectedValue29 + this.state.selectedValue31)*10/4)/10} % <br />
        <b>Emotional well-being</b>: {Math.round((this.state.selectedValue24 + this.state.selectedValue25 + this.state.selectedValue26 + this.state.selectedValue28 + this.state.selectedValue30)*10/5)/10} % <br />
        <b>Social functioning</b>: {Math.round((this.state.selectedValue20 + this.state.selectedValue32)*10/2)/10} % <br />
        <b>Pain</b>: {Math.round((this.state.selectedValue21 + this.state.selectedValue22)*10/2)/10} % <br />
        <b>General health</b>: {Math.round((this.state.selectedValue1 + this.state.selectedValue33 + this.state.selectedValue34 + this.state.selectedValue35 + this.state.selectedValue36)*10/5)/10} % <br />
		<b>Health change</b>: {Math.round((this.state.selectedValue2)*10)/10} % <br /></Well>        


                  
                  
                  
                  
                <p />

                <br/>                      
                
            
        <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
        <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
        <h4>The following items are about activities you might do during a typical day. Does <b>your health now limit you</b> in these activities? If so, how much?</h4>
        <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
        <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
        <Buttons5 callbackFromParent5={this.myCallback5} callbackFromParent5_text={this.myCallback5_text}/>
        <Buttons6 callbackFromParent6={this.myCallback6} callbackFromParent6_text={this.myCallback6_text}/>
        <Buttons7 callbackFromParent7={this.myCallback7} callbackFromParent7_text={this.myCallback7_text}/>
        <Buttons8 callbackFromParent8={this.myCallback8} callbackFromParent8_text={this.myCallback8_text}/>
        <Buttons9 callbackFromParent9={this.myCallback9} callbackFromParent9_text={this.myCallback9_text}/>
        <Buttons10 callbackFromParent10={this.myCallback10} callbackFromParent10_text={this.myCallback10_text}/>
        <Buttons11 callbackFromParent11={this.myCallback11} callbackFromParent11_text={this.myCallback11_text}/>
        <Buttons12 callbackFromParent12={this.myCallback12} callbackFromParent12_text={this.myCallback12_text}/>
		<h4>During the past <b>4 weeks</b>, have you had any of the following problems with your work or other regular daily activities <b>as a result of your physical health?</b></h4>
        <Buttons13 callbackFromParent13={this.myCallback13} callbackFromParent13_text={this.myCallback13_text}/>
        <Buttons14 callbackFromParent14={this.myCallback14} callbackFromParent14_text={this.myCallback14_text}/>
        <Buttons15 callbackFromParent15={this.myCallback15} callbackFromParent15_text={this.myCallback15_text}/>
        <Buttons16 callbackFromParent16={this.myCallback16} callbackFromParent16_text={this.myCallback16_text}/>
        <h4>During the <b>past 4 weeks</b>, have you had any of the following problems with your work or other regular daily activities <b>as a result of any emotional problems</b> (such as feeling depressed or anxious)?</h4>
        <Buttons17 callbackFromParent17={this.myCallback17} callbackFromParent17_text={this.myCallback17_text}/>
        <Buttons18 callbackFromParent18={this.myCallback18} callbackFromParent18_text={this.myCallback18_text}/>
        <Buttons19 callbackFromParent19={this.myCallback19} callbackFromParent19_text={this.myCallback19_text}/>
        <Buttons20 callbackFromParent20={this.myCallback20} callbackFromParent20_text={this.myCallback20_text}/>
        <Buttons21 callbackFromParent21={this.myCallback21} callbackFromParent21_text={this.myCallback21_text}/>
        <Buttons22 callbackFromParent22={this.myCallback22} callbackFromParent22_text={this.myCallback22_text}/>
        <h4>These questions are about how you feel and how things have been with you <b>during the past 4 weeks</b>. For each question, please give the one answer that comes closest to the way you have been feeling.</h4>
        <h4>How much of the time during the <b>past 4 weeks</b>...</h4>
        <Buttons23 callbackFromParent23={this.myCallback23} callbackFromParent23_text={this.myCallback23_text}/>
        <Buttons24 callbackFromParent24={this.myCallback24} callbackFromParent24_text={this.myCallback24_text}/>
        <Buttons25 callbackFromParent25={this.myCallback25} callbackFromParent25_text={this.myCallback25_text}/>
        <Buttons26 callbackFromParent26={this.myCallback26} callbackFromParent26_text={this.myCallback26_text}/>
        <Buttons27 callbackFromParent27={this.myCallback27} callbackFromParent27_text={this.myCallback27_text}/>
        <Buttons28 callbackFromParent28={this.myCallback28} callbackFromParent28_text={this.myCallback28_text}/>
        <Buttons29 callbackFromParent29={this.myCallback29} callbackFromParent29_text={this.myCallback29_text}/>
        <Buttons30 callbackFromParent30={this.myCallback30} callbackFromParent30_text={this.myCallback30_text}/>
        <Buttons31 callbackFromParent31={this.myCallback31} callbackFromParent31_text={this.myCallback31_text}/>
        <Buttons32 callbackFromParent32={this.myCallback32} callbackFromParent32_text={this.myCallback32_text}/>
        <h4>How TRUE or FALSE is <b>each</b> of the following statements for you.</h4>
        <Buttons33 callbackFromParent33={this.myCallback33} callbackFromParent33_text={this.myCallback33_text}/>
        <Buttons34 callbackFromParent34={this.myCallback34} callbackFromParent34_text={this.myCallback34_text}/>
        <Buttons35 callbackFromParent35={this.myCallback35} callbackFromParent35_text={this.myCallback35_text}/>
        <Buttons36 callbackFromParent36={this.myCallback36} callbackFromParent36_text={this.myCallback36_text}/>       


        <br />
            
                SF-36 Visual Score (%)
                <br />
      <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
      <div class="chart-container" style={{width:"60%", display: 'flex', justifyContent:'center', alignItems:'center'}} >  
      <Polar data={data}

        width={320} height={250}  


               options={{
                   responsive: false,
                   maintainAspectRatio: true,
                    legend: {
                             display: false
                           },                        
                   animation: {
                      animateRotate: true,
                      animateScale: true,                       
                   },
                   scale:{
                       ticks: {
                       display: true,
                       z:1,
                       },
                       gridLines:{
                           display: true,
                           circular: true,
                           z: 1,
                       }
                   }
                }}
        />
    </div>
    </div>

    <br/>


        <Well bsSize="small"><b>Physical functioning</b>: {Math.round((this.state.selectedValue3 + this.state.selectedValue4 + this.state.selectedValue5 + this.state.selectedValue6 + this.state.selectedValue7 + this.state.selectedValue8 + this.state.selectedValue9 + this.state.selectedValue10 + this.state.selectedValue11 + this.state.selectedValue12)*10/10)/10} % <br />
        <b>Role limitations due to physical health</b>: {Math.round((this.state.selectedValue13 + this.state.selectedValue14 + this.state.selectedValue15 + this.state.selectedValue16)*10/4)/10} % <br />
        <b>Role limitations due to emotional problems</b>: {Math.round((this.state.selectedValue17 + this.state.selectedValue18 + this.state.selectedValue19)*10/3)/10} % <br />
        <b>Energy/fatigue</b>: {Math.round((this.state.selectedValue23 + this.state.selectedValue27 + this.state.selectedValue29 + this.state.selectedValue31)*10/4)/10} % <br />
        <b>Emotional well-being</b>: {Math.round((this.state.selectedValue24 + this.state.selectedValue25 + this.state.selectedValue26 + this.state.selectedValue28 + this.state.selectedValue30)*10/5)/10} % <br />
        <b>Social functioning</b>: {Math.round((this.state.selectedValue20 + this.state.selectedValue32)*10/2)/10} % <br />
        <b>Pain</b>: {Math.round((this.state.selectedValue21 + this.state.selectedValue22)*10/2)/10} % <br />
        <b>General health</b>: {Math.round((this.state.selectedValue1 + this.state.selectedValue33 + this.state.selectedValue34 + this.state.selectedValue35 + this.state.selectedValue36)*10/5)/10} % <br />
		<b>Health change</b>: {Math.round((this.state.selectedValue2)*10)/10} % <br /></Well>        


                  
                  
                  
                  
                <p />

                <br/>              
                    
        <button style = {{width: '100%'}}>Submit Score</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(SF36_Link);

