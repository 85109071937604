import React, { PureComponent } from 'react';
import {Link} from "react-router-dom";
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import mainLogo from'../orthopowertools.png';

class Contact_Us extends PureComponent {
    
  constructor() {
    super();
    this.state = {
      user: null        
    }
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);      
  }
    
componentDidMount() {
   
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    } 
  });    
    
}    
    
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}    
    
    

    
render() {    
  return (
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>    

        <Link to="/" style={{padding:15}}>Home</Link>            

        </div>
        
      
      
      </header>     


        <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>

        <br/>
        <div style={{textAlign: "left"}}>
        <h3>Contact Us:</h3>
        <p/>   
        <br/>       
        <span>Thank you for your interest in OrthoToolKit and OrthoPowerTools! We appreciate hearing your comments, suggestions, and questions. We use this information to improve your experience. Additionally, if there is tool that you would like added to the website, please let us know.</span>
        <p/>
        <span >The best way to contact us is via email (</span><a href="mailto:orthopowertools@gmail.com"><span>orthopowertools@gmail.com</span></a><span>). We strive to read and address all communications!</span>
        </div> 
      
      
      </div>
      
    </div>      
  );
}    
    
    
}
export default Contact_Us;
