import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Slider, {createSliderWithTooltip} from 'rc-slider';

///////////////////////////////////////////Calculator Methods
const SliderWithTooltip = createSliderWithTooltip(Slider);

const wrapperStyle = {'max-width': '400px', 'margin-left': 'auto', 'margin-right': 'auto', 'margin-top': 40, 'margin-bottom': 60 };

const marks1 = {
		0: <strong>0<br />sin dolor</strong>,
		10: <strong>10<br />peor dolor</strong>,
	};

class Slider1 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS1(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>1. ¿En su peor momento?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks1} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks2 = {
		0: <strong>0<br />sin dolor</strong>,
		10: <strong>10<br />peor dolor</strong>,
	};

class Slider2 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS2(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>2. ¿Cuándo se tumba sobre ese lado?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks2} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks3 = {
		0: <strong>0<br />sin dolor</strong>,
		10: <strong>10<br />peor dolor</strong>,
	};

class Slider3 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS3(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>3. ¿Al alcanzar algo en un estante alto?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks3} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks4 = {
		0: <strong>0<br />sin dolor</strong>,
		10: <strong>10<br />peor dolor</strong>,
	};

class Slider4 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS4(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>4. ¿Al tocarse la parte posterior de su cuello?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks4} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks5 = {
		0: <strong>0<br />sin dolor</strong>,
		10: <strong>10<br />peor dolor</strong>,
	};

class Slider5 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS5(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>5. ¿Al empujar con el brazo afecto?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks5} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks6 = {
		0: <strong>0<br />sin dificultad</strong>,
		10: <strong>10<br />mayor dificultad</strong>,
	};

class Slider6 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS6(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>6. ¿Lavándose el pelo?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks6} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks7 = {
		0: <strong>0<br />sin dificultad</strong>,
		10: <strong>10<br />mayor dificultad</strong>,
	};

class Slider7 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS7(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>7. ¿Lavándose la espalda?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks7} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks8 = {
		0: <strong>0<br />sin dificultad</strong>,
		10: <strong>10<br />mayor dificultad</strong>,
	};

class Slider8 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS8(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>8. ¿Poniéndose una camiseta o un jersey?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks8} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks9 = {
		0: <strong>0<br />sin dificultad</strong>,
		10: <strong>10<br />mayor dificultad</strong>,
	};

class Slider9 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS9(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>9. ¿Poniéndose una camisa con los botones delante?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks9} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks10 = {
		0: <strong>0<br />sin dificultad</strong>,
		10: <strong>10<br />mayor dificultad</strong>,
	};

class Slider10 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS10(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>10. ¿Poniéndose los pantalones?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks10} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks11 = {
		0: <strong>0<br />sin dificultad</strong>,
		10: <strong>10<br />mayor dificultad</strong>,
	};

class Slider11 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS11(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>11. ¿Colocando un objeto en un estante alto?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks11} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks12 = {
		0: <strong>0<br />sin dificultad</strong>,
		10: <strong>10<br />mayor dificultad</strong>,
	};

class Slider12 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS12(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>12. ¿Llevando un objeto pesado (4.5 kg)?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks12} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}

const marks13 = {
		0: <strong>0<br />sin dificultad</strong>,
		10: <strong>10<br />mayor dificultad</strong>,
	};

class Slider13 extends PureComponent {

	constructor(){
		super();
		this.state= {position: 0}
	}

	someFn = (value) => {
		this.props.callbackFromParentS13(value);
		this.setState({position: value});
	}

	render() {
	return (
		<div>
		<h5><b>13. ¿Cogiendo algo de su bolsillo trasero?</b></h5>
		<div style={wrapperStyle}>
		<SliderWithTooltip min = {0} max = {10} defaultValue={0} onAfterChange = {(value) => this.someFn(value)} tipProps = {{visible: 'true', placement: "top"}} marks = {marks13} trackStyle={{ backgroundColor: '#5f8dd3'}} handleStyle={[{borderColor: '#5f8dd3'}]} dotStyle = {{borderColor: '#5f8dd3'}} />
		</div>
		</div>
	);
	}
	}
 
 


  


class SPADI_Link_Spanish extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

        
        
        
        
    //Calculator variables    
    selectedValueS1: 0,
    selectedValueS1_text: 'Dolor en su peor momento: ',

    selectedValueS2: 0,
    selectedValueS2_text: 'Dolor al acostarse sobre el lado afectado: ',

    selectedValueS3: 0,
    selectedValueS3_text: 'Dolor al alcanzar algo en un estante alto: ',

    selectedValueS4: 0,
    selectedValueS4_text: 'Dolor al tocar la nuca: ',

    selectedValueS5: 0,
    selectedValueS5_text: 'Dolor al empujar con el brazo afectado: ',

    selectedValueS6: 0,
    selectedValueS6_text: 'Dificultad para lavarse el cabello: ',

    selectedValueS7: 0,
    selectedValueS7_text: 'Dificultad para lavarse: ',

    selectedValueS8: 0,
    selectedValueS8_text: 'Dificultad para ponerse la camiseta o el jersey: ',

    selectedValueS9: 0,
    selectedValueS9_text: 'Dificultad para ponerse una camisa abotonada en la parte delantera: ',

    selectedValueS10: 0,
    selectedValueS10_text: 'Dificultad para ponerse los pantalones: ',

    selectedValueS11: 0,
    selectedValueS11_text: 'Dificultad para caminar sobre un objeto en un estante alto: ',

    selectedValueS12: 0,
    selectedValueS12_text: 'Dificultad para cargar un objeto pesado: ',

    selectedValueS13: 0,
    selectedValueS13_text: 'Dificultad para sacar algo del bolsillo trasero: ',              
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const SPADIScoresRef = firebase.firestore();
  SPADIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SPADIScoresRef.collection("spadi_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const SPADIScoresRef = firebase.firestore();
  SPADIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SPADIScoresRef.collection("spadi_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const SPADIScoresRef = firebase.firestore();
  SPADIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SPADIScoresRef.collection("spadi_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const SPADIScoresRef = firebase.firestore();
  SPADIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SPADIScoresRef.collection("spadi_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const SPADIScoresRef = firebase.firestore();
  SPADIScoresRef.settings({
      timestampsInSnapshots: true
  }); 
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){        
  const spadi_score = SPADIScoresRef.collection("spadi_scores").doc(this.props.requestSurveyID).set({            
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Spanish Shoulder Pain and Disability Index',
    Question_01_SPADI: this.state.selectedValueS1,
    Question_02_SPADI: this.state.selectedValueS2,
    Question_03_SPADI: this.state.selectedValueS3,
    Question_04_SPADI: this.state.selectedValueS4,
    Question_05_SPADI: this.state.selectedValueS5,
    Question_06_SPADI: this.state.selectedValueS6,
    Question_07_SPADI: this.state.selectedValueS7,            
    Question_08_SPADI: this.state.selectedValueS8,
    Question_09_SPADI: this.state.selectedValueS9,
    Question_10_SPADI: this.state.selectedValueS10,
    Question_11_SPADI: this.state.selectedValueS11,
    Question_12_SPADI: this.state.selectedValueS12,
    Question_13_SPADI: this.state.selectedValueS13,
    Total_Score_SPADI: (((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValueS7+this.state.selectedValueS8+this.state.selectedValueS9+this.state.selectedValueS10+this.state.selectedValueS11+this.state.selectedValueS12+this.state.selectedValueS13))/(130)*100).toFixed(1),     
  });
}
else {
  const spadi_score = SPADIScoresRef.collection("spadi_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'Spanish Shoulder Pain and Disability Index',
    Question_01_SPADI: this.state.selectedValueS1,
    Question_02_SPADI: this.state.selectedValueS2,
    Question_03_SPADI: this.state.selectedValueS3,
    Question_04_SPADI: this.state.selectedValueS4,
    Question_05_SPADI: this.state.selectedValueS5,
    Question_06_SPADI: this.state.selectedValueS6,
    Question_07_SPADI: this.state.selectedValueS7,            
    Question_08_SPADI: this.state.selectedValueS8,
    Question_09_SPADI: this.state.selectedValueS9,
    Question_10_SPADI: this.state.selectedValueS10,
    Question_11_SPADI: this.state.selectedValueS11,
    Question_12_SPADI: this.state.selectedValueS12,
    Question_13_SPADI: this.state.selectedValueS13,
    Total_Score_SPADI: (((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValueS7+this.state.selectedValueS8+this.state.selectedValueS9+this.state.selectedValueS10+this.state.selectedValueS11+this.state.selectedValueS12+this.state.selectedValueS13))/(130)*100).toFixed(1),     
  });      
}      
  this.setState({
    clinician_to_share_with: '',
    user_id: '',
  });
    alert('¡Gracias por usar OrthoPowerTools! Se recibió su envío y se compartirá con el siguiente proveedor: '+ this.props.clinicianSendingEmail +'. Ahora puede cerrar esta alerta y toda la ventana.');    
}     
    
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("spadi_spanish_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
 
    myCallbackS1  = (dataFromChild) => {
    this.setState({selectedValueS1: dataFromChild});
    }
    myCallbackS2  = (dataFromChild) => {
    this.setState({selectedValueS2: dataFromChild});
    }
    myCallbackS3  = (dataFromChild) => {
    this.setState({selectedValueS3: dataFromChild});
    }
    myCallbackS4  = (dataFromChild) => {
    this.setState({selectedValueS4: dataFromChild});
    }
    myCallbackS5  = (dataFromChild) => {
    this.setState({selectedValueS5: dataFromChild});
    }
    myCallbackS6  = (dataFromChild) => {
    this.setState({selectedValueS6: dataFromChild});
    }
    myCallbackS7  = (dataFromChild) => {
    this.setState({selectedValueS7: dataFromChild});
    }
    myCallbackS8  = (dataFromChild) => {
    this.setState({selectedValueS8: dataFromChild});
    }
    myCallbackS9  = (dataFromChild) => {
    this.setState({selectedValueS9: dataFromChild});
    }
    myCallbackS10  = (dataFromChild) => {
    this.setState({selectedValueS10: dataFromChild});
    }
    myCallbackS11  = (dataFromChild) => {
    this.setState({selectedValueS11: dataFromChild});
    }
    myCallbackS12  = (dataFromChild) => {
    this.setState({selectedValueS12: dataFromChild});
    }
    myCallbackS13  = (dataFromChild) => {
    this.setState({selectedValueS13: dataFromChild});
    }
  
    
    

    
render() { 

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>Indice de Dolor y Discapacidad del Hombro (SPADI) Calculadora</h1>
                <h4>Hola, {this.props.submitterName}, su médico, {this.props.clinicianSendingEmail}, le envió esta encuesta para ayudarlo a comprender mejor su salud. Haga clic en los botones correspondientes a continuación y luego haga clic en "Enviar Puntuación". Tenga en cuenta que su <u> privacidad digital se toma muy en serio </u> y que se han implementado varias tecnologías para proteger la privacidad y la integridad de sus datos, incluido el uso de cifrado SSL, almacenamiento compatible con HIPAA y servicios de autenticación de última generación. Sus datos <u>nunca se compartirán con nadie más que el médico asignado</u>.</h4>

            <form onSubmit={this.handleSubmit}>



                <StickyContainer>
 
                  <Sticky>
                    {({ style }) => <div className="sticky_style" style={style}><Well bsSize="small">Puntaje SPADI: {(this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValueS7+this.state.selectedValueS8+this.state.selectedValueS9+this.state.selectedValueS10+this.state.selectedValueS11+this.state.selectedValueS12+this.state.selectedValueS13)} / {130
                    } = {((((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValueS7+this.state.selectedValueS8+this.state.selectedValueS9+this.state.selectedValueS10+this.state.selectedValueS11+this.state.selectedValueS12+this.state.selectedValueS13))/(130
                    )*100).toFixed(1))} %
                    <p/>
                    Gráfica Puntaje SPADI: (%)
                    <ProgressBar>
                    <ProgressBar active now={100-(100*(((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValueS7+this.state.selectedValueS8+this.state.selectedValueS9+this.state.selectedValueS10+this.state.selectedValueS11+this.state.selectedValueS12+this.state.selectedValueS13))/(130
                    )))}/>
                    <ProgressBar active bsStyle="danger" now={(100*(((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5+this.state.selectedValueS6+this.state.selectedValueS7+this.state.selectedValueS8+this.state.selectedValueS9+this.state.selectedValueS10+this.state.selectedValueS11+this.state.selectedValueS12+this.state.selectedValueS13))/(130
                    )))}/>
                    </ProgressBar>    
                    </Well>
                    </div>}

                  </Sticky> 
                <p />
                    
                <br/>
                <br/>                      

                    <h3>Escala de dolor: ¿Cómo de grave es el dolor?
                    </h3>
                    <h4>Ponga una cruz en el número que mejor describa su dolor, donde: 0 = ausencia de dolor y 10 = el peor dolor imaginable
                    </h4>
                    <Slider1 callbackFromParentS1={this.myCallbackS1}/>
                    <Slider2 callbackFromParentS2={this.myCallbackS2}/>
                    <Slider3 callbackFromParentS3={this.myCallbackS3}/>
                    <Slider4 callbackFromParentS4={this.myCallbackS4}/>
                    <Slider5 callbackFromParentS5={this.myCallbackS5}/>

                    <br /><Well bsSize="small">Puntaje De Dolor: {(this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5)} / {50
                    } = {((((this.state.selectedValueS1+this.state.selectedValueS2+this.state.selectedValueS3+this.state.selectedValueS4+this.state.selectedValueS5))/(50
                    )*100).toFixed(1))} %</Well>    

                    <h3>Escala de Discapacidad: ¿Cuánta dificultad tiene usted? 
                    </h3>
                    <h4>Ponga una cruz en el número que mejor describa su experiencia, donde: 0 = sin dificultad y 10 = tan difícil que requiere ayuda.
                    </h4>
                    <Slider6 callbackFromParentS6={this.myCallbackS6}/>
                    <Slider7 callbackFromParentS7={this.myCallbackS7}/>
                    <Slider8 callbackFromParentS8={this.myCallbackS8}/>
                    <Slider9 callbackFromParentS9={this.myCallbackS9}/>
                    <Slider10 callbackFromParentS10={this.myCallbackS10}/>
                    <Slider11 callbackFromParentS11={this.myCallbackS11}/>
                    <Slider12 callbackFromParentS12={this.myCallbackS12}/>
                    <Slider13 callbackFromParentS13={this.myCallbackS13}/>                       
                    <br /><Well bsSize="small">Puntaje De Discapacidad: {(this.state.selectedValueS6+this.state.selectedValueS7+this.state.selectedValueS8+this.state.selectedValueS9+this.state.selectedValueS10+this.state.selectedValueS11+this.state.selectedValueS12+this.state.selectedValueS13)} / {80
                    } = {((((this.state.selectedValueS6+this.state.selectedValueS7+this.state.selectedValueS8+this.state.selectedValueS9+this.state.selectedValueS10+this.state.selectedValueS11+this.state.selectedValueS12+this.state.selectedValueS13))/(80
                    )*100).toFixed(1))} %</Well>    
                    </StickyContainer>

                <br />
                    
              <button style = {{width: '100%'}}>Enviar Puntuación</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(SPADI_Link_Spanish);
