import React, { Component } from "react"
import {BrowserRouter, Switch, Route, Link, useParams} from "react-router-dom"
import firebase, { auth, provider } from './firebase.js';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import './App.css';

import Home from "./components/Home"
import FunFoodFriends from "./components/FunFoodFriends"


import Send_Text_Request from "./components/Send-Text-Request"
import Send_Email_Request from "./components/Send-Email-Request"

import ODI from "./components/ODI"
import ODILink from "./components/ODI-Link"
import ODI_Results from "./components/ODI-Results"
import ODI_Send_Link from "./components/ODI-Send-Link"

import LEFS from "./components/LEFS"
import LEFSLink from "./components/LEFS-Link"
import LEFS_Results from "./components/LEFS-Results"
import LEFS_Send_Link from "./components/LEFS-Send-Link"

import LEFS_Spanish from "./components/LEFS-Spanish"
import LEFSLinkSpanish from "./components/LEFS-Link-Spanish"
import LEFS_Send_Link_Spanish from "./components/LEFS-Send-Link-Spanish"


import SPADI from "./components/SPADI"
import SPADILink from "./components/SPADI-Link"
import SPADI_Results from "./components/SPADI-Results"
import SPADI_Send_Link from "./components/SPADI-Send-Link"

import SPADI_Spanish from "./components/SPADI-Spanish"
import SPADILinkSpanish from "./components/SPADI-Link-Spanish"
import SPADI_Send_Link_Spanish from "./components/SPADI-Send-Link-Spanish"


import SF12 from "./components/SF12"
import SF12Link from "./components/SF12-Link"
import SF12_Results from "./components/SF12-Results"
import SF12_Send_Link from "./components/SF12-Send-Link"

import WHOQOLBREF from "./components/WHOQOLBREF"
import WHOQOLBREFLink from "./components/WHOQOLBREF-Link"
import WHOQOLBREF_Results from "./components/WHOQOLBREF-Results" 
import WHOQOLBREF_Send_Link from "./components/WHOQOLBREF-Send-Link"
import Essies_Place from "./components/Essies-Place"

import FAAM from "./components/FAAM"
import FAAMLink from "./components/FAAM-Link"
import FAAM_Results from "./components/FAAM-Results"
import FAAM_Send_Link from "./components/FAAM-Send-Link"

import AOFASH from "./components/AOFASH"
import AOFASHLink from "./components/AOFASH-Link"
import AOFASH_Results from "./components/AOFASH-Results"
import AOFASH_Send_Link from "./components/AOFASH-Send-Link"

import KOOS12 from "./components/KOOS12"
import KOOS12Link from "./components/KOOS12-Link"
import KOOS12_Results from "./components/KOOS12-Results"
import KOOS12_Send_Link from "./components/KOOS12-Send-Link"

import HOOS12 from "./components/HOOS12"
import HOOS12Link from "./components/HOOS12-Link"
import HOOS12_Results from "./components/HOOS12-Results"
import HOOS12_Send_Link from "./components/HOOS12-Send-Link"

import QuickDASH from "./components/QuickDASH"
import QuickDASHLink from "./components/QuickDASH-Link"
import QuickDASH_Results from "./components/QuickDASH-Results"
import QuickDASH_Send_Link from "./components/QuickDASH-Send-Link"

import QuickDASH_Spanish from "./components/QuickDASH-Spanish"
import QuickDASHLinkSpanish from "./components/QuickDASH-Link-Spanish"
import QuickDASH_Send_Link_Spanish from "./components/QuickDASH-Send-Link-Spanish"

import NDI from "./components/NDI"
import NDILink from "./components/NDI-Link"
import NDI_Results from "./components/NDI-Results"
import NDI_Send_Link from "./components/NDI-Send-Link"

import NDI_Spanish from "./components/NDI-Spanish"
import NDILinkSpanish from "./components/NDI-Link-Spanish"
import NDI_Send_Link_Spanish from "./components/NDI-Send-Link-Spanish"

import NDI_Italian from "./components/NDI-Italian"
import NDILinkItalian from "./components/NDI-Link-Italian"


import SF36 from "./components/SF36"
import SF36Link from "./components/SF36-Link"
import SF36_Results from "./components/SF36-Results"
import SF36_Send_Link from "./components/SF36-Send-Link"

import ACLRSI from "./components/ACLRSI"
import ACLRSILink from "./components/ACLRSI-Link"
import ACLRSI_Results from "./components/ACLRSI-Results"
import ACLRSI_Send_Link from "./components/ACLRSI-Send-Link"

import VISAA from "./components/VISAA"
import VISAALink from "./components/VISAA-Link"
import VISAA_Results from "./components/VISAA-Results"
import VISAA_Send_Link from "./components/VISAA-Send-Link"

import VISAP from "./components/VISAP"
import VISAPLink from "./components/VISAP-Link"
import VISAP_Results from "./components/VISAP-Results"
import VISAP_Send_Link from "./components/VISAP-Send-Link"

import VISAH from "./components/VISAH"
import VISAHLink from "./components/VISAH-Link"
import VISAH_Results from "./components/VISAH-Results"
import VISAH_Send_Link from "./components/VISAH-Send-Link"

import VISAG from "./components/VISAG"
import VISAGLink from "./components/VISAG-Link"
import VISAG_Results from "./components/VISAG-Results"
import VISAG_Send_Link from "./components/VISAG-Send-Link"

import DHI from "./components/DHI"
import DHILink from "./components/DHI-Link"
import DHI_Results from "./components/DHI-Results"
import DHI_Send_Link from "./components/DHI-Send-Link"


import BIPQ from "./components/BIPQ"
import BIPQLink from "./components/BIPQ-Link"
import BIPQ_Results from "./components/BIPQ-Results"
import BIPQ_Send_Link from "./components/BIPQ-Send-Link"


import TSK from "./components/TSK"
import TSKLink from "./components/TSK-Link"
import TSK_Results from "./components/TSK-Results"


import VAS from "./components/VAS"
import VASLink from "./components/VAS-Link"
import VAS_Results from "./components/VAS-Results"


import About_Us from "./components/About-Us"
import Contact_Us from "./components/Contact-Us"
import FAQ from "./components/FAQ"
import Terms_Of_Use from "./components/Terms-Of-Use"


import Navbar from "./components/Navbar"
import Footer from "./components/Footer"
import ScrollToTop from "./components/ScrollToTop"


class App extends Component {


  
render() {    
    return (        
        <main> 
            <ScrollToTop />
        
            <Switch>                
                <Route path="/" component={Home} exact />                
                <Route path="/FunFoodFriends" component={FunFoodFriends} />
        
                <Route path="/Send-Text-Request" component={Send_Text_Request} />        
                <Route path="/Send-Email-Request" component={Send_Email_Request} />        
        
        
                <Route path="/ODI" component={ODI} />        
                <Route path="/ODI-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfo /></Route>
                <Route path="/ODI-Results" component={ODI_Results} />                           
                <Route path="/ODI-Send-Link" component={ODI_Send_Link} />
        
                <Route path="/LEFS" component={LEFS} />
                <Route path="/LEFS-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoLEFS /></Route>
                <Route path="/LEFS-Results" component={LEFS_Results} />          
                <Route path="/LEFS-Send-Link" component={LEFS_Send_Link} />
        
                <Route path="/LEFS-Spanish" component={LEFS_Spanish} />
                <Route path="/LEFS-Link-Spanish/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoLEFSSpanish /></Route>
                <Route path="/LEFS-Send-Link-Spanish" component={LEFS_Send_Link_Spanish} />        
        
                <Route path="/SPADI" component={SPADI} /> 
                <Route path="/SPADI-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoSPADI /></Route>
                <Route path="/SPADI-Results" component={SPADI_Results} />         
                <Route path="/SPADI-Send-Link" component={SPADI_Send_Link} />
        
                <Route path="/SPADI-Spanish" component={SPADI_Spanish} /> 
                <Route path="/SPADI-Link-Spanish/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoSPADISpanish /></Route>
                <Route path="/SPADI-Send-Link-Spanish" component={SPADI_Send_Link_Spanish} />        
        
        
                <Route path="/SF12" component={SF12} /> 
                <Route path="/SF12-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoSF12 /></Route>
                <Route path="/SF12-Results" component={SF12_Results} />         
                <Route path="/SF12-Send-Link" component={SF12_Send_Link} />
        
                <Route path="/WHOQOLBREF" component={WHOQOLBREF} /> 
                <Route path="/WHOQOLBREF-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoWHOQOLBREF /></Route>
                <Route path="/WHOQOLBREF-Results" component={WHOQOLBREF_Results} />         
                <Route path="/WHOQOLBREF-Send-Link" component={WHOQOLBREF_Send_Link} />
                <Route path="/Essies-Place" component={Essies_Place} /> 
        
        
                <Route path="/FAAM" component={FAAM} />
                <Route path="/FAAM-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoFAAM /></Route>
                <Route path="/FAAM-Results" component={FAAM_Results} />          
                <Route path="/FAAM-Send-Link" component={FAAM_Send_Link} />    
        
        
                <Route path="/AOFASH" component={AOFASH} />
                <Route path="/AOFASH-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoAOFASH /></Route>
                <Route path="/AOFASH-Results" component={AOFASH_Results} />          
                <Route path="/AOFASH-Send-Link" component={AOFASH_Send_Link} /> 
        
                <Route path="/KOOS12" component={KOOS12} />
                <Route path="/KOOS12-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoKOOS12 /></Route>
                <Route path="/KOOS12-Results" component={KOOS12_Results} />          
                <Route path="/KOOS12-Send-Link" component={KOOS12_Send_Link} /> 
        
                <Route path="/HOOS12" component={HOOS12} />
                <Route path="/HOOS12-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoHOOS12 /></Route>
                <Route path="/HOOS12-Results" component={HOOS12_Results} />          
                <Route path="/HOOS12-Send-Link" component={HOOS12_Send_Link} />
        
                <Route path="/QuickDASH" component={QuickDASH} />
                <Route path="/QuickDASH-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoQuickDASH /></Route>
                <Route path="/QuickDASH-Results" component={QuickDASH_Results} />          
                <Route path="/QuickDASH-Send-Link" component={QuickDASH_Send_Link} /> 

                <Route path="/QuickDASH-Spanish" component={QuickDASH_Spanish} />
                <Route path="/QuickDASH-Link-Spanish/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoQuickDASHSpanish /></Route>
                <Route path="/QuickDASH-Send-Link-Spanish" component={QuickDASH_Send_Link_Spanish} />        
        
        
                <Route path="/NDI" component={NDI} />        
                <Route path="/NDI-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoNDI /></Route>
                <Route path="/NDI-Results" component={NDI_Results} />                           
                <Route path="/NDI-Send-Link" component={NDI_Send_Link} />
        
                <Route path="/NDI-Spanish" component={NDI_Spanish} />
                <Route path="/NDI-Link-Spanish/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoNDISpanish /></Route>
                <Route path="/NDI-Send-Link-Spanish" component={NDI_Send_Link_Spanish} /> 
        
                <Route path="/NDI-Italian" component={NDI_Italian} />
                <Route path="/NDI-Link-Italian/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoNDIItalian /></Route>        
        
        
                <Route path="/SF36" component={SF36} /> 
                <Route path="/SF36-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoSF36 /></Route>        
                <Route path="/SF36-Results" component={SF36_Results} />         
                <Route path="/SF36-Send-Link" component={SF36_Send_Link} />
        
                <Route path="/ACLRSI" component={ACLRSI} /> 
                <Route path="/ACLRSI-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoACLRSI /></Route>        
                <Route path="/ACLRSI-Results" component={ACLRSI_Results} />         
                <Route path="/ACLRSI-Send-Link" component={ACLRSI_Send_Link} /> 
        

                <Route path="/VISAA" component={VISAA} /> 
                <Route path="/VISAA-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoVISAA /></Route>        
                <Route path="/VISAA-Results" component={VISAA_Results} />         
                <Route path="/VISAA-Send-Link" component={VISAA_Send_Link} /> 
        
                <Route path="/VISAP" component={VISAP} /> 
                <Route path="/VISAP-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoVISAP /></Route>        
                <Route path="/VISAP-Results" component={VISAP_Results} />         
                <Route path="/VISAP-Send-Link" component={VISAP_Send_Link} /> 
        
                <Route path="/VISAH" component={VISAH} /> 
                <Route path="/VISAH-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoVISAH /></Route>        
                <Route path="/VISAH-Results" component={VISAH_Results} />         
                <Route path="/VISAH-Send-Link" component={VISAH_Send_Link} /> 
        
                <Route path="/VISAG" component={VISAG} /> 
                <Route path="/VISAG-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoVISAG /></Route>        
                <Route path="/VISAG-Results" component={VISAG_Results} />         
                <Route path="/VISAG-Send-Link" component={VISAG_Send_Link} /> 
        
                <Route path="/DHI" component={DHI} /> 
                <Route path="/DHI-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoDHI /></Route>        
                <Route path="/DHI-Results" component={DHI_Results} />         
                <Route path="/DHI-Send-Link" component={DHI_Send_Link} />  
        
                <Route path="/BIPQ" component={BIPQ} /> 
                <Route path="/BIPQ-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoBIPQ /></Route>        
                <Route path="/BIPQ-Results" component={BIPQ_Results} />         
                <Route path="/BIPQ-Send-Link" component={BIPQ_Send_Link} /> 
        
                <Route path="/TSK" component={TSK} /> 
                <Route path="/TSK-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoTSK /></Route>        
                <Route path="/TSK-Results" component={TSK_Results} />
        
                <Route path="/VAS" component={VAS} /> 
                <Route path="/VAS-Link/:submitter_name/:clinician_sending_email/:request_survey_id" > <GetURLInfoVAS /></Route>        
                <Route path="/VAS-Results" component={VAS_Results} />        

        
                <Route path="/About-Us" component={About_Us} />                           
                <Route path="/Contact-Us" component={Contact_Us} />                           
                <Route path="/FAQ" component={FAQ} />                           
                <Route path="/Terms-Of-Use" component={Terms_Of_Use} />          
        
        
                         
        
        
            </Switch> 
        
        <Footer />
        </main>    
    );
}

} 


function GetURLInfo(){
    let params = useParams ();
    
    return(
    <div>
        <ODILink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}



function GetURLInfoLEFS(){
    let params = useParams ();
    
    return(
    <div>
        <LEFSLink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}


function GetURLInfoLEFSSpanish(){
    let params = useParams ();
    
    return(
    <div>
        <LEFSLinkSpanish submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}


function GetURLInfoSPADI(){
    let params = useParams ();
    
    return(
    <div>
        <SPADILink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoSPADISpanish(){
    let params = useParams ();
    
    return(
    <div>
        <SPADILinkSpanish submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}


function GetURLInfoSF12(){
    let params = useParams ();
    
    return(
    <div>
        <SF12Link submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoWHOQOLBREF(){
    let params = useParams ();
    
    return(
    <div>
        <WHOQOLBREFLink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoFAAM(){
    let params = useParams ();
    
    return(
    <div>
        <FAAMLink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoAOFASH(){
    let params = useParams ();
    
    return(
    <div>
        <AOFASHLink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoKOOS12(){
    let params = useParams ();
    
    return(
    <div>
        <KOOS12Link submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoHOOS12(){
    let params = useParams ();
    
    return(
    <div>
        <HOOS12Link submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoQuickDASH(){
    let params = useParams ();
    
    return(
    <div>
        <QuickDASHLink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoQuickDASHSpanish(){
    let params = useParams ();
    
    return(
    <div>
        <QuickDASHLinkSpanish submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoNDI(){
    let params = useParams ();
    
    return(
    <div>
        <NDILink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoNDISpanish(){
    let params = useParams ();
    
    return(
    <div>
        <NDILinkSpanish submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoNDIItalian(){
    let params = useParams ();
    
    return(
    <div>
        <NDILinkItalian submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoSF36(){
    let params = useParams ();
    
    return(
    <div>
        <SF36Link submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoACLRSI(){
    let params = useParams ();
    
    return(
    <div>
        <ACLRSILink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}


function GetURLInfoVISAA(){
    let params = useParams ();
    
    return(
    <div>
        <VISAALink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}


function GetURLInfoVISAP(){
    let params = useParams ();
    
    return(
    <div>
        <VISAPLink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoVISAH(){
    let params = useParams ();
    
    return(
    <div>
        <VISAHLink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}


function GetURLInfoVISAG(){
    let params = useParams ();
    
    return(
    <div>
        <VISAGLink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}


function GetURLInfoDHI(){
    let params = useParams ();
    
    return(
    <div>
        <DHILink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoBIPQ(){
    let params = useParams ();
    
    return(
    <div>
        <BIPQLink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}


function GetURLInfoTSK(){
    let params = useParams ();
    
    return(
    <div>
        <TSKLink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}

function GetURLInfoVAS(){
    let params = useParams ();
    
    return(
    <div>
        <VASLink submitterName = {params.submitter_name} clinicianSendingEmail = {params.clinician_sending_email} requestSurveyID = {params.request_survey_id}/>
    </div>    
    );
}


   

export default App;

//            <Navbar /> 
//^^this was directly under the first <main> tag

/*
<Profile  />

function Profile (){
    const { name } = useParams();
    return <p>{name}'s Profile</p>;
}
*/