import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup, Table} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
//const jsPDF = require('jspdf');
import jsPDF from 'jspdf';
import {Link, Route} from "react-router-dom";
import mainLogo from'../orthopowertools.png';

 


class WHOQOLBREF_Send_Link extends PureComponent {
    
  constructor() {
    super();
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      scores_id: [], //this displays ID number for the scores saved for each clinician        
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual
        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to
      patient_name_to_send_score: '', //this is the variable where clinicians enter the given name of a patient they want to send a score to, which makes the email more personable
      clinician_name_to_send_score: '', //this is the variable where clinicians enter their own given name when they send  score to a patient, which makes the email more personable         
        
    //Calculator variables    
        selectedValue1: 5,
        selectedValue1_text: 'Very good quality of life',

        selectedValue2: 5,
        selectedValue2_text: 'Very satisfied with health',

        selectedValue3: 1,
        selectedValue3_text: 'Physical pain does not prevent doing needs',

        selectedValue4: 1,
        selectedValue4_text: 'Medical treatment not needed to function',

        selectedValue5: 5,
        selectedValue5_text: 'Enjoy life an extreme amount',

        selectedValue6: 5,
        selectedValue6_text: 'Find life meaningful an extreme amount',

        selectedValue7: 5,
        selectedValue7_text: 'Extremely able to concentrate',

        selectedValue8: 5,
        selectedValue8_text: 'Feel extremely safe',

        selectedValue9: 5,
        selectedValue9_text: 'Physical environment is extremely healthy',

        selectedValue10: 5,
        selectedValue10_text: 'Have enough energy for everyday life completely',

        selectedValue11: 5,
        selectedValue11_text: 'Able to accept bodily appearance completely',

        selectedValue12: 5,
        selectedValue12_text: 'Have you enough money to meet needs completely',

        selectedValue13: 5,
        selectedValue13_text: 'Information needed for day-to-day life available completely',

        selectedValue14: 5,
        selectedValue14_text: 'Have opportunities for leisure activities completely',

        selectedValue15: 5,
        selectedValue15_text: 'Able to get around very good',

        selectedValue16: 5,
        selectedValue16_text: 'Very satisfied with sleep',

        selectedValue17: 5,
        selectedValue17_text: 'Very satisfied with ability to perform daily living activities',

        selectedValue18: 5,
        selectedValue18_text: 'Very satisfied with capacity for work',

        selectedValue19: 5,
        selectedValue19_text: 'Very satisfied with self',

        selectedValue20: 5,
        selectedValue20_text: 'Very satisfied with personal relationships',

        selectedValue21: 5,
        selectedValue21_text: 'Very satisfied with sex life',

        selectedValue22: 5,
        selectedValue22_text: 'Very satisfied with support from friends',

        selectedValue23: 5,
        selectedValue23_text: 'Very satisfied with conditions of living place',

        selectedValue24: 5,
        selectedValue24_text: 'Very satisfied with access to health services',

        selectedValue25: 5,
        selectedValue25_text: 'Very satisfied with transport',

        selectedValue26: 1,
        selectedValue26_text: 'Never have negative feelings',            
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.modifyData = this.modifyData.bind(this);
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
      
      
    this.transformedScore_0_100_1 = [0,6,6,13,13,19,19,25,31,31,38,38,44,44,50,56,56,63,63,69,69,75,81,81,88,88,94,94,100];
    this.transformedScore_4_20_1 = [4,5,5,6,6,7,7,8,9,9,10,10,11,11,12,13,13,14,14,15,15,16,17,17,18,18,19,19,20];
    this.transformedScore_0_100_2 = [0,6,6,13,19,19,25,31,31,38,44,44,50,56,56,63,69,69,75,81,81,88,94,94,100];
    this.transformedScore_4_20_2 = [4,5,5,6,7,7,8,9,9,10,11,11,12,13,13,14,15,15,16,17,17,18,19,19,20];
    this.transformedScore_0_100_3 = [0,6,19,25,31,44,50,56,69,75,81,94,100];
    this.transformedScore_4_20_3 = [4,5,7,8,9,11,12,13,15,16,17,19,20];
    this.transformedScore_0_100_4 = [0,6,6,13,13,19,19,25,25,31,31,38,38,44,44,50,50,56,56,63,63,69,69,75,75,81,81,88,88,94,94,100,100];
    this.transformedScore_4_20_4 = [4,5,5,6,6,7,7,8,8,9,9,10,10,11,11,12,12,13,13,14,14,15,15,16,16,17,17,18,18,19,19,20,20];      
            
      
     
      
  }
    
componentDidMount() {
    
    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const WHOQOLBREFScoresRef = firebase.firestore();
  WHOQOLBREFScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  WHOQOLBREFScoresRef.collection("whoqolbref_scores").get().then(querySnapshot =>{
      
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id //this part is a weird flaw in the system where querySnapshot doesn't actually pull the doc ID, so you have to do it seperately and then combined the document data with each doc ID
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  })
              
        
}  else {
    
  const WHOQOLBREFScoresRef = firebase.firestore();
  WHOQOLBREFScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  WHOQOLBREFScoresRef.collection("whoqolbref_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const WHOQOLBREFScoresRef = firebase.firestore();
  WHOQOLBREFScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  WHOQOLBREFScoresRef.collection("whoqolbref_scores").get().then(querySnapshot =>{
      

    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
                       
        
} else {
    

    
  const WHOQOLBREFScoresRef = firebase.firestore();
  WHOQOLBREFScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  WHOQOLBREFScoresRef.collection("whoqolbref_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value.replace('"','')
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const WHOQOLBREFScoresRef = firebase.firestore();
  WHOQOLBREFScoresRef.settings({
      timestampsInSnapshots: true
  });  
  const whoqolbref_score = WHOQOLBREFScoresRef.collection("whoqolbref_scores").add({
    shared_with: this.state.clinician_to_share_with,
    user: this.state.user_id,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'WHO QOL BREF',
    Question_01_WHOQOLBREF: this.state.selectedValue1,
    Question_02_WHOQOLBREF: this.state.selectedValue2,
    Question_03_WHOQOLBREF: this.state.selectedValue3,
    Question_04_WHOQOLBREF: this.state.selectedValue4,
    Question_05_WHOQOLBREF: this.state.selectedValue5,
    Question_06_WHOQOLBREF: this.state.selectedValue6,
    Question_07_WHOQOLBREF: this.state.selectedValue7,            
    Question_08_WHOQOLBREF: this.state.selectedValue8,
    Question_09_WHOQOLBREF: this.state.selectedValue9,
    Question_10_WHOQOLBREF: this.state.selectedValue10,
    Question_11_WHOQOLBREF: this.state.selectedValue11,
    Question_12_WHOQOLBREF: this.state.selectedValue12,
    Question_13_WHOQOLBREF: this.state.selectedValue13,
    Question_14_WHOQOLBREF: this.state.selectedValue14,
    Question_15_WHOQOLBREF: this.state.selectedValue15,
    Question_16_WHOQOLBREF: this.state.selectedValue16,
    Question_17_WHOQOLBREF: this.state.selectedValue17,
    Question_18_WHOQOLBREF: this.state.selectedValue18,
    Question_19_WHOQOLBREF: this.state.selectedValue19,
    Question_20_WHOQOLBREF: this.state.selectedValue20,
    Question_21_WHOQOLBREF: this.state.selectedValue21,
    Question_22_WHOQOLBREF: this.state.selectedValue22,
    Question_23_WHOQOLBREF: this.state.selectedValue23,
    Question_24_WHOQOLBREF: this.state.selectedValue24,
    Question_25_WHOQOLBREF: this.state.selectedValue25,
    Question_26_WHOQOLBREF: this.state.selectedValue26,
    Physical_Health_Score_WHOQOLBREF: (this.transformedScore_0_100_1[(6-this.state.selectedValue3)+(6-this.state.selectedValue4)+this.state.selectedValue10+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18-7]),  
    Psychological_Score_WHOQOLBREF: (this.transformedScore_0_100_2[this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue11+this.state.selectedValue19+(6-this.state.selectedValue26)-6]),
    Social_Relationships_Score_WHOQOLBREF: (this.transformedScore_0_100_3[this.state.selectedValue20+this.state.selectedValue21+this.state.selectedValue22-3]),
    Environment_Score_WHOQOLBREF: (this.transformedScore_0_100_4[this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue23+this.state.selectedValue24+this.state.selectedValue25-8]),    
  });
  this.setState({
    clinician_to_share_with: '',
    user_id: '',
    selectedValue1: 5,
    selectedValue2: 5,
    selectedValue3: 1,
    selectedValue4: 1,
    selectedValue5: 5,
    selectedValue6: 5,
    selectedValue7: 5,
    selectedValue8: 5,
    selectedValue9: 5,                  
    selectedValue10: 5,
    selectedValue11: 5,
    selectedValue12: 5,
    selectedValue13: 5,
    selectedValue14: 5,
    selectedValue15: 5,
    selectedValue16: 5,
    selectedValue17: 5,
    selectedValue18: 5,
    selectedValue19: 5,
    selectedValue20: 5,
    selectedValue21: 5,
    selectedValue22: 5,
    selectedValue23: 5,
    selectedValue24: 5,
    selectedValue25: 5,
    selectedValue26: 1,
    Physical_Health_Score_WHOQOLBREF: 100,
    Psychological_Score_WHOQOLBREF: 100,
    Social_Relationships_Score_WHOQOLBREF: 100,
    Environment_Score_WHOQOLBREF: 100,
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.state.clinician_to_share_with + '.');   
    
}  
    
modifyData(score_id) {  
  const WHOQOLBREFScoresRef = firebase.firestore();
  WHOQOLBREFScoresRef.collection("whoqolbref_scores").doc(String(score_id)).update({
    shared_with: "None"    
  });  
    alert('Score deleted.');     
    
} 
    
    
    
    
    
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("whoqolbref_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent_email: this.state.user.email,       
    patient_name_to_send_score: this.state.patient_name_to_send_score,
    clinician_who_sent_name: this.state.clinician_name_to_send_score,        
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',
    clinician_who_sent: '',
    patient_name_to_send_score: '',
    clinician_name_to_send_score: '',  
  });
    if (this.state.patient_email_to_send_score !== ''){
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');
    }
    else {
    alert('Thank you for using OrthoPowerTools! Your invitation was not sent because you did not enter the patients email address. To send the invitation, please enter the patients email address at the top of the page in the section that states "Enter the patients email address here"');        
    }      
    
}      
  
    
    
    
 
    
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
myCallback1  = (dataFromChild) => {
this.setState({selectedValue1: dataFromChild});
}
myCallback1_text  = (dataFromChild) => {
this.setState({selectedValue1_text: dataFromChild});
}
myCallback2  = (dataFromChild) => {
this.setState({selectedValue2: dataFromChild});
}
myCallback2_text  = (dataFromChild) => {
this.setState({selectedValue2_text: dataFromChild});
}
myCallback3  = (dataFromChild) => {
this.setState({selectedValue3: dataFromChild});
}
myCallback3_text  = (dataFromChild) => {
this.setState({selectedValue3_text: dataFromChild});
}
myCallback4  = (dataFromChild) => {
this.setState({selectedValue4: dataFromChild});
}
myCallback4_text  = (dataFromChild) => {
this.setState({selectedValue4_text: dataFromChild});
}
myCallback5  = (dataFromChild) => {
this.setState({selectedValue5: dataFromChild});
}
myCallback5_text  = (dataFromChild) => {
this.setState({selectedValue5_text: dataFromChild});
}
myCallback6  = (dataFromChild) => {
this.setState({selectedValue6: dataFromChild});
}
myCallback6_text  = (dataFromChild) => {
this.setState({selectedValue6_text: dataFromChild});
}
myCallback7  = (dataFromChild) => {
this.setState({selectedValue7: dataFromChild});
}
myCallback7_text  = (dataFromChild) => {
this.setState({selectedValue7_text: dataFromChild});
}
myCallback8  = (dataFromChild) => {
this.setState({selectedValue8: dataFromChild});
}
myCallback8_text  = (dataFromChild) => {
this.setState({selectedValue8_text: dataFromChild});
}
myCallback9  = (dataFromChild) => {
this.setState({selectedValue9: dataFromChild});
}
myCallback9_text  = (dataFromChild) => {
this.setState({selectedValue9_text: dataFromChild});
}
myCallback10  = (dataFromChild) => {
this.setState({selectedValue10: dataFromChild});
}
myCallback10_text  = (dataFromChild) => {
this.setState({selectedValue10_text: dataFromChild});
}
myCallback11  = (dataFromChild) => {
this.setState({selectedValue11: dataFromChild});
}
myCallback11_text  = (dataFromChild) => {
this.setState({selectedValue11_text: dataFromChild});
}
myCallback12  = (dataFromChild) => {
this.setState({selectedValue12: dataFromChild});
}
myCallback12_text  = (dataFromChild) => {
this.setState({selectedValue12_text: dataFromChild});
}
myCallback13  = (dataFromChild) => {
this.setState({selectedValue13: dataFromChild});
}
myCallback13_text  = (dataFromChild) => {
this.setState({selectedValue13_text: dataFromChild});
}
myCallback14  = (dataFromChild) => {
this.setState({selectedValue14: dataFromChild});
}
myCallback14_text  = (dataFromChild) => {
this.setState({selectedValue14_text: dataFromChild});
}
myCallback15  = (dataFromChild) => {
this.setState({selectedValue15: dataFromChild});
}
myCallback15_text  = (dataFromChild) => {
this.setState({selectedValue15_text: dataFromChild});
}
myCallback16  = (dataFromChild) => {
this.setState({selectedValue16: dataFromChild});
}
myCallback16_text  = (dataFromChild) => {
this.setState({selectedValue16_text: dataFromChild});
}
myCallback17  = (dataFromChild) => {
this.setState({selectedValue17: dataFromChild});
}
myCallback17_text  = (dataFromChild) => {
this.setState({selectedValue17_text: dataFromChild});
}
myCallback18  = (dataFromChild) => {
this.setState({selectedValue18: dataFromChild});
}
myCallback18_text  = (dataFromChild) => {
this.setState({selectedValue18_text: dataFromChild});
}
myCallback19  = (dataFromChild) => {
this.setState({selectedValue19: dataFromChild});
}
myCallback19_text  = (dataFromChild) => {
this.setState({selectedValue19_text: dataFromChild});
}
myCallback20  = (dataFromChild) => {
this.setState({selectedValue20: dataFromChild});
}
myCallback20_text  = (dataFromChild) => {
this.setState({selectedValue20_text: dataFromChild});
}
myCallback21  = (dataFromChild) => {
this.setState({selectedValue21: dataFromChild});
}
myCallback21_text  = (dataFromChild) => {
this.setState({selectedValue21_text: dataFromChild});
}
myCallback22  = (dataFromChild) => {
this.setState({selectedValue22: dataFromChild});
}
myCallback22_text  = (dataFromChild) => {
this.setState({selectedValue22_text: dataFromChild});
}
myCallback23  = (dataFromChild) => {
this.setState({selectedValue23: dataFromChild});
}
myCallback23_text  = (dataFromChild) => {
this.setState({selectedValue23_text: dataFromChild});
}
myCallback24  = (dataFromChild) => {
this.setState({selectedValue24: dataFromChild});
}
myCallback24_text  = (dataFromChild) => {
this.setState({selectedValue24_text: dataFromChild});
}
myCallback25  = (dataFromChild) => {
this.setState({selectedValue25: dataFromChild});
}
myCallback25_text  = (dataFromChild) => {
this.setState({selectedValue25_text: dataFromChild});
}
myCallback26  = (dataFromChild) => {
this.setState({selectedValue26: dataFromChild});
}
myCallback26_text  = (dataFromChild) => {
this.setState({selectedValue26_text: dataFromChild});
}
    

    
render() {
    
    if (this.state.user === null){
        return(
            
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>                 
        <Link to="/" style={{padding:15}}>Home</Link>            
        </div>
      </header>     

      <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
        <h1>Welcome to OrthoPowerTools, a project of <a href="https://orthotoolkit.com/">OrthoToolKit!</a></h1>

        <p>This initiative strives to improve the way in which people understand and communicate their orthopaedic health by providing all the features of OrthoToolKit as well as an <b>easy-to-use system</b> for clinicians to collect, store, and analyze common outcome measures from their patients.</p>
      
      <br/>
      
        
      <p>To learn more, please visit our <Link to="/">home page</Link></p>
      

 
      

      </div>
    </div>  
            
            
            
        );
    }
    
  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>                 
        <Link to="/" style={{padding:15}}>Home</Link>            
        </div>
      </header>  
      

            <div style={{marginLeft:50, marginRight:50}}>
            <br/>
            <h2>Send Unique WHOQOL-BREF Link To Your Patients</h2>
            <br/>
            <form onSubmit={this.handleSubmitSendInvite}>
      
      
            <h4>Please fill in the below three text inputs. When you click the 'Send Score Invite' button, an email will be sent to your patient that contains a unique link to a WHOQOL-BREF score. When the patient finishes the survey and clicks submit, it will automatically be shared with you. An example email is listed below.</h4>
      
      
     
              <h4>Patient email address:</h4>                    
              <input type="text" name="patient_email_to_send_score" placeholder="Enter patient's email address here" onChange={this.handleChange} value={this.state.patient_email_to_send_score} /> 
      
              <h4>Patient given name:</h4>                          
              <input type="text" name="patient_name_to_send_score" placeholder="Enter patient's given name here (how name will be displayed on saved score)" onChange={this.handleChange} value={this.state.patient_name_to_send_score} /> 
      
              <h4>Your given name:</h4>                                
              <input type="text" name="clinician_name_to_send_score" placeholder="Enter the your name here (as you would like it to appear in the score invitation)" onChange={this.handleChange} value={this.state.clinician_name_to_send_score} />       
              <br />
      
          <button style = {{width: '100%'}}>Send Score Invite</button>       
      

            <p><h5>To: {this.state.patient_email_to_send_score}</h5></p>
            <p><h5>From: orthopowertools@gmail.com</h5></p>
            <p><h5>Subject: Provider Requested WHO QOL BREF Score Completion</h5></p>
      
      
            <p><h5>Hello {this.state.patient_name_to_send_score},<br/><br/>One of your health care providers, {this.state.clinician_name_to_send_score}, has asked that you complete a World Health Organization Quality of Life Abbreviated (WHOQOL-BREF) Survey. The information in this survey is confidential and will only be shared with your provider. <br/><br/>Please click <u>here</u> to complete the survey.</h5></p><br/>Best regards,<br/>OrthoPowerTools`                      

     
                    
            </form>        
      
      
              <div className='user-profile'>
                 <img src={this.state.user.photoURL} />
              </div>
      
      
            <br/>
      
            <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
            {this.state.user ? (
                <span>
                <button onClick={this.logout}>Logout</button>
                </span>
            ) : (
                <StyledFirebaseAuth
                    uiConfig={this.uiConfig}
                    firebaseAuth={firebase.auth()}
                />
  
            )}  
            </div>

            </div>      
      

      
    </div>
  );
}
}
    
export default WHOQOLBREF_Send_Link;


