import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth"
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup, Table, DropdownButton, Dropdown} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
//const jsPDF = require('jspdf');
import jsPDF from 'jspdf';
import {Link, Route} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
const currentDate = new Date();







class Send_Text_Request extends PureComponent {
    
  constructor() {
    super();
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      scores_id: [], //this displays ID number for the scores saved for each clinician        
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual
        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to
      patient_phone_number_to_send_score: '', //this is the variable where clinicians enter the phone number of a patient they want to send a score to
      patient_name_to_send_score: '', //this is the variable where clinicians enter the given name of a patient they want to send a score to, which makes the email more personable
      clinician_name_to_send_score: '', //this is the variable where clinicians enter their own given name when they send  score to a patient, which makes the email more personable 
        
      chosen_score: 'aclrsi-link', //this is the variable for the drop down menu which allows clinicians to choose which score link they would like to send
        
    //Calculator variables    
        selectedValueS1: 0,
        selectedValueS1_text: 'How much does illness affect your life: ',

        selectedValueS2: 0,
        selectedValueS2_text: 'How long do you think your illness will continue: ',

        selectedValueS3: 10,
        selectedValueS3_text: 'How much control do you feel you have over your illness: ',

        selectedValueS4: 10,
        selectedValueS4_text: 'How much do you think your treatment can help: ',

        selectedValueS5: 0,
        selectedValueS5_text: 'How much do you experience symptoms from your illness: ',

        selectedValueS6: 0,
        selectedValueS6_text: 'How concerned are you about your illness: ',

        selectedValueS7: 10,
        selectedValueS7_text: 'How well do you feel you understand your illness: ',

        selectedValueS8: 0,
        selectedValueS8_text: 'How much does your illness affect you emotionally: ',
      
        first_cause: '', //free text responses for this score
        second_cause: '', //free text responses for this score
        third_cause: '', //free text responses for this score  
                   
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.modifyData = this.modifyData.bind(this);
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
      
  this.handleSelect = this.handleSelect.bind(this);
      
     
      
  }
    
componentDidMount() {
    
    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  BIPQScoresRef.collection("bipq_scores").get().then(querySnapshot =>{
      
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id //this part is a weird flaw in the system where querySnapshot doesn't actually pull the doc ID, so you have to do it seperately and then combined the document data with each doc ID
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  })
              
        
}  else {
    
  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  BIPQScoresRef.collection("bipq_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  BIPQScoresRef.collection("bipq_scores").get().then(querySnapshot =>{
      

    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
                       
        
} else {
    

    
  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  BIPQScoresRef.collection("bipq_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
    const data_combined = [];  
      
    querySnapshot.docs.forEach(doc => {
         const myData = doc.data()
         myData.id = doc.id
         data_combined.push(myData)
    })
      
    this.setState({
      scores: data_combined,
    });   
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value.replace('"','')
  });
} 
    
handleSelect(e) {
    this.setState({ chosen_score: e.target.value });
  }    
    
 
handleSubmit(e) {
  e.preventDefault();  
  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.settings({
      timestampsInSnapshots: true
  });  
  const bipq_score = BIPQScoresRef.collection("bipq_scores").add({
    shared_with: this.state.clinician_to_share_with,
    user: this.state.user_id,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Brief Illness Perception Questionnaire',
    Question_01_BIPQ: this.state.selectedValueS1,
    Question_02_BIPQ: this.state.selectedValueS2,
    Question_03_BIPQ: this.state.selectedValueS3,
    Question_04_BIPQ: this.state.selectedValueS4,
    Question_05_BIPQ: this.state.selectedValueS5,
    Question_06_BIPQ: this.state.selectedValueS6,
    Question_07_BIPQ: this.state.selectedValueS7,            
    Question_08_BIPQ: this.state.selectedValueS8,
    First_Cause_BIPQ: this.state.first_cause,
    Second_Cause_BIPQ: this.state.second_cause,
    Third_Cause_BIPQ: this.state.third_cause,      
    Total_Score_BIPQ:((this.state.selectedValueS1+this.state.selectedValueS2+(10-this.state.selectedValueS3)+(10-this.state.selectedValueS4)+this.state.selectedValueS5+this.state.selectedValueS6+(10-this.state.selectedValueS7)+this.state.selectedValueS8)),         
  });
  this.setState({
    clinician_to_share_with: '',
    user_id: '',      
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.state.clinician_to_share_with + '.');   
    
} 
    
modifyData(score_id) {  
  const BIPQScoresRef = firebase.firestore();
  BIPQScoresRef.collection("bipq_scores").doc(String(score_id)).update({
    shared_with: "None"    
  });  
    alert('Score deleted.');     
    
} 
    
    
    
    
    
    
/*   
//The below code works. It may appear it doesn't with 'yarn start' but to check this, just try another one of the 
//firestore writes that you know works (eg BIPQ-Send-Link.js). When you get that error, just publish the project
//and try from there. This is annoying because it takes a lot of time
handleSubmitSendInvite(e) {
  e.preventDefault();  
  firebase.firestore().collection('messages').add({
    to: '+17245167914',
    body: 'Test',         
  });

}
*/
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
if (this.state.chosen_score == "lefs-link-spanish" | this.state.chosen_score =="ndi-link-spanish" | this.state.chosen_score == "quickdash-link-spanish" | this.state.chosen_score == "spadi-link-spanish"){
  const patient_invites = PatientInvites.collection("messages").add({
    //to: this.state.patient_phone_number_to_send_score,
    //use above if clinician has to add +1    
    to: "+1" + this.state.patient_phone_number_to_send_score, 
    //use above if you don't want user to have to add +1      
    body: "Hola " + this.state.patient_name_to_send_score + " su proveedor de atención médica " + this.state.clinician_name_to_send_score + " desea que complete una encuesta de salud, a la que puede acceder haciendo clic en el enlace", 
    //source for body: "https://orthopowertools.com/spadi-link/${snap.data().patient_name_to_send_score}/${snap.data().clinician_who_sent_email}/${snap.id}"  
    clinician_who_sent_email: this.state.user.email,       
    patient_name_to_send_score: this.state.patient_name_to_send_score,
    clinician_who_sent_name: this.state.clinician_name_to_send_score,        
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  const patient_invites_2 = PatientInvites.collection("messages").add({
    //to: this.state.patient_phone_number_to_send_score,
    //use above if clinician has to add +1
    to: "+1" + this.state.patient_phone_number_to_send_score, 
    //use above if you don't want user to have to add +1      
    body: encodeURI("https://orthopowertools.com/" + this.state.chosen_score + "/" + this.state.patient_name_to_send_score + "/" + this.state.user.email + "/zzzz" + Date.now().toString()), 
    //source for body: "https://orthopowertools.com/spadi-link/${snap.data().patient_name_to_send_score}/${snap.data().clinician_who_sent_email}/${snap.id}"  
    clinician_who_sent_email: this.state.user.email,       
    patient_name_to_send_score: this.state.patient_name_to_send_score,
    clinician_who_sent_name: this.state.clinician_name_to_send_score,        
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });    
}else if (this.state.chosen_score =="ndi-link-italian"){
  const patient_invites = PatientInvites.collection("messages").add({
    //to: this.state.patient_phone_number_to_send_score,
    //use above if clinician has to add +1
    to: "+1" + this.state.patient_phone_number_to_send_score, 
    //use above if you don't want user to have to add +1      
    body: "Ciao " + this.state.patient_name_to_send_score + " il tuo medico " + this.state.clinician_name_to_send_score + " desidera che tu completi un sondaggio sulla salute, a cui puoi accedere facendo clic sul collegamento", 
    //source for body: "https://orthopowertools.com/spadi-link/${snap.data().patient_name_to_send_score}/${snap.data().clinician_who_sent_email}/${snap.id}"  
    clinician_who_sent_email: this.state.user.email,       
    patient_name_to_send_score: this.state.patient_name_to_send_score,
    clinician_who_sent_name: this.state.clinician_name_to_send_score,        
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  const patient_invites_2 = PatientInvites.collection("messages").add({
    //to: this.state.patient_phone_number_to_send_score,
    //use above if clinician has to add +1
    to: "+1" + this.state.patient_phone_number_to_send_score, 
    //use above if you don't want user to have to add +1      
    body: encodeURI("https://orthopowertools.com/" + this.state.chosen_score + "/" + this.state.patient_name_to_send_score + "/" + this.state.user.email + "/zzzz" + Date.now().toString()), 
    //source for body: "https://orthopowertools.com/spadi-link/${snap.data().patient_name_to_send_score}/${snap.data().clinician_who_sent_email}/${snap.id}"  
    clinician_who_sent_email: this.state.user.email,       
    patient_name_to_send_score: this.state.patient_name_to_send_score,
    clinician_who_sent_name: this.state.clinician_name_to_send_score,        
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });    
}else{
  const patient_invites = PatientInvites.collection("messages").add({
    //to: this.state.patient_phone_number_to_send_score,
    //use above if clinician has to add +1
    to: "+1" + this.state.patient_phone_number_to_send_score, 
    //use above if you don't want user to have to add +1      
    body: "Hello " + this.state.patient_name_to_send_score + " your health care provider " + this.state.clinician_name_to_send_score + " would like you to complete a health survey, which you can access by clicking the link", 
    //source for body: "https://orthopowertools.com/spadi-link/${snap.data().patient_name_to_send_score}/${snap.data().clinician_who_sent_email}/${snap.id}"  
    clinician_who_sent_email: this.state.user.email,       
    patient_name_to_send_score: this.state.patient_name_to_send_score,
    clinician_who_sent_name: this.state.clinician_name_to_send_score,        
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  const patient_invites_2 = PatientInvites.collection("messages").add({
    //to: this.state.patient_phone_number_to_send_score,
    //use above if clinician has to add +1
    to: "+1" + this.state.patient_phone_number_to_send_score, 
    //use above if you don't want user to have to add +1
    body: encodeURI("https://orthopowertools.com/" + this.state.chosen_score + "/" + this.state.patient_name_to_send_score + "/" + this.state.user.email + "/zzzz" + Date.now().toString()), 
    //source for body: "https://orthopowertools.com/spadi-link/${snap.data().patient_name_to_send_score}/${snap.data().clinician_who_sent_email}/${snap.id}"  
    clinician_who_sent_email: this.state.user.email,       
    patient_name_to_send_score: this.state.patient_name_to_send_score,
    clinician_who_sent_name: this.state.clinician_name_to_send_score,        
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
}
    
  this.setState({
    patient_phone_number_to_send_score: '',
    clinician_who_sent: '',
    patient_name_to_send_score: '',
    clinician_name_to_send_score: '',  
  });
    if (this.state.patient_phone_number_to_send_score !== ''){
    alert('Thank you for using OrthoPowerTools! A text message has been sent to the entered phone number with a link to complete a survey.');
    }
    else {
    alert('Thank you for using OrthoPowerTools! Your invitation was not sent because you did not enter the patients phone number. To send the invitation, please enter the patients phone number at the top of the page in the section that states "Enter the patients phone number here"');        
    }       
    
} 
      
    
    


  
    
    
    
 
    
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallbackS1  = (dataFromChild) => {
    this.setState({selectedValueS1: dataFromChild});
    }
    myCallbackS2  = (dataFromChild) => {
    this.setState({selectedValueS2: dataFromChild});
    }
    myCallbackS3  = (dataFromChild) => {
    this.setState({selectedValueS3: dataFromChild});
    }
    myCallbackS4  = (dataFromChild) => {
    this.setState({selectedValueS4: dataFromChild});
    }
    myCallbackS5  = (dataFromChild) => {
    this.setState({selectedValueS5: dataFromChild});
    }
    myCallbackS6  = (dataFromChild) => {
    this.setState({selectedValueS6: dataFromChild});
    }
    myCallbackS7  = (dataFromChild) => {
    this.setState({selectedValueS7: dataFromChild});
    }
    myCallbackS8  = (dataFromChild) => {
    this.setState({selectedValueS8: dataFromChild});
    }  
    myCallbackDropdown = (dataFromChild) => {
    this.setState({chosen_score: dataFromChild});
    }
  
    
    

    
render() {
    
    let {chosen_score} = this.state;
        
    const spanish_score = () => {
         if (chosen_score == "lefs-link-spanish" | chosen_score =="ndi-link-spanish" | chosen_score == "quickdash-link-spanish" | chosen_score == "spadi-link-spanish"){
            return <span>
                      
            <p><h4>Example text message:</h4></p>

            <p><h5>Cell phone number: {this.state.patient_phone_number_to_send_score}</h5></p>

            <p><h5>Hola {this.state.patient_name_to_send_score}, su proveedor de atención médica {this.state.clinician_name_to_send_score} desea que complete una encuesta de salud, a la que puede acceder haciendo clic en el enlace:</h5></p>
                
            <p><h5><u>https://orthopowertools.com/{this.state.chosen_score}/examplelink...</u></h5></p>        
      
      
            <p><h4>English Translation:</h4></p></span>                
                
                
                
            }
        } 
    
    const italian_score = () => {
         if (chosen_score =="ndi-link-italian"){
            return <span>
                      
            <p><h4>Esempio di messaggio di testo:</h4></p>

            <p><h5>Numero di cellulare: {this.state.patient_phone_number_to_send_score}</h5></p>

            <p><h5>Ciao {this.state.patient_name_to_send_score}, il tuo medico {this.state.clinician_name_to_send_score} desidera che tu completi un sondaggio sulla salute, a cui puoi accedere facendo clic sul collegamento:</h5></p>
                
            <p><h5><u>https://orthopowertools.com/{this.state.chosen_score}/examplelink...</u></h5></p>        
      
      
            <p><h4>English Translation:</h4></p></span>                
                
                
                
            }
        }     
    
    
    
    if (this.state.user === null){
        return(
            
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>                 
        <Link to="/" style={{padding:15}}>Home</Link>            
        </div>
      </header>     

      <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
        <h1>Welcome to OrthoPowerTools, a project of <a href="https://orthotoolkit.com/">OrthoToolKit!</a></h1>

        <p>This initiative strives to improve the way in which people understand and communicate their orthopaedic health by providing all the features of OrthoToolKit as well as an <b>easy-to-use system</b> for clinicians to collect, store, and analyze common outcome measures from their patients.</p>
      
      <br/>
      
        
      <p>To learn more, please visit our <Link to="/">home page</Link></p>
      

 
      

      </div>
    </div>  
            
            
            
        );
    }
    
  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>                 
        <Link to="/" style={{padding:15}}>Home</Link>            
        </div>
      </header>  
      

            <div style={{marginLeft:50, marginRight:50}}>
            <br/>
            <h2>Send a Text Message with Unique Score Link To Your Patients (United States and Canada)</h2>
            <br/>
            <form onSubmit={this.handleSubmitSendInvite}>
      
      
            <h4>Please select the score you would like to send to your patient and then fill in the below three text inputs. When you click the 'Send Score Invite' button, a text message will be sent to your patient that contains a unique link to the chosen score. When the patient finishes the survey and clicks submit, it will automatically be shared with you. An example text message is listed below.</h4>
      
      
              <h4>Choose a score:</h4>                    

                <select name="scores" id="scores" value={this.state.chosen_score} onChange={this.handleSelect}>
                  <option value="aclrsi-link">ACL Return to Sport after Injury (ACL-RSI)</option>                       
                  <option value="aofash-link">AOFAS Hallux MTP IP (AOFASH)</option>        
                  <option value="bipq-link">Brief Illness Perception Questionnaire (BIPQ)</option> 
                  <option value="dhi-link">Dizziness Handicap Inventory (DHI)</option> 
                  <option value="faam-link">Foot and Ankle Ability Measure (FAAM)</option>        
                  <option value="hoos12-link">Hip disability and Osteoarthritis Outcome Score 12 (HOOS-12)</option>
                  <option value="koos12-link">Knee injury and Osteoarthritis Outcome Score 12 (KOOS-12)</option>
                  <option value="lefs-link">Lower Extremity Functional Scale (LEFS)</option>
                  <option value="lefs-link-spanish">Escala Funcional De Extremidades Inferiores (LEFS Spanish)</option>    
                  <option value="ndi-link">Neck Disability Index</option>    
                  <option value="ndi-link-spanish">El Índice de Discapacidad Cervical (NDI Spanish)</option>
                  <option value="odi-link">Oswestry Disability Index (ODI)</option>    
                  <option value="quickdash-link">QuickDASH</option>
                  <option value="quickdash-link-spanish">QuickDASH Espanol</option>    
                  <option value="sf12-link">12 Item Short Form Survey (SF-12)</option>    
                  <option value="sf36-link">36 Item Short Form Survey (SF-36)</option> 
                  <option value="spadi-link">Shouler Pain and Disability Index (SPADI)</option>
                  <option value="spadi-link-spanish">Indice de Dolor y Discapacidad del Hombro (SPADI Spanish)</option>    
                  <option value="tsk-link">Tampa Scale for Kinesiophobia (TSK)</option>   
                  <option value="vas-link">Visual Analogue Scale (VAS)</option>    
                  <option value="visaa-link">Victorian Institute of Sports Assessment - Achilles (VISA-A)</option>    
                  <option value="visag-link">Victorian Institute of Sports Assessment - Greater Trochanter (VISA-G)</option>  
                  <option value="visah-link">Victorian Institute of Sports Assessment - Proximal Hamstring Tendon (VISA-H)</option>
                  <option value="visap-link">Victorian Institute of Sports Assessment - Patellar Tendon (VISA-P)</option>
                  <option value="whoqolbref-link">World Health Organization Quality of Life Abbreviated (WHOQOL-BREF)</option>    
    
                </select>

      
              <h4>Patient phone number (eg. (012)-345-6789 should be entered as 0123456789):</h4>                    
              <input type="text" name="patient_phone_number_to_send_score" placeholder="Enter patient's phone number here ((012)-345-6789 should be entered as 0123456789)" onChange={this.handleChange} value={this.state.patient_phone_number_to_send_score} /> 
      
              <h4>Patient given name:</h4>                          
              <input type="text" name="patient_name_to_send_score" placeholder="Enter patient's given name here (how name will be displayed on saved score)" onChange={this.handleChange} value={this.state.patient_name_to_send_score} /> 
      
              <h4>Your given name:</h4>                                
              <input type="text" name="clinician_name_to_send_score" placeholder="Enter the your name here (as you would like it to appear in the score invitation)" onChange={this.handleChange} value={this.state.clinician_name_to_send_score} />       
              <br />
      
      

      
     
      
          <button style = {{width: '100%'}}>Send Score Invite</button>       
      
            {spanish_score()}

            {italian_score()}

            <p><h4>Example text message:</h4></p>

            <p><h5>Cell phone number: {this.state.patient_phone_number_to_send_score}</h5></p>

            <p><h5>Hello {this.state.patient_name_to_send_score}, your health care provider {this.state.clinician_name_to_send_score} would like you to complete a health survey, which you can access by clicking the link:</h5></p>
                
            <p><h5><u>https://orthopowertools.com/{this.state.chosen_score}/examplelink...</u></h5></p>                
                             

     
                    
            </form>      


   
      
      
              <div className='user-profile'>
                 <img src={this.state.user.photoURL} />
              </div>
      
      
            <br/>
      
            <div style={{textAlign: "center", marginLeft:50, marginRight:50}}>
            {this.state.user ? (
                <span>
                <button onClick={this.logout}>Logout</button>
                </span>
            ) : (
                <StyledFirebaseAuth
                    uiConfig={this.uiConfig}
                    firebaseAuth={firebase.auth()}
                />
  
            )}  
            </div>

            </div>      
      

      
    </div>
  );
}
}
    
export default Send_Text_Request;

                  //<option value="ndi-link-italian">Indice Di Disabilità Del Collo (NDI Italian)</option> not sure text works in Itay but just need to add the above line if you decide to incorporate foreign SMS messaging (all other code should be set up)
