import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
//const jsPDF = require('jspdf');
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
//import queryString from 'query-string'; don't think I'll use this line

///////////////////////////////////////////Calculator Methods

class Buttons1 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent1(value);
if (value === 0)
this.props.callbackFromParent1_text("Al momento non ho dolore.");
if (value === 1)
this.props.callbackFromParent1_text("Al momento il dolore è molto lieve.");
if (value === 2)
this.props.callbackFromParent1_text("Al momento il dolore è di media intensità.");
if (value === 3)
this.props.callbackFromParent1_text("Al momento il dolore è abbastanza forte.");
if (value === 4)
this.props.callbackFromParent1_text("Al momento il dolore è molto forte.");
if (value === 5)
this.props.callbackFromParent1_text("Al momento il dolore non potrebbe essere peggiore.");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Sezione 1 - Intensità del dolore</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Al momento non ho dolore.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Al momento il dolore è molto lieve.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Al momento il dolore è di media intensità.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Al momento il dolore è abbastanza forte.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Al momento il dolore è molto forte.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Al momento il dolore non potrebbe essere peggiore.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Sezione 1 - Intensità del dolore</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Al momento non ho dolore.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Al momento il dolore è molto lieve.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Al momento il dolore è di media intensità.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Al momento il dolore è abbastanza forte.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Al momento il dolore è molto forte.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Al momento il dolore non potrebbe essere peggiore.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons2 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent2(value);
if (value === 0)
this.props.callbackFromParent2_text("Riesco a prendermi cura di me stesso/a normalmente senza sentire più dolore del solito.");
if (value === 1)
this.props.callbackFromParent2_text("Riesco a prendermi cura di me stesso/a normalmente ma mi fa molto male.");
if (value === 2)
this.props.callbackFromParent2_text("Mi fa male prendermi cura di me stesso/a e sono lento/a e prudente.");
if (value === 3)
this.props.callbackFromParent2_text("Ho bisogno di un po' di aiuto ma riesco per lo più a prendermi cura di me stesso/a.");
if (value === 4)
this.props.callbackFromParent2_text("Ho bisogno di aiuto ogni giorno in quasi tutti gli aspetti della cura di me stesso/a.");
if (value === 5)
this.props.callbackFromParent2_text("Non mi vesto, mi lavo con difficoltà e sto a letto.");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Sezione 2 - Cura personale (lavarsi, vestirsi, ecc.)</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a prendermi cura di me stesso/a normalmente senza sentire più dolore del solito.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a prendermi cura di me stesso/a normalmente ma mi fa molto male.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mi fa male prendermi cura di me stesso/a e sono lento/a e prudente.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ho bisogno di un po' di aiuto ma riesco per lo più a prendermi cura di me stesso/a.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ho bisogno di aiuto ogni giorno in quasi tutti gli aspetti della cura di me stesso/a.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non mi vesto, mi lavo con difficoltà e sto a letto.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Sezione 2 - Cura personale (lavarsi, vestirsi, ecc.)</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a prendermi cura di me stesso/a normalmente senza sentire più dolore del solito.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a prendermi cura di me stesso/a normalmente ma mi fa molto male.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Mi fa male prendermi cura di me stesso/a e sono lento/a e prudente.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ho bisogno di un po' di aiuto ma riesco per lo più a prendermi cura di me stesso/a.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Ho bisogno di aiuto ogni giorno in quasi tutti gli aspetti della cura di me stesso/a.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non mi vesto, mi lavo con difficoltà e sto a letto.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons3 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent3(value);
if (value === 0)
this.props.callbackFromParent3_text("Riesco a sollevare oggetti pesanti senza sentire più dolore del solito.");
if (value === 1)
this.props.callbackFromParent3_text("Riesco a sollevare oggetti pesanti ma sentendo più dolore del solito.");
if (value === 2)
this.props.callbackFromParent3_text("Il dolore mi impedisce di sollevare oggetti pesanti da terra, ma ci riesco se sono posizionati in maniera opportuna, per esempio su un tavolo.");
if (value === 3)
this.props.callbackFromParent3_text("Il dolore mi impedisce di sollevare oggetti pesanti, ma riesco a sollevare oggetti leggeri o di medio peso se sono posizionati in maniera opportuna.");
if (value === 4)
this.props.callbackFromParent3_text("Riesco a sollevare solo oggetti molto leggeri.");
if (value === 5)
this.props.callbackFromParent3_text("Non riesco a sollevare o trasportare assolutamente niente.");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Sezione 3 - Alzare pesi</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a sollevare oggetti pesanti senza sentire più dolore del solito.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a sollevare oggetti pesanti ma sentendo più dolore del solito.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Il dolore mi impedisce di sollevare oggetti pesanti da terra, ma ci riesco se sono posizionati in maniera opportuna, per esempio su un tavolo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Il dolore mi impedisce di sollevare oggetti pesanti, ma riesco a sollevare oggetti leggeri o di medio peso se sono posizionati in maniera opportuna.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a sollevare solo oggetti molto leggeri.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco a sollevare o trasportare assolutamente niente.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Sezione 3 - Alzare pesi</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a sollevare oggetti pesanti senza sentire più dolore del solito.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a sollevare oggetti pesanti ma sentendo più dolore del solito.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Il dolore mi impedisce di sollevare oggetti pesanti da terra, ma ci riesco se sono posizionati in maniera opportuna, per esempio su un tavolo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Il dolore mi impedisce di sollevare oggetti pesanti, ma riesco a sollevare oggetti leggeri o di medio peso se sono posizionati in maniera opportuna.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a sollevare solo oggetti molto leggeri.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco a sollevare o trasportare assolutamente niente.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons4 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent4(value);
if (value === 0)
this.props.callbackFromParent4_text("Riesco a leggere quanto voglio senza provare alcun dolore al collo.");
if (value === 1)
this.props.callbackFromParent4_text("Riesco a leggere quanto voglio avvertendo un dolore al collo lieve.");
if (value === 2)
this.props.callbackFromParent4_text("Riesco a leggere quanto voglio avvertendo un dolore al collo di media intensità.");
if (value === 3)
this.props.callbackFromParent4_text("Non riesco a leggere quanto voglio a causa di un dolore al collo di media intensità.");
if (value === 4)
this.props.callbackFromParent4_text("Non riesco a leggere quanto voglio a causa di un dolore al collo molto forte.");
if (value === 5)
this.props.callbackFromParent4_text("Non riesco a leggere del tutto.");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Sezione 4 – Leggere</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a leggere quanto voglio senza provare alcun dolore al collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a leggere quanto voglio avvertendo un dolore al collo lieve.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a leggere quanto voglio avvertendo un dolore al collo di media intensità.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco a leggere quanto voglio a causa di un dolore al collo di media intensità.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco a leggere quanto voglio a causa di un dolore al collo molto forte.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco a leggere del tutto.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Sezione 4 – Leggere</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a leggere quanto voglio senza provare alcun dolore al collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a leggere quanto voglio avvertendo un dolore al collo lieve.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a leggere quanto voglio avvertendo un dolore al collo di media intensità.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco a leggere quanto voglio a causa di un dolore al collo di media intensità.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco a leggere quanto voglio a causa di un dolore al collo molto forte.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco a leggere del tutto.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons5 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent5(value);
if (value === 0)
this.props.callbackFromParent5_text("Non provo mal di testa per nulla.");
if (value === 1)
this.props.callbackFromParent5_text("Provo un mal di testa lieve che insorge raramente.");
if (value === 2)
this.props.callbackFromParent5_text("Provo un mal di testa di media intensità che insorge raramente.");
if (value === 3)
this.props.callbackFromParent5_text("Provo un mal di testa di media intensità che insorge frequentemente.");
if (value === 4)
this.props.callbackFromParent5_text("Provo un mal di testa molto forte che insorge frequentemente.");
if (value === 5)
this.props.callbackFromParent5_text("Provo quasi sempre mal di testa.");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Sezione 5 – Mal di testa</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non provo mal di testa per nulla.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Provo un mal di testa lieve che insorge raramente.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Provo un mal di testa di media intensità che insorge raramente.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Provo un mal di testa di media intensità che insorge frequentemente.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Provo un mal di testa molto forte che insorge frequentemente.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Provo quasi sempre mal di testa.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Sezione 5 – Mal di testa</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non provo mal di testa per nulla.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Provo un mal di testa lieve che insorge raramente.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Provo un mal di testa di media intensità che insorge raramente.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Provo un mal di testa di media intensità che insorge frequentemente.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Provo un mal di testa molto forte che insorge frequentemente.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Provo quasi sempre mal di testa.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons6 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent6(value);
if (value === 0)
this.props.callbackFromParent6_text("Riesco a concentrarmi perfettamente quando lo desidero senza difficoltà.");
if (value === 1)
this.props.callbackFromParent6_text("Riesco a concentrarmi perfettamente quando lo desidero con leggera difficoltà.");
if (value === 2)
this.props.callbackFromParent6_text("Avverto una difficoltà intermedia a concentrarmi quando lo desidero.");
if (value === 3)
this.props.callbackFromParent6_text("Avverto molta difficoltà a concentrarmi quando lo desidero.");
if (value === 4)
this.props.callbackFromParent6_text("Avverto moltissima difficoltà a concentrarmi quando lo desidero.");
if (value === 5)
this.props.callbackFromParent6_text("Non riesco a concentrarmi del tutto.");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Sezione 6 - Concentrarsi</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a concentrarmi perfettamente quando lo desidero senza difficoltà.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a concentrarmi perfettamente quando lo desidero con leggera difficoltà.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Avverto una difficoltà intermedia a concentrarmi quando lo desidero.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Avverto molta difficoltà a concentrarmi quando lo desidero.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Avverto moltissima difficoltà a concentrarmi quando lo desidero.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco a concentrarmi del tutto.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Sezione 6 - Concentrarsi</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a concentrarmi perfettamente quando lo desidero senza difficoltà.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a concentrarmi perfettamente quando lo desidero con leggera difficoltà.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Avverto una difficoltà intermedia a concentrarmi quando lo desidero.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Avverto molta difficoltà a concentrarmi quando lo desidero.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Avverto moltissima difficoltà a concentrarmi quando lo desidero.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco a concentrarmi del tutto.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons7 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent7(value);
if (value === 0)
this.props.callbackFromParent7_text("Riesco a svolgere tutto il lavoro che voglio.");
if (value === 1)
this.props.callbackFromParent7_text("Riesco a svolgere solo il mio lavoro abituale, ma nulla di più.");
if (value === 2)
this.props.callbackFromParent7_text("Riesco a svolgere parte del mio lavoro abituale, ma nulla di più.");
if (value === 3)
this.props.callbackFromParent7_text("Non riesco a svolgere il mio lavoro abituale.");
if (value === 4)
this.props.callbackFromParent7_text("Svolgo ogni lavoro con molta difficoltà.");
if (value === 5)
this.props.callbackFromParent7_text("Non riesco più a svolgere alcun lavoro.");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Sezione 7 - Lavorare</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a svolgere tutto il lavoro che voglio.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a svolgere solo il mio lavoro abituale, ma nulla di più.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a svolgere parte del mio lavoro abituale, ma nulla di più.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco a svolgere il mio lavoro abituale.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Svolgo ogni lavoro con molta difficoltà.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco più a svolgere alcun lavoro.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Sezione 7 - Lavorare</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a svolgere tutto il lavoro che voglio.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a svolgere solo il mio lavoro abituale, ma nulla di più.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a svolgere parte del mio lavoro abituale, ma nulla di più.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco a svolgere il mio lavoro abituale.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Svolgo ogni lavoro con molta difficoltà.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco più a svolgere alcun lavoro.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons8 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent8(value);
if (value === 0)
this.props.callbackFromParent8_text("Riesco a guidare la mia macchina senza alcun dolore al collo.");
if (value === 1)
this.props.callbackFromParent8_text("Riesco a guidare la mia macchina fin quando voglio provando un lieve dolore al collo.");
if (value === 2)
this.props.callbackFromParent8_text("Riesco a guidare la mia macchina fin quando voglio provando un dolore al collo di media intensità.");
if (value === 3)
this.props.callbackFromParent8_text("Non riesco a guidare la mia macchina fin quando voglio a causa di un dolore al collo di media intensità.");
if (value === 4)
this.props.callbackFromParent8_text("Riesco a guidare proprio con molta difficoltà a causa di un forte dolore al collo.");
if (value === 5)
this.props.callbackFromParent8_text("Non riesco più a guidare la mia macchina a causa del dolore cervicale.");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Sezione 8 - Guidare</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a guidare la mia macchina senza alcun dolore al collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a guidare la mia macchina fin quando voglio provando un lieve dolore al collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a guidare la mia macchina fin quando voglio provando un dolore al collo di media intensità.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco a guidare la mia macchina fin quando voglio a causa di un dolore al collo di media intensità.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a guidare proprio con molta difficoltà a causa di un forte dolore al collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco più a guidare la mia macchina a causa del dolore cervicale.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Sezione 8 - Guidare</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a guidare la mia macchina senza alcun dolore al collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a guidare la mia macchina fin quando voglio provando un lieve dolore al collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a guidare la mia macchina fin quando voglio provando un dolore al collo di media intensità.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco a guidare la mia macchina fin quando voglio a causa di un dolore al collo di media intensità.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Riesco a guidare proprio con molta difficoltà a causa di un forte dolore al collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco più a guidare la mia macchina a causa del dolore cervicale.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons9 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent9(value);
if (value === 0)
this.props.callbackFromParent9_text("Non ho problemi per dormire.");
if (value === 1)
this.props.callbackFromParent9_text("Il mio riposo è scarsamente disturbato (meno di un’ora di insonnia).");
if (value === 2)
this.props.callbackFromParent9_text("Il mio riposo è leggermente disturbato (1-2 ore di insonnia).");
if (value === 3)
this.props.callbackFromParent9_text("Il mio riposo è moderatamente disturbato (2-3 ore di insonnia).");
if (value === 4)
this.props.callbackFromParent9_text("Il mio riposo è disturbato moltissimo (3-5 ore di insonnia).");
if (value === 5)
this.props.callbackFromParent9_text("Il mio riposo è completamente disturbato (5-7 ore di insonnia).");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Sezione 9 - Dormire</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non ho problemi per dormire.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Il mio riposo è scarsamente disturbato (meno di un’ora di insonnia).</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Il mio riposo è leggermente disturbato (1-2 ore di insonnia).</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Il mio riposo è moderatamente disturbato (2-3 ore di insonnia).</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Il mio riposo è disturbato moltissimo (3-5 ore di insonnia).</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Il mio riposo è completamente disturbato (5-7 ore di insonnia).</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Sezione 9 - Dormire</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non ho problemi per dormire.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Il mio riposo è scarsamente disturbato (meno di un’ora di insonnia).</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Il mio riposo è leggermente disturbato (1-2 ore di insonnia).</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Il mio riposo è moderatamente disturbato (2-3 ore di insonnia).</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Il mio riposo è disturbato moltissimo (3-5 ore di insonnia).</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Il mio riposo è completamente disturbato (5-7 ore di insonnia).</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons10 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn = (value) => {
this.props.callbackFromParent10(value);
if (value === 0)
this.props.callbackFromParent10_text("Posso dedicarmi a tutti i miei passatempi senza alcun dolore al collo.");
if (value === 1)
this.props.callbackFromParent10_text("Posso dedicarmi a tutti i miei passatempi con un po’ di dolore al mio collo.");
if (value === 2)
this.props.callbackFromParent10_text("Posso dedicarmi a molti, ma non a tutti i miei passatempi a causa del dolore al mio collo.");
if (value === 3)
this.props.callbackFromParent10_text("Posso dedicarmi solo ad alcuni dei miei passatempi a causa del dolore al mio collo.");
if (value === 4)
this.props.callbackFromParent10_text("Posso dedicarmi con difficoltà ai miei passatempi a causa del dolore al mio collo.");
if (value === 5)
this.props.callbackFromParent10_text("Non riesco più a dedicarmi a nessun passatempo.");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>Sezione 10 - Svagarsi</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Posso dedicarmi a tutti i miei passatempi senza alcun dolore al collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Posso dedicarmi a tutti i miei passatempi con un po’ di dolore al mio collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Posso dedicarmi a molti, ma non a tutti i miei passatempi a causa del dolore al mio collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Posso dedicarmi solo ad alcuni dei miei passatempi a causa del dolore al mio collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Posso dedicarmi con difficoltà ai miei passatempi a causa del dolore al mio collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco più a dedicarmi a nessun passatempo.</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>Sezione 10 - Svagarsi</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Posso dedicarmi a tutti i miei passatempi senza alcun dolore al collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Posso dedicarmi a tutti i miei passatempi con un po’ di dolore al mio collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Posso dedicarmi a molti, ma non a tutti i miei passatempi a causa del dolore al mio collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Posso dedicarmi solo ad alcuni dei miei passatempi a causa del dolore al mio collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Posso dedicarmi con difficoltà ai miei passatempi a causa del dolore al mio collo.</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={5}>{(this.state.clicked === 5) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Non riesco più a dedicarmi a nessun passatempo.</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
    


  


class NDI_Link_Italian extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

    //Calculator variables    
    selectedValue1: 0,
    selectedValue1_text: 'Al momento non ho dolore.',

    selectedValue2: 0,
    selectedValue2_text: 'Riesco a prendermi cura di me stesso/a normalmente senza sentire più dolore del solito.',

    selectedValue3: 0,
    selectedValue3_text: 'Riesco a sollevare oggetti pesanti senza sentire più dolore del solito.',

    selectedValue4: 0,
    selectedValue4_text: 'Riesco a leggere quanto voglio senza provare alcun dolore al collo.',

    selectedValue5: 0,
    selectedValue5_text: 'Non provo mal di testa per nulla.',

    selectedValue6: 0,
    selectedValue6_text: 'Riesco a concentrarmi perfettamente quando lo desidero senza difficoltà.',

    selectedValue7: 0,
    selectedValue7_text: 'Riesco a svolgere tutto il lavoro che voglio.',

    selectedValue8: 0,
    selectedValue8_text: 'Riesco a guidare la mia macchina senza alcun dolore al collo.',

    selectedValue9: 0,
    selectedValue9_text: 'Non ho problemi per dormire.',

    selectedValue10: 0,
    selectedValue10_text: 'Posso dedicarmi a tutti i miei passatempi senza alcun dolore al collo.',      
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  NDIScoresRef.collection("ndi_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  NDIScoresRef.collection("ndi_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  NDIScoresRef.collection("ndi_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  NDIScoresRef.collection("ndi_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const NDIScoresRef = firebase.firestore();
  NDIScoresRef.settings({
      timestampsInSnapshots: true
  });  
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){    
  const ndi_score = NDIScoresRef.collection("ndi_scores").doc(this.props.requestSurveyID).set({
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Italian Neck Disability Index',      
    Question_01_NDI: this.state.selectedValue1,
    Question_02_NDI: this.state.selectedValue2,
    Question_03_NDI: this.state.selectedValue3,
    Question_04_NDI: this.state.selectedValue4,
    Question_05_NDI: this.state.selectedValue5,
    Question_06_NDI: this.state.selectedValue6,
    Question_07_NDI: this.state.selectedValue7,            
    Question_08_NDI: this.state.selectedValue8,
    Question_09_NDI: this.state.selectedValue9,
    Question_10_NDI: this.state.selectedValue10,
    Total_Score_NDI: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10),     
  });
}
else {
  const ndi_score = NDIScoresRef.collection("ndi_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'Italian Neck Disability Index',      
    Question_01_NDI: this.state.selectedValue1,
    Question_02_NDI: this.state.selectedValue2,
    Question_03_NDI: this.state.selectedValue3,
    Question_04_NDI: this.state.selectedValue4,
    Question_05_NDI: this.state.selectedValue5,
    Question_06_NDI: this.state.selectedValue6,
    Question_07_NDI: this.state.selectedValue7,            
    Question_08_NDI: this.state.selectedValue8,
    Question_09_NDI: this.state.selectedValue9,
    Question_10_NDI: this.state.selectedValue10,
    Total_Score_NDI: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10),  
  });      
}     
  this.setState({
    clinician_to_share_with: '',
    user_id: '',        
  });
    alert("Grazie per aver utilizzato OrthoPowerTools! La tua richiesta è stata ricevuta e sarà condivisa con il seguente provider: ' + this.props.clinicianSendingEmail + '. Ora puoi chiudere questo avviso e l'intera finestra.");   
}
    

    
    
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("ndi_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallback1  = (dataFromChild) => {
    this.setState({selectedValue1: dataFromChild});
    }
    myCallback1_text  = (dataFromChild) => {
    this.setState({selectedValue1_text: dataFromChild});
    }
    myCallback2  = (dataFromChild) => {
    this.setState({selectedValue2: dataFromChild});
    }
    myCallback2_text  = (dataFromChild) => {
    this.setState({selectedValue2_text: dataFromChild});
    }
    myCallback3  = (dataFromChild) => {
    this.setState({selectedValue3: dataFromChild});
    }
    myCallback3_text  = (dataFromChild) => {
    this.setState({selectedValue3_text: dataFromChild});
    }
    myCallback4  = (dataFromChild) => {
    this.setState({selectedValue4: dataFromChild});
    }
    myCallback4_text  = (dataFromChild) => {
    this.setState({selectedValue4_text: dataFromChild});
    }
    myCallback5  = (dataFromChild) => {
    this.setState({selectedValue5: dataFromChild});
    }
    myCallback5_text  = (dataFromChild) => {
    this.setState({selectedValue5_text: dataFromChild});
    }
    myCallback6  = (dataFromChild) => {
    this.setState({selectedValue6: dataFromChild});
    }
    myCallback6_text  = (dataFromChild) => {
    this.setState({selectedValue6_text: dataFromChild});
    }
    myCallback7  = (dataFromChild) => {
    this.setState({selectedValue7: dataFromChild});
    }
    myCallback7_text  = (dataFromChild) => {
    this.setState({selectedValue7_text: dataFromChild});
    }
    myCallback8  = (dataFromChild) => {
    this.setState({selectedValue8: dataFromChild});
    }
    myCallback8_text  = (dataFromChild) => {
    this.setState({selectedValue8_text: dataFromChild});
    }
    myCallback9  = (dataFromChild) => {
    this.setState({selectedValue9: dataFromChild});
    }
    myCallback9_text  = (dataFromChild) => {
    this.setState({selectedValue9_text: dataFromChild});
    }
    myCallback10  = (dataFromChild) => {
    this.setState({selectedValue10: dataFromChild});
    }
    myCallback10_text  = (dataFromChild) => {
    this.setState({selectedValue10_text: dataFromChild});
    }     
  

  
    
    

    
render() { 

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>Questionarioe Per Indice Di Disabilità Del Collo Calcolatrice</h1>
                <h4>Ciaco, {this.props.submitterName}, il tuo medico, {this.props.clinicianSendingEmail}, ti ha inviato questo sondaggio per aiutarti a capire meglio la tua salute. Fare clic sui pulsanti appropriati di seguito e quindi su "Invia punteggio". Tieni presente che la tua <u>privacy digitale è presa molto sul serio</u> e che sono state implementate numerose tecnologie per proteggere la privacy e l'integrità dei tuoi dati, incluso l'uso della crittografia SSL, l'archiviazione conforme a HIPAA e lo stato di - servizi di autenticazione all'avanguardia. I tuoi dati <u>non saranno mai condivisi con nessuno diverso dal medico assegnato</u>.</h4>

            <form onSubmit={this.handleSubmit}>



                <StickyContainer>
                <Sticky>
                {({ style }) => <div className="sticky_style" style={style}><Well bsSize="small">Indice Di Disabilità Del Collo: {(this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10)} / {50
                } = {((((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10))/(50
                )*100).toFixed(1))} % <br/>
                Grafico Indice Di Disabilità Del Collo: (%)
                <ProgressBar>
                <ProgressBar active now={100-(100*(((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10))/(50
                )))}/>
                <ProgressBar active bsStyle="danger" now={(100*(((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10))/(50
                )))}/>
                </ProgressBar>
                </Well></div>}
                </Sticky>
                <p />
                    
                     
                <h4>Questo questionario è stato creato per farti capire in che modo il dolore che provi al collo condizioni la tua capacità di gestire le attività della vita quotidiana. Rispondi ad ogni sezione cerchiando LA RISPOSTA che giudichi più pertinente. CERCHIA SOLO LA RISPOSTA CHE DESCRIVE MAGGIORMENTE IL TUO PROBLEMA IN QUESTO MOMENTO. 
                </h4>
                
                <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
                <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
                <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
                <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
                <Buttons5 callbackFromParent5={this.myCallback5} callbackFromParent5_text={this.myCallback5_text}/>
                <Buttons6 callbackFromParent6={this.myCallback6} callbackFromParent6_text={this.myCallback6_text}/>
                <Buttons7 callbackFromParent7={this.myCallback7} callbackFromParent7_text={this.myCallback7_text}/>
                <Buttons8 callbackFromParent8={this.myCallback8} callbackFromParent8_text={this.myCallback8_text}/>
                <Buttons9 callbackFromParent9={this.myCallback9} callbackFromParent9_text={this.myCallback9_text}/>
                <Buttons10 callbackFromParent10={this.myCallback10} callbackFromParent10_text={this.myCallback10_text}/>
                </StickyContainer>
                <br />
                    
              <button style = {{width: '100%'}}>Inserisci il punteggio</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(NDI_Link_Italian);
