import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';

///////////////////////////////////////////Calculator Methods
    
class Buttons1 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent1(value);
if (value === 4)
this.props.callbackFromParent1_text("No difficulty with usual work, housework or school activities");
if (value === 3)
this.props.callbackFromParent1_text("A little bit of difficulty with usual work, housework or school activities");
if (value === 2)
this.props.callbackFromParent1_text("Moderate difficulty with usual work, housework or school activities");
if (value === 1)
this.props.callbackFromParent1_text("Quite a bit of difficulty with usual work, housework or school activities");
if (value === 0)
this.props.callbackFromParent1_text("A lot of difficulty with usual work, housework or school activities");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>1. Any of usual work, housework or school activities.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>1. Any of usual work, housework or school activities.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons2 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent2(value);
if (value === 4)
this.props.callbackFromParent2_text("No difficulty with usual hobbies, recreational or sporting activities");
if (value === 3)
this.props.callbackFromParent2_text("A little bit of difficulty with usual hobbies, recreational or sporting activities");
if (value === 2)
this.props.callbackFromParent2_text("Moderate difficulty with usual hobbies, recreational or sporting activities");
if (value === 1)
this.props.callbackFromParent2_text("Quite a bit of difficulty with usual hobbies, recreational or sporting activities");
if (value === 0)
this.props.callbackFromParent2_text("A lot of difficulty with usual hobbies, recreational or sporting activities");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>2. Usual hobbies, recreational or sporting activities.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>2. Usual hobbies, recreational or sporting activities.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons3 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent3(value);
if (value === 4)
this.props.callbackFromParent3_text("No difficulty getting into or out of the bath");
if (value === 3)
this.props.callbackFromParent3_text("A little bit of difficulty getting into or out of the bath");
if (value === 2)
this.props.callbackFromParent3_text("Moderate difficulty getting into or out of the bath");
if (value === 1)
this.props.callbackFromParent3_text("Quite a bit of difficulty getting into or out of the bath");
if (value === 0)
this.props.callbackFromParent3_text("A lot of difficulty getting into or out of the bath");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>3. Getting into or out of the bath.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>3. Getting into or out of the bath.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons4 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent4(value);
if (value === 4)
this.props.callbackFromParent4_text("No difficulty walking between rooms");
if (value === 3)
this.props.callbackFromParent4_text("A little bit of difficulty walking between rooms");
if (value === 2)
this.props.callbackFromParent4_text("Moderate difficulty walking between rooms");
if (value === 1)
this.props.callbackFromParent4_text("Quite a bit of difficulty walking between rooms");
if (value === 0)
this.props.callbackFromParent4_text("A lot of difficulty walking between rooms");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>4. Walking between rooms.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>4. Walking between rooms.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons5 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent5(value);
if (value === 4)
this.props.callbackFromParent5_text("No difficulty putting on shoes or socks");
if (value === 3)
this.props.callbackFromParent5_text("A little bit of difficulty putting on shoes or socks");
if (value === 2)
this.props.callbackFromParent5_text("Moderate difficulty putting on shoes or socks");
if (value === 1)
this.props.callbackFromParent5_text("Quite a bit of difficulty putting on shoes or socks");
if (value === 0)
this.props.callbackFromParent5_text("A lot of difficulty putting on shoes or socks");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>5. Putting on your shoes or socks.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>5. Putting on your shoes or socks.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons6 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent6(value);
if (value === 4)
this.props.callbackFromParent6_text("No difficulty squatting");
if (value === 3)
this.props.callbackFromParent6_text("A little bit of difficulty squatting");
if (value === 2)
this.props.callbackFromParent6_text("Moderate difficulty squatting");
if (value === 1)
this.props.callbackFromParent6_text("Quite a bit of difficulty squatting");
if (value === 0)
this.props.callbackFromParent6_text("A lot of difficulty squatting");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>6. Squatting.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>6. Squatting.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons7 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent7(value);
if (value === 4)
this.props.callbackFromParent7_text("No difficulty lifting an object from the floor");
if (value === 3)
this.props.callbackFromParent7_text("A little bit of difficulty lifting an object from the floor");
if (value === 2)
this.props.callbackFromParent7_text("Moderate difficulty lifting an object from the floor");
if (value === 1)
this.props.callbackFromParent7_text("Quite a bit of difficulty lifting an object from the floor");
if (value === 0)
this.props.callbackFromParent7_text("A lot of difficulty lifting an object from the floor");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>7. Lifting an object from the floor.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>7. Lifting an object from the floor.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons8 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent8(value);
if (value === 4)
this.props.callbackFromParent8_text("No difficulty performing light activities around home");
if (value === 3)
this.props.callbackFromParent8_text("A little bit of difficulty performing light activities around home");
if (value === 2)
this.props.callbackFromParent8_text("Moderate difficulty performing light activities around home");
if (value === 1)
this.props.callbackFromParent8_text("Quite a bit of difficulty  performing light activities around home");
if (value === 0)
this.props.callbackFromParent8_text("A lot of difficulty performing light activities around home");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>8. Performing light activities around home.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>8. Performing light activities around home.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons9 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent9(value);
if (value === 4)
this.props.callbackFromParent9_text("No difficulty performing heavy activities around home");
if (value === 3)
this.props.callbackFromParent9_text("A little bit of difficulty performing heavy activities around home");
if (value === 2)
this.props.callbackFromParent9_text("Moderate difficulty performing heavy activities around home");
if (value === 1)
this.props.callbackFromParent9_text("Quite a bit of difficulty  performing heavy activities around home");
if (value === 0)
this.props.callbackFromParent9_text("A lot of difficulty performing heavy activities around home");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>9. Performing heavy activities around home.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>9. Performing heavy activities around home.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons10 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent10(value);
if (value === 4)
this.props.callbackFromParent10_text("No difficulty getting into or out of a car");
if (value === 3)
this.props.callbackFromParent10_text("A little bit of difficulty getting into or out of a car");
if (value === 2)
this.props.callbackFromParent10_text("Moderate difficulty getting into or out of a car");
if (value === 1)
this.props.callbackFromParent10_text("Quite a bit of difficulty  getting into or out of a car");
if (value === 0)
this.props.callbackFromParent10_text("A lot of difficulty getting into or out of a car");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>10. Getting into or out of a car.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>10. Getting into or out of a car.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons11 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent11(value);
if (value === 4)
this.props.callbackFromParent11_text("No difficulty walking 2 blocks");
if (value === 3)
this.props.callbackFromParent11_text("A little bit of difficulty walking 2 blocks");
if (value === 2)
this.props.callbackFromParent11_text("Moderate difficulty walking 2 blocks");
if (value === 1)
this.props.callbackFromParent11_text("Quite a bit of difficulty walking 2 blocks");
if (value === 0)
this.props.callbackFromParent11_text("A lot of difficulty walking 2 blocks");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>11. Walking 2 blocks.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>11. Walking 2 blocks.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons12 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent12(value);
if (value === 4)
this.props.callbackFromParent12_text("No difficulty walking a mile");
if (value === 3)
this.props.callbackFromParent12_text("A little bit of difficulty walking a mile");
if (value === 2)
this.props.callbackFromParent12_text("Moderate difficulty walking a mile");
if (value === 1)
this.props.callbackFromParent12_text("Quite a bit of difficulty walking a mile");
if (value === 0)
this.props.callbackFromParent12_text("A lot of difficulty walking a mile");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>12. Walking a mile.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>12. Walking a mile.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons13 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent13(value);
if (value === 4)
this.props.callbackFromParent13_text("No difficulty going up or down 10 stairs");
if (value === 3)
this.props.callbackFromParent13_text("A little bit of difficulty going up or down 10 stairs");
if (value === 2)
this.props.callbackFromParent13_text("Moderate difficulty going up or down 10 stairs");
if (value === 1)
this.props.callbackFromParent13_text("Quite a bit of difficulty going up or down 10 stairs");
if (value === 0)
this.props.callbackFromParent13_text("A lot of difficulty going up or down 10 stairs");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>13. Going up or down 10 stairs.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>13. Going up or down 10 stairs.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons14 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent14(value);
if (value === 4)
this.props.callbackFromParent14_text("No difficulty standing for 1 hour");
if (value === 3)
this.props.callbackFromParent14_text("A little bit of difficulty standing for 1 hour");
if (value === 2)
this.props.callbackFromParent14_text("Moderate difficulty standing for 1 hour");
if (value === 1)
this.props.callbackFromParent14_text("Quite a bit of difficulty standing for 1 hour");
if (value === 0)
this.props.callbackFromParent14_text("A lot of difficulty standing for 1 hour");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>14. Standing for 1 hour.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>14. Standing for 1 hour.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons15 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent15(value);
if (value === 4)
this.props.callbackFromParent15_text("No difficulty sitting for 1 hour");
if (value === 3)
this.props.callbackFromParent15_text("A little bit of difficulty sitting for 1 hour");
if (value === 2)
this.props.callbackFromParent15_text("Moderate difficulty sitting for 1 hour");
if (value === 1)
this.props.callbackFromParent15_text("Quite a bit of difficulty sitting for 1 hour");
if (value === 0)
this.props.callbackFromParent15_text("A lot of difficulty sitting for 1 hour");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>15. Sitting for 1 hour.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>15. Sitting for 1 hour.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons16 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent16(value);
if (value === 4)
this.props.callbackFromParent16_text("No difficulty running on even ground");
if (value === 3)
this.props.callbackFromParent16_text("A little bit of difficulty running on even ground");
if (value === 2)
this.props.callbackFromParent16_text("Moderate difficulty running on even ground");
if (value === 1)
this.props.callbackFromParent16_text("Quite a bit of difficulty running on even ground");
if (value === 0)
this.props.callbackFromParent16_text("A lot of difficulty running on even ground");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>16. Running on even ground.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>16. Running on even ground.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons17 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent17(value);
if (value === 4)
this.props.callbackFromParent17_text("No difficulty running on uneven ground");
if (value === 3)
this.props.callbackFromParent17_text("A little bit of difficulty running on uneven ground");
if (value === 2)
this.props.callbackFromParent17_text("Moderate difficulty running on uneven ground");
if (value === 1)
this.props.callbackFromParent17_text("Quite a bit of difficulty running on uneven ground");
if (value === 0)
this.props.callbackFromParent17_text("A lot of difficulty running on uneven ground");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>17. Running on uneven ground.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>17. Running on uneven ground.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons18 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent18(value);
if (value === 4)
this.props.callbackFromParent18_text("No difficulty making sharp turns while running fast");
if (value === 3)
this.props.callbackFromParent18_text("A little bit of difficulty making sharp turns while running fast");
if (value === 2)
this.props.callbackFromParent18_text("Moderate difficulty making sharp turns while running fast");
if (value === 1)
this.props.callbackFromParent18_text("Quite a bit of difficulty making sharp turns while running fast");
if (value === 0)
this.props.callbackFromParent18_text("A lot of difficulty making sharp turns while running fast");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>18. Making sharp turns while running fast.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>18. Making sharp turns while running fast.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons19 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent19(value);
if (value === 4)
this.props.callbackFromParent19_text("No difficulty hopping");
if (value === 3)
this.props.callbackFromParent19_text("A little bit of difficulty hopping");
if (value === 2)
this.props.callbackFromParent19_text("Moderate difficulty hopping");
if (value === 1)
this.props.callbackFromParent19_text("Quite a bit of difficulty hopping");
if (value === 0)
this.props.callbackFromParent19_text("A lot of difficulty hopping");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>19. Hopping.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>19. Hopping.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons20 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 4}
}
someFn = (value) => {
this.props.callbackFromParent20(value);
if (value === 4)
this.props.callbackFromParent20_text("No difficulty rolling over in bed");
if (value === 3)
this.props.callbackFromParent20_text("A little bit of difficulty rolling over in bed");
if (value === 2)
this.props.callbackFromParent20_text("Moderate difficulty rolling over in bed");
if (value === 1)
this.props.callbackFromParent20_text("Quite a bit of difficulty rolling over in bed");
if (value === 0)
this.props.callbackFromParent20_text("A lot of difficulty rolling over in bed");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>20. Rolling over in bed.</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>20. Rolling over in bed.</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={4}>
<ToggleButton bsStyle='btn-custom' value={4}>{(this.state.clicked === 4) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No difficulty (4)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3}>{(this.state.clicked === 3) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit of difficulty (3)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2}>{(this.state.clicked === 2) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderate difficulty (2)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1}>{(this.state.clicked === 1) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit of difficulty (1)</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A lot of difficulty (0)</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}  

  


class LEFS_Link extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

        
        
        
        
    //Calculator variables    
        selectedValue1: 4,
        selectedValue1_text: 'No difficulty with usual work, housework or school activities',

        selectedValue2: 4,
        selectedValue2_text: 'No difficulty with usual hobbies, recreational or sporting activities',

        selectedValue3: 4,
        selectedValue3_text: 'No difficulty getting into or out of the bath',

        selectedValue4: 4,
        selectedValue4_text: 'No difficulty walking between rooms',

        selectedValue5: 4,
        selectedValue5_text: 'No difficulty putting on shoes or socks',

        selectedValue6: 4,
        selectedValue6_text: 'No difficulty squatting',

        selectedValue7: 4,
        selectedValue7_text: 'No difficulty lifting an object from the floor',

        selectedValue8: 4,
        selectedValue8_text: 'No difficulty performing light activities around home',

        selectedValue9: 4,
        selectedValue9_text: 'No difficulty performing heavy activities around home',

        selectedValue10: 4,
        selectedValue10_text: 'No difficulty getting into or out of a car',

        selectedValue11: 4,
        selectedValue11_text: 'No difficulty walking 2 blocks',

        selectedValue12: 4,
        selectedValue12_text: 'No difficulty walking a mile',

        selectedValue13: 4,
        selectedValue13_text: 'No difficulty going up or down 10 stairs',

        selectedValue14: 4,
        selectedValue14_text: 'No difficulty standing for 1 hour',

        selectedValue15: 4,
        selectedValue15_text: 'No difficulty sitting for 1 hour',

        selectedValue16: 4,
        selectedValue16_text: 'No difficulty running on even ground',

        selectedValue17: 4,
        selectedValue17_text: 'No difficulty running on uneven ground',

        selectedValue18: 4,
        selectedValue18_text: 'No difficulty making sharp turns while running fast',

        selectedValue19: 4,
        selectedValue19_text: 'No difficulty hopping',

        selectedValue20: 4,
        selectedValue20_text: 'No difficulty rolling over in bed',    
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const LEFSScoresRef = firebase.firestore();
  LEFSScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  LEFSScoresRef.collection("lefs_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const LEFSScoresRef = firebase.firestore();
  LEFSScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  LEFSScoresRef.collection("lefs_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const LEFSScoresRef = firebase.firestore();
  LEFSScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  LEFSScoresRef.collection("lefs_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const LEFSScoresRef = firebase.firestore();
  LEFSScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  LEFSScoresRef.collection("lefs_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();  
  const LEFSScoresRef = firebase.firestore();
  LEFSScoresRef.settings({
      timestampsInSnapshots: true
  }); 
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){    
  const lefs_score = LEFSScoresRef.collection("lefs_scores").doc(this.props.requestSurveyID).set({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'Lower Extremity Functional Scale',
    Question_01_LEFS: this.state.selectedValue1,
    Question_02_LEFS: this.state.selectedValue2,
    Question_03_LEFS: this.state.selectedValue3,
    Question_04_LEFS: this.state.selectedValue4,
    Question_05_LEFS: this.state.selectedValue5,
    Question_06_LEFS: this.state.selectedValue6,
    Question_07_LEFS: this.state.selectedValue7,            
    Question_08_LEFS: this.state.selectedValue8,
    Question_09_LEFS: this.state.selectedValue9,
    Question_10_LEFS: this.state.selectedValue10,
    Question_11_LEFS: this.state.selectedValue11,
    Question_12_LEFS: this.state.selectedValue12,
    Question_13_LEFS: this.state.selectedValue13,
    Question_14_LEFS: this.state.selectedValue14,
    Question_15_LEFS: this.state.selectedValue15,
    Question_16_LEFS: this.state.selectedValue16,
    Question_17_LEFS: this.state.selectedValue17,
    Question_18_LEFS: this.state.selectedValue18,
    Question_19_LEFS: this.state.selectedValue19,
    Question_20_LEFS: this.state.selectedValue20,
    Total_Score_LEFS: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20),       
  });
}
else {
  const lefs_score = LEFSScoresRef.collection("lefs_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'Lower Extremity Functional Scale',
    Question_01_LEFS: this.state.selectedValue1,
    Question_02_LEFS: this.state.selectedValue2,
    Question_03_LEFS: this.state.selectedValue3,
    Question_04_LEFS: this.state.selectedValue4,
    Question_05_LEFS: this.state.selectedValue5,
    Question_06_LEFS: this.state.selectedValue6,
    Question_07_LEFS: this.state.selectedValue7,            
    Question_08_LEFS: this.state.selectedValue8,
    Question_09_LEFS: this.state.selectedValue9,
    Question_10_LEFS: this.state.selectedValue10,
    Question_11_LEFS: this.state.selectedValue11,
    Question_12_LEFS: this.state.selectedValue12,
    Question_13_LEFS: this.state.selectedValue13,
    Question_14_LEFS: this.state.selectedValue14,
    Question_15_LEFS: this.state.selectedValue15,
    Question_16_LEFS: this.state.selectedValue16,
    Question_17_LEFS: this.state.selectedValue17,
    Question_18_LEFS: this.state.selectedValue18,
    Question_19_LEFS: this.state.selectedValue19,
    Question_20_LEFS: this.state.selectedValue20,
    Total_Score_LEFS: (this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20), 
  });      
}     
  this.setState({
    clinician_to_share_with: '',
    user_id: '',      
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.props.clinicianSendingEmail + '. You may now close this alert and the entire window.');    
}  
        
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("lefs_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallback1  = (dataFromChild) => {
    this.setState({selectedValue1: dataFromChild});
    }
    myCallback1_text  = (dataFromChild) => {
    this.setState({selectedValue1_text: dataFromChild});
    }
    myCallback2  = (dataFromChild) => {
    this.setState({selectedValue2: dataFromChild});
    }
    myCallback2_text  = (dataFromChild) => {
    this.setState({selectedValue2_text: dataFromChild});
    }
    myCallback3  = (dataFromChild) => {
    this.setState({selectedValue3: dataFromChild});
    }
    myCallback3_text  = (dataFromChild) => {
    this.setState({selectedValue3_text: dataFromChild});
    }
    myCallback4  = (dataFromChild) => {
    this.setState({selectedValue4: dataFromChild});
    }
    myCallback4_text  = (dataFromChild) => {
    this.setState({selectedValue4_text: dataFromChild});
    }
    myCallback5  = (dataFromChild) => {
    this.setState({selectedValue5: dataFromChild});
    }
    myCallback5_text  = (dataFromChild) => {
    this.setState({selectedValue5_text: dataFromChild});
    }
    myCallback6  = (dataFromChild) => {
    this.setState({selectedValue6: dataFromChild});
    }
    myCallback6_text  = (dataFromChild) => {
    this.setState({selectedValue6_text: dataFromChild});
    }
    myCallback7  = (dataFromChild) => {
    this.setState({selectedValue7: dataFromChild});
    }
    myCallback7_text  = (dataFromChild) => {
    this.setState({selectedValue7_text: dataFromChild});
    }
    myCallback8  = (dataFromChild) => {
    this.setState({selectedValue8: dataFromChild});
    }
    myCallback8_text  = (dataFromChild) => {
    this.setState({selectedValue8_text: dataFromChild});
    }
    myCallback9  = (dataFromChild) => {
    this.setState({selectedValue9: dataFromChild});
    }
    myCallback9_text  = (dataFromChild) => {
    this.setState({selectedValue9_text: dataFromChild});
    }
    myCallback10  = (dataFromChild) => {
    this.setState({selectedValue10: dataFromChild});
    }
    myCallback10_text  = (dataFromChild) => {
    this.setState({selectedValue10_text: dataFromChild});
    }     
    myCallback11  = (dataFromChild) => {
    this.setState({selectedValue11: dataFromChild});
    }
    myCallback11_text  = (dataFromChild) => {
    this.setState({selectedValue11_text: dataFromChild});
    }
    myCallback12  = (dataFromChild) => {
    this.setState({selectedValue12: dataFromChild});
    }
    myCallback12_text  = (dataFromChild) => {
    this.setState({selectedValue12_text: dataFromChild});
    }
    myCallback13  = (dataFromChild) => {
    this.setState({selectedValue13: dataFromChild});
    }
    myCallback13_text  = (dataFromChild) => {
    this.setState({selectedValue13_text: dataFromChild});
    }
    myCallback14  = (dataFromChild) => {
    this.setState({selectedValue14: dataFromChild});
    }
    myCallback14_text  = (dataFromChild) => {
    this.setState({selectedValue14_text: dataFromChild});
    }
    myCallback15  = (dataFromChild) => {
    this.setState({selectedValue15: dataFromChild});
    }
    myCallback15_text  = (dataFromChild) => {
    this.setState({selectedValue15_text: dataFromChild});
    }
    myCallback16  = (dataFromChild) => {
    this.setState({selectedValue16: dataFromChild});
    }
    myCallback16_text  = (dataFromChild) => {
    this.setState({selectedValue16_text: dataFromChild});
    }
    myCallback17  = (dataFromChild) => {
    this.setState({selectedValue17: dataFromChild});
    }
    myCallback17_text  = (dataFromChild) => {
    this.setState({selectedValue17_text: dataFromChild});
    }
    myCallback18  = (dataFromChild) => {
    this.setState({selectedValue18: dataFromChild});
    }
    myCallback18_text  = (dataFromChild) => {
    this.setState({selectedValue18_text: dataFromChild});
    }
    myCallback19  = (dataFromChild) => {
    this.setState({selectedValue19: dataFromChild});
    }
    myCallback19_text  = (dataFromChild) => {
    this.setState({selectedValue19_text: dataFromChild});
    }
    myCallback20  = (dataFromChild) => {
    this.setState({selectedValue20: dataFromChild});
    }
    myCallback20_text  = (dataFromChild) => {
    this.setState({selectedValue20_text: dataFromChild});
    }

  
    
    

    
render() { 

  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>Lower Extremity Functional Scale Calculator</h1>
                <h4>Hello, {this.props.submitterName}, your clinician, {this.props.clinicianSendingEmail}, has sent you this survey to help further understand your health. Please click the appropriate buttons below and then click "Submit Score". Please note that your <u>digital privacy is taken very seriously</u> and a number of technologies have been implemented to protect the privacy and integrity of your data, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Your data <u>will never be shared with anyone other than the assigned clinician</u>.</h4>

            <form onSubmit={this.handleSubmit}>



                <StickyContainer>
                <Sticky>
                {({ style }) => <div className="sticky_style" style={style}><Well style={{height: 100}}>Lower Extremity Functional Score: {this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20} / {80} = {(((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20)/(80)*100).toFixed(1))} %
                <p/>
                Graphical Lower Extremity Functional Score: (%)
                <ProgressBar>
                <ProgressBar active now={100*((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20)/(80))}/>
                <ProgressBar active bsStyle="danger" now={100-(100*((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+this.state.selectedValue13+this.state.selectedValue14+this.state.selectedValue15+this.state.selectedValue16+this.state.selectedValue17+this.state.selectedValue18+this.state.selectedValue19+this.state.selectedValue20)/(80)))}/>
                </ProgressBar>
                </Well></div>}
                </Sticky>
                <p />

                <br/>                      
                
                <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
                <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
                <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
                <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
                <Buttons5 callbackFromParent5={this.myCallback5} callbackFromParent5_text={this.myCallback5_text}/>
                <Buttons6 callbackFromParent6={this.myCallback6} callbackFromParent6_text={this.myCallback6_text}/>
                <Buttons7 callbackFromParent7={this.myCallback7} callbackFromParent7_text={this.myCallback7_text}/>
                <Buttons8 callbackFromParent8={this.myCallback8} callbackFromParent8_text={this.myCallback8_text}/>
                <Buttons9 callbackFromParent9={this.myCallback9} callbackFromParent9_text={this.myCallback9_text}/>
                <Buttons10 callbackFromParent10={this.myCallback10} callbackFromParent10_text={this.myCallback10_text}/>
                <Buttons11 callbackFromParent11={this.myCallback11} callbackFromParent11_text={this.myCallback11_text}/>
                <Buttons12 callbackFromParent12={this.myCallback12} callbackFromParent12_text={this.myCallback12_text}/>
                <Buttons13 callbackFromParent13={this.myCallback13} callbackFromParent13_text={this.myCallback13_text}/>
                <Buttons14 callbackFromParent14={this.myCallback14} callbackFromParent14_text={this.myCallback14_text}/>
                <Buttons15 callbackFromParent15={this.myCallback15} callbackFromParent15_text={this.myCallback15_text}/>
                <Buttons16 callbackFromParent16={this.myCallback16} callbackFromParent16_text={this.myCallback16_text}/>
                <Buttons17 callbackFromParent17={this.myCallback17} callbackFromParent17_text={this.myCallback17_text}/>
                <Buttons18 callbackFromParent18={this.myCallback18} callbackFromParent18_text={this.myCallback18_text}/>
                <Buttons19 callbackFromParent19={this.myCallback19} callbackFromParent19_text={this.myCallback19_text}/>
                <Buttons20 callbackFromParent20={this.myCallback20} callbackFromParent20_text={this.myCallback20_text}/>
                </StickyContainer>
                <br />
                    
              <button style = {{width: '100%'}}>Submit Score</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(LEFS_Link);
