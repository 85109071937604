import React, { PureComponent } from 'react';
import '../App.css';
import firebase, { auth, provider } from '../firebase.js';
import {Alert, ToggleButtonGroup, ToggleButton, Button, Well, Glyphicon, ProgressBar, Tabs, Tab, Panel, PanelGroup} from 'react-bootstrap';
import { StickyContainer, Sticky} from 'react-sticky';
//const jsPDF = require('jspdf');
import jsPDF from 'jspdf';
import {Link, useParams, useLocation, withRouter} from "react-router-dom";
import mainLogo from'../orthopowertools.png';
//import queryString from 'query-string'; don't think I'll use this line

///////////////////////////////////////////Calculator Methods
    
class Buttons1 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn (value){
this.props.callbackFromParent1(value);
if (value === 0)
this.props.callbackFromParent1_text("In general, health is excellent");
if (value === -1.31872)
this.props.callbackFromParent1_text("In general, health is very good");
if (value === -3.02396)
this.props.callbackFromParent1_text("In general, health is good");
if (value === -5.56461)
this.props.callbackFromParent1_text("In general, health is fair");
if (value === -8.37399)
this.props.callbackFromParent1_text("In general, health is poor");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>1. In general would you say your health is:</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Excellent</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-1.31872}>{(this.state.clicked === -1.31872) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very good</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-3.02396}>{(this.state.clicked === -3.02396) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Good</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-5.56461}>{(this.state.clicked === -5.56461) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Fair</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-8.37399}>{(this.state.clicked === -8.37399) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Poor</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>1. In general would you say your health is:</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Excellent</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-1.31872}>{(this.state.clicked === -1.31872) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Very good</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-3.02396}>{(this.state.clicked === -3.02396) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Good</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-5.56461}>{(this.state.clicked === -5.56461) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Fair</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-8.37399}>{(this.state.clicked === -8.37399) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Poor</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons2 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn (value){
this.props.callbackFromParent2(value);
if (value === -7.23216)
this.props.callbackFromParent2_text("Limited a lot in moderate activities");
if (value === -3.45555)
this.props.callbackFromParent2_text("Limited a little in moderate activities");
if (value === 0)
this.props.callbackFromParent2_text("Not limited in moderate activities");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>2. <b>Moderate activities</b>, such as moving a table, pushing a vacuum clearner, bowling, or playing golf</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={-7.23216}>{(this.state.clicked === -7.23216) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes, Limited A Lot</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-3.45555}>{(this.state.clicked === -3.45555) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes, Limited A Little</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No, Not Limited at all</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>2. <b>Moderate activities</b>, such as moving a table, pushing a vacuum clearner, bowling, or playing golf</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={-7.23216}>{(this.state.clicked === -7.23216) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes, Limited A Lot</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-3.45555}>{(this.state.clicked === -3.45555) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes, Limited A Little</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No, Not Limited at all</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons3 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn (value){
this.props.callbackFromParent3(value);
if (value === -6.24397)
this.props.callbackFromParent3_text("Limited a lot in moderate activities");
if (value === -2.73557)
this.props.callbackFromParent3_text("Limited a little in moderate activities");
if (value === 0)
this.props.callbackFromParent3_text("Not limited in moderate activities");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>3. Climbing <b>several</b> flights of stairs</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={-6.24397}>{(this.state.clicked === -6.24397) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes, Limited A Lot</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-2.73557}>{(this.state.clicked === -2.73557) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes, Limited A Little</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No, Not Limited at all</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>3. Climbing <b>several</b> flights of stairs</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={-6.24397}>{(this.state.clicked === -6.24397) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes, Limited A Lot</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-2.73557}>{(this.state.clicked === -2.73557) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes, Limited A Little</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No, Not Limited at all</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons4 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn (value){
this.props.callbackFromParent4(value);
if (value === -4.61617)
this.props.callbackFromParent4_text("Accomplished less work than would like due to physical health");
if (value === 0)
this.props.callbackFromParent4_text("Accomplished as much work as would like (unaffected by physical health)");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>4. <b>Accomplished</b> less than you would like</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={-4.61617}>{(this.state.clicked === -4.61617) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>4. <b>Accomplished</b> less than you would like</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={-4.61617}>{(this.state.clicked === -4.61617) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons5 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn (value){
this.props.callbackFromParent5(value);
if (value === -5.51747)
this.props.callbackFromParent5_text("Limited in kind of work or other activities in last week due to physical health");
if (value === 0)
this.props.callbackFromParent5_text("Not limited in kind of work or other activities in last week (unaffected by physical health)");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>5. Were limited in the <b>kind</b> of work or other activites</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={-5.51747}>{(this.state.clicked === -5.51747) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>5. Were limited in the <b>kind</b> of work or other activites</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={-5.51747}>{(this.state.clicked === -5.51747) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons6 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn (value){
this.props.callbackFromParent6(value);
if (value === 3.04365)
this.props.callbackFromParent6_text("Accomplished less work than would like due to emotional problems");
if (value === 0)
this.props.callbackFromParent6_text("Accomplished as much work as would like (unaffected by emotional problems)");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>6. <b>Accomplished less</b> than you would like</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={3.04365}>{(this.state.clicked === 3.04365) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>6. <b>Accomplished less</b> than you would like</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={3.04365}>{(this.state.clicked === 3.04365) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons7 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn (value){
this.props.callbackFromParent7(value);
if (value === 2.32091)
this.props.callbackFromParent7_text("Did not work as carefully as usual due to emotional problems");
if (value === 0)
this.props.callbackFromParent7_text("Works as carefully as usual (unaffected by emotional problems)");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>7. Didn{"'"}t do work or other activities <b>as carefully</b> as usual</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={2.32091}>{(this.state.clicked === 2.32091) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>7. Didn{"'"}t do work or other activities <b>as carefully</b> as usual</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={2.32091}>{(this.state.clicked === 2.32091) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Yes</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} No</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons8 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn (value){
this.props.callbackFromParent8(value);
if (value === 0)
this.props.callbackFromParent8_text("No interference of pain with normal work");
if (value === -3.80130)
this.props.callbackFromParent8_text("A little bit of interference of pain with normal work");
if (value === -6.50522)
this.props.callbackFromParent8_text("Moderate interference of pain with normal work");
if (value === -8.38063)
this.props.callbackFromParent8_text("Quite a bit of interference of pain with normal work");
if (value === -11.25544)
this.props.callbackFromParent8_text("Extreme interference of pain with normal work");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>8. During the <u>past week</u>, how much did <u>pain</u> interfere with your normal work (including both work outside the home and housework)?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-3.80130}>{(this.state.clicked === -3.80130) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-6.50522}>{(this.state.clicked === -6.50522) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderately</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-8.38063}>{(this.state.clicked === -8.38063) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-11.25544}>{(this.state.clicked === -11.25544) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Extremely</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>8. During the <u>past week</u>, how much did <u>pain</u> interfere with your normal work (including both work outside the home and housework)?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Not at all</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-3.80130}>{(this.state.clicked === -3.80130) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little bit</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-6.50522}>{(this.state.clicked === -6.50522) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Moderately</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-8.38063}>{(this.state.clicked === -8.38063) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Quite a bit</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-11.25544}>{(this.state.clicked === -11.25544) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Extremely</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons9 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn (value){
this.props.callbackFromParent9(value);
if (value === 0)
this.props.callbackFromParent9_text("Felt calm and peaceful all of the time");
if (value === 0.66514)
this.props.callbackFromParent9_text("Felt calm and peaceful most of the time");
if (value === 1.36689)
this.props.callbackFromParent9_text("Felt calm and peaceful a good bit of the time");
if (value === 2.37241)
this.props.callbackFromParent9_text("Felt calm and peaceful some of the time");
if (value === 2.90426)
this.props.callbackFromParent9_text("Felt calm and peaceful a little of the time");
if (value === 3.46638)
this.props.callbackFromParent9_text("Felt calm and peaceful none of the time");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>9. Have you felt calm and peaceful</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} All of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0.66514}>{(this.state.clicked === 0.66514) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Most of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1.36689}>{(this.state.clicked === 1.36689) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A Good Bit of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2.37241}>{(this.state.clicked === 2.37241) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Some of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2.90426}>{(this.state.clicked === 2.90426) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A Little of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3.46638}>{(this.state.clicked === 3.46638) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} None of the Time</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>9. Have you felt calm and peaceful</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} All of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0.66514}>{(this.state.clicked === 0.66514) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Most of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1.36689}>{(this.state.clicked === 1.36689) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A Good Bit of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2.37241}>{(this.state.clicked === 2.37241) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Some of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2.90426}>{(this.state.clicked === 2.90426) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A Little of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3.46638}>{(this.state.clicked === 3.46638) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} None of the Time</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons10 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn (value){
this.props.callbackFromParent10(value);
if (value === 0)
this.props.callbackFromParent10_text("Had a lot of energy all of the time");
if (value === -0.42251)
this.props.callbackFromParent10_text("Had a lot of energy most of the time");
if (value === -1.14387)
this.props.callbackFromParent10_text("Had a lot of energy a good bit of the time");
if (value === -1.61850)
this.props.callbackFromParent10_text("Had a lot of energy some of the time");
if (value === -2.02168)
this.props.callbackFromParent10_text("Had a lot of energy a little of the time");
if (value === -2.44706)
this.props.callbackFromParent10_text("Had a lot of energy none of the time");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>10. Did you have a lot of energy?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} All of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-0.42251}>{(this.state.clicked === -0.42251) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Most of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-1.14387}>{(this.state.clicked === -1.14387) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A Good Bit of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-1.61850}>{(this.state.clicked === -1.61850) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Some of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-2.02168}>{(this.state.clicked === -2.02168) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A Little of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-2.44706}>{(this.state.clicked === -2.44706) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} None of the Time</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>10. Did you have a lot of energy?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} All of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-0.42251}>{(this.state.clicked === -0.42251) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Most of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-1.14387}>{(this.state.clicked === -1.14387) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A Good Bit of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-1.61850}>{(this.state.clicked === -1.61850) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Some of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-2.02168}>{(this.state.clicked === -2.02168) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A Little of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-2.44706}>{(this.state.clicked === -2.44706) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} None of the Time</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons11 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn (value){
this.props.callbackFromParent11(value);
if (value === 4.61446)
this.props.callbackFromParent11_text("Felt downhearted and blue all of the time");
if (value === 3.41593)
this.props.callbackFromParent11_text("Felt downhearted and blue most of the time");
if (value === 2.34247)
this.props.callbackFromParent11_text("Felt downhearted and blue a good bit of the time");
if (value === 1.28044)
this.props.callbackFromParent11_text("Felt downhearted and blue some of the time");
if (value === 0.41188)
this.props.callbackFromParent11_text("Felt downhearted and blue a little of the time");
if (value === 0)
this.props.callbackFromParent11_text("Felt downhearted and blue none of the time");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>11. Have you felt downhearted and blue?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4.61446}>{(this.state.clicked === 4.61446) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} All of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3.41593}>{(this.state.clicked === 3.41593) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Most of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2.34247}>{(this.state.clicked === 2.34247) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A Good Bit of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1.28044}>{(this.state.clicked === 1.28044) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Some of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0.41188}>{(this.state.clicked === 0.41188) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A Little of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} None of the Time</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>11. Have you felt downhearted and blue?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={4.61446}>{(this.state.clicked === 4.61446) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} All of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={3.41593}>{(this.state.clicked === 3.41593) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Most of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={2.34247}>{(this.state.clicked === 2.34247) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A Good Bit of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={1.28044}>{(this.state.clicked === 1.28044) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Some of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0.41188}>{(this.state.clicked === 0.41188) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A Little of the Time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} None of the Time</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
class Buttons12 extends PureComponent {

constructor(){
	super();
	this.state= {clicked: 0}
}
someFn (value){
this.props.callbackFromParent12(value);
if (value === -0.33682)
this.props.callbackFromParent12_text("Physical or emotional health interferes with social activities all of the time");
if (value === -0.94342)
this.props.callbackFromParent12_text("Physical or emotional health interferes with social activities most of the time");
if (value === -0.18043)
this.props.callbackFromParent12_text("Physical or emotional health interferes with social activities some of the time");
if (value === 0.11038)
this.props.callbackFromParent12_text("Physical or emotional health interferes with social activities a little of the time");
if (value === 0)
this.props.callbackFromParent12_text("Physical or emotional health interferes with social activities none of the time");
this.setState({clicked: value});
}
render() {
return (
<div>
<div class="hidden-xs">
<h5><b>12. During the <u>past week</u>, how much of the time has your <u>physical health or emotional problems</u> interfered with your social activities (like visiting with friends, relatives, etc.)?</b></h5>
<ToggleButtonGroup justified type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={-0.33682}>{(this.state.clicked === -0.33682) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} All of the time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-0.94342}>{(this.state.clicked === -0.94342) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Most of the time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-0.18043}>{(this.state.clicked === -0.18043) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Some of the time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0.11038}>{(this.state.clicked === 0.11038) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little of the time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} None of the time</ToggleButton>
</ToggleButtonGroup>
</div>
<div class="visible-xs">
<h5><b>12. During the <u>past week</u>, how much of the time has your <u>physical health or emotional problems</u> interfered with your social activities (like visiting with friends, relatives, etc.)?</b></h5>
<ToggleButtonGroup vertical block type="radio" name="options" value = {this.state.clicked} onChange = {(value) => this.someFn(value)} defaultValue={0}>
<ToggleButton bsStyle='btn-custom' value={-0.33682}>{(this.state.clicked === -0.33682) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} All of the time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-0.94342}>{(this.state.clicked === -0.94342) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Most of the time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={-0.18043}>{(this.state.clicked === -0.18043) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} Some of the time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0.11038}>{(this.state.clicked === 0.11038) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} A little of the time</ToggleButton>
<ToggleButton bsStyle='btn-custom' value={0}>{(this.state.clicked === 0) ? <Glyphicon glyph='check' /> : <Glyphicon glyph='unchecked' />} None of the time</ToggleButton>
</ToggleButtonGroup>
</div>
</div>
);
}
}
    
    


  


class SF12_Link extends PureComponent {
    
  constructor() {
    super();      
    this.state = {
      currentItem: '', //this is a relic of the tutorial
      username: '', //this is a relic of the tutorial
      user_id: '', //this is the name of the patinet submitting the survey
      clinician_to_share_with: '', //the email that users enter in order to share their score with a clinician
      scores: [], //this displays the scores saved for each clinician
      items: [], //this is a relic of the tutorial   
      user: null, //this is the email address or lack of email address for the clincian who is logged in 
      patient_filter: '', //this allows clinicians to sort for results from a single individual        
      patient_email_to_send_score: '', //this is the variable where clinicians enter the email of a patient they want to send a score to

        
        
        
        
    //Calculator variables    
        selectedValue1: 0,
        selectedValue1_text: 'In general, health is excellent',

        selectedValue2: 0,
        selectedValue2_text: 'Not limited in moderate activities',

        selectedValue3: 0,
        selectedValue3_text: 'Not limited in moderate activities',

        selectedValue4: 0,
        selectedValue4_text: 'Accomplished as much work as would like (unaffected by physical health)',

        selectedValue5: 0,
        selectedValue5_text: 'Not limited in kind of work or other activities in last week (unaffected by physical health)',

        selectedValue6: 0,
        selectedValue6_text: 'Accomplished as much work as would like (unaffected by emotional problems)',

        selectedValue7: 0,
        selectedValue7_text: 'Works as carefully as usual (unaffected by emotional problems)',

        selectedValue8: 0,
        selectedValue8_text: 'No interference of pain with normal work',

        selectedValue9: 0,
        selectedValue9_text: 'Felt calm and peaceful all of the time',

        selectedValue10: 0,
        selectedValue10_text: 'Had a lot of energy all of the time',

        selectedValue11: 0,
        selectedValue11_text: 'Felt downhearted and blue none of the time',

        selectedValue12: 0,
        selectedValue12_text: 'Physical or emotional health interferes with social activities none of the time',
        
        point1 : { '0.00000':0, '-1.31872':-0.06064, '-3.02396':0.03482, '-5.56461':-0.16891, '-8.37399':-1.71175},
        point2 : { '0.00000':0, '-3.45555':1.86840, '-7.23216':3.93115},
        point3 : { '0.00000':0, '-2.73557':1.43103, '-6.24397':2.68282},
        point4 : { '0.00000':0, '-4.61617':1.44060},
        point5 : { '0.00000':0, '-5.51747':1.66968},
        point6 : { '0.00000':0, '3.04365':-6.82672},
        point7 : { '0.00000':0, '2.32091':-5.69921},
        point8 : { '0.00000':0, '-3.80130':0.90384, '-6.50522':1.49384, '-8.38063':1.76691, '-11.25544':1.48619},
        point9 : { '0.00000':0, '0.66514':-1.94949, '1.36689':-4.09842, '2.37241':-6.31121, '2.90426':-7.92717, '3.46638':-10.19085},
        point10 : { '0.00000':0, '-0.42251':-0.92057, '-1.14387':-1.65178, '-1.61850':-3.29805, '-2.02168':-4.88962, '-2.44706':-6.02409},
        point11 : { '0.00000':0, '0.41188':-1.95934, '1.28044':-4.59055, '2.34247':-8.09914, '3.41593':-10.77911, '4.61446':-16.15395},
        point12 : { '0.00000':0, '0.11038':-3.13896, '-0.18043':-5.63286, '-0.94342':-8.26066, '-0.33682':-6.29724},             
        
        
    }
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.handleSubmitSendInvite = this.handleSubmitSendInvite.bind(this);      
  this.login = this.login.bind(this); 
  this.logout = this.logout.bind(this);
     
     
      
  }
    
      

    
componentDidMount() {



    
if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){    
          

  const SF12ScoresRef = firebase.firestore();
  SF12ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF12ScoresRef.collection("sf12_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  })
              
        
}  else {
    
  const SF12ScoresRef = firebase.firestore();
  SF12ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF12ScoresRef.collection("sf12_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
      
    
}
}
    

    
    
  auth.onAuthStateChanged((user) => {
    if (user) {
      this.setState({ user });
    }
      

if (this.state.user != null){
    if(this.state.user.email === "orthotoolkit@gmail.com" | this.state.user.email === "neurologytoolkit@gmail.com"){ 
        
  const SF12ScoresRef = firebase.firestore();
  SF12ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF12ScoresRef.collection("sf12_scores").get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
                       
        
} else {
    

    
  const SF12ScoresRef = firebase.firestore();
  SF12ScoresRef.settings({
      timestampsInSnapshots: true
  });        
        
  SF12ScoresRef.collection("sf12_scores").where("shared_with", "==", this.state.user.email).get().then(querySnapshot =>{
      
      
      const data = querySnapshot.docs.map(doc => doc.data());
      
    this.setState({
      scores: data
    });  
      
  }) 
     
    
}
}      

    
      
  });    
    
}    
    
handleChange(e) {
  this.setState({
    [e.target.name]: e.target.value
  });
} 
    
handleSubmit(e) {
  e.preventDefault();
    
    var point1 = { '0.00000':0, '-1.31872':-0.06064, '-3.02396':0.03482, '-5.56461':-0.16891, '-8.37399':-1.71175};
    var point2 = { '0.00000':0, '-3.45555':1.86840, '-7.23216':3.93115};
    var point3 = { '0.00000':0, '-2.73557':1.43103, '-6.24397':2.68282};
    var point4 = { '0.00000':0, '-4.61617':1.44060};
    var point5 = { '0.00000':0, '-5.51747':1.66968};
    var point6 = { '0.00000':0, '3.04365':-6.82672};
    var point7 = { '0.00000':0, '2.32091':-5.69921};
    var point8 = { '0.00000':0, '-3.80130':0.90384, '-6.50522':1.49384, '-8.38063':1.76691, '-11.25544':1.48619};
    var point9 = { '0.00000':0, '0.66514':-1.94949, '1.36689':-4.09842, '2.37241':-6.31121, '2.90426':-7.92717, '3.46638':-10.19085};
    var point10 = { '0.00000':0, '-0.42251':-0.92057, '-1.14387':-1.65178, '-1.61850':-3.29805, '-2.02168':-4.88962, '-2.44706':-6.02409};
    var point11 = { '0.00000':0, '0.41188':-1.95934, '1.28044':-4.59055, '2.34247':-8.09914, '3.41593':-10.77911, '4.61446':-16.15395};
    var point12 = { '0.00000':0, '0.11038':-3.13896, '-0.18043':-5.63286, '-0.94342':-8.26066, '-0.33682':-6.29724};       
    
    
  const SF12ScoresRef = firebase.firestore();
  SF12ScoresRef.settings({
      timestampsInSnapshots: true
  });  
if (this.props.requestSurveyID.slice(0,4) !== 'zzzz'){        
  const sf12_score = SF12ScoresRef.collection("sf12_scores").doc(this.props.requestSurveyID).set({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    score_name: 'SF12',
    Question_01_SF12: this.state.selectedValue1,
    Question_02_SF12: this.state.selectedValue2,
    Question_03_SF12: this.state.selectedValue3,
    Question_04_SF12: this.state.selectedValue4,
    Question_05_SF12: this.state.selectedValue5,
    Question_06_SF12: this.state.selectedValue6,
    Question_07_SF12: this.state.selectedValue7,            
    Question_08_SF12: this.state.selectedValue8,
    Question_09_SF12: this.state.selectedValue9,
    Question_10_SF12: this.state.selectedValue10,
    Question_11_SF12: this.state.selectedValue11,
    Question_12_SF12: this.state.selectedValue12,
    Physical_Score_SF12: ((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+56.57706).toFixed(5)), 
    Mental_Score_SF12: ((this.state.point1[String(this.state.selectedValue1.toFixed(5))]+this.state.point2[String(this.state.selectedValue2.toFixed(5))]+this.state.point3[String(this.state.selectedValue3.toFixed(5))]+this.state.point4[String(this.state.selectedValue4.toFixed(5))]+this.state.point5[String(this.state.selectedValue5.toFixed(5))]+this.state.point6[String(this.state.selectedValue6.toFixed(5))]+this.state.point7[String(this.state.selectedValue7.toFixed(5))]+this.state.point8[String(this.state.selectedValue8.toFixed(5))]+this.state.point9[String(this.state.selectedValue9.toFixed(5))]+this.state.point10[String(this.state.selectedValue10.toFixed(5))]+this.state.point11[String(this.state.selectedValue11.toFixed(5))]+this.state.point12[String(this.state.selectedValue12.toFixed(5))]+60.75781).toFixed(5)),     
  });
}
else {
  const sf12_score = SF12ScoresRef.collection("sf12_scores").add({      
    shared_with: this.props.clinicianSendingEmail,
    user: this.props.submitterName,
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    text_timestamp: this.props.requestSurveyID.substring(4),      
    score_name: 'SF12',
    Question_01_SF12: this.state.selectedValue1,
    Question_02_SF12: this.state.selectedValue2,
    Question_03_SF12: this.state.selectedValue3,
    Question_04_SF12: this.state.selectedValue4,
    Question_05_SF12: this.state.selectedValue5,
    Question_06_SF12: this.state.selectedValue6,
    Question_07_SF12: this.state.selectedValue7,            
    Question_08_SF12: this.state.selectedValue8,
    Question_09_SF12: this.state.selectedValue9,
    Question_10_SF12: this.state.selectedValue10,
    Question_11_SF12: this.state.selectedValue11,
    Question_12_SF12: this.state.selectedValue12,
    Physical_Score_SF12: ((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+56.57706).toFixed(5)), 
    Mental_Score_SF12: ((this.state.point1[String(this.state.selectedValue1.toFixed(5))]+this.state.point2[String(this.state.selectedValue2.toFixed(5))]+this.state.point3[String(this.state.selectedValue3.toFixed(5))]+this.state.point4[String(this.state.selectedValue4.toFixed(5))]+this.state.point5[String(this.state.selectedValue5.toFixed(5))]+this.state.point6[String(this.state.selectedValue6.toFixed(5))]+this.state.point7[String(this.state.selectedValue7.toFixed(5))]+this.state.point8[String(this.state.selectedValue8.toFixed(5))]+this.state.point9[String(this.state.selectedValue9.toFixed(5))]+this.state.point10[String(this.state.selectedValue10.toFixed(5))]+this.state.point11[String(this.state.selectedValue11.toFixed(5))]+this.state.point12[String(this.state.selectedValue12.toFixed(5))]+60.75781).toFixed(5)),       
  });      
}     
  this.setState({
    clinician_to_share_with: '',
    user_id: '',     
  });
    alert('Thank you for using OrthoPowerTools! Your submission was received and will be shared with the following provider: ' + this.props.clinicianSendingEmail + '. You may now close this alert and the entire window.');       
}  
    
    
   
    
    
handleSubmitSendInvite(e) {
  e.preventDefault();  
  const PatientInvites = firebase.firestore();
  PatientInvites.settings({
      timestampsInSnapshots: true
  });
  const patient_invites = PatientInvites.collection("sf12_patient_invites").add({
    patient_email_to_send_score: this.state.patient_email_to_send_score,
    clinician_who_sent: this.state.user.email,  
    timestamp: firebase.firestore.FieldValue.serverTimestamp(),      
  });
  this.setState({
    patient_email_to_send_score: '',    
  });
    alert('Thank you for using OrthoPowerTools! A message has been sent to the entered email address with a link to complete a survey.');    
    
}      
  
    
    
    
  
login() {
  auth.signInWithPopup(provider) 
    .then((result) => {
      const user = result.user;
      this.setState({
        user
      });
    });
    
}
    
logout() {
  auth.signOut()
    .then(() => {
      this.setState({
        user: null
      });
    });
}
    
  //Calculator Methods
    myCallback1  = (dataFromChild) => {
    this.setState({selectedValue1: dataFromChild});
    }
    myCallback1_text  = (dataFromChild) => {
    this.setState({selectedValue1_text: dataFromChild});
    }
    myCallback2  = (dataFromChild) => {
    this.setState({selectedValue2: dataFromChild});
    }
    myCallback2_text  = (dataFromChild) => {
    this.setState({selectedValue2_text: dataFromChild});
    }
    myCallback3  = (dataFromChild) => {
    this.setState({selectedValue3: dataFromChild});
    }
    myCallback3_text  = (dataFromChild) => {
    this.setState({selectedValue3_text: dataFromChild});
    }
    myCallback4  = (dataFromChild) => {
    this.setState({selectedValue4: dataFromChild});
    }
    myCallback4_text  = (dataFromChild) => {
    this.setState({selectedValue4_text: dataFromChild});
    }
    myCallback5  = (dataFromChild) => {
    this.setState({selectedValue5: dataFromChild});
    }
    myCallback5_text  = (dataFromChild) => {
    this.setState({selectedValue5_text: dataFromChild});
    }
    myCallback6  = (dataFromChild) => {
    this.setState({selectedValue6: dataFromChild});
    }
    myCallback6_text  = (dataFromChild) => {
    this.setState({selectedValue6_text: dataFromChild});
    }
    myCallback7  = (dataFromChild) => {
    this.setState({selectedValue7: dataFromChild});
    }
    myCallback7_text  = (dataFromChild) => {
    this.setState({selectedValue7_text: dataFromChild});
    }
    myCallback8  = (dataFromChild) => {
    this.setState({selectedValue8: dataFromChild});
    }
    myCallback8_text  = (dataFromChild) => {
    this.setState({selectedValue8_text: dataFromChild});
    }
    myCallback9  = (dataFromChild) => {
    this.setState({selectedValue9: dataFromChild});
    }
    myCallback9_text  = (dataFromChild) => {
    this.setState({selectedValue9_text: dataFromChild});
    }
    myCallback10  = (dataFromChild) => {
    this.setState({selectedValue10: dataFromChild});
    }
    myCallback10_text  = (dataFromChild) => {
    this.setState({selectedValue10_text: dataFromChild});
    }     
    myCallback11  = (dataFromChild) => {
    this.setState({selectedValue11: dataFromChild});
    }
    myCallback11_text  = (dataFromChild) => {
    this.setState({selectedValue11_text: dataFromChild});
    }
    myCallback12  = (dataFromChild) => {
    this.setState({selectedValue12: dataFromChild});
    }
    myCallback12_text  = (dataFromChild) => {
    this.setState({selectedValue12_text: dataFromChild});
    }
  

  
    
    

    
render() { 

    var point1 = { '0.00000':0, '-1.31872':-0.06064, '-3.02396':0.03482, '-5.56461':-0.16891, '-8.37399':-1.71175};
    var point2 = { '0.00000':0, '-3.45555':1.86840, '-7.23216':3.93115};
    var point3 = { '0.00000':0, '-2.73557':1.43103, '-6.24397':2.68282};
    var point4 = { '0.00000':0, '-4.61617':1.44060};
    var point5 = { '0.00000':0, '-5.51747':1.66968};
    var point6 = { '0.00000':0, '3.04365':-6.82672};
    var point7 = { '0.00000':0, '2.32091':-5.69921};
    var point8 = { '0.00000':0, '-3.80130':0.90384, '-6.50522':1.49384, '-8.38063':1.76691, '-11.25544':1.48619};
    var point9 = { '0.00000':0, '0.66514':-1.94949, '1.36689':-4.09842, '2.37241':-6.31121, '2.90426':-7.92717, '3.46638':-10.19085};
    var point10 = { '0.00000':0, '-0.42251':-0.92057, '-1.14387':-1.65178, '-1.61850':-3.29805, '-2.02168':-4.88962, '-2.44706':-6.02409};
    var point11 = { '0.00000':0, '0.41188':-1.95934, '1.28044':-4.59055, '2.34247':-8.09914, '3.41593':-10.77911, '4.61446':-16.15395};
    var point12 = { '0.00000':0, '0.11038':-3.13896, '-0.18043':-5.63286, '-0.94342':-8.26066, '-0.33682':-6.29724};     
    
  return (
     
    <div className='app'>
      <header>
        <div className="wrapper">
        <Link to="/"><img src={mainLogo} style={{padding:15,width:200}} alt="orthotoolkit logo"/></Link>      
        </div>
      </header>            
            
            
            <div className="App">
                <h1>SF-12 Calculator</h1>
                <h4>Hello, {this.props.submitterName}, your clinician, {this.props.clinicianSendingEmail}, has sent you this survey to help further understand your health. Please click the appropriate buttons below and then click "Submit Score". Please note that your <u>digital privacy is taken very seriously</u> and a number of technologies have been implemented to protect the privacy and integrity of your data, including the use of SSL encryption, HIPAA compliant storage, and state-of-the-art authentication services. Your data <u>will never be shared with anyone other than the assigned clinician</u>.</h4>

            <form onSubmit={this.handleSubmit}>

        <br /><Well bsSize="small">PCS-12 (Physical Score): <b>{(this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+56.57706).toFixed(5)}</b> (difference from USA average: <b>{(this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+6.57706).toFixed(5)}</b>)
        <br />MCS-12 (Mental Score): <b>{(point1[String(this.state.selectedValue1.toFixed(5))]+point2[String(this.state.selectedValue2.toFixed(5))]+point3[String(this.state.selectedValue3.toFixed(5))]+point4[String(this.state.selectedValue4.toFixed(5))]+point5[String(this.state.selectedValue5.toFixed(5))]+point6[String(this.state.selectedValue6.toFixed(5))]+point7[String(this.state.selectedValue7.toFixed(5))]+point8[String(this.state.selectedValue8.toFixed(5))]+point9[String(this.state.selectedValue9.toFixed(5))]+point10[String(this.state.selectedValue10.toFixed(5))]+point11[String(this.state.selectedValue11.toFixed(5))]+point12[String(this.state.selectedValue12.toFixed(5))]+60.75781).toFixed(5)}</b> (difference from USA average: <b>{(point1[String(this.state.selectedValue1.toFixed(5))]+point2[String(this.state.selectedValue2.toFixed(5))]+point3[String(this.state.selectedValue3.toFixed(5))]+point4[String(this.state.selectedValue4.toFixed(5))]+point5[String(this.state.selectedValue5.toFixed(5))]+point6[String(this.state.selectedValue6.toFixed(5))]+point7[String(this.state.selectedValue7.toFixed(5))]+point8[String(this.state.selectedValue8.toFixed(5))]+point9[String(this.state.selectedValue9.toFixed(5))]+point10[String(this.state.selectedValue10.toFixed(5))]+point11[String(this.state.selectedValue11.toFixed(5))]+point12[String(this.state.selectedValue12.toFixed(5))]+10.75781).toFixed(5)}</b>)
        </Well>
        Graphical PCS-12 (Physical Score):
        <ProgressBar>
        <ProgressBar active style={{"background-color":"black","opacity":"0.5"}} now={this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+56.57706}/>
        <ProgressBar active style={{"background-color":"black","opacity":"0.5"}} now={100-(100*((this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12+56.57706)/(1
        )))}/>
        </ProgressBar>
        Graphical PCS-12 difference from United States average (avg=50)
        <ProgressBar>
        <ProgressBar active style={{"background-color":"black","opacity":"0.0"}} now={(50+Math.min(0,6.57706+this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12)).toFixed(5)} key={1}/>
        <ProgressBar active bsStyle="danger" now={-Math.min(0,6.57706+this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12).toFixed(5)} key={2}/>
        <ProgressBar active now={Math.max(0,6.57706+this.state.selectedValue1+this.state.selectedValue2+this.state.selectedValue3+this.state.selectedValue4+this.state.selectedValue5+this.state.selectedValue6+this.state.selectedValue7+this.state.selectedValue8+this.state.selectedValue9+this.state.selectedValue10+this.state.selectedValue11+this.state.selectedValue12).toFixed(5)} key={2}/>
        </ProgressBar>
        Graphical MCS-12 (Mental Score):
        <ProgressBar>
        <ProgressBar active style={{"background-color":"black","opacity":"0.5"}} now={point1[String(this.state.selectedValue1.toFixed(5))]+point2[String(this.state.selectedValue2.toFixed(5))]+point3[String(this.state.selectedValue3.toFixed(5))]+point4[String(this.state.selectedValue4.toFixed(5))]+point5[String(this.state.selectedValue5.toFixed(5))]+point6[String(this.state.selectedValue6.toFixed(5))]+point7[String(this.state.selectedValue7.toFixed(5))]+point8[String(this.state.selectedValue8.toFixed(5))]+point9[String(this.state.selectedValue9.toFixed(5))]+point10[String(this.state.selectedValue10.toFixed(5))]+point11[String(this.state.selectedValue11.toFixed(5))]+point12[String(this.state.selectedValue12.toFixed(5))]+60.75781}/>
        <ProgressBar active style={{"background-color":"black","opacity":"0.5"}} now={100-(100*((point1[String(this.state.selectedValue1.toFixed(5))]+point2[String(this.state.selectedValue2.toFixed(5))]+point3[String(this.state.selectedValue3.toFixed(5))]+point4[String(this.state.selectedValue4.toFixed(5))]+point5[String(this.state.selectedValue5.toFixed(5))]+point6[String(this.state.selectedValue6.toFixed(5))]+point7[String(this.state.selectedValue7.toFixed(5))]+point8[String(this.state.selectedValue8.toFixed(5))]+point9[String(this.state.selectedValue9.toFixed(5))]+point10[String(this.state.selectedValue10.toFixed(5))]+point11[String(this.state.selectedValue11.toFixed(5))]+point12[String(this.state.selectedValue12.toFixed(5))]+60.75781)/(1
        )))}/>
        </ProgressBar>
        Graphical MCS-12 difference from United States average (avg=50)
        <ProgressBar>
        <ProgressBar active style={{"background-color":"black","opacity":"0.0"}} now={(50+Math.min(0,10.75781+point1[String(this.state.selectedValue1.toFixed(5))]+point2[String(this.state.selectedValue2.toFixed(5))]+point3[String(this.state.selectedValue3.toFixed(5))]+point4[String(this.state.selectedValue4.toFixed(5))]+point5[String(this.state.selectedValue5.toFixed(5))]+point6[String(this.state.selectedValue6.toFixed(5))]+point7[String(this.state.selectedValue7.toFixed(5))]+point8[String(this.state.selectedValue8.toFixed(5))]+point9[String(this.state.selectedValue9.toFixed(5))]+point10[String(this.state.selectedValue10.toFixed(5))]+point11[String(this.state.selectedValue11.toFixed(5))]+point12[String(this.state.selectedValue12.toFixed(5))])).toFixed(5)} key={1}/>
        <ProgressBar active bsStyle="danger" now={-Math.min(0,10.75781+point1[String(this.state.selectedValue1.toFixed(5))]+point2[String(this.state.selectedValue2.toFixed(5))]+point3[String(this.state.selectedValue3.toFixed(5))]+point4[String(this.state.selectedValue4.toFixed(5))]+point5[String(this.state.selectedValue5.toFixed(5))]+point6[String(this.state.selectedValue6.toFixed(5))]+point7[String(this.state.selectedValue7.toFixed(5))]+point8[String(this.state.selectedValue8.toFixed(5))]+point9[String(this.state.selectedValue9.toFixed(5))]+point10[String(this.state.selectedValue10.toFixed(5))]+point11[String(this.state.selectedValue11.toFixed(5))]+point12[String(this.state.selectedValue12.toFixed(5))]).toFixed(5)} key={2}/>
        <ProgressBar active now={Math.max(0,10.75781+point1[String(this.state.selectedValue1.toFixed(5))]+point2[String(this.state.selectedValue2.toFixed(5))]+point3[String(this.state.selectedValue3.toFixed(5))]+point4[String(this.state.selectedValue4.toFixed(5))]+point5[String(this.state.selectedValue5.toFixed(5))]+point6[String(this.state.selectedValue6.toFixed(5))]+point7[String(this.state.selectedValue7.toFixed(5))]+point8[String(this.state.selectedValue8.toFixed(5))]+point9[String(this.state.selectedValue9.toFixed(5))]+point10[String(this.state.selectedValue10.toFixed(5))]+point11[String(this.state.selectedValue11.toFixed(5))]+point12[String(this.state.selectedValue12.toFixed(5))]).toFixed(5)} key={2}/>
        </ProgressBar>
                                                                                    

       

        <br/>            
            
        <Buttons1 callbackFromParent1={this.myCallback1} callbackFromParent1_text={this.myCallback1_text}/>
        <h4>The following questions are about activities you might do during a typical day. Does <u>your health now limit you</u> in these activities? If so, how much?
        </h4>
        <Buttons2 callbackFromParent2={this.myCallback2} callbackFromParent2_text={this.myCallback2_text}/>
        <Buttons3 callbackFromParent3={this.myCallback3} callbackFromParent3_text={this.myCallback3_text}/>
        <h4>During the <u>past week</u>, have you had any of the following problems with your work or other regular daily activities <u>as a result of your physical health</u>?
        </h4>
        <Buttons4 callbackFromParent4={this.myCallback4} callbackFromParent4_text={this.myCallback4_text}/>
        <Buttons5 callbackFromParent5={this.myCallback5} callbackFromParent5_text={this.myCallback5_text}/>
        <h4>During the <u>past week</u>, have you had any of the following problems with your work or other regular daily activities <u>as a result of any emotional problems</u> (such as feeling depressed or anxious)?
        </h4>
        <Buttons6 callbackFromParent6={this.myCallback6} callbackFromParent6_text={this.myCallback6_text}/>
        <Buttons7 callbackFromParent7={this.myCallback7} callbackFromParent7_text={this.myCallback7_text}/>
        <Buttons8 callbackFromParent8={this.myCallback8} callbackFromParent8_text={this.myCallback8_text}/>
        <h4>These questions are about how you feel and how things have been with you <u>during the past week</u>. For each question, please give the one answer that comes closest to the way you have been feeling. How much of the time during the <u>past week</u> -
        </h4>
        <Buttons9 callbackFromParent9={this.myCallback9} callbackFromParent9_text={this.myCallback9_text}/>
        <Buttons10 callbackFromParent10={this.myCallback10} callbackFromParent10_text={this.myCallback10_text}/>
        <Buttons11 callbackFromParent11={this.myCallback11} callbackFromParent11_text={this.myCallback11_text}/>
        <Buttons12 callbackFromParent12={this.myCallback12} callbackFromParent12_text={this.myCallback12_text}/>

        <br />
                    
        <button style = {{width: '100%'}}>Submit Score</button>                    
                    
            </form>    

            </div>     

    </div>
            
      
      
  );
}
}
    
export default withRouter(SF12_Link);

